import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Card,
  Image,
} from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Select from "react-select";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import ImageUploader from "react-images-upload";
import logo from "../../../Images/logo.png";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EmailIcon from "@mui/icons-material/Email";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
// import NumbersIcon from '@mui/icons-material/Numbers';
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import LanguageIcon from "@mui/icons-material/Language";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { addPackages } from "./Packages.action";
// import { addHall } from "./Hall.action";
// import { getRestaurantData } from "../AddResturant/Resturant.action";
// import { setHeading } from "../../../accounts/account.actions";
// import { getUserRes } from "../../AddUser/user.actions";
const AddPackages = (props) => {
  const [name, setName] = useState("");
  const [weight, setWeight] = useState();
  const [height, setHeight] = useState();
  const [length, setLength] = useState();
  const [width, setWidth] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function savePackage() {
    props.addPackages(
      {
        name: name,
        weight: weight,
        length: length,
        width: width,
        height: height,
      },
      handleVisible,
      handleDangerVisible
    );
  }

  const [nameError, setNameError] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (e.target.value.length > 3) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  useEffect(() => {
    // props.setHeading("Add Hall");
    // props.getRole()

    document.title = props.title;
  }, []);
  return (
    <>
      {props.isAddingSender ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main" style={{ height: "88vh" }}>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="outlined-basic"
                  required
                  error={nameError}
                  value={name}
                  helperText={
                    nameError
                      ? "Please Enter Your Package Name (Atleast 3 Character)"
                      : ""
                  }
                  label="Package Name"
                  variant="outlined"
                  onChange={(e) => handleNameChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="outlined-basic"
                  required
                  label="Weight"
                  variant="outlined"
                  onChange={(e) => setWeight(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="outlined-basic"
                  required
                  label="Length"
                  variant="outlined"
                  onChange={(e) => setLength(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="outlined-basic"
                  required
                  label="Width"
                  variant="outlined"
                  onChange={(e) => setWidth(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="outlined-basic"
                  required
                  label="Height"
                  variant="outlined"
                  onChange={(e) => setHeight(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: "15px" }}>
              <Button
                disabled={
                  isEmpty(name) ||
                  isEmpty(length) ||
                  isEmpty(width) ||
                  isEmpty(height) ||
                  isEmpty(weight)
                }
                style={{
                  marginLeft: "2%",
                  color: "white",
                  width: "20%",
                  backgroundColor: "primary",
                  border: "1px solid white",
                }}
                onClick={savePackage}
              >
                Save
              </Button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPackages: (data, handleVisible, handleDangerVisible) =>
      dispatch(addPackages(data, handleVisible, handleDangerVisible)),
    // getRestaurantData: () => dispatch(getRestaurantData()),
    // setHeading: (data) => dispatch(setHeading(data)),
    // getUser: () => dispatch(getUserRes()),
    // imageFront: (data) => dispatch(addImage(data)),
    // imageBack: (data) => dispatch(addImageBack(data)),
    // companyLogo: (data) => dispatch(addLogo(data)),
    // getRole: () => dispatch(getRole()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  packages: state.CargoPackagesReducer.packages,
  isAddingPackages: state.CargoPackagesReducer.isAddingPackages,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPackages);
