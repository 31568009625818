import React, { useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import { getCountry } from "../../country/Country.action";
import { getSender } from "../../sender/Sender.action";
import Step1SelectSender from "../createShipment/Step1SelectSender";
import Step2SelectReceiver from "../createShipment/Step2SelectReceiver";
import {
  backSubSteps,
  getCargoService,
  getPayList,
  getServiceType,
  getShipmentPurpose,
  getUom,
  nextSubSteps,
  setAddSenderData,
  setShowReceiver,
  setShowShipmentDetail,
} from "../createShipment/CreateShipment.action";
import { backSteps, nextSteps, startSteps } from "../Shipment.action";
import Step3ShipmentDetail from "../createShipment/Step3ShipmentDetail";
import { getCurrency } from "../../currency/Currency.action";
import { getReceiver } from "../../receiver/Receiver.action";

const EditShipmentMain = (props) => {
  useEffect(() => {
    props.getSender();
    props.getReceiver();
    props.getCountry();
    props.getShipmentPurpose();
    props.getCurrency();
    props.getPayList();
    props.getServiceType();
    props.getUom();
    // props.getCargoService()
    props.setShowReceiver(false);
    props.setShowShipmentDetail(false);
  }, []);
  return (
    <>
      {props.isFetchingSender ||
      props.isFetchingUomList ||
      props.isFetchingPayList ||
      props.isFetchingServiceTypeList ||
      props.isFetchingCargoServiceList ||
      props.isFetchingReceiver ||
      props.isFetchingShipmentPurposeList ||
      props.isFetchingCurrency ||
      props.isFetchingCountry ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          {/* <div className="main" > */}
          <Card style={{ backgroundColor: "#f1f1f1", border: "none" }}>
            <Step1SelectSender />
            {props.showReceiver === true ? <Step2SelectReceiver /> : ""}
            {props.showShipmentDetail === true ? <Step3ShipmentDetail /> : ""}
          </Card>
          {/* </div> */}
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleNextStep: () => dispatch(nextSubSteps()),
    handleBackStep: () => dispatch(backSubSteps()),

    setSenderData: (data) => dispatch(setAddSenderData(data)),
    handleNextStepMain: () => dispatch(nextSteps()),
    handleBackStepMain: () => dispatch(backSteps()),
    startSteps: () => dispatch(startSteps()),

    getCountry: () => dispatch(getCountry()),
    getUom: () => dispatch(getUom()),
    getReceiver: () => dispatch(getReceiver()),
    getSender: () => dispatch(getSender()),
    getShipmentPurpose: () => dispatch(getShipmentPurpose()),
    getCurrency: () => dispatch(getCurrency()),
    getPayList: () => dispatch(getPayList()),
    getServiceType: () => dispatch(getServiceType()),
    getCargoService: () => dispatch(getCargoService()),
    setShowReceiver: (data) => dispatch(setShowReceiver(data)),
    setShowShipmentDetail: (data) => dispatch(setShowShipmentDetail(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  senderData: state.CargoCreateShipmentReducer.senderData,
  subStepsCreateShipment:
    state.CargoCreateShipmentReducer.subStepsCreateShipment,
  activeSubSteps: state.CargoCreateShipmentReducer.activeSubSteps,
  showReceiver: state.CargoCreateShipmentReducer.showReceiver,
  showShipmentDetail: state.CargoCreateShipmentReducer.showShipmentDetail,
  showPackaging: state.CargoCreateShipmentReducer.showPackaging,

  senderList: state.CargoSenderReducer.senderList,
  isFetchingSender: state.CargoSenderReducer.isFetchingSender,
  receiverList: state.CargoReceiverReducer.receiverList,
  isFetchingReceiver: state.CargoReceiverReducer.isFetchingReceiver,
  shipmentPurposeList: state.CargoCreateShipmentReducer.shipmentPurposeList,
  isFetchingShipmentPurposeList:
    state.CargoCreateShipmentReducer.isFetchingShipmentPurposeList,
  payList: state.CargoCreateShipmentReducer.payList,
  isFetchingPayList: state.CargoCreateShipmentReducer.isFetchingPayList,
  isFetchingCargoServiceList:
    state.CargoCreateShipmentReducer.isFetchingCargoServiceList,
  isFetchingServiceTypeList:
    state.CargoCreateShipmentReducer.isFetchingServiceTypeList,
  currencyList: state.CargoCurrencyReducer.currencyList,
  isFetchingCurrency: state.CargoCurrencyReducer.isFetchingCurrency,
  countryList: state.CargoCountryReducer.countryList,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  isFetchingUomList: state.CargoCreateShipmentReducer.isFetchingUomList,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditShipmentMain);
