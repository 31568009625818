import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import {
  getLevel5LedgerData,
  searchAccountLedger,
  searchAccountLedgerReport,
} from "./Ledger.action";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import "./style.css";
import { isEmpty, get } from "lodash";
import Select from "react-select";
import { InputAdornment } from "@mui/material";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
// import { startFinancialDate } from "../../../../global/globalFunctions";
import moment from "moment";

const AccountLedger = (props) => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [item, setItem] = useState("");
  const [itemName, setItemName] = useState("");
  const userType = window.localStorage.getItem("userType");

  const getLevel5LedgerData1 = props.level5LedgerData.map((x) => {
    let data = {
      value: x.levelFiveId,
      label: x.allLevelKey + " / " + x.levelFiveName,
    };
    return data;
  });
  const handleChangeLevel5Ledger = (selectedOption) => {
    setItem(selectedOption.value);
    setItemName(selectedOption.label);
  };

  const exportPDF = () => {
    props.searchAccountLedgerReport(
      {
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        levelFiveId: item,
        name: itemName,
      },
      startDate,
      endDate
    );
    setStartDate("");
    setEndDate("");
    setItem("");
    setItemName("");
  };

  useEffect(() => {
    props.getLevel5LedgerData();
  }, []);

  function searchData() {
    props.searchAccountLedger({
      startedDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
      levelFiveId: item,
      name: itemName,
    });
    // return (
    //   <Redirect
    //     to={{ pathName: "/user/Ledger-List", state: { name: itemName } }}
    //   />
    // );
  }

  return (
    <>
      {props.isFetchingLevel5LedgerData ? (
        <div className="loader-div">
          <Button variant="secondary" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="secondary"
            />
            {props.isFetchingLevel5LedgerData ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Row style={{ marginTop: "20px" }}>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="date"
                  id="standard-basic"
                  variant="standard"
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="date"
                  id="standard-basic"
                  variant="standard"
                  label="End Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                {userType == 4 ||
                  (userType == 1 && (
                    <Col
                      xl="6"
                      lg="6"
                      md="6"
                      sm="6"
                      xs="12"
                      style={{ zIndex: 8 }}
                    >
                      <Form.Group>
                        <p
                          style={{
                            fontSize: "12px",
                            fontFamily: "Public Sans,sans-serif",
                            marginBottom: "-2px",
                            color: "#00000061",
                          }}
                        >
                          SELECT ACCOUNT*
                        </p>

                        <Select
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: "none",
                              borderBottom: "1px solid black",
                              borderRadius: 0,
                              boxShadow: "none",
                              backgroundColor: "white",
                              marginTop: "-1px",
                            }),
                          }}
                          value={{ label: itemName }}
                          placeholder="Select Account..."
                          onChange={handleChangeLevel5Ledger}
                          options={getLevel5LedgerData1}
                        />
                      </Form.Group>
                    </Col>
                  ))}
              </Col>
            </Row>

            {props.isFetchingSearchAccountLedgerReport ? (
              <div className="loader-div">
                <Button variant="secondary" disabled>
                  <Spinner
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="secondary"
                  />
                  {props.isFetchingSearchAccountLedgerReport
                    ? "Loading..."
                    : "Loading..."}
                </Button>
              </div>
            ) : (
              <>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="5" lg="5" md="5" sm="5" xs="5"></Col>
                  <Col
                    xl="2"
                    lg="2"
                    md="2"
                    sm="4"
                    xs="6"
                    style={{ marginLeft: "4%" }}
                  >
                    <Link
                      to={{
                        pathname: "/cargo/ledger/ledger_table",
                        state: { start: startDate, end: endDate },
                      }}
                    >
                      <Button
                        onClick={(e) => searchData()}
                        disabled={
                          userType == 4 || (userType == 1 && item == "")
                        }
                        style={{
                          marginLeft: "2%",
                          color: "black",
                          width: "70%",
                          backgroundColor: "#C5CBCF",
                          border: "1px solid white",
                        }}
                      >
                        Search
                      </Button>
                    </Link>
                  </Col>
                  <Col xl="5" lg="5" md="5" sm="5" xs="5"></Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="5" lg="5" md="5" sm="5" xs="5"></Col>
                  <Col xl="2" lg="2" md="2" sm="4" xs="6">
                    <Button
                      onClick={() => exportPDF()}
                      disabled={item == ""}
                      style={{
                        marginLeft: "25%",
                        color: "black",
                        width: "80%",
                        backgroundColor: "#C5CBCF",
                        border: "1px solid white",
                      }}
                    >
                      Generate PDF
                    </Button>
                  </Col>
                  <Col xl="5" lg="5" md="5" sm="5" xs="5"></Col>
                </Row>
              </>
              // <Row style={{marginTop:'15px'}}>
              //   <Col lg="12" sm="12" className="text-center">
              //     {item == "" ? (
              //       <Button
              //         disabled
              //         style={{ backgroundColor: "black" }}
              //         className="sendButton"
              //       >
              //         Search
              //       </Button>
              //     ) : (
              //       <Link
              //         to={{
              //           pathname: "/cargo/search-ledger",
              //           state: { start: startDate, end: endDate },
              //         }}
              //       >
              //         <Button
              //           className="sendButton"
              //           onClick={(e) => searchData()}
              //         >
              //           Search
              //         </Button>
              //       </Link>
              //     )}
              //   </Col>

              //   <div className="sendDiv">
              //     {item == "" ? (
              //       <Button
              //         disabled
              //         style={{ backgroundColor: "black" }}
              //         className="sendButton"
              //       >
              //         Generate Ledger Report
              //       </Button>
              //     ) : (
              //       <Button className="sendButton" onClick={() => exportPDF()}>
              //         Generate Ledger Report
              //       </Button>
              //     )}
              //   </div>
              // </Row>
            )}
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel5LedgerData: () => dispatch(getLevel5LedgerData()),
    searchAccountLedger: (data) => dispatch(searchAccountLedger(data)),
    searchAccountLedgerReport: (data, startDate, endDate) =>
      dispatch(searchAccountLedgerReport(data, startDate, endDate)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
  isFetchingLevel5LedgerData:
    state.AccountLegderReducer.isFetchingLevel5LedgerData,
  isFetchingSearchAccountLedger:
    state.AccountLegderReducer.isFetchingSearchAccountLedger,
  isFetchingSearchAccountLedgerReport:
    state.AccountLegderReducer.isFetchingSearchAccountLedgerReport,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountLedger);
