import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { getServiceType } from "../shipment/createShipment/CreateShipment.action";
import Typography from "@mui/material/Typography";
import {
  getPackageWeight,
  getCalculateRates,
  setShowTable,
} from "./Rates.action";

import { ThemeProvider, createTheme } from "@mui/material";
import { getCountry } from "../country/Country.action";

const Rates = (props) => {
  const [weight, setWeight] = useState("");
  const [countryId, setCountryId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const defaultMaterialTheme = createTheme();

  function calculateRates() {
    props.setShowTable(true);
    props.getCalculateRates({
      weight: weight,
      serviceId: serviceId,
      country_id: countryId,
    });
    setWeight("");
    setCountryId("");
    setServiceId("");
  }
  const getCountryList =
    !isEmpty(props.countryList) &&
    props.countryList.map((x) => {
      let data = { value: x.country_id, label: x.name };
      return data;
    });
  const handleChangeCountry = (e) => {
    setCountryId(e.target.value);
  };
  const handleChangeServiceType = (e) => {
    setServiceId(e.target.value);
  };
  const handleChangeWeight = (e) => {
    setWeight(e.target.value);
  };

  useEffect(() => {
    props.getPackageWeight();
    props.getServiceType();
    props.getCountry();
    props.setShowTable(false);
    document.title = props.title;
  }, []);
  return (
    <>
      <Container fluid>
        <div
          className="main"
          style={{ backgroundColor: "#F9FAFB", height: "100vh" }}
        >
          <Card
            style={{
              padding: "3%",
              height: "94vh",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              borer: "none",
            }}
          >
            <Row>
              <Col lg="12">
                {props.isFetchingCountry ||
                props.isFetchingPackageWeightList ||
                props.isFetchingServiceTypeList ? (
                  <div className="loader-div">
                    <Spinner
                      variant="secondary"
                      animation="grow"
                      size="xl"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <>
                    <div className="main">
                      <Row
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <Typography
                            style={{
                              fontFamily: "Public Sans,sans-serif",
                              fontSize: "40px",
                            }}
                          >
                            <b>CALCULATE RATES</b>
                          </Typography>
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ marginTop: "15px" }}>
                        <Col xl="4" lg="4" md="4" sm="4" xs="12">
                          <FormControl variant="standard" fullWidth>
                            <InputLabel
                              id="demo-simple-select-standard-label"
                              required
                            >
                              Select Service
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={serviceId}
                              label="Select Service"
                              onChange={(e) => handleChangeServiceType(e)}
                            >
                              {props.serviceTypeList.map((name) => (
                                <MenuItem
                                  key={name.service_type_id}
                                  value={name.service_type_id}
                                >
                                  {name.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col xl="4" lg="4" md="4" sm="4" xs="12">
                          <FormControl variant="standard" fullWidth>
                            <InputLabel
                              id="demo-simple-select-standard-label"
                              required
                            >
                              Weight
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={weight}
                              label="Weight"
                              onChange={(e) => handleChangeWeight(e)}
                            >
                              {props.packageWeightList.map((name) => (
                                <MenuItem key={name.id} value={name.id}>
                                  {name.weight}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col xl="4" lg="4" md="4" sm="4" xs="12">
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">
                              Select Country
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={countryId}
                              label="Select Country"
                              onChange={(e) => handleChangeCountry(e)}
                            >
                              {props.countryList.map((name) => (
                                <MenuItem
                                  key={name.country_id}
                                  value={name.country_id}
                                >
                                  {name.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>

                      <div className="sendDiv" style={{ marginTop: "30px" }}>
                        <Button
                          disabled={serviceId == "" || weight == ""}
                          style={{
                            marginLeft: "2%",
                            color: "black",
                            // width: "20%",
                            backgroundColor: "#C5CBCF",
                            border: "1px solid white",
                          }}
                          onClick={calculateRates}
                        >
                          Calculate Rate
                        </Button>
                      </div>
                      <br />
                    </div>
                    {props.isFetchingCalculateRateList ? (
                      <div className="loader-div">
                        <Spinner
                          variant="secondary"
                          animation="grow"
                          size="xl"
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : (
                      <div>
                        <ThemeProvider theme={defaultMaterialTheme}>
                          <MaterialTable
                            title="Rate Details"
                            style={{
                              display: props.isShowTable == true ? "" : "none",
                            }}
                            columns={[
                              {
                                title: "Rate ID",
                                field: "",
                                editable: "never",
                              },
                              { title: "Service Type", field: "serviceId" },
                              { title: "Weight", field: "weight" },
                              { title: "Country Name", field: "country_id" },
                            ]}
                            data={props.calculateRateList}
                            options={{
                              actionsColumnIndex: -1,
                              exportButton: true,
                              exportAllData: true,
                              paging: true,
                              pageSize: 50, // make initial page size
                              emptyRowsWhenPaging: false, // To avoid of having empty rows
                              pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                              maxBodyHeight: "600px",
                              headerStyle: {
                                position: "sticky",
                                top: 0,
                                color: "#919EAB",
                                fontFamily: "Public Sans,sans-serif",
                                fontWeight: "550",
                                onRowAdd: "none",
                              },
                            }}
                          />
                        </ThemeProvider>
                      </div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageWeight: (data) => dispatch(getPackageWeight(data)),
    getCalculateRates: (data) => dispatch(getCalculateRates(data)),
    getServiceType: () => dispatch(getServiceType()),
    getCountry: () => dispatch(getCountry()),
    setShowTable: (data) => dispatch(setShowTable(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingPackageWeightList:
    state.CargoRateReducer.isFetchingPackageWeightList,
  packageWeightList: state.CargoRateReducer.packageWeightList,
  isShowTable: state.CargoRateReducer.isShowTable,
  calculateRateList: state.CargoRateReducer.calculateRateList,
  isFetchingCalculateRateList:
    state.CargoRateReducer.isFetchingCalculateRateList,
  serviceTypeList: state.CargoCreateShipmentReducer.serviceTypeList,
  isFetchingServiceTypeList:
    state.CargoCreateShipmentReducer.isFetchingServiceTypeList,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  countryList: state.CargoCountryReducer.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(Rates);
