import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import "./style.css";
import Select from 'react-select';
import { GetVoucher, SearchVoucher } from "./Voucher.action";
import { Link } from "react-router-dom";
import { setHeading } from "../../accounts/account.actions";

const VoucherSearch = (props) => {
    console.log(props.voucherList,"iiiiiiiiiiiii")
    const voucherData = !isEmpty(props.voucherList) && props.voucherList.map((x) => { let data = { value: x._id,accType:x.voucherTypeId, label: x.voucherType }; return data });
    const [searchVoucherId, setSearchVoucherId] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        props.GetVoucher();
            props.setHeading("Voucher Search");
    }, []);

    const handleVoucher = (selectedOption) => {
        setSearchVoucherId(selectedOption.accType);
    };

    const searchData = () => {
        //console.log(searchVoucherId, "sv")
        props.SearchVoucher({
            startedDate:isEmpty(startDate)?"":startDate,
            endDate:isEmpty(endDate)?"":endDate,
            voucherTypeId:searchVoucherId
        });
    };

    return (
        <>
            {props.isFetchingVoucherList || props.isFetchingSearchVoucherList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        Loading...
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                    <Row>
              <Col lg="6" md="6" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="6" md="6" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                    value={endDate}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
                        <Row>
{/*Do it from backend*/}
                            <Col lg="12" sm="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Select Voucher *</Form.Label>
                                    <Select className="selectOptionColor"
                                        placeholder="Select Voucher..."
                                        onChange={(e) => handleVoucher(e)}
                                        options={voucherData}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>

                            <Col lg="12" sm="12" className="text-center">
                                {searchVoucherId === "" ?
                                    <Button disabled >Search</Button> :

                                    <Link to="/accounts/voucher-search-detail">
                                        <Button style={{width:"20%"}} onClick={(e) => searchData()} >Search</Button>
                                    </Link>
                                }
                            </Col>
                        </Row>
                    </div>

                </Container>

            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        GetVoucher: () => dispatch(GetVoucher()),
        SearchVoucher: (data) => dispatch(SearchVoucher(data)),
        setHeading:(data)=>dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    voucherList: state.VoucherReducer.voucherList,
    searchVoucherList: state.VoucherReducer.searchVoucherList,
    isFetchingVoucherList: state.VoucherReducer.isFetchingVoucherList,
    isFetchingSearchVoucherList: state.VoucherReducer.isFetchingSearchVoucherList
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoucherSearch);