import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert";
import { isEmpty, filter, find } from "lodash";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import {
  getUsers,
  getPermissions,
  addPermissions,
  getUsersById,
  setUsersByIdChecked,
} from "./UserPermissions.actions";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
// import { setHeading } from "../../../accounts/account.actions";
import { setHeading } from "./UserPermissions.actions";
const UserPermissions = (props) => {
  const userData = props.usersList;

  const [show, setShow] = useState(false);

  const [users, setUsers] = useState("");
  const [mainCategory1, setMainCategory1] = useState();
  const [mainCategory2, setMainCategory2] = useState();
  const [mainCategory3, setMainCategory3] = useState();
  const [mainCategory4, setMainCategory4] = useState();
  const [mainCategory5, setMainCategory5] = useState();
  const [mainCategory6, setMainCategory6] = useState();
  const [mainCategory7, setMainCategory7] = useState();
  const [checkedData, setChecked] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleChangeUsers = (selectedOption) => {
    setUsers(selectedOption.value);
    props.getUsersById(selectedOption.value);
    // setTimeout(handleUsersPermissions, 5000)
    // var list1 = [];
    // !isEmpty(props.permissionsByUserId) &&
    // props.permissionsByUserId.map((x, i) => {
    //   console.log(x, 'ttttttttttttttttttttttttttmapp')
    //   list1.push({
    //     permissions_name: x.permissions_name,
    //     permissions_cat_name: x.permissions_cat_name,
    //   });
    // });
    // console.log('tttttttttttttttttttttttttt222222222222')
    // setChecked(list1)
    // handleUsersPermissions()
  };
  const handleUsersPermissions = () => {
    var list1 = [];
    // !isEmpty(props.permissionsByUserId) &&
    // [
    //   {
    //     cargo_assign_permission_id: 446,
    //     permissions_name: "Shipment",
    //     permissions_cat_name: null,
    //     createdAt: "2024-05-14T10:32:39.000Z",
    //     updatedAt: "2024-05-14T10:32:39.000Z",
    //     user_id: 12
    //   },
    //   {
    //     cargo_assign_permission_id: 447,
    //     permissions_name: "MasterControl",
    //     permissions_cat_name: null,
    //     createdAt: "2024-05-14T10:32:39.000Z",
    //     updatedAt: "2024-05-14T10:32:39.000Z",
    //     user_id: 12
    //   },
    //   {
    //     cargo_assign_permission_id: 456,
    //     permissions_name: "Complaint",
    //     permissions_cat_name: "Complaint Add",
    //     createdAt: "2024-05-14T10:32:39.000Z",
    //     updatedAt: "2024-05-14T10:32:39.000Z",
    //     user_id: 12
    //   },
    //   {
    //     cargo_assign_permission_id: 457,
    //     permissions_name: "Complaint",
    //     permissions_cat_name: "Complaint View",
    //     createdAt: "2024-05-14T10:32:39.000Z",
    //     updatedAt: "2024-05-14T10:32:39.000Z",
    //     user_id: 12
    //   },
    //   {
    //     cargo_assign_permission_id: 458,
    //     permissions_name: "Rate",
    //     permissions_cat_name: "Rate Inquiry",
    //     createdAt: "2024-05-14T10:32:39.000Z",
    //     updatedAt: "2024-05-14T10:32:39.000Z",
    //     user_id: 12
    //   },
    //   {
    //     cargo_assign_permission_id: 459,
    //     permissions_name: "MasterControl",
    //     permissions_cat_name: "Receiver View",
    //     createdAt: "2024-05-14T10:32:39.000Z",
    //     updatedAt: "2024-05-14T10:32:39.000Z",
    //     user_id: 12
    //   }
    // ]
    props.permissionsByUserId.map((x, i) => {
      console.log(x, 'ttttttttttttttttttttttttttmapp')
      list1.push({
        permissions_name: x.permissions_name,
        permissions_cat_name: x.permissions_cat_name,
      });
    });
    console.log('tttttttttttttttttttttttttt222222222222')
    setChecked(list1)
  }
  const handleShipment = (event) => {
    setMainCategory1(event.target.value);
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[0].cargo_permission_cats.map((x) => {
          updatedList.push({
            permissions_cat_name: x.permissions_cat_name,
            permissions_name: props.permissionsList[0].permissions_name,
          });
        });
      updatedList.push({
        permissions_name: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[0].cargo_permission_cats.map((x) => {
        });
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };
  const handleCheckShipment = (event) => {
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[0].cargo_permission_cats.find(
          (x) => x.permissions_cat_name == event.target.value
        );
      // .permissionSubCats.map((y) => {
      //   return y.permissionsSubCatName;
      // });
      !isEmpty(data) &&
        updatedList.push({
          permissions_cat_name: event.target.value,
          permissions_name: props.permissionsList[0].permissions_name,
        });
      // : data.map((z) => {
      //     updatedList.push({
      //       permissions_cat_name: event.target.value,
      //       permissions_name: props.permissionsList[0].permissions_name,
      //       // permissionsSubCatName: z,
      //     });
      //   });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };
  const handleComplaint = (event) => {
    setMainCategory2(event.target.value);
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[1].cargo_permission_cats.map((x) => {
          updatedList.push({
            permissions_cat_name: x.permissions_cat_name,
            permissions_name: props.permissionsList[1].permissions_name,
          });
          // !isEmpty(x.permissionSubCats) &&
          //   x.permissionSubCats.map((sub) => {
          //     updatedList.push({
          //       permissions_cat_name: x.permissions_cat_name,
          //       permissions_name: props.permissionsList[0].permissions_name,
          //       permissionsSubCatName: sub.permissionsSubCatName,
          //     });
          //   });
        });
      updatedList.push({
        permissions_name: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[1].cargo_permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissions_cat_name != x.permissions_cat_name
              );
              // updatedList = updatedList.filter(
              //   (e) => e.permissionsSubCatName != sub.permissionsSubCatName
              // );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };
  const handleCheckComplaint = (event) => {
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[1].cargo_permission_cats.find(
          (x) => x.permissions_cat_name == event.target.value
        );
      // .permissionSubCats.map((y) => {
      //   return y.permissionsSubCatName;
      // });
      !isEmpty(data) &&
        updatedList.push({
          permissions_cat_name: event.target.value,
          permissions_name: props.permissionsList[1].permissions_name,
        });
      // : data.map((z) => {
      //     updatedList.push({
      //       permissions_cat_name: event.target.value,
      //       permissions_name: props.permissionsList[1].permissions_name,
      //       // permissionsSubCatName: z,
      //     });
      //   });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };
  const handleUser = (event) => {
    setMainCategory3(event.target.value);
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[2].cargo_permission_cats.map((x) => {
          updatedList.push({
            permissions_cat_name: x.permissions_cat_name,
            permissions_name: props.permissionsList[2].permissions_name,
          });
          // !isEmpty(x.permissionSubCats) &&
          //   x.permissionSubCats.map((sub) => {
          //     updatedList.push({
          //       permissions_cat_name: x.permissions_cat_name,
          //       permissions_name: props.permissionsList[0].permissions_name,
          //       permissionsSubCatName: sub.permissionsSubCatName,
          //     });
          //   });
        });
      updatedList.push({
        permissions_name: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[2].cargo_permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissions_cat_name != x.permissions_cat_name
              );
              // updatedList = updatedList.filter(
              //   (e) => e.permissionsSubCatName != sub.permissionsSubCatName
              // );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };
  const handleCheckUser = (event) => {
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[2].cargo_permission_cats.find(
          (x) => x.permissions_cat_name == event.target.value
        );
      // .permissionSubCats.map((y) => {
      //   return y.permissionsSubCatName;
      // });
      !isEmpty(data) &&
        updatedList.push({
          permissions_cat_name: event.target.value,
          permissions_name: props.permissionsList[2].permissions_name,
        });
      // : data.map((z) => {
      //     updatedList.push({
      //       permissions_cat_name: event.target.value,
      //       permissions_name: props.permissionsList[2].permissions_name,
      //       // permissionsSubCatName: z,
      //     });
      //   });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };

  const handleReporting = (event) => {
    setMainCategory4(event.target.value);
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[3].cargo_permission_cats.map((x) => {
          // updatedList.push({
          //   permissions_cat_name: x.permissions_cat_name,
          //   permissions_name: props.permissionsList[3].permissions_name,
          // });
          // !isEmpty(x.permissionSubCats) &&
          //   x.permissionSubCats.map((sub) => {
          //     updatedList.push({
          //       permissions_cat_name: x.permissions_cat_name,
          //       permissions_name: props.permissionsList[0].permissions_name,
          //       permissionsSubCatName: sub.permissionsSubCatName,
          //     });
          //   });
        });
      updatedList.push({
        permissions_name: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[3].cargo_permission_cats.map((x) => {
          // !isEmpty(x.permissionSubCats) &&
          //   x.permissionSubCats.map((sub) => {
          //     updatedList = updatedList.filter(
          //       (e) => e.permissions_cat_name != x.permissions_cat_name
          //     );
          // updatedList = updatedList.filter(
          //   (e) => e.permissionsSubCatName != sub.permissionsSubCatName
          // );
          // });
        });
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };
  const handleCheckReporting = (event) => {
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[3].cargo_permission_cats.find(
          (x) => x.permissions_cat_name == event.target.value
        );
      // .permissionSubCats.map((y) => {
      //   return y.permissionsSubCatName;
      // });
      !isEmpty(data) &&
        updatedList.push({
          permissions_cat_name: event.target.value,
          permissions_name: props.permissionsList[3].permissions_name,
        });
      // : data.map((z) => {
      //     updatedList.push({
      //       permissions_cat_name: event.target.value,
      //       permissions_name: props.permissionsList[3].permissions_name,
      //       // permissionsSubCatName: z,
      //     });
      //   });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };

  const handleMasterControl = (event) => {
    setMainCategory5(event.target.value);
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[4].cargo_permission_cats.map((x) => {
          updatedList.push({
            permissions_cat_name: x.permissions_cat_name,
            permissions_name: props.permissionsList[4].permissions_name,
          });
          // !isEmpty(x.permissionSubCats) &&
          //   x.permissionSubCats.map((sub) => {
          //     updatedList.push({
          //       permissions_cat_name: x.permissions_cat_name,
          //       permissions_name: props.permissionsList[0].permissions_name,
          //       permissionsSubCatName: sub.permissionsSubCatName,
          //     });
          //   });
        });
      updatedList.push({
        permissions_name: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[4].cargo_permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissions_cat_name != x.permissions_cat_name
              );
              // updatedList = updatedList.filter(
              //   (e) => e.permissionsSubCatName != sub.permissionsSubCatName
              // );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };
  const handleCheckMasterControl = (event) => {
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[4].cargo_permission_cats.find(
          (x) => x.permissions_cat_name == event.target.value
        );
      // .permissionSubCats.map((y) => {
      //   return y.permissionsSubCatName;
      // });
      !isEmpty(data) &&
        updatedList.push({
          permissions_cat_name: event.target.value,
          permissions_name: props.permissionsList[4].permissions_name,
        });
      // : data.map((z) => {
      //     updatedList.push({
      //       permissions_cat_name: event.target.value,
      //       permissions_name: props.permissionsList[4].permissions_name,
      //       // permissionsSubCatName: z,
      //     });
      //   });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };

  const handlePermission = (event) => {
    setMainCategory6(event.target.value);
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[5].cargo_permission_cats.map((x) => {
          updatedList.push({
            permissions_cat_name: x.permissions_cat_name,
            permissions_name: props.permissionsList[5].permissions_name,
          });
          // !isEmpty(x.permissionSubCats) &&
          //   x.permissionSubCats.map((sub) => {
          //     updatedList.push({
          //       permissions_cat_name: x.permissions_cat_name,
          //       permissions_name: props.permissionsList[0].permissions_name,
          //       permissionsSubCatName: sub.permissionsSubCatName,
          //     });
          //   });
        });
      updatedList.push({
        permissions_name: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[5].cargo_permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissions_cat_name != x.permissions_cat_name
              );
              // updatedList = updatedList.filter(
              //   (e) => e.permissionsSubCatName != sub.permissionsSubCatName
              // );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };
  const handleCheckPermission = (event) => {
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[5].cargo_permission_cats.find(
          (x) => x.permissions_cat_name == event.target.value
        );
      // .permissionSubCats.map((y) => {
      //   return y.permissionsSubCatName;
      // });
      !isEmpty(data) &&
        updatedList.push({
          permissions_cat_name: event.target.value,
          permissions_name: props.permissionsList[5].permissions_name,
        });
      // : data.map((z) => {
      //     updatedList.push({
      //       permissions_cat_name: event.target.value,
      //       permissions_name: props.permissionsList[5].permissions_name,
      //       // permissionsSubCatName: z,
      //     });
      //   });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };
  const handleRate = (event) => {
    setMainCategory7(event.target.value);
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[6].cargo_permission_cats.map((x) => {
          updatedList.push({
            permissions_cat_name: x.permissions_cat_name,
            permissions_name: props.permissionsList[6].permissions_name,
          });
          // !isEmpty(x.permissionSubCats) &&
          //   x.permissionSubCats.map((sub) => {
          //     updatedList.push({
          //       permissions_cat_name: x.permissions_cat_name,
          //       permissions_name: props.permissionsList[0].permissions_name,
          //       permissionsSubCatName: sub.permissionsSubCatName,
          //     });
          //   });
        });
      updatedList.push({
        permissions_name: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[6].cargo_permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissions_cat_name != x.permissions_cat_name
              );
              // updatedList = updatedList.filter(
              //   (e) => e.permissionsSubCatName != sub.permissionsSubCatName
              // );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };
  const handleCheckRate = (event) => {
    var updatedList = [...props.permissionsByUserIdChecked];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[6].cargo_permission_cats.find(
          (x) => x.permissions_cat_name == event.target.value
        );
      // .permissionSubCats.map((y) => {
      //   return y.permissionsSubCatName;
      // });
      !isEmpty(data) &&
        updatedList.push({
          permissions_cat_name: event.target.value,
          permissions_name: props.permissionsList[6].permissions_name,
        });
      // : data.map((z) => {
      //     updatedList.push({
      //       permissions_cat_name: event.target.value,
      //       permissions_name: props.permissionsList[5].permissions_name,
      //       // permissionsSubCatName: z,
      //     });
      //   });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissions_cat_name != `${event.target.value}`
      );
    }
    props.setUsersByIdChecked(updatedList);
  };

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  useEffect(() => {
    document.title = props.title;
    props.getUsers();
    props.getPermissions();
    props.setHeading("Permission");
  }, []);

  const sendData = () => {
    props.addPermissions(
      {
        user: users,
        permission: props.permissionsByUserIdChecked,
      },
      handleVisible,
      handleDangerVisible
    );
  };
  const getUsersData =
    !isEmpty(props.usersList) &&
    props.usersList.map((x) => {
      let data = {
        value: x.id,
        label: `${x.firstName}${" "}${x.lastName.toUpperCase()}${" "}(${x.companyName
          })`,
      };
      // let data = { value: x.id, label: x.firstName };
      // `${
      //   props.userDetail.firstName
      // }${" "}${props.userDetail.lastName.toUpperCase()}/${
      //   props.userDetail.companyName
      // }`
      return data;
    });

  return (
    <>
      {props.isFetchingUsersList || props.isFetchingPermissionsList ? (
        <div className="loader-div">
          <Button variant="secondary" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="secondary"
            />
            {props.isSavingLevel2 ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b> Select User</b>
                    </Form.Label>
                    <Select
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "none",
                          borderBottom: "1px solid black",
                          borderRadius: 0,
                          boxShadow: "none",
                          backgroundColor: "white",
                        }),
                      }}
                      placeholder="Select User..."
                      // value={{label:level1label}}
                      onChange={(e) => handleChangeUsers(e)}
                      options={getUsersData}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <h3 style={{ marginTop: "20px", fontWeight: "bold" }}>
                User Permissions
              </h3>
              {/* {props.isFetchingPermissionsByUserId || isEmpty(props.permissionsByUserId) ? (
        <div className="loader-div">
          <Button variant="secondary" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="secondary"
            />
            {props.isSavingLevel2 ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <> */}
              <Row>
                <Col lg="3" md="3" sm="12">
                  <div style={{ marginLeft: "5%" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      // style={{ appearance: "auto" }}
                      value={
                        !isEmpty(props.permissionsList) &&
                        props.permissionsList[0].permissions_name
                      }
                      onChange={(event) => handleShipment(event)}
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      {!isEmpty(props.permissionsList) &&
                        props.permissionsList[0].permissions_name}
                    </label>
                  </div>
                  {!isEmpty(props.permissionsList) &&
                    props.permissionsList[0].cargo_permission_cats.map(
                      (inv, index) => {
                        return (
                          <Row>
                            <Col lg="12" md="12" sm="12">
                              <div style={{ marginLeft: "15%" }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={inv.permissions_cat_name}
                                  checked={
                                    !isEmpty(props.permissionsByUserIdChecked)
                                      ? find(
                                        props.permissionsByUserIdChecked,
                                        (x) =>
                                          x.permissions_cat_name ==
                                          inv.permissions_cat_name
                                      )
                                      : false
                                  }
                                  id="flexCheckDefault"
                                  onChange={(e) => handleCheckShipment(e)}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  {inv.permissions_cat_name}
                                </label>

                              </div>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                </Col>
                <Col lg="3" md="3" sm="12">
                  <div style={{ marginLeft: "5%" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={
                        !isEmpty(props.permissionsList) &&
                        props.permissionsList[1].permissions_name
                      }
                      permissions_cat_name={""}
                      onChange={(event) => handleComplaint(event)}
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      {!isEmpty(props.permissionsList) &&
                        props.permissionsList[1].permissions_name}
                    </label>
                  </div>
                  {!isEmpty(props.permissionsList) &&
                    props.permissionsList[1].cargo_permission_cats.map(
                      (acc, index) => {
                        return (
                          <Row>
                            <Col lg="12" md="12" sm="12">
                              <div style={{ marginLeft: "15%" }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={acc.permissions_cat_name}
                                  permissions_cat_name="flexCheckDefault"
                                  checked={
                                    !isEmpty(props.permissionsByUserIdChecked)
                                      ? find(
                                        props.permissionsByUserIdChecked,
                                        (x) =>
                                          x.permissions_cat_name ==
                                          acc.permissions_cat_name
                                      )
                                      : false
                                  }
                                  onChange={(e) => handleCheckComplaint(e)}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  {acc.permissions_cat_name}
                                </label>

                              </div>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                </Col>
                <Col lg="3" md="3" sm="12">
                  <div style={{ marginLeft: "5%" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={
                        !isEmpty(props.permissionsList) &&
                        props.permissionsList[2].permissions_name
                      }
                      permissions_cat_name={""}
                      onChange={(event) => handleUser(event)}
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      {!isEmpty(props.permissionsList) &&
                        props.permissionsList[2].permissions_name}
                    </label>
                  </div>
                  {!isEmpty(props.permissionsList) &&
                    props.permissionsList[2].cargo_permission_cats.map(
                      (acc, index) => {
                        return (
                          <Row>
                            <Col lg="12" md="12" sm="12">
                              <div style={{ marginLeft: "15%" }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={acc.permissions_cat_name}
                                  permissions_cat_name="flexCheckDefault"
                                  checked={
                                    !isEmpty(props.permissionsByUserIdChecked)
                                      ? find(
                                        props.permissionsByUserIdChecked,
                                        (x) =>
                                          x.permissions_cat_name ==
                                          acc.permissions_cat_name
                                      )
                                      : false
                                  }
                                  onChange={(e) => handleCheckUser(e)}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  {acc.permissions_cat_name}
                                </label>
                              </div>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                </Col>
                <Col lg="3" md="3" sm="12">
                  <div style={{ marginLeft: "5%" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={
                        !isEmpty(props.permissionsList) &&
                        props.permissionsList[3].permissions_name
                      }
                      permissions_cat_name={""}
                      onChange={(event) => handleReporting(event)}
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      {!isEmpty(props.permissionsList) &&
                        props.permissionsList[3].permissions_name}
                    </label>
                  </div>
                  {!isEmpty(props.permissionsList) &&
                    props.permissionsList[3].cargo_permission_cats.map(
                      (acc, index) => {
                        return (
                          <Row>
                            <Col lg="12" md="12" sm="12">
                              <div style={{ marginLeft: "15%" }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={acc.permissions_cat_name}
                                  permissions_cat_name="flexCheckDefault"
                                  checked={
                                    !isEmpty(props.permissionsByUserIdChecked)
                                      ? find(
                                        props.permissionsByUserIdChecked,
                                        (x) =>
                                          x.permissions_cat_name ==
                                          acc.permissions_cat_name
                                      )
                                      : false
                                  }
                                  onChange={(e) => handleCheckReporting(e)}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  {acc.permissions_cat_name}
                                </label>
                              </div>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="3" sm="12">
                  <div style={{ marginLeft: "5%" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={
                        !isEmpty(props.permissionsList) &&
                        props.permissionsList[4].permissions_name
                      }
                      permissions_cat_name={""}
                      onChange={(event) => handleMasterControl(event)}
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      {!isEmpty(props.permissionsList) &&
                        props.permissionsList[4].permissions_name}
                    </label>
                  </div>
                  {!isEmpty(props.permissionsList) &&
                    props.permissionsList[4].cargo_permission_cats.map(
                      (acc, index) => {
                        return (
                          <Row>
                            <Col lg="12" md="12" sm="12">
                              <div style={{ marginLeft: "15%" }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={acc.permissions_cat_name}
                                  permissions_cat_name="flexCheckDefault"
                                  checked={
                                    !isEmpty(props.permissionsByUserIdChecked)
                                      ? find(
                                        props.permissionsByUserIdChecked,
                                        (x) =>
                                          x.permissions_cat_name ==
                                          acc.permissions_cat_name
                                      )
                                      : false
                                  }
                                  onChange={(e) => handleCheckMasterControl(e)}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  {acc.permissions_cat_name}
                                </label>
                              </div>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                </Col>
                <Col lg="3" md="3" sm="12">
                  <div style={{ marginLeft: "5%" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={
                        !isEmpty(props.permissionsList) &&
                        props.permissionsList[5].permissions_name
                      }
                      permissions_cat_name={""}
                      onChange={(event) => handlePermission(event)}
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      {!isEmpty(props.permissionsList) &&
                        props.permissionsList[5].permissions_name}
                    </label>
                  </div>
                  {!isEmpty(props.permissionsList) &&
                    props.permissionsList[5].cargo_permission_cats.map(
                      (acc, index) => {
                        return (
                          <Row>
                            <Col lg="12" md="12" sm="12">
                              <div style={{ marginLeft: "15%" }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={acc.permissions_cat_name}
                                  permissions_cat_name="flexCheckDefault"
                                  checked={
                                    !isEmpty(props.permissionsByUserIdChecked)
                                      ? find(
                                        props.permissionsByUserIdChecked,
                                        (x) =>
                                          x.permissions_cat_name ==
                                          acc.permissions_cat_name
                                      )
                                      : false
                                  }
                                  onChange={(e) => handleCheckPermission(e)}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  {acc.permissions_cat_name}
                                </label>
                              </div>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                </Col>
                <Col lg="3" md="3" sm="12">
                  <div style={{ marginLeft: "5%" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={
                        !isEmpty(props.permissionsList) &&
                        props.permissionsList[6].permissions_name
                      }
                      permissions_cat_name={""}
                      onChange={(event) => handleRate(event)}
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      {!isEmpty(props.permissionsList) &&
                        props.permissionsList[6].permissions_name}
                    </label>
                  </div>
                  {!isEmpty(props.permissionsList) &&
                    props.permissionsList[6].cargo_permission_cats.map(
                      (acc, index) => {
                        return (
                          <Row>
                            <Col lg="12" md="12" sm="12">
                              <div style={{ marginLeft: "15%" }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={acc.permissions_cat_name}
                                  permissions_cat_name="flexCheckDefault"
                                  checked={
                                    !isEmpty(props.permissionsByUserIdChecked)
                                      ? find(
                                        props.permissionsByUserIdChecked,
                                        (x) =>
                                          x.permissions_cat_name ==
                                          acc.permissions_cat_name
                                      )
                                      : false
                                  }
                                  onChange={(e) => handleCheckRate(e)}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  {acc.permissions_cat_name}
                                </label>
                              </div>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                </Col>
              </Row>

              <Row style={{ textAlign: "center" }}>
                <Col lg="12" md="12" sm="12">
                  <Button
                    disabled={users == "" ? true : false}
                    style={{
                      marginLeft: "2%",
                      color: "black",
                      width: "20%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                    onClick={() => sendData()}
                  >
                    Send
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsers()),
    getUsersById: (id) => dispatch(getUsersById(id)),
    setUsersByIdChecked: (data) => dispatch(setUsersByIdChecked(data)),
    getPermissions: () => dispatch(getPermissions()),
    addPermissions: (data, handleVisible, handleDangerVisible) =>
      dispatch(addPermissions(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};

const mapStateToProps = (state, ownProps) => ({
  usersList: state.permissionReducer.usersList,
  permissionsList: state.permissionReducer.permissionsList,
  isFetchingUsersList: state.permissionReducer.isFetchingUsersList,
  isFetchingPermissionsList: state.permissionReducer.isFetchingPermissionsList,

  permissionsByUserId: state.permissionReducer.permissionsByUserId,
  isFetchingPermissionsByUserId:
    state.permissionReducer.isFetchingPermissionsByUserId,
  permissionsByUserIdChecked: state.permissionReducer.permissionsByUserIdChecked,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissions);
