import API from "../../../../global/api";
export const setAddSenderData = (data) => {
  return {
    type: "SET_ADD_SENDER_DATA",
    payload: data,
  };
};
export const setAddReceiverData = (data) => {
  return {
    type: "SET_ADD_RECEIVER_DATA",
    payload: data,
  };
};
export const setAddShipmentDetail = (data) => {
  return {
    type: "SET_ADD_SHIPMENT_DETAIL",
    payload: data,
  };
};
export const handleAddInputShipmentDetail = () => {
  return {
    type: "SET_ADD_INPUT_SHIPMENT_DETAIL",
    // payload: data
  };
};
export const handleRemoveInputShipmentDetail = (i) => {
  return {
    type: "SET_REMOVE_INPUT_SHIPMENT_DETAIL",
    payload: i,
  };
};
export const nextSubSteps = () => {
  return {
    type: "NEXT_SUB_STEPS",
    // payload: data
  };
};
export const backSubSteps = () => {
  return {
    type: "BACK_SUB_STEPS",
    // payload: data
  };
};
export const startSteps = () => {
  return {
    type: "START_STEPS",
    // payload: data
  };
};
export const setShowReceiver = (data) => {
  return {
    type: "SET_SHOW_RECEIVER",
    payload: data,
  };
};
export const setShipmentRef = (data) => {
  return {
    type: "SET_SHIPMENT_REF",
    payload: data,
  };
};
export const setShowShipmentDetail = (data) => {
  return {
    type: "SET_SHOW_SHIPMENT_DETAIL",
    payload: data,
  };
};
export const setShowPackaging = () => {
  return {
    type: "SET_SHOW_PACKAGING",
    // payload: data
  };
};

export const setType = (data) => {
  return {
    type: "SET_TYPE",
    payload: data,
  };
};
export const setPurpose = (data) => {
  return {
    type: "SET_PURPOSE",
    payload: data,
  };
};
export const requestGetShipmentPurpose = () => {
  return {
    type: "REQUEST_GET_SHIPMENT_PURPOSE",
  };
};
export const successGetShipmentPurpose = (data) => {
  return {
    type: "SUCCESS_GET_SHIPMENT_PURPOSE",
    payload: data,
  };
};
export const errorGetShipmentPurpose = () => {
  return {
    type: "ERROR_GET_SHIPMENT_PURPOSE",
  };
};
export const getShipmentPurpose = () => {
  return (dispatch) => {
    dispatch(requestGetShipmentPurpose());
    API.get(`/shipment/shipment_purpose`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetShipmentPurpose(getData));
      })
      .catch((error) => {
        dispatch(errorGetShipmentPurpose());
      });
  };
};

export const requestGetPayList = () => {
  return {
    type: "REQUEST_GET_PAY_LIST",
  };
};
export const successGetPayList = (data) => {
  return {
    type: "SUCCESS_GET_PAY_LIST",
    payload: data,
  };
};
export const errorGetPayList = () => {
  return {
    type: "ERROR_GET_PAY_LIST",
  };
};
export const getPayList = () => {
  return (dispatch) => {
    dispatch(requestGetPayList());
    API.get(`/shipment/shipment_tax_type`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetPayList(getData));
      })
      .catch((error) => {
        dispatch(errorGetPayList());
      });
  };
};
export const setTaxType = (data) => {
  return {
    type: "SET_TAX_TYPE",
    payload: data,
  };
};
export const setEstimatedDate = (data) => {
  return {
    type: "SET_ESTIMATED_DATE",
    payload: data,
  };
};
export const setFinancialInstrument = (data) => {
  return {
    type: "SET_INSTRUMENT",
    payload: data,
  };
};
export const setRebate = (data) => {
  return {
    type: "SET_REBATE",
    payload: data,
  };
};
export const setInvoiceType = (data) => {
  return {
    type: "SET_INVOICE_TYPE",
    payload: data,
  };
};
export const setSRO = (data) => {
  return {
    type: "SET_SRO",
    payload: data,
  };
};
export const setNo = (data) => {
  return {
    type: "SET_NO",
    payload: data,
  };
};
export const setBank = (data) => {
  return {
    type: "SET_BANK",
    payload: data,
  };
};
export const setDescription = (data) => {
  return {
    type: "SET_DESCRIPTION",
    payload: data,
  };
};
export const setEditable = (data) => {
  return {
    type: "SET_EDITABLE",
    payload: data,
  };
};
export const setShowButtonSender = (data) => {
  return {
    type: "SET_SHOW_BUTTON_SENDER",
    payload: data,
  };
};
export const setShowButtonReceiver = (data) => {
  return {
    type: "SET_SHOW_BUTTON_RECEIVER",
    payload: data,
  };
};
export const setEditableReceiver = (data) => {
  return {
    type: "SET_EDITABLE_RECEIVER",
    payload: data,
  };
};

export const requestGetCargoService = () => {
  return {
    type: "REQUEST_GET_CARGO_SERVICE",
  };
};
export const successGetCargoService = (data) => {
  return {
    type: "SUCCESS_GET_CARGO_SERVICE",
    payload: data,
  };
};
export const errorGetCargoService = () => {
  return {
    type: "ERROR_GET_CARGO_SERVICE",
  };
};
export const getCargoService = () => {
  return (dispatch) => {
    dispatch(requestGetCargoService());
    API.get(`/shipment/shipment_tax_type`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetCargoService(getData));
      })
      .catch((error) => {
        dispatch(errorGetCargoService());
      });
  };
};

export const setCargoService = (data) => {
  return {
    type: "SET_CARGO_SERVICE",
    payload: data,
  };
};

export const requestGetServiceType = () => {
  return {
    type: "REQUEST_GET_SERVICE_TYPE",
  };
};
export const successGetServiceType = (data) => {
  return {
    type: "SUCCESS_GET_SERVICE_TYPE",
    payload: data,
  };
};
export const errorGetServiceType = () => {
  return {
    type: "ERROR_GET_SERVICE_TYPE",
  };
};
export const getServiceType = () => {
  return (dispatch) => {
    dispatch(requestGetServiceType());
    API.get(`/service_type`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetServiceType(getData));
      })
      .catch((error) => {
        dispatch(errorGetServiceType());
      });
  };
};
export const setServiceType = (data) => {
  return {
    type: "SET_SERVICE_TYPE",
    payload: data,
  };
};

export const requestGetUom = () => {
  return {
    type: "REQUEST_GET_UOM",
  };
};
export const successGetUom = (data) => {
  return {
    type: "SUCCESS_GET_UOM",
    payload: data,
  };
};
export const errorGetUom = () => {
  return {
    type: "ERROR_GET_UOM",
  };
};
export const getUom = () => {
  return (dispatch) => {
    dispatch(requestGetUom());
    API.get(`/uom`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetUom(getData));
      })
      .catch((error) => {
        dispatch(errorGetUom());
      });
  };
};
export const resetReducerShipment = () => {
  return {
    type: "RESET_REDUCER_SHIPMENT",
  };
};
