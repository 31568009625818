import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";

import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import AddIcCallTwoToneIcon from "@mui/icons-material/AddIcCallTwoTone";
import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import AddBusinessTwoToneIcon from "@mui/icons-material/AddBusinessTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import DialpadTwoToneIcon from "@mui/icons-material/DialpadTwoTone";
import { getRole } from "../cargoLogin/CargoLogin.action";
import { getCountry } from "../country/Country.action";
import { setEditReceiver, updateReceiver } from "./Receiver.action";

const EditReceiver = (props) => {
  const [name, setName] = useState("");
  // const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(0);
  const [companyName, setCompanyName] = useState("");
  // const [website, setWebsiteURL] = useState("");
  const [postalCode, setPostalCode] = useState(0);
  const [city, setCity] = useState("");
  // const [CNIC, setCNIC] = useState(0);
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyAddress2, setCompanyAddress2] = useState("");
  const [companyAddress3, setCompanyAddress3] = useState("");
  const [state, setState] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [countryId, setCountryId] = useState("");
  const [country, setCountry] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function saveSender() {
    props.updateReceiver(
      {
        name: props.receiverByID[0].name,
        company: props.receiverByID[0].company,
        country_id: props.receiverByID[0].country_id,
        address: props.receiverByID[0].address,
        address_2: props.receiverByID[0].address_2,
        address_3: props.receiverByID[0].address_3,
        postal_code: props.receiverByID[0].postal_code,
        city: props.receiverByID[0].city,
        state: props.receiverByID[0].state,
        email: props.receiverByID[0].email,
        phone: props.receiverByID[0].phone,
      },
      props.receiverByID[0].receiver_id,
      handleVisible,
      handleDangerVisible
    );
  }
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cellNumberError, setCellNumberError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [addressError2, setAddressError2] = useState(false);
  const handleAddressChange = (e) => {
    if (e.target.value.length < 45) {
      props.setEditReceiver({
        key: "address",
        value: e.target.value.toUpperCase(),
      });
      setAddressError(false);
    } else {
      setAddressError(true);
    }
  };
  const handleAddress2Change = (e) => {
    if (e.target.value.length < 45) {
      props.setEditReceiver({
        key: "address_2",
        value: e.target.value.toUpperCase(),
      });
      setAddressError2(false);
    } else {
      setAddressError2(true);
    }
  };
  const handleCompanyNameChange = (e) => {
    props.setEditReceiver({
      key: "company",
      value: e.target.value.toUpperCase(),
    });
    if (e.target.value.length > 3) {
      setCompanyError(false);
    } else {
      setCompanyError(true);
    }
  };
  const handleNameChange = (e) => {
    props.setEditReceiver({
      key: "name",
      value: e.target.value.toUpperCase(),
    });
    if (e.target.value.length > 3) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };
  const handleEmailChange = (e) => {
    props.setEditReceiver({
      key: "email",
      value: e.target.value,
    });
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };
  const handleCellNumberChange = (e) => {
    // let data = phone(e.target.value, { country: `${countryCode}` });
    // props.setEditReceiver({
    //   key: "phone",
    //   value: e.target.value,
    // })
    // if (countryCode == "US") {
    //   if (e.target.value.length == 10) {
    //     setCellNumberError(false);
    //   } else {
    //     setCellNumberError(true);
    //   }
    // } else {
    //   if (data.isValid == true) {
    //     setCellNumberError(false);
    //   } else {
    //     setCellNumberError(true);
    //   }
    // }
  };
  const getCountryList =
    !isEmpty(props.countryList) &&
    props.countryList.map((x) => {
      let data = {
        value: x.country_id,
        label: x.name,
        country_code: x.code,
        dial_code: x.dial_code,
      };
      return data;
    });
  const handleChangeCountry = (e) => {
    // setCountryId(e.value);
    // setCountry(e.label);
    // setCountryCode(e.country_code);
    // setDialCode(e.dial_code);
    // setPhone();
  };

  useEffect(() => {
    // props.setHeading("Add Hall");
    props.getCountry();
    document.title = props.title;
  }, []);
  return (
    <>
      {props.isFetchingReceiverById ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Row style={{ marginTop: "15px" }}>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  error={nameError}
                  value={
                    !isEmpty(props.receiverByID) && props.receiverByID[0].name
                  }
                  helperText={
                    nameError
                      ? "Please Enter Your Name (Atleast 3 Character)"
                      : ""
                  }
                  label="Name"
                  onChange={(e) => handleNameChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DomainTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="Company Name "
                  error={companyError}
                  value={
                    !isEmpty(props.receiverByID) &&
                    props.receiverByID[0].company
                  }
                  helperText={
                    companyError
                      ? "Please Enter Your Company Name (Atleast 3 Character)"
                      : ""
                  }
                  onChange={(e) => handleCompanyNameChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col
                xl="4"
                lg="4"
                md="4"
                sm="12"
                xs="12"
                style={{ zIndex: 10, marginTop: "-8px" }}
              >
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label" required>
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={
                      !isEmpty(props.receiverByID) &&
                      props.receiverByID[0].country_id
                    }
                    label="Select Country"
                    onChange={(e) =>
                      props.setEditReceiver({
                        key: "country_id",
                        value: e.target.value,
                      })
                    }
                  >
                    {props.countryList.map((name) => (
                      <MenuItem key={name.country_id} value={name.country_id}>
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <Select
                  isDisabled={isEmpty(props.countryList)}
                  // value={{label:props.receiverData[0].country_name}}
                  // isOptionDisabled={(option) =>
                  //   option.value==props.senderData[0].country_id
                  // }
                  placeholder="Select Country..."
                  onChange={handleChangeCountry}
                  options={getCountryList}
                /> */}
                </FormControl>
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddBusinessTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  value={
                    !isEmpty(props.receiverByID) &&
                    props.receiverByID[0].address
                  }
                  label="Address"
                  onChange={(e) => handleAddressChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddBusinessTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  value={
                    !isEmpty(props.receiverByID) &&
                    props.receiverByID[0].address_2
                  }
                  label="Address 2"
                  onChange={(e) => handleAddress2Change(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddBusinessTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  value={
                    !isEmpty(props.receiverByID) &&
                    props.receiverByID[0].address_3
                  }
                  label="Address 3"
                  onChange={(e) =>
                    props.setEditReceiver({
                      key: "address_3",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddLocationAltTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="City"
                  value={
                    !isEmpty(props.receiverByID) && props.receiverByID[0].city
                  }
                  onChange={(e) =>
                    props.setEditReceiver({
                      key: "city",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddLocationAltTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  value={
                    !isEmpty(props.receiverByID) && props.receiverByID[0].state
                  }
                  label="State"
                  onChange={(e) =>
                    props.setEditReceiver({
                      key: "state",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DialpadTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  // type="Number"
                  id="standard-basic"
                  variant="standard"
                  required
                  value={
                    !isEmpty(props.receiverByID) &&
                    props.receiverByID[0].postal_code
                  }
                  label="Postal/Zip Code"
                  onChange={(e) =>
                    props.setEditReceiver({
                      key: "postal_code",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              {/* email */}
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    type: "email",
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="email"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="Email "
                  error={emailError}
                  value={
                    !isEmpty(props.receiverByID) && props.receiverByID[0].email
                  }
                  helperText={emailError ? "Please Enter Valid Email" : ""}
                  onChange={(e) => handleEmailChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddIcCallTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  required
                  value={
                    !isEmpty(props.receiverByID) && props.receiverByID[0].phone
                  }
                  label="Phone"
                  onChange={(e) =>
                    // handleCellNumberChange(e)
                    props.setEditReceiver({
                      key: "phone",
                      value: e.target.value,
                    })
                  }
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: "25px" }}>
              <Button
                onClick={saveSender}
                disabled={
                  nameError ||
                  companyError ||
                  emailError ||
                  addressError ||
                  addressError2
                }
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "20%",
                  backgroundColor: "#C5CBCF",
                  border: "1px solid white",
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateReceiver: (data, id, handleVisible, handleDangerVisible) =>
      dispatch(updateReceiver(data, id, handleVisible, handleDangerVisible)),
    getCountry: () => dispatch(getCountry()),
    setEditReceiver: (data) => dispatch(setEditReceiver(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  receiverByID: state.CargoReceiverReducer.receiverByID,
  isFetchingReceiverById: state.CargoReceiverReducer.isFetchingReceiverById,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  countryList: state.CargoCountryReducer.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditReceiver);
