import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import {
  deleteSender,
  getSender,
  getSenderById,
  updateSender,
} from "./Sender.action";

const ViewSender = (props) => {
  const navigate = useNavigate();
  const defaultMaterialTheme = createTheme();
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  useEffect(() => {
    //  props.setHeading("Uom List");
    props.getSender();
    document.title = props.title;
  }, []);
  return (
    <>
      {props.isFetchingSender ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{ height: "100%", backgroundColor: "#F9FAFB" }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                style={{
                  fontFamily: "Public Sans,sans-serif",
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                title="Sender Details"
                columns={[
                  // { title: "Sender Id", field: "sender_id", editable: "never" },
                  { title: "Sender Name", field: "name" },
                  { title: "Company", field: "company" },
                  {
                    title: "Country",
                    field: "country.name",
                    editable: "never",
                  },
                  { title: "City", field: "city" },
                  { title: "State", field: "state" },
                  { title: "Postal Code", field: "postal_code" },
                  { title: "Address", field: "address" },
                  { title: "Address 2", field: "address_2" },
                  { title: "Address 3", field: "address_3" },
                  { title: "Email", field: "email" },
                  { title: "Phone", field: "phone" },
                ]}
                data={props.senderList}
                //    data={(query) =>
                //     new Promise((resolve, reject) => {
                //         let url = `${baseURL}/country?`;
                //         url += "size=" + query.pageSize
                //         url += "&page=" + (query.page+1);
                //         //  url += "&search=" + query.search;
                //         fetch(url, {
                //             method: 'GET',
                //             header: headers,
                //         })
                //             .then((response) => response.json())
                //             .then((result) => {
                //                 resolve({
                //                     data: result.data,
                //                     page: result.page,
                //                     totalCount: result.total_results,
                //                 });
                //             });

                //     })
                // }
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  paging: true,
                  pageSize: 50, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                  maxBodyHeight: "600px",
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#919EAB",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
                // editable={[
                //   // isEditHidden: rowData => Checkpermissions("permissionsSubCatName","Hall Edit"),
                //   // isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName","Hall Delete"),
                //   // onRowUpdate: (newData, oldData) =>
                //   //   new Promise((resolve) => {
                //   //     setTimeout(() => {
                //   //       resolve();
                //   //       {
                //   //         props.updateSender(newData, oldData);
                //   //       }
                //   //     }, 600);
                //   //   }),

                //   // onRowDelete: (oldData) =>
                //   //   new Promise((resolve) => {
                //   //     setTimeout(() => {
                //   //       resolve();
                //   //       props.deleteSender(oldData.sender_id);
                //   //     }, 600);
                //   //   }),
                // ]}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "View Sender",
                    hidden: Checkpermissions(
                      "permissions_cat_name",
                      "Sender Edit"
                    ),
                    onClick: (event, rowData) => {
                      props.getSenderById(rowData.sender_id);
                      navigate("/cargo/sender/edit_sender");
                    },
                  },
                ]}
              />
            </ThemeProvider>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSender: (data) => dispatch(getSender(data)),
    getSenderById: (id) => dispatch(getSenderById(id)),
    deleteSender: (SenderId) => dispatch(deleteSender(SenderId)),
    updateSender: (newData, oldData) =>
      dispatch(updateSender(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  senderList: state.CargoSenderReducer.senderList,
  isFetchingSender: state.CargoSenderReducer.isFetchingSender,
  // flavorsDataList: state.FlavourReducer.flavorsDataList,
  // sizeDataList: state.sizeReducer.sizeDataList,
  // masterControlDataList:state.MasterControlReducer.masterControlDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSender);
