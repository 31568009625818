import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Card,
  Image,
} from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Select from "react-select";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";

import logo from "../../../Images/logo.png";
import "jspdf-autotable";
import Swal from "sweetalert";
import ImageUploader from "react-images-upload";
import {
  Register,
  addImage,
  addImageBack,
  addLogo,
  getRole,
} from "./CargoLogin.action";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import AddIcCallTwoToneIcon from "@mui/icons-material/AddIcCallTwoTone";
import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import KeyTwoToneIcon from "@mui/icons-material/KeyTwoTone";
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import DialpadTwoToneIcon from "@mui/icons-material/DialpadTwoTone";

const RegisterCargo = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsiteURL] = useState("");
  const [NTNNo, setNTNNo] = useState(0);
  const [GSTNo, setGSTNo] = useState(0);
  const [CNIC, setCNIC] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [role, setRole] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function registerUser() {
    props.register(
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        companyName: companyName,
        companyLogo: props.logoURL.path,
        webSite: website,
        gst: GSTNo,
        ntn: NTNNo,
        cnic: CNIC,
        address: companyAddress,
        password: confirmPassword,
        phone_number: cellNumber,
        cnicFrontImage: props.imageURL.path,
        cnicBackImage: props.backImageURL.path,
        role: roleId,
        term_and_condition: checked,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const onDrop = (pictureFiles, pictureDataURLs) => {
    props.imageFront({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  const onDropBackImage = (pictureFiles, pictureDataURLs) => {
    props.imageBack({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  const onDropCompanyLogo = (pictureFiles, pictureDataURLs) => {
    props.companyLogo({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [websiteURLError, setwebsiteURLError] = useState(false);
  const [cellNumberError, setCellNumberError] = useState(false);
  const [CNICError, setCNICError] = useState(false);
  const [companyError, setCompanyError] = useState(false);

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value.toUpperCase());
    if (e.target.value.length > 3) {
      setCompanyError(false);
    } else {
      setCompanyError(true);
    }
  };
  const handleNameChange = (e) => {
    setFirstName(e.target.value.toUpperCase());
    if (e.target.value.length > 3) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  const handleCNICChange = (e) => {
    const cnicPattern = /^[0-9]{13}$/;
    if (e.target.value.length <= 13) {
      setCNIC(e.target.value);
      if (cnicPattern.test(e.target.value)) {
        setCNICError(false);
      } else {
        setCNICError(true);
      }
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handlePassChange = (e) => {
    setConfirmPassword(e.target.value);
    e.target.value == password
      ? setPasswordError(false)
      : setPasswordError(true);
  };
  const handleWebsiteURLChange = (e) => {
    setWebsiteURL(e.target.value);
    const pattern =
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    if (pattern.test(e.target.value)) {
      setwebsiteURLError(false);
    } else {
      setwebsiteURLError(true);
    }
  };
  const handleCellNumberChange = (e) => {
    const pattern =
      /^(?:\+92|0)?(3\d{2}|5[0-9]\d{1}|7[0-9]\d{1}|8[0-9]\d{1}|9[0-9]\d{1})\d{7}$/;
    const inputValue = e.target.value;
    if (e.target.value.length <= 11) {
      setCellNumber(inputValue);

      if (pattern.test(inputValue)) {
        setCellNumberError(false);
      } else {
        setCellNumberError(true);
      }
    }
  };
  const getRoleList =
    !isEmpty(props.roleList) &&
    props.roleList.map((x) => {
      let data = { value: x.id, label: x.role };
      return data;
    });
  const handleChangeRole = (e) => {
    setRoleId(e.target.value);
    // setRole(e.label);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOk = () => {
    setChecked(true);
    setOpen(false);
  };

  useEffect(() => {
    // props.setHeading("Add Hall");
    props.getRole();
    document.title = props.title;
  }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Container fluid>
      <div className="main" style={{ backgroundColor: "#F9FAFB" }}>
        <Card style={{ padding: "3%" }}>
          <Image src={logo} />
          <Row>
            <Col lg="12">
              {props.isFetchingRole ? (
                <div className="loader-div">
                  <Spinner
                    variant="secondary"
                    animation="grow"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <>
                  <Row style={{ marginTop: "15px" }}>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DriveFileRenameOutlineTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="text"
                        id="standard-basic"
                        variant="standard"
                        required
                        error={nameError}
                        value={firstName}
                        helperText={
                          nameError
                            ? "Please Enter Your First Name (Atleast 4 Character)"
                            : ""
                        }
                        label="First Name"
                        onChange={(e) => handleNameChange(e)}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DriveFileRenameOutlineTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="text"
                        id="standard-basic"
                        variant="standard"
                        label="Last Name "
                        value={lastName}
                        onChange={(e) =>
                          setLastName(e.target.value.toUpperCase())
                        }
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          type: "email",
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="email"
                        id="standard-basic"
                        variant="standard"
                        required
                        label="Email "
                        error={emailError}
                        value={email}
                        helperText={
                          emailError ? "Please Enter Valid Email" : ""
                        }
                        onChange={(e) => handleEmailChange(e)}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddIcCallTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="Number"
                        id="standard-basic"
                        variant="standard"
                        required
                        label="Cell Number "
                        error={cellNumberError}
                        value={cellNumber}
                        helperText={
                          cellNumberError
                            ? "Please Enter Valid Cell Number"
                            : ""
                        }
                        onChange={(e) => handleCellNumberChange(e)}
                        sx={{
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                            {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DomainTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="text"
                        id="standard-basic"
                        variant="standard"
                        required
                        label="Company Name "
                        error={companyError}
                        helperText={
                          companyError
                            ? "Please Enter Your Company Name (Atleast 4 Character)"
                            : ""
                        }
                        value={companyName}
                        onChange={(e) =>
                          // setCompanyName(e.target.value.toUpperCase())
                          handleCompanyNameChange(e)
                        }
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LanguageTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="text"
                        id="standard-basic"
                        variant="standard"
                        label="Website "
                        error={websiteURLError}
                        value={website}
                        helperText={
                          websiteURLError
                            ? "Please Enter Valid Website URL"
                            : ""
                        }
                        onChange={(e) => handleWebsiteURLChange(e)}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DialpadTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="Number"
                        id="standard-basic"
                        variant="standard"
                        required
                        label="NTN No "
                        onChange={(e) => setNTNNo(e.target.value)}
                        sx={{
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                            {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DialpadTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="Number"
                        id="standard-basic"
                        variant="standard"
                        label="GST No "
                        onChange={(e) => setGSTNo(e.target.value)}
                        sx={{
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                            {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddLocationAltTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="text"
                        id="standard-basic"
                        variant="standard"
                        required
                        label="Company Address"
                        value={companyAddress}
                        onChange={(e) =>
                          setCompanyAddress(e.target.value.toUpperCase())
                        }
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <NumbersTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="Number"
                        id="standard-basic"
                        variant="standard"
                        required
                        label="CNIC"
                        error={CNICError}
                        value={CNIC}
                        helperText={
                          CNICError
                            ? "Please Enter Valid CNIC (Without Dashes)"
                            : ""
                        }
                        onChange={(e) => handleCNICChange(e)}
                        sx={{
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                            {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <KeyTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="password"
                        id="standard-basic"
                        variant="standard"
                        required
                        label="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <KeyTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="password"
                        id="standard-basic"
                        variant="standard"
                        required
                        label="Confirm Password"
                        error={passwordError}
                        value={confirmPassword}
                        helperText={
                          passwordError
                            ? "Password and Confirm Password is not Match"
                            : ""
                        }
                        onChange={(e) => handlePassChange(e)}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col xl="3" lg="3" md="3" sm="3" xs="12">
                      <FormControl variant="standard" fullWidth>
                        <InputLabel
                          id="demo-simple-select-standard-label"
                          required
                        >
                          Select Account Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={roleId}
                          label="Select Account Type"
                          onChange={handleChangeRole}
                        >
                          {props.roleList.map((name) => (
                            <MenuItem key={name.id} value={name.id}>
                              {name.role}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "25px" }}>
                    <Col xl="4" lg="4" md="4" sm="4" xs="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b style={{ fontSize: "large" }}>
                            Upload CNIC Front Image *
                          </b>
                        </Form.Label>
                        <ImageUploader
                          fileContainerStyle={{
                            borderColor: "red",
                            borderWidth: "2px",
                          }}
                          withIcon={false}
                          buttonText="Upload Image"
                          onChange={onDrop}
                          // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                          imgExtension={[".jpg", ".jpeg", ".png"]}
                          maxFileSize={999999999999999999999}
                          singleImage={true}
                          withPreview={true}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="4" lg="4" md="4" sm="4" xs="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b style={{ fontSize: "large" }}>
                            Upload CNIC Back Image *
                          </b>
                        </Form.Label>
                        <ImageUploader
                          withIcon={false}
                          buttonText="Upload Image"
                          onChange={onDropBackImage}
                          // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                          imgExtension={[".jpg", ".jpeg", ".png"]}
                          maxFileSize={999999999999999999999}
                          singleImage={true}
                          withPreview={true}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="4" lg="4" md="4" sm="4" xs="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b style={{ fontSize: "large" }}>
                            Upload Company Logo *
                          </b>
                        </Form.Label>
                        <ImageUploader
                          withIcon={false}
                          buttonText="Upload Image"
                          onChange={onDropCompanyLogo}
                          // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                          imgExtension={[".jpg", ".jpeg", ".png"]}
                          maxFileSize={999999999999999999999}
                          singleImage={true}
                          withPreview={true}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <div className="sendDiv">
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            color="default"
                            checked={checked}
                            //  onChange={handleOpen}
                            onClick={handleOpen}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            style={{ fontFamily: "Public Sans,sans-serif" }}
                          >
                            By logging in, you agree to abide by our Terms of
                            Use.
                          </Typography>
                        }
                      />
                    </div>
                  </Row>

                  <div className="sendDiv">
                    {props.isAddingLogo ||
                    props.isAddingImage ||
                    props.isAddingBackImage ||
                    props.isRegister ? (
                      <div className="loader-div">
                        <Spinner
                          variant="secondary"
                          animation="grow"
                          size="xl"
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : (
                      <Button
                        disabled={
                          isEmpty(firstName) ||
                          isEmpty(email) ||
                          isEmpty(companyName) ||
                          isEmpty(CNIC) ||
                          isEmpty(NTNNo) ||
                          isEmpty(companyAddress) ||
                          isEmpty(cellNumber) ||
                          isEmpty(password) ||
                          isEmpty(confirmPassword) ||
                          roleId === 0 ||
                          nameError ||
                          emailError ||
                          cellNumberError ||
                          CNICError ||
                          passwordError
                          // isEmpty(roleId) ||
                          // checked
                          // ||
                          // isEmpty(props.imageURL) ||
                          // isEmpty(props.backImageURL) ||
                          // isEmpty(props.logoURL)
                        }
                        style={{
                          marginLeft: "2%",
                          color: "black",
                          width: "20%",
                          backgroundColor: "#C5CBCF",
                          border: "1px solid white",
                        }}
                        onClick={() => registerUser()}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </>
              )}

              {/* </div> */}

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    style={{ fontFamily: "Public Sans,sans-serif" }}
                  >
                    Terms of Use:
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                    style={{ fontFamily: "Public Sans,sans-serif" }}
                  >
                    You agree to use this platform/service in accordance with
                    applicable laws and regulations. You understand that any
                    misuse or abuse of the platform/service may result in the
                    termination of your account. You agree not to engage in any
                    activities that may harm or disrupt the functioning of the
                    platform/service. You acknowledge that you are responsible
                    for maintaining the confidentiality of your login
                    credentials. You agree to respect the privacy of other users
                    and not to disclose their personal information without their
                    consent.
                  </Typography>
                  <Button
                    onClick={handleOk}
                    style={{
                      color: "#6D7C89",
                      width: "20%",
                      backgroundColor: "#C5CBCF",
                      border: "none",
                      fontFamily: "Public Sans,sans-serif",
                    }}
                  >
                    OK
                  </Button>
                </Box>
              </Modal>
            </Col>
          </Row>
        </Card>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (data, handleVisible, handleDangerVisible) =>
      dispatch(Register(data, handleVisible, handleDangerVisible)),
    // getRestaurantData: () => dispatch(getRestaurantData()),
    // setHeading: (data) => dispatch(setHeading(data)),
    // getUser: () => dispatch(getUserRes()),
    imageFront: (data) => dispatch(addImage(data)),
    imageBack: (data) => dispatch(addImageBack(data)),
    companyLogo: (data) => dispatch(addLogo(data)),
    getRole: () => dispatch(getRole()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  imageURL: state.CargoLoginReducer.imageURL,
  isRegister: state.CargoLoginReducer.isRegister,
  isAddingImage: state.CargoLoginReducer.isAddingImage,
  backImageURL: state.CargoLoginReducer.backImageURL,
  isAddingBackImage: state.CargoLoginReducer.isAddingBackImage,
  logoURL: state.CargoLoginReducer.logoURL,
  isAddingLogo: state.CargoLoginReducer.isAddingLogo,
  isFetchingRole: state.CargoLoginReducer.isFetchingRole,
  roleList: state.CargoLoginReducer.roleList,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCargo);
