import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  getLevel5LedgerData,
  searchAccountLedger,
  searchAccountLedgerReport,
} from "./Ledger.action";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "./style.css";
import { get } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { isEmpty, isUndefined, hasIn, isNull } from "lodash";
import { ThemeProvider, createTheme } from "@mui/material";
const LedgerTable = (props) => {
  const defaultMaterialTheme = createTheme();
  const location = useLocation();
  const getledger = isEmpty(props.searchAccountLedgerList)
    ? []
    : props.searchAccountLedgerList.data.map((v, i) => v);

  const name = isEmpty(props.searchAccountLedgerList)
    ? ""
    : props.searchAccountLedgerList.name;
  const start = isEmpty(props.searchAccountLedgerList)
    ? ""
    : props.searchAccountLedgerList.startedDate;
  const end = isEmpty(props.searchAccountLedgerList)
    ? ""
    : props.searchAccountLedgerList.endDate;

  useEffect(() => {}, []);

  return (
    <>
      <div className="sendDiv" style={{ marginTop: "30px" }}>
        <Link to="/cargo/ledger/ledger_report">
          <Button
            style={{
              marginLeft: "2%",
              color: "black",
              width: "20%",
              backgroundColor: "#C5CBCF",
              border: "1px solid white",
            }}
          >
            {" "}
            Back to Search
          </Button>
        </Link>
      </div>
      {props.isFetchingSearchAccountLedger ? (
        <div className="loader-div">
          <Button variant="secondary" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="secondary"
            />
            {props.isFetchingSearchAccountLedger ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          {!isEmpty(props.searchAccountLedgerList) && (
            <div
              className="main"
              style={{ height: "100%", backgroundColor: "#F9FAFB" }}
            >
              <ThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                  style={{
                    padding: "2%",
                    fontFamily: "Public Sans,sans-serif",
                    borderRadius: "30px",
                    boxShadow:
                      " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                  title={
                    <div style={{ marginTop: "5px" }}>
                      <h5>{name == "" ? "Ledgers" : `${"Ledger :" + name}`}</h5>
                      <h5>
                        Date Range: {start} - {end}
                      </h5>
                      <h5>
                        Closing Balance:{" "}
                        {props.searchAccountLedgerList.data.length <= 1
                          ? props.searchAccountLedgerList.data[0].accBalance
                          : props.searchAccountLedgerList.closing_balance}
                      </h5>
                      <h5>
                        Total Credit:{" "}
                        {props.searchAccountLedgerList.data.length <= 1
                          ? 0
                          : props.searchAccountLedgerList.credit_sum}
                      </h5>
                      <h5>
                        Total Debit:{" "}
                        {props.searchAccountLedgerList.data.length <= 1
                          ? 0
                          : props.searchAccountLedgerList.debit_sum}
                      </h5>
                    </div>
                  }
                  columns={[
                    { title: "Date", field: "voucher_date", filtering: false },
                    {
                      title: "Voucher#",
                      field: "voc_manual_number",
                      filtering: false,
                    },
                    // {
                    //   title: "Cross Account",
                    //   field: "cross_acc",
                    //   filtering: false,
                    // },
                    {
                      title: "Description",
                      field: "voucher_description",
                      filtering: false,
                    },
                    { title: "Tracking No", field: "trackingNo" },
                    { title: "Destination", field: "destination" },
                    { title: "Packages", field: "packages" },
                    { title: "Weight", field: "weight" },
                    { title: "Weight Charges", field: "weightCharges" },
                    { title: "Rate First Kg", field: "rateFirstKg" },
                    { title: "Rate Add Kg", field: "rateAddKg" },
                    { title: "Rate Flat", field: "rateFlat" },
                    { title: "Service", field: "service" },
                    {
                      title: "Bill #",
                      field: "voucher_bill_no",
                      filtering: false,
                    },
                    // { title: "Opening", field: "opening_balance",filtering: false, render: rowData => !hasIn(rowData, 'opening_balance') || isNull(rowData.opening_balance) ? "" :rowData.opening_balance.toLocaleString()  },
                    {
                      title: "Debit",
                      field: "debit",
                      filtering: false,
                      render: (rowData) =>
                        !hasIn(rowData, "debit") || isNull(rowData.debit)
                          ? ""
                          : rowData.debit.toLocaleString(),
                    },
                    {
                      title: "Credit",
                      field: "credit",
                      filtering: false,
                      render: (rowData) =>
                        !hasIn(rowData, "credit") || isNull(rowData.credit)
                          ? ""
                          : rowData.credit.toLocaleString(),
                    },
                    {
                      title: "Balance",
                      field: "accBalance",
                      filtering: false,
                      render: (rowData) =>
                        !hasIn(rowData, "accBalance") ||
                        isNull(rowData.accBalance)
                          ? ""
                          : rowData.accBalance.toLocaleString(),
                    },
                    // { title: 'DR/CR', field: 'accType', render: rowData => rowData.accType == 2 ? "Debit" : rowData.accType == 1 ? "Credit" : "", filtering: false },
                    {
                      title: "DR/CR",
                      field: "accBalance_sign",
                      render: (rowData) =>
                        rowData.accBalance_sign == 2
                          ? "Debit"
                          : rowData.accBalance_sign == 1
                          ? "Credit"
                          : "",
                      filtering: false,
                    },
                  ]}
                  data={getledger}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    paging: true,
                    pageSize: 200, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#919EAB",
                      fontFamily: "Public Sans,sans-serif",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                />
              </ThemeProvider>
            </div>
          )}
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel5LedgerData: () => dispatch(getLevel5LedgerData()),
    searchAccountLedger: (data) => dispatch(searchAccountLedger(data)),
    searchAccountLedgerReport: (data, startDate, endDate) =>
      dispatch(searchAccountLedgerReport(data, startDate, endDate)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
  isFetchingLevel5LedgerData:
    state.AccountLegderReducer.isFetchingLevel5LedgerData,
  isFetchingSearchAccountLedger:
    state.AccountLegderReducer.isFetchingSearchAccountLedger,
});

export default connect(mapStateToProps, mapDispatchToProps)(LedgerTable);
