import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import "./add-levels.css";
import { getLevel1Data, getLevel2Data, getLevel3Data, getLevel4Data, getLevel5Data, AddLevel5Name, getcreditdebit,getLevelOneName,getLevelTwoName, deleteLevel5,getLevelFourName } from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, toUpper } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Swal from 'sweetalert';
import { setHeading } from "../../accounts/account.actions";


const AddLevel5 = (props) => {
    const [level1data, setLevel1data] = useState();
    const [level1Id, setLevel1Id] = useState();
    const [level3Id, setLevel3Id] = useState();
    const [level3Data, setLevel3Data] = useState();
    const [level4Id, setLevel4Id] = useState();
    const [level4Data, setLevel4Data] = useState();
    const [level5, setLevel5Name] = useState();
    const [creditdebit, setCreditDebit] = useState("0");
    const [income, setIncome] = useState();
    const [setAlertVisible] = useState(false);
     const [ setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
      setAlertVisible(true)
      setTimeout(() => {
          setAlertVisible(false)
      }, 9000);
      Swal({
          title: "Success",
          text: "Added successfully...",
          icon: "success",
          button: "Ok",
        });
  }

  const handleDangerVisible = () => {
      setDangerVisibleAlert(true)
      setTimeout(() => {
          setDangerVisibleAlert(false)
      }, 9000);
      Swal({
          title: "Error",
          text: "Something went wrong...",
          icon: "error",
          button: "Ok",
        });
  }

    function uploadLevel5() {
        props.AddLevel5Name({
            levelOneId: String(level1Id),
            levelTwoId: String(level3Id),
            levelThreeId: String(level4Id),
            levelFourName: toUpper(level5),
            debitCreditId: creditdebit,
            levelBalance: income,
        },handleVisible,handleDangerVisible);
      
        setLevel1data("");
        setLevel3Data("");
        setLevel4Data("");
        document.getElementById("level5id").value="";
        document.getElementById("rsid").value="";
    }

    // const getLevel4List = !isEmpty(props.level4List) && props.level4List.map((x) => { let data = { value: x.levelFourData.levelFourId, label: x.levelFourData.levelFourName }; return data });
    const getLevelFourList = !isEmpty(props.levelFour) && props.levelFour.map((x) => { let data = { value: x._id,  label: x.levelThreeName}; return data });
    const handleChangeLevel4 = (selectedOption) => {

        setLevel4Data(selectedOption.label)
        setLevel4Id(selectedOption.value);
    };

    const creditDebit = !isEmpty(props.creditDebitValue) && props.creditDebitValue.map((x) => { let data = { value: x._id, label: x.account_type_name }; return data });
    const handleChangeDebitCredit = (selectedOption) => {
        setCreditDebit(selectedOption.value);
    };
 
     // add level
     const getLevel1List = props.level1List.map((x) => { let data = { value: x._id, label: x.name }; return data });
     const handleChangeLevel1 = (selectedOption) => {
         props.getLevelOneName(selectedOption.value);
     setLevel1Id(selectedOption.value);
     setLevel1data(selectedOption.label);
     };
     const getlevel3val = !isEmpty(props.levelThree) && props.levelThree.map((x) => { let data = { value: x._id,  label: x.levelTwoName}; return data });
     const handleChangeLevel3 = (selectedOption) => {
        props.getLevelFourName(selectedOption.value);
        setLevel3Id(selectedOption.value);
        setLevel3Data(selectedOption.label)
    };
     // end level
    useEffect(() => {
        props.getcreditdebit();
        props.getLevel1Data();
        props.setHeading("Add Account");
    });

    return (
        <>
                        {props.isFetchinglevel1List || props.isFetchingLevelTow || props.isFetchingLevelThree || props.isFetchingLevelFour || props.isSavingLevel5? (
                        <div className="loader-div">
                            <Button variant="info" disabled>
                                <Spinner
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="info"
                                />
                                {props.isSavingLevel5 ? "Saving" : "Loading"}
                            </Button>
                        </div>
                    ) : (
                        <Container fluid>
                            <div className="main">

                                <div>
                                <Row>
                                    <Col>
                                    <Form.Group>
                                        <Form.Label>
                                        <b> Select Level 1 *</b>
                                        </Form.Label>
                                        <Select
                                        value = {{label: level1data}}
                                        placeholder="Select Level 1..."
                                        onChange={handleChangeLevel1}
                                        options={getLevel1List}
                                        />
                                    </Form.Group>
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>
                                                    <b> Select Level 2 *</b>
                                                </Form.Label>
                                                <Select
                                                 isDisabled={isEmpty(getlevel3val) || isEmpty(level1data) }
                                                 value = {{label: level3Data}}
                                                    placeholder="Select Level 2..."
                                                    onChange={handleChangeLevel3}
                                                    options={getlevel3val}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>
                                                    <b> Select Level 3 *</b>
                                                </Form.Label>
                                                <Select
                                                 isDisabled={isEmpty(getLevelFourList) || isEmpty(level3Data)}
                                                 value = {{label: level4Data}}
                                                    placeholder="Select Level 3..."
                                                    onChange={(e) => handleChangeLevel4(e)}
                                                    options={getLevelFourList}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" md="12" xs="12">
                                            <Form.Group className="mb-3" controlId="garana" >
                                                <Form.Label><b>Enter Level 4 Name *</b></Form.Label>
                                               {!level4Id ? <Form.Control type="text" placeholder="Level Name...." readOnly /> : <Form.Control type="text" id="level5id" placeholder="Level Name...." onMouseLeave={(e) =>
                                                    setLevel5Name(e.target.value)} />}  
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="6" sm="12">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label><b>Amount Type *</b></Form.Label>
                                                <Select
                                                 isDisabled={isEmpty(creditDebit) || isEmpty(level5) }
                                                    placeholder="Select Type..."
                                                    onChange={handleChangeDebitCredit}
                                                    options={creditDebit}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="6" sm="12">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label><b>Rs *</b></Form.Label>
                                                { creditdebit===0 ? <Form.Control type="Number" placeholder="Your Rs..." readOnly /> : <Form.Control type="Number" id="rsid"  placeholder="Your Rs...."  onChange={(e) => setIncome(e.target.value)} />}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <div className="sendDiv">
                                    {!income ?   <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", border: "1px solid" }}>Save</Button>  :  <Button  onClick={uploadLevel5}>Save</Button> } 
                                    </div>
                                 
                                </div>

                            </div>

                        </Container>

                    )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLevel1Data: () => dispatch(getLevel1Data()),
        getLevel2Data: () => dispatch(getLevel2Data()),
        getLevel3Data: () => dispatch(getLevel3Data()),
        getLevel4Data: () => dispatch(getLevel4Data()),
        getLevel5Data: () => dispatch(getLevel5Data()),
        AddLevel5Name: (data,handleVisible,handleDangerVisible) => dispatch(AddLevel5Name(data,handleVisible,handleDangerVisible)),
        getcreditdebit: () => dispatch(getcreditdebit()),
        deleteLevel5: (levelFiveId) => dispatch(deleteLevel5(levelFiveId)),
        getLevelOneName: (data)=>dispatch(getLevelOneName(data)),
        getLevelTwoName: (data)=>dispatch(getLevelTwoName(data)),
        getLevelFourName: (data)=>dispatch(getLevelFourName(data)),
        setHeading:(data)=>dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    levelTow:state.accountsLevelReducer.levelTow,
    levelThree:state.accountsLevelReducer.levelThree,
    levelFour:state.accountsLevelReducer.levelFour,
    level1List: state.accountsLevelReducer.level1List,
    level2List: state.accountsLevelReducer.level2List,
    level3List: state.accountsLevelReducer.level3List,
    level4List: state.accountsLevelReducer.level4List,
    level5List: state.accountsLevelReducer.level5List,
    D_CList: state.accountsLevelReducer.D_CList,
    creditDebitValue: state.accountsLevelReducer.creditDebitValue,
    isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
    isAddingLevel5: state.accountsLevelReducer.isAddingLevel5,
    isNotAddLevel5: state.accountsLevelReducer.isNotAddLevel5,
    isFetchinglevel1List: state.accountsLevelReducer.isFetchinglevel1List,
    isFetchingLevelTow: state.accountsLevelReducer.isFetchingLevelTow,
    isFetchingLevelThree: state.accountsLevelReducer.isFetchingLevelThree,
    isFetchingLevelFour:state.accountsLevelReducer.isFetchingLevelFour,
    isSavingLevel5: state.accountsLevelReducer.isSavingLevel5
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddLevel5);