import React, { useEffect, useState } from "react";
import { Col, Row, Form, Container, Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import jsPDF from "jspdf";
import { isEmpty } from "lodash";
import "jspdf-autotable";
import {
  GenerateLedgerReport,
  getAccount,
  getTrialDataPdf,
} from "../reporting.action";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import Select from "react-select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { isUndefined } from "lodash";

const LedgerReport = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [accountId, setAccountId] = useState(3);
  const [accountName, setAccountName] = useState("");
  useEffect(() => {
    document.title = props.title;
    props.getAccount();
  }, []);
  function generateReport() {
    props.generatepdf({
      startedDate: startDate,
      endDate: endDate,
      levelFiveId: accountId,
    });
  }

  const searchTrialPdf = () => {
    props.getTrialDataPdf(
      {
        startedDate: !isUndefined(startDate) ? startDate : "",
        endDate: !isUndefined(endDate) ? endDate : "",
        levelFiveId: !isUndefined(accountId) ? accountId : "",
        // accountName: setAccountName(),
      },
      accountName
    );
    setStartDate("");
    setEndDate("");
    setAccountId("");
    setAccountName("");
  };

  const getAccountList =
    !isEmpty(props.accountList) &&
    props.accountList.map((x) => {
      let data = {
        value: x.levelFiveId,
        label: x.levelFiveName,
      };
      return data;
    });

  const handleChangeAccount = (e) => {
    setAccountId(e.value);
    setAccountName(e.label);
  };

  return (
    <>
      <div></div>
      {props.isFetchingData ? (
        <div className="loader-div">
          <Button variant="secondary" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="secondary"
            />
            Loading
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Row style={{ marginTop: "15px" }}>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="date"
                  value={startDate}
                  id="standard-basic"
                  variant="standard"
                  label="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                {" "}
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="date"
                  id="standard-basic"
                  variant="standard"
                  label="End Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col
                xl="4"
                lg="4"
                md="4"
                sm="4"
                xs="12"
                style={{ zIndex: 8, marginTop: "-5px" }}
              >
                {/* <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label" required> */}
                Select Account *{/* </InputLabel> */}
                <Select
                  // labelId="demo-simple-select-standard-label"
                  // id="demo-simple-select-standard"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      borderBottom: "1px solid black",
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: "white",
                      marginTop: "-7px",
                    }),
                  }}
                  value={{ label: accountName }}
                  placeholder=" Select Account"
                  onChange={(e) => handleChangeAccount(e)}
                  options={getAccountList}
                >
                  {/* {props.accountList.map((name) => (
                    <MenuItem
                      key={name.levelFiveId}
                      value={{
                        id: name.levelFiveId,
                        name: name.levelFiveName,
                      }}
                    >
                      {name.levelFiveName}
                    </MenuItem>
                  ))} */}
                </Select>
                {/* </FormControl> */}
              </Col>
            </Row>
            <br />
            <div className="sendDiv" style={{ marginTop: "25px" }}>
              <Button
                disabled={accountId == ""}
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "30%",
                  backgroundColor: "#C5CBCF",
                  border: "1px solid white",
                }}
                onClick={searchTrialPdf}
              >
                Generate Report
              </Button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    generatepdf: (data) => dispatch(GenerateLedgerReport(data)),
    getAccount: (data) => dispatch(getAccount(data)),

    getTrialDataPdf: (data, name) => dispatch(getTrialDataPdf(data, name)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  accountList: state.ReportingReducer.accountList,
  isFetchingAccount: state.ReportingReducer.isFetchingAccount,
  reportList: state.ReportingReducer.reportList,
  isFetchingReport: state.ReportingReducer.isFetchingReport,
  trialList: state.ReportingReducer.trialList,
  isFetchingTrialBalance: state.ReportingReducer.isFetchingTrialBalance,
});

export default connect(mapStateToProps, mapDispatchToProps)(LedgerReport);
