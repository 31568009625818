import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import "./add-levels.css";
import { getLevel1Data, getLevel2Data, getLevel3Data, getLevel4Data, AddLevel5Name, getcreditdebit,deleteLevel5, updateLevel5, getLevel5DataForReport } from "./accountsLevel.action";
import { connect } from "react-redux";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { sortBy } from "lodash";
import { setHeading } from "../../accounts/account.actions";


const Level5Tap = (props) => {
    const level5Data = sortBy(props.level5ListReport.allData, ['props.level5ListReport', 'levelfiveData.allLevelKey']);
    const [state] = React.useState({
        columns:[ { title: 'Level 1 Name',editable: 'never', field: 'levelFourData.levelOneId.name', render: rowData => rowData.levelFourData.levelOneId.name + " /" + rowData.levelFourData.levelOneId.key },
        // { title: 'Level 2 Name', editable: 'never',field: 'levelfiveData.level_two.levelTwoName', render: rowData => rowData.levelfiveData.level_two.levelTwoName + " /" + rowData.levelfiveData.level_one.key + "-" + rowData.levelfiveData.level_two.levelTwoKey },
         { title: 'Level 2 Name', editable: 'never',field: 'levelFourData.levelTwoId.levelTwoName', render: rowData => rowData.levelFourData.levelTwoId.levelTwoName + " /" + rowData.levelFourData.levelTwoId.levelTwoKey + "-" + rowData.levelFourData.levelTwoId.levelTwoKey + "-" + rowData.levelFourData.levelThreeId.levelThreeKey },
         { title: 'Level 3 Name',editable: 'never', field: 'levelFourData.levelThreeId.levelThreeName', render: rowData => rowData.levelFourData.levelThreeId.levelThreeName + " /" + rowData.levelFourData.levelOneId.key + "-" + rowData.levelFourData.levelTwoId.levelTwoKey + "-" + rowData.levelFourData.levelThreeId.levelThreeKey  },
         { title: 'Level 4 Code',editable: 'never', field: 'levelFourData.allLevelKey' },
         { title: 'Level 4 Name', field: 'levelFourData.levelFourName', },
       //  { title: 'Account Type', editable: 'never',field: 'levelFourData.debitCreditId.account_type_name' },
         { title: 'Closing Balance', editable: 'never', field: 'levelFourData.levelBalance',  render: rowData =>rowData.levelFourData.levelBalance.toLocaleString() }],
    });

    useEffect(() => {
        
        props.getLevel5Data();
        props.setHeading("Level Four Detail");
    });

    return (
        <>


            {props.isFetchinglevel5ListReport ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>

                    <div className="main">
                        {/* <div className="sendDiv">
                            <Link to="/user/level5"> <Button >Back</Button></Link>
                        </div> */}

                        <MaterialTable
                            title="Level 4 Details"
                            // columns={state.columns}
                            columns={[
                                { title: 'Level 1 Name',editable: 'never', field: 'levelFourData.levelOneId.name', render: rowData => rowData.levelFourData.levelOneId.name + " /" + rowData.levelFourData.levelOneId.key },
                               // { title: 'Level 2 Name', editable: 'never',field: 'levelfiveData.level_two.levelTwoName', render: rowData => rowData.levelfiveData.level_two.levelTwoName + " /" + rowData.levelfiveData.level_one.key + "-" + rowData.levelfiveData.level_two.levelTwoKey },
                                { title: 'Level 2 Name', editable: 'never',field: 'levelFourData.levelTwoId.levelTwoName', render: rowData => rowData.levelFourData.levelTwoId.levelTwoName + " /" + rowData.levelFourData.levelTwoId.levelTwoKey + "-" + rowData.levelFourData.levelTwoId.levelTwoKey + "-" + rowData.levelFourData.levelThreeId.levelThreeKey },
                                { title: 'Level 3 Name',editable: 'never', field: 'levelFourData.levelThreeId.levelThreeName', render: rowData => rowData.levelFourData.levelThreeId.levelThreeName + " /" + rowData.levelFourData.levelOneId.key + "-" + rowData.levelFourData.levelTwoId.levelTwoKey + "-" + rowData.levelFourData.levelThreeId.levelThreeKey  },
                                { title: 'Level 4 Code',editable: 'never', field: 'levelFourData.allLevelKey',  },
                                { title: 'Level 4 Name', field: 'levelFourData.levelFourName', },
                              //  { title: 'Account Type', editable: 'never',field: 'levelFourData.debitCreditId.account_type_name' },
                                { title: 'Closing Balance', editable: 'never', field: 'levelFourData.levelBalance',  render: rowData =>rowData.levelFourData.levelBalance.toLocaleString() },
                            ]}
                            data={level5Data}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                pageSize: 30,
                                pageSizeOptions: [30, 50, 100],
                                emptyRowsWhenPaging: false,
                            
                                exportPdf: (columns, data) => {
                                    console.log(data,"aaaaaaaaaaaaaaa")
                                    const doc = new jsPDF();

                                    const columnTitles = state.columns
                                        .map(columnDef => columnDef.title);

                                    // const pdfData = data.map(rowData =>
                                    //   state.columns.map(columnDef => (console.log(rowData.levelTwoData[columnDef.field], "eeeee"), columnDef.field == 'voucherDate' ? moment(rowData[columnDef.field]).format("YYYY-MM-DD") : rowData[columnDef.field])),
                                    // );

                                    const pdfData = data.map((elt) => [elt.levelFourData.levelOneId.name, elt.levelFourData.levelTwoId.levelTwoName, elt.levelFourData.levelThreeId.levelThreeName, elt.levelFourData.allLevelKey, elt.levelFourData.levelFourName, elt.levelFourData.levelBalance]);
                                    let content = {
                                        startY: 65,
                                        startX: 5,
                                        head: [columnTitles],
                                        body: pdfData
                                    };
                                    doc.addImage(props.level5ListReport.restaurantImage, 'PNG', 10, 11, 30, 30);
                                    doc.setFontSize(20);
                                    doc.setFont(undefined, 'bold')
                                    doc.text(`${props.level5ListReport.resImage.restaurantName}`, 50, 20, 0, 20);
                                    doc.setFontSize(13);
                                    doc.setFont('TimesNewRoman', 'Normal')
                                    doc.text(`${props.level5ListReport.resImage.restaurantLocation}`, 50, 30, 0, 20);
                                    doc.text(`${props.level5ListReport.resImage.ntn}`, 50, 40, 0, 20);
                                    doc.setFontSize(16);
                                    doc.setFont('TimesNewRoman', 'bold')
                                    doc.text("Level3 Report", 80, 55, 0, 20);
                                    doc.autoTable(content);

                                    doc.save(`data.pdf`);
                                },
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            // editable={{
                            //     onRowDelete: (oldData) =>
                            //         new Promise((resolve) => {
                            //             console.log(oldData, "rerrrr")
                            //             setTimeout(() => {
                            //                 resolve();
                            //                 props.deleteLevel5(oldData.levelfiveData.levelFiveId);
                            //             }, 600);
                            //         }),
                            //         onRowUpdate: (newData, oldData) =>
                            //         new Promise((resolve) => {
                            //             setTimeout(() => {
                            //                 console.log(newData, "nf")
                            //                 resolve();
                            //                 props.updateLevel5(oldData.levelfiveData.levelFiveId, {levelFiveName: toUpper(newData.levelfiveData.levelFiveName)})
                            //             }, 600);
                            //         }),
                            // }}
                        />



                    </div>

                </Container>

            )}

        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLevel1Data: () => dispatch(getLevel1Data()),
        getLevel2Data: () => dispatch(getLevel2Data()),
        getLevel3Data: () => dispatch(getLevel3Data()),
        getLevel4Data: () => dispatch(getLevel4Data()),
        getLevel5Data: () => dispatch(getLevel5DataForReport()),
        AddLevel5Name: (data) => dispatch(AddLevel5Name(data)),
        getcreditdebit: () => dispatch(getcreditdebit()),
        deleteLevel5: (levelFiveId) => dispatch(deleteLevel5(levelFiveId)),
        updateLevel5: (levelFiveId, data) => dispatch(updateLevel5(levelFiveId, data)),
        setHeading:(data)=>dispatch(setHeading(data)),

    };
};
const mapStateToProps = (state, ownProps) => ({
    level1List: state.accountsLevelReducer.level1List,
    level2List: state.accountsLevelReducer.level2List,
    level3List: state.accountsLevelReducer.level3List,
    level4List: state.accountsLevelReducer.level4List,
    level5List: state.accountsLevelReducer.level5List,
    level5ListReport: state.accountsLevelReducer.level5ListReport,
    D_CList: state.accountsLevelReducer.D_CList,
    creditDebitValue: state.accountsLevelReducer.creditDebitValue,
    isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
    isFetchinglevel5ListReport: state.accountsLevelReducer.isFetchinglevel5ListReport,
    isAddingLevel5: state.accountsLevelReducer.isAddingLevel5,
    isNotAddLevel5: state.accountsLevelReducer.isNotAddLevel5,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Level5Tap);