import React, { useEffect } from "react";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty, sumBy } from "lodash";
import { useNavigate } from "react-router-dom";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfTwoTone";
import {
  getShipmentByIdForInvoice,
  getShipmentByIdForPdf,
  getShipmentByIdForWayBack,
} from "./Shipment.action";

const ViewShipmentById = (props) => {
  const navigate = useNavigate();
  // const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  // const Checkpermissions = (permissionsType, permissionsName) => {
  //   return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? false : true
  // }
  useEffect(() => {
    //  props.setHeading("Uom List");
    // isEmpty(props.shipmentById)&&navigate("/cargo/shipment/viewshipmentlist")
    document.title = props.title;
  }, []);
  const handleClick = (item) => {
    // props.getShipmentById(item.shipment_id)
    navigate("/cargo/shipment/shipments_list");
  };
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  return (
    <>
      {props.isFetchingShipmentById ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "100%",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <div className="sendDiv" style={{ marginTop: "30px" }}>
              <Button
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "20%",
                  backgroundColor: "#C5CBCF",
                  border: "1px solid white",
                }}
                onClick={handleClick}
              >
                Back
              </Button>
            </div>
            {!isEmpty(props.shipmentById) && (
              <>
                {" "}
                <Card style={{ marginTop: "20px", padding: "10px" }}>
                  <Row>
                    <Col xl="6" lg="6" md="6" sm="6" xs="6">
                      <b> Tracking Id</b>
                    </Col>
                    <Col xl="6" lg="6" md="6" sm="6" xs="6">
                      <b> Status</b>
                    </Col>
                    {/* <Col xl="2" lg="2" md="2" sm="2" xs="2">
                      <Button><PictureAsPdfIcon onClick={(e)=>{props.getShipmentByIdForWayBack(props.shipmentById[0].shipment_id)}}/></Button>
                    </Col>
                    <Col xl="2" lg="2" md="2" sm="2" xs="2">
                    <Button><PictureAsPdfIcon onClick={(e)=>{props.getShipmentByIdForInvoice(props.shipmentById[0].shipment_id)}}/></Button>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col xl="6" lg="6" md="6" sm="6" xs="6">
                      {props.shipmentById[0].tracking_id}
                    </Col>
                    <Col xl="6" lg="6" md="6" sm="6" xs="6">
                      {props.shipmentById[0].track_status}
                    </Col>
                  </Row>
                </Card>
                <Card style={{ marginTop: "10px", padding: "10px" }}>
                  <Row>
                    <Col xl="6" lg="6" md="6" sm="6" xs="6">
                      <b> Ship From</b>

                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {props.shipmentById[0].shipment_senders[0].name}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {props.shipmentById[0].shipment_senders[0].company}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {props.shipmentById[0].shipment_senders[0].phone}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {props.shipmentById[0].shipment_senders[0].address}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {
                            props.shipmentById[0].shipment_senders[0]
                              .postal_code
                          }
                          , {props.shipmentById[0].shipment_senders[0].city}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {
                            props.shipmentById[0].shipment_senders[0].country
                              .name
                          }
                        </Col>
                      </Row>
                    </Col>
                    <Col xl="6" lg="6" md="6" sm="6" xs="6">
                      <b>Ship To</b>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {props.shipmentById[0].shipment_receivers[0].name}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {props.shipmentById[0].shipment_receivers[0].company}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {props.shipmentById[0].shipment_receivers[0].phone}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {props.shipmentById[0].shipment_receivers[0].address}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {
                            props.shipmentById[0].shipment_receivers[0]
                              .postal_code
                          }
                          , {props.shipmentById[0].shipment_receivers[0].city}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          {
                            props.shipmentById[0].shipment_receivers[0].country
                              .name
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Card style={{ marginTop: "10px", padding: "10px" }}>
                  <Row>
                    <Col xl="6" lg="6" md="6" sm="6" xs="6">
                      <b>Shipment Detail</b>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          Shipper's Reference:{" "}
                          {props.shipmentById[0].shipment_ref}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          Deliver Option:{" "}
                          {props.shipmentById[0].service_type.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          Shipment Type: {props.shipmentById[0].shipment_type}
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          No of piece: {props.shipmentById[0].shipment_packages.length}
                        </Col>
                      </Row> */}
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          Total Weight: {props.shipmentById[0].total_weight}
                        </Col>
                      </Row>
                    </Col>
                    <Col xl="6" lg="6" md="6" sm="6" xs="6">
                      <b>Customs Information</b>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          Total Value:{" "}
                          {!isEmpty(props.shipmentById[0].shipment_data) &&
                            sumBy(props.shipmentById[0].shipment_data, "total")}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <b>Billing Information</b>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          Tax pay :
                          {props.shipmentById[0].shipment_tax_type.name}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <div className="sendDiv" style={{ marginTop: "30px" }}>
                  <Button
                    // hidden={Checkpermissions(
                    //   "permissions_cat_name",
                    //   "Shipment WayBill"
                    // )}
                    style={{
                      marginLeft: "2%",
                      color: "black",
                      width: "10%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                  >
                    <ReceiptTwoToneIcon
                      onClick={(e) => {
                        props.getShipmentByIdForWayBack(
                          props.shipmentById[0].shipment_id
                        );
                      }}
                    />
                  </Button>
                  <Button
                    // hidden={Checkpermissions(
                    //   "permissions_cat_name",
                    //   "Shipment Invoice"
                    // )}
                    disabled={
                      props.shipmentById[0].invoice_type == true ? false : true
                    }
                    style={{
                      marginLeft: "2%",
                      color: "black",
                      width: "10%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                  >
                    <PictureAsPdfTwoToneIcon
                      onClick={(e) => {
                        props.getShipmentByIdForInvoice(props.shipmentById[0]);
                      }}
                    />
                  </Button>
                </div>
              </>
            )}
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getShipmentByIdForWayBack: (id) => dispatch(getShipmentByIdForWayBack(id)),
    getShipmentByIdForInvoice: (data) =>
      dispatch(getShipmentByIdForInvoice(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingShipmentById: state.CargoShipmentReducer.isFetchingShipmentById,
  shipmentById: state.CargoShipmentReducer.shipmentById,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewShipmentById);
