import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  InputGroup,
  Card,
  FloatingLabel,
} from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { isEmpty, isNull, isNumber, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import { getShipmentByTrackingId } from "./TrackCargo.action";
import { TextField } from "@mui/material";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useMediaQuery, useTheme } from "@material-ui/core";
import moment from "moment";
// import HorizontalTimeline from "react-horizontal-timeline";

const TrackCargo = (props) => {
  let { id } = useParams();
  const [trackingId, setTrackingId] = useState();
  const [comment, setComment] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function trackCargo() {
    props.getShipmentByTrackingId(trackingId);
  }
  const [trackingError, setTrackingError] = useState(false);
  const VALUES = ["2021-01-01", "2021-01-15", "2021-03-22"];
  const handleTrackingIdChange = (e) => {
    // props.setReceiverData({ key: "company", value: e.target.value });
    if (e.target.value.length > 9) {
      setTrackingError(false);
      setTrackingId(e.target.value);
    } else {
      setTrackingError(true);
    }
    // if (e.target.value.length < 10) {
    //   setTrackingError(true);
    // }
    // if (e.target.value.length == 10) {

    // }
  };
  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    }),
  }));
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <FlightTakeoffIcon />,
      2: <FlightLandIcon />,
      3: <VideoLabelIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  const steps = [``, "Create an ad group"];

  useEffect(() => {
    !isUndefined(id) && setTrackingId(id);
    !isUndefined(id) && props.getShipmentByTrackingId(id);
    document.title = props.title;
  }, []);
  const dumy = [
    { date: 1, value: 2 },
    { date: 22, value: 22 },
    { date: 13, value: 23 },
    { date: 14, value: 24 },
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  return (
    <>
      <Container fluid>
        <div
          className="main"
          style={{ backgroundColor: "#F9FAFB", height: "100vh" }}
        >
          <Card
            style={{
              padding: isMobile ? "0.5" : "3%",
              height: isEmpty(props.shipmentByTrackingId)
                ? "94vh"
                : props.shipmentByTrackingId.length < 6
                ? "94vh"
                : "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              borer: "none",
            }}
          >
            <>
              <Row
                style={{
                  marginTop: "5px",
                  textAlign: "center",
                  fontSize: 40,
                }}
              >
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <b>TRACK & TRACE</b>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: 5,
                  textAlign: "center",
                }}
              >
                <Col xl="8" lg="8" md="8" sm="12" xs="12">
                  <InputGroup
                    className="mb-3"
                    style={{
                      marginLeft: isMobile ? "4vh" : "20vh",
                    }}
                  >
                    <TextField
                      required
                      id="standard-basic"
                      variant="standard"
                      type="number"
                      placeholder="Enter Tracking Number"
                      // disabled={!isUndefined(id) ? true : false}
                      value={trackingId}
                      // disabled={editable}
                      error={trackingError}
                      helperText={
                        trackingError
                          ? "Enter Valid Formate(10 Characters)"
                          : ""
                      }
                      onChange={handleTrackingIdChange}
                      sx={{
                        width: "80%",
                        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        "input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col xl="4" lg="4" md="4" sm="12" xs="12">
                  <Button
                    // variant="outline-secondary"
                    id="button-addon2"
                    disabled={isEmpty(trackingId)}
                    onClick={trackCargo}
                    style={{
                      color: "black",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",

                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    Track Shipment
                  </Button>
                </Col>
              </Row>

              {props.isFetchingShipmentByTrackingId ? (
                <div className="loader-div">
                  <Spinner
                    variant="secondary"
                    animation="grow"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <>
                  {isEmpty(props.shipmentByTrackingId) &&
                  props.shipmentByTrackingIdError == false ? (
                    ""
                  ) : props.shipmentByTrackingIdError == true ? (
                    <Card style={{ padding: "2%" }}>
                      <Row>
                        <Col xl="3" lg="3" md="3" sm="3" xs="3">
                          <ErrorTwoToneIcon sx={{ height: 150, width: 150 }} />
                        </Col>
                        <Col
                          xl="8"
                          lg="8"
                          md="8"
                          sm="8"
                          xs="8"
                          style={{
                            marginTop: "10px",
                            textAlign: "center",
                            fontSize: "40px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Public Sans,sans-serif",
                              fontSize: "36px",
                            }}
                          >
                            <p>
                              <b>No Shipment Is Found</b>
                              <br />
                              <b>Enter Correct Tracking Number</b>
                            </p>
                          </Typography>
                        </Col>
                      </Row>
                    </Card>
                  ) : (
                    <>
                      <Row style={{marginTop:'10px'}}>
                        <Stepper
                          alternativeLabel
                          activeStep={1}
                          connector={<ColorlibConnector />}
                        >
                          {!isEmpty(props.shipmentByTrackingId) &&
                            [
                              `Origin:${props.shipmentByTrackingId[0].shipment.shipment_senders[0].country.name}`,
                              `Destination:${props.shipmentByTrackingId[0].shipment.shipment_receivers[0].country.name}`,
                            ].map((label) => (
                              <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                  {label}
                                </StepLabel>
                              </Step>
                            ))}
                        </Stepper>
                      </Row>
                      <Row
                        style={{
                          marginTop: "20px",
                          textAlign: "center",
                          fontSize: "40px",
                        }}
                      >
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <Typography
                            style={{ fontFamily: "Public Sans,sans-serif" }}
                          >
                            {/* moment().format('MMMM Do YYYY, h:mm:ss a') */}
                            <b>Expected Delivery: {isNull(props.shipmentByTrackingId[0].shipment.expected_delivery_date)?'Expected Delivery Date To Be Announced Later': moment(props.shipmentByTrackingId[0].shipment.expected_delivery_date).format('dddd,MMMM Do YYYY')}</b>
                          </Typography>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginTop: "20px",
                          textAlign: "center",
                          fontSize: "40px",
                        }}
                      >
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <Typography
                            style={{ fontFamily: "Public Sans,sans-serif" }}
                          >
                            <b>TRACKING HISTORY</b>
                          </Typography>
                        </Col>
                      </Row>
                      {/* <HorizontalTimeline
                        styles={{ outline: "black", foreground: "#637381" }}
                        index={2}
                        // indexClick={(index) => {}}
                        values={VALUES}
                      /> */}
                      <Timeline>
                        {props.shipmentByTrackingId.map((item, i) => {
                          return (
                            <TimelineItem>
                              <TimelineOppositeContent color="text.secondary">
                                {item.status_date}
                              </TimelineOppositeContent>
                              <TimelineSeparator>
                                <CheckCircleTwoToneIcon />
                                {i == props.shipmentByTrackingId.length - 1 ? (
                                  ""
                                ) : (
                                  <TimelineConnector
                                    sx={{
                                      backgroundColor: "#3285A8",
                                      width: "3px",
                                    }}
                                  />
                                )}
                              </TimelineSeparator>
                              <TimelineContent>
                                <p><b>{item.status}</b></p>
                                <p style={{marginTop:'-20px'}}>{item.status_location}</p>
                              </TimelineContent>
                              {/* <TimelineContent>{item.status_location}</TimelineContent> */}
                              
                            </TimelineItem>
                          );
                        })}
                      </Timeline>
                    </>
                  )}
                </>
              )}
            </>
          </Card>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShipmentByTrackingId: (id) => dispatch(getShipmentByTrackingId(id)),
    // setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingShipmentByTrackingId:
    state.TrackCargoReducer.isFetchingShipmentByTrackingId,
  shipmentByTrackingId: state.TrackCargoReducer.shipmentByTrackingId,
  shipmentByTrackingIdError: state.TrackCargoReducer.shipmentByTrackingIdError,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackCargo);
