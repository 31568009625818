import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";

import { isEmpty } from "lodash";
import { baseURL, headers } from "../../../global/api";
import { ThemeProvider, createTheme } from "@mui/material";

const ViewRateLogs = (props) => {
  const navigate = useNavigate();
  const defaultMaterialTheme = createTheme();
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  useEffect(() => {
    //  props.setHeading("Uom List");
    // props.getReceiver();
    document.title = props.title;
    // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingReceiver ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{ height: "100%", backgroundColor: "#F9FAFB" }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                style={{
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                title="Rate Logs"
                columns={[
                  { title: "Date", field: "date" },
                  { title: "Shipment Type", field: "shipment_type" },
                  { title: "Delivery Time", field: "service_type.name" },
                  { title: "Path", field: "path" },
                ]}
                // data={props.receiverList}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    let url = `${baseURL}/rate/get_rate_logs?`;
                    url += "size=" + query.pageSize;
                    url += "&page=" + (query.page + 1);
                    //  url += "&search=" + query.search;
                    fetch(url, {
                      method: "GET",
                      headers: headers,
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        resolve({
                          data: result.result,
                          page: result.page - 1,
                          totalCount: result.total_results,
                        });
                      });
                  })
                }
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  paging: true,
                  pageSize: 50, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                  maxBodyHeight: "600px",
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#919EAB",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
                // editable={{
                //   // isEditHidden: rowData => Checkpermissions("permissionsSubCatName","Hall Edit"),
                //   // isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName","Hall Delete"),
                //   onRowUpdate: (newData, oldData) =>
                //     new Promise((resolve) => {
                //       setTimeout(() => {
                //         resolve();
                //         {
                //           props.updateReceiver(newData, oldData);
                //         }
                //       }, 600);
                //     }),
                //   onRowDelete: (oldData) =>
                //     new Promise((resolve) => {
                //       setTimeout(() => {
                //         resolve();
                //         props.deleteReceiver(oldData.receiver_id);
                //       }, 600);
                //     }),
                // }}
                // actions={[
                //   {
                //     icon: "edit",
                //     tooltip: "View Receiver",
                //     hidden: Checkpermissions(
                //       "permissions_cat_name",
                //       "Receiver Edit"
                //     ),
                //     onClick: (event, rowData) => {
                //       props.getReceiverByID(rowData.receiver_id);
                //       navigate("/cargo/receiver/edit_receiver");
                //     },
                //   },
                // ]}
              />
            </ThemeProvider>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({
  receiverList: state.CargoReceiverReducer.receiverList,
  isFetchingReceiver: state.CargoReceiverReducer.isFetchingReceiver,
  // flavorsDataList: state.FlavourReducer.flavorsDataList,
  // sizeDataList: state.sizeReducer.sizeDataList,
  // masterControlDataList:state.MasterControlReducer.masterControlDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRateLogs);
