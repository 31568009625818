import React, { useEffect } from "react";
import { Container, Button} from "react-bootstrap";
import "./add-levels.css";
import { getLevel2Data } from "./accountsLevel.action";
import { connect } from "react-redux";
import {  map, sortBy } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDF from "./PDF";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Level2Report = (props) => {
  const level1Data = props.level2List;
  useEffect(() => {
    props.getLevel2Data();
  }, []);
  return (
    <>
          {props.isFetchinglevel2List ? (
            <div className="loader-div">
              <Button variant="info" disabled>
                <Spinner
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="info"
                />

              </Button>
            </div>
          ) : (
            <Container fluid>
              <div className="main">
                <ExcelFile element={<button style={{ marginRight: "5%" }}>Generate Excel</button>}>
                  <ExcelSheet data={map(level1Data, lev => Object.assign({}, ...function flatten(o) { return [].concat(...Object.keys(o).map(k => typeof o[k] === 'object' ? flatten(o[k]) : ({ [k]: o[k] }))) }(lev)))} name="Level1Report">
                    <ExcelColumn label="level 1 code" value="key" />
                    <ExcelColumn label="level 1 name" value="name" />
                    <ExcelColumn label="level 2 code" value="allKey" />
                    <ExcelColumn label="level 2 name" value="levelTwoName" />
                  </ExcelSheet>
                </ExcelFile>

                    <button >
                    <PDFDownloadLink style={{ textDecoration: "none"}} document={<PDF array={sortBy(props.level2List, ['props.level2List', 'levelTwoData.allKey'])} />} fileName="Level2 Report.pdf">
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : 'Generate Level 2 Report!'
                    }
                  </PDFDownloadLink>
                  </button>
                  
              </div>

            </Container>

          )}

    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel2Data: () => dispatch(getLevel2Data()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level2List: state.accountsLevelReducer.level2List,
  isFetchinglevel2List: state.accountsLevelReducer.isFetchinglevel2List
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Level2Report);