import * as React from "react";
import { connect } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {Container} from "react-bootstrap"; 
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Menu from "./menu.js";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
//import { setHeading } from "../accounts/account.actions.js";
import IconMenu from "./IconMenu.js";
import { Navbar } from "react-bootstrap";
const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const drawerWidth = 240;

export function Dashboard1(props) {
  const permissions=JSON.parse(window.localStorage.getItem("permissions"))
  // console.log(props.props,permissions, "pppppppppppppppppp")
  return (
    <Box sx={{ display: "flex" }}>
       <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
    <Navbar style={{paddingLeft:'3%',paddingRight:'3%',backgroundColor:'#81abd3'}} >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>{props.props.heading}</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar>
    </AppBar>
    <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <Menu moduleKey={props.props.moduleKey} heading={props.props.heading} permissions={permissions}  />
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Outlet />
    </Box>
      </Box>
    // <Box sx={{ display: "flex" }}>
    //   {"hhh"}
    //   <CssBaseline />
    //   <AppBar
    //     position="fixed"
    //     sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
    //   >
    //     <Toolbar>
    //       <Typography variant="h6" noWrap component="div">
    //         {props.props.heading}
    //       </Typography>
          
    //       <IconMenu/>
    //     </Toolbar>
   
    //   </AppBar>
    //   <Drawer
    //     sx={{
    //       width: drawerWidth,
    //       flexShrink: 0,
    //       "& .MuiDrawer-paper": {
    //         width: drawerWidth,
    //         boxSizing: "border-box",
    //       },
    //     }}
    //     variant="permanent"
    //     anchor="left"
    //   >
    //     <Toolbar />
    //     <Divider />
    //     <Menu moduleKey={props.props.moduleKey} heading={props.props.heading} permissions={permissions}  />
    //   </Drawer>
    //   <Box
    //     component="main"
    //     sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
    //   >
    //     <Toolbar />
    //     <Outlet />
    //     {/* <Typography paragraph>
    //       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //       eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus
    //       dolor purus non enim praesent elementum facilisis leo vel. Risus at
    //       ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum
    //       quisque non tellus. Convallis convallis tellus id interdum velit
    //       laoreet id donec ultrices. Odio morbi quis commodo odio aenean sed
    //       adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies
    //       integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate
    //       eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo
    //       quis imperdiet massa tincidunt. Cras tincidunt lobortis feugiat
    //       vivamus at augue. At augue eget arcu dictum varius duis at consectetur
    //       lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa sapien
    //       faucibus et molestie ac.
    //     </Typography>
    //     <Typography paragraph>
    //       Consequat mauris nunc congue nisi vitae suscipit. Fringilla est
    //       ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar
    //       elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse
    //       sed nisi lacus sed viverra tellus. Purus sit amet volutpat consequat
    //       mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis
    //       risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas
    //       purus viverra accumsan in. In hendrerit gravida rutrum quisque non
    //       tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant
    //       morbi tristique senectus et. Adipiscing elit duis tristique
    //       sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
    //       eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
    //       posuere sollicitudin aliquam ultrices sagittis orci a.
    //     </Typography> */}
    //   </Box>
    // </Box>
  );
}

function Dashboard(props) {
  const [mode, setMode] = React.useState("light");

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        // setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      getColorMode: () => {
        //getMode(mode, state, dispatch)
      },
    }),

    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Dashboard1  props={props}/>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

const mapDispatchToProps = (dispatch) => ({
 // setHeading: (data) => dispatch(setHeading(data))
});

const mapStateToProps = (state, ownProps, number) => ({
  moduleKey: state.accountReducer.moduleKey,
  heading: state.accountReducer.heading
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
//export default ResponsiveDrawer;
