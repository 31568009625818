import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { phone } from "phone";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import ImageUploader from "react-images-upload";
import logo from "../../../Images/logo.png";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import AddIcCallTwoToneIcon from "@mui/icons-material/AddIcCallTwoTone";
import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import AddBusinessTwoToneIcon from "@mui/icons-material/AddBusinessTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import DialpadTwoToneIcon from "@mui/icons-material/DialpadTwoTone";
import { addSender, setEditSender, updateSender } from "./Sender.action";
import { getRole } from "../cargoLogin/CargoLogin.action";
import { getCountry } from "../country/Country.action";
// import { addHall } from "./Hall.action";
// import { getRestaurantData } from "../AddResturant/Resturant.action";
// import { setHeading } from "../../../accounts/account.actions";
// import { getUserRes } from "../../AddUser/user.actions";
const EditSender = (props) => {
  const [countryCode, setCountryCode] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [name, setName] = useState("");
  // const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhone] = useState(0);
  const [companyName, setCompanyName] = useState("");
  // const [website, setWebsiteURL] = useState("");
  const [postalCode, setPostalCode] = useState(0);
  const [city, setCity] = useState("");
  const [cnic, setCnic] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyAddress2, setCompanyAddress2] = useState("");
  const [companyAddress3, setCompanyAddress3] = useState("");
  const [state, setState] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [countryId, setCountryId] = useState("");
  const [country, setCountry] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function saveSender() {
    props.updateSender(
      {
        name: props.senderByID[0].name,
        company: props.senderByID[0].company,
        country_id: props.senderByID[0].country_id,
        address: props.senderByID[0].address,
        address_2: props.senderByID[0].address_2,
        address_3: props.senderByID[0].address_3,
        postal_code: props.senderByID[0].postal_code,
        city: props.senderByID[0].city,
        state: props.senderByID[0].state,
        email: props.senderByID[0].email,
        phone: props.senderByID[0].phone,
        cnic: props.senderByID[0].cnic,
      },
      props.senderByID[0].sender_id,
      handleVisible,
      handleDangerVisible
    );
  }

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cellNumberError, setCellNumberError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [addressError2, setAddressError2] = useState(false);
  const [CNICError, setCNICError] = useState(false);

  const handleCompanyNameChange = (e) => {
    props.setEditSender({
      key: "company",
      value: e.target.value.toUpperCase(),
    });
    if (e.target.value.length > 3) {
      setCompanyError(false);
    } else {
      setCompanyError(true);
    }
  };
  const handleNameChange = (e) => {
    props.setEditSender({
      key: "name",
      value: e.target.value.toUpperCase(),
    });
    if (e.target.value.length > 3) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };
  const handleEmailChange = (e) => {
    props.setEditSender({ key: "email", value: e.target.value });
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleCNICChange = (e) => {
    const cnicPattern = /^[0-9]{13}$/;
    if (e.target.value.length <= 13) {
      props.setEditSender({ key: "cnic", value: e.target.value });
      if (cnicPattern.test(e.target.value)) {
        setCNICError(false);
      } else {
        setCNICError(true);
      }
    }
  };

  const handleAddressChange = (e) => {
    if (e.target.value.length < 45) {
      props.setEditSender({
        key: "address",
        value: e.target.value.toUpperCase(),
      });
      setAddressError(false);
    } else {
      setAddressError(true);
    }
  };
  const handleAddress2Change = (e) => {
    if (e.target.value.length < 45) {
      props.setEditSender({
        key: "address_2",
        value: e.target.value.toUpperCase(),
      });
      setAddressError2(false);
    } else {
      setAddressError2(true);
    }
  };

  const handleCellNumberChange = (e) => {
    let data = phone(e.target.value, { country: `${countryCode}` });
    props.setEditSender({ key: "phone", value: e.target.value });
    if (countryCode == "US") {
      if (e.target.value.length == 10) {
        setCellNumberError(false);
      } else {
        setCellNumberError(true);
      }
    } else {
      if (data.isValid == true) {
        setCellNumberError(false);
        // props.setSenderData({ key: "phone", value: props.senderData[0].phone });
      } else {
        setCellNumberError(true);
        // props.setSenderData({ key: "phone", value: e.target.value });
      }
    }
    // }
  };
  const getCountryList =
    !isEmpty(props.countryList) &&
    props.countryList.map((x) => {
      let data = { value: x.country_id, label: x.name };
      return data;
    });
  const handleChangeCountry = (e) => {
    // setCountryId(e.target.value);
    // setCountry(e.target.value);
    props.setEditSender({
      key: "country_id",
      value: e.target.value,
    });
    setCountry(e.label);
    setCountryCode(e.country_code);
    setDialCode(e.dial_code);
    setPhone("");
    setCellNumberError(false);
  };

  useEffect(() => {
    // props.setHeading("Add Hall");
    props.getCountry();
    document.title = props.title;
  }, []);
  return (
    <>
      {props.isAddingSender ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Row style={{ marginTop: "15px" }}>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  error={nameError}
                  value={!isEmpty(props.senderByID) && props.senderByID[0].name}
                  helperText={
                    nameError
                      ? "Please Enter Your Name (Atleast 3 Character)"
                      : ""
                  }
                  label="Name"
                  onChange={(e) => handleNameChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DomainTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="Company Name "
                  error={companyError}
                  value={
                    !isEmpty(props.senderByID) && props.senderByID[0].company
                  }
                  helperText={
                    nameError
                      ? "Please Enter Your Company Name (Atleast 3 Character)"
                      : ""
                  }
                  onChange={(e) => handleCompanyNameChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label" required>
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={
                      !isEmpty(props.senderByID) &&
                      props.senderByID[0].country_id
                    }
                    // onChange={handleChangeCountry}
                    // options={getCountryList}
                    label="Select Country"
                    onChange={(e) =>
                      props.setEditSender({
                        key: "country_id",
                        value: e.target.value,
                      })
                    }
                  >
                    {props.countryList.map((name) => (
                      <MenuItem key={name.country_id} value={name.country_id}>
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddBusinessTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  value={
                    !isEmpty(props.senderByID) && props.senderByID[0].address
                  }
                  label="Address"
                  error={addressError}
                  helperText={
                    addressError ? "Address Length (Maximium 45 Character)" : ""
                  }
                  onChange={(e) => handleAddressChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddBusinessTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  value={
                    !isEmpty(props.senderByID) && props.senderByID[0].address_2
                  }
                  label="Address 2"
                  error={addressError2}
                  helperText={
                    addressError2
                      ? "Address Length (Maximium 45 Character)"
                      : ""
                  }
                  onChange={(e) => handleAddress2Change(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddBusinessTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  value={
                    !isEmpty(props.senderByID) && props.senderByID[0].address_3
                  }
                  label="Address 3"
                  onChange={(e) =>
                    props.setEditSender({
                      key: "address_3",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddLocationAltTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="City"
                  value={!isEmpty(props.senderByID) && props.senderByID[0].city}
                  onChange={(e) =>
                    props.setEditSender({
                      key: "city",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddLocationAltTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  value={
                    !isEmpty(props.senderByID) && props.senderByID[0].state
                  }
                  label="State"
                  onChange={(e) =>
                    props.setEditSender({
                      key: "state",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DialpadTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  // type="Number"
                  id="standard-basic"
                  variant="standard"
                  required
                  value={
                    !isEmpty(props.senderByID) &&
                    props.senderByID[0].postal_code
                  }
                  label="Postal/Zip Code"
                  onChange={(e) =>
                    props.setEditSender({
                      key: "postal_code",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              {/* email */}
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    type: "email",
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="email"
                  id="standard-basic"
                  variant="standard"
                  label="Email "
                  error={emailError}
                  value={
                    !isEmpty(props.senderByID) && props.senderByID[0].email
                  }
                  helperText={emailError ? "Please Enter Valid Email" : ""}
                  onChange={(e) => handleEmailChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddIcCallTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  required
                  value={
                    !isEmpty(props.senderByID) && props.senderByID[0].phone
                  }
                  error={cellNumberError}
                  helperText={
                    cellNumberError ? "Please Enter Valid Cell Number" : ""
                  }
                  label="Phone"
                  onChange={(e) =>
                    props.setEditSender({ key: "phone", value: e.target.value })
                  }
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  value={!isEmpty(props.senderByID) && props.senderByID[0].cnic}
                  label="CNIC"
                  error={CNICError}
                  helperText={
                    CNICError ? "Please Enter Valid CNIC (Without Dashes)" : ""
                  }
                  onChange={(e) => handleCNICChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: "25px" }}>
              <Button
                onClick={saveSender}
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "20%",
                  backgroundColor: "#C5CBCF",
                  border: "1px solid white",
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSender: (data, handleVisible, handleDangerVisible) =>
      dispatch(addSender(data, handleVisible, handleDangerVisible)),
    // getRestaurantData: () => dispatch(getRestaurantData()),
    // setHeading: (data) => dispatch(setHeading(data)),
    // getUser: () => dispatch(getUserRes()),
    // imageFront: (data) => dispatch(addImage(data)),
    // imageBack: (data) => dispatch(addImageBack(data)),
    // companyLogo: (data) => dispatch(addLogo(data)),
    updateSender: (data, id, handleVisible, handleDangerVisible) =>
      dispatch(updateSender(data, id, handleVisible, handleDangerVisible)),
    getCountry: () => dispatch(getCountry()),
    setEditSender: (data) => dispatch(setEditSender(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  senderByID: state.CargoSenderReducer.senderByID,
  isFetchingSenderById: state.CargoSenderReducer.isFetchingSenderById,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  countryList: state.CargoCountryReducer.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSender);
