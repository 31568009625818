const initialState = {
  stepsCreateShipment: ["Create Shipment1", "Select Packging", "Demy"],
  activeSteps: 0,
  packagingDetail: [
    {
      name: "BOX 1",
      weight: "",
      length: "",
      width: "",
      height: "",
      accWeight: 0,
      chWeight: 0,
    },
  ],
  isFetchingShipmentList: false,
  shipmentList: [],
  shipmentTotalPage: 0,
  isFetchingShipmentById: false,
  shipmentById: [],
  isFetchingShipmentStatus: false,
  shipmentStatus: [],
  shipmentByIdPackages: [],
  shipmentByIdData: [],
  isSavingShipment: false,
};

const ShipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_REDUCER":
      return {
        ...state,
        packagingDetail: [
          {
            name: "BOX 1",
            weight: "",
            length: "",
            width: "",
            height: "",
            accWeight: 0,
            chWeight: 0,
          },
        ],
        // isFetchingShipmentList: false,
        // shipmentList: [],
        // shipmentTotalPage:0,
        // isFetchingShipmentById: false,
        // shipmentById: [],
        // isFetchingShipmentStatus: false,
        // shipmentStatus: [],
        // shipmentByIdPackages: [],
        // shipmentByIdData:[],
      };
    case "NEXT_STEPS":
      let prevActiveStep = state.activeSteps;
      let activeStep = prevActiveStep + 1;
      // valuePackages[keyPackages] = action.payload.value;
      return {
        ...state,
        activeSteps: activeStep,
      };
    case "BACK_STEPS":
      let prevActiveStep1 = state.activeSteps;
      let activeStep1 = prevActiveStep1 - 1;
      // valuePackages[keyPackages] = action.payload.value;
      return {
        ...state,
        activeSteps: activeStep1,
      };
    case "START_STEPS":
      // let prevActiveStep1 = state.activeSubSteps;
      // let activeStep1= prevActiveStep1-1;
      // valuePackages[keyPackages] = action.payload.value;
      return {
        ...state,
        activeSteps: 0,
      };
    case "SET_ADD_INPUT_PACKING_DETAIL":
      return {
        ...state,
        packagingDetail: [
          ...state.packagingDetail,
          {
            name: `BOX ${state.packagingDetail.length + 1}`,
            weight: "",
            length: "",
            width: "",
            height: "",
            accWeight: 0,
            chWeight: 0,
          },
        ],
      };
    // const list = [...inputField1];
    // list.splice(index, 1);
    // setInputField1(list);
    case "SET_REMOVE_INPUT_PACKING_DETAIL":
      let prevList = [...state.packagingDetail];
      prevList.splice(action.payload, 1);
      return {
        ...state,
        // packagingDetail: [{
        //   name: "BOX 1",
        //   weight: "",
        //   length: "",
        //   width: "",
        //   height: "",
        //   accWeight:0,
        //   chWeight:0,
        //   no_packages:1,
        // }]
        packagingDetail: prevList,
      };

    case "SET_ADD_PACKING_DETAIL":
      // list[index]["product"]
      let list = [...state.packagingDetail];
      // let keyPackages11= action.payload.key;

      list[action.payload.length][action.payload.key] = action.payload.value;

      return {
        ...state,
        packagingDetail: list,
      };
    case "REQUEST_GET_SHIPMENT":
      return { ...state, isFetchingShipmentList: true };
    case "SUCCESS_GET_SHIPMENT":
      return {
        ...state,
        shipmentList: action.payload.data,
        shipmentTotalPage: action.payload.total_pages,
        isFetchingShipmentList: false,
      };
    case "ERROR_GET_SHIPMENT":
      return { ...state, isFetchingShipmentList: false };

    case "REQUEST_GET_SHIPMENT_BY_STATUS_ID":
      return { ...state, isFetchingShipmentList: true };
    case "SUCCESS_GET_SHIPMENT_BY_STATUS_ID":
      return {
        ...state,
        shipmentList: action.payload.data,
        isFetchingShipmentList: false,
      };
    case "ERROR_GET_SHIPMENT_BY_STATUS_ID":
      return { ...state, isFetchingShipmentList: false };

    case "REQUEST_GET_SHIPMENT_BY_ID":
      return { ...state, isFetchingShipmentById: true };
    case "SUCCESS_GET_SHIPMENT_BY_ID":
      return {
        ...state,
        shipmentById: action.payload.data,
        shipmentByIdPackages: action.payload.data[0].shipment_packages,
        shipmentByIdData: action.payload.data[0].shipment_data,
        isFetchingShipmentById: false,
      };
    case "ERROR_GET_SHIPMENT_BY_ID":
      return { ...state, isFetchingShipmentById: false };

    case "REQUEST_GET_SHIPMENT_STATUS":
      return { ...state, isFetchingShipmentStatus: true };
    case "SUCCESS_GET_SHIPMENT_STATUS":
      return {
        ...state,
        shipmentStatus: action.payload.data,
        isFetchingShipmentStatus: false,
      };
    case "ERROR_GET_SHIPMENT_STATUS":
      return { ...state, isFetchingShipmentStatus: false };
    case "SET_EDIT_SHIPMENT_DATA":
      let valueEmp = state.shipmentById[0];
      let keyEmp = action.payload.key;
      valueEmp[keyEmp] = action.payload.value;
      // action.payload.key=="shipment_type"&&action.payload.value=="Package"&&isEmpty(state.shipmentByIdData)
      return { ...state, shipmentById: [valueEmp] };
    case "SET_EDIT_SHIPMENT_PACKAGING_DATA":
      let valueEmppack = state.shipmentByIdPackages[action.payload.length];
      let keyEmppack = action.payload.key;
      valueEmppack[keyEmppack] = action.payload.value;
      let dumy = [...state.shipmentByIdPackages];
      return { ...state, shipmentByIdPackages: dumy };
    case "SET_EDIT_SHIPMENT_PACKAGING_ADD_DATA":
      return {
        ...state,
        shipmentByIdPackages: [
          ...state.shipmentByIdPackages,
          {
            name: "",
            quantity: 0,
            weight: "",
            length: "",
            width: "",
            height: "",
          },
        ],
      };
    case "SET_EDIT_SHIPMENT_PACKAGING_REMOVE_DATA":
      let prevDataList = [...state.shipmentByIdPackages];
      prevDataList.splice(action.payload, 1);
      return {
        ...state,
        shipmentByIdPackages: prevDataList,
      };
    case "SET_EDIT_SHIPMENT_DETAIL":
      let valueEmpDetail = state.shipmentByIdData[action.payload.length];
      let keyEmpDetail = action.payload.key;
      console.log(valueEmpDetail,"valueEmpDetail")
      valueEmpDetail[keyEmpDetail] = action.payload.value;
      let dumyDetail = [...state.shipmentByIdData];
      return { ...state, shipmentByIdData: dumyDetail };
    case "SET_EDIT_SHIPMENT_ADD_DETAIL":
      return {
        ...state,
        shipmentByIdData: [
          ...state.shipmentByIdData,
          {
            desc: "",
            code: "",
            quantity: 0,
            uom_id: 1,
            // units: "",
            value: 0,
            currency_id: 5,
            weight: 0,
            country_id: "",
          },
        ],
      };
    case "SET_EDIT_SHIPMENT_REMOVE_DETAIL":
      let prevDetailDataList = [...state.shipmentByIdData];
      prevDetailDataList.splice(action.payload, 1);
      return {
        ...state,
        shipmentByIdData: prevDetailDataList,
      };

    case "SET_EDIT_SHIPMENT_SENDER":
      let valueSender = state.shipmentById[0];
      let valueSender1 = valueSender.shipment_senders[0];
      let keySender = action.payload.key;
      valueSender1[keySender] = action.payload.value;
      // action.payload.key=="shipment_type"&&action.payload.value=="Package"&&isEmpty(state.shipmentByIdData)
      let data = state.shipmentById[0].shipment_senders;
      return { ...state, data: [valueSender1] };
    case "REQUEST_ADD_SHIPMENT":
      return { ...state, isSavingShipment: true };
    case "SUCCESS_ADD_SHIPMENT":
      return {
        ...state,
        // shipmentStatus: action.payload.data,
        isSavingShipment: false,
      };
    case "ERROR_ADD_SHIPMENT":
      return { ...state, isSavingShipment: false };
    case "SET_EDIT_STATUS":
      let valueEmp1 = state.shipmentById[0];
      let keyEmp1 = action.payload.key;
      valueEmp1[keyEmp1] = action.payload.value;
      let valueEmp2 = [valueEmp1];
      return { ...state, shipmentById: [valueEmp1] };
    default:
      return state;
  }
};

export default ShipmentReducer;
