import API from "../../../global/api";

export const requestAddReceiver = () => {
  return {
    type: "REQUEST_ADD_RECEIVER",
  };
};
export const successAddReceiver = (data) => {
  return {
    type: "SUCCESS_ADD_RECEIVER",
    payload: data,
  };
};
export const errorAddReceiver = () => {
  return {
    type: "ERROR_ADD_RECEIVER",
  };
};
export const addReceiver = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddReceiver());
    API.post(`/receiver`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successAddReceiver(data1));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddReceiver());
        handleDangerVisible(error.response.data.message);
      });
  };
};

export const requestGetReceiver = () => {
  return {
    type: "REQUEST_GET_RECEIVER",
  };
};
export const successGetReceiver = (data) => {
  return {
    type: "SUCCESS_GET_RECEIVER",
    payload: data,
  };
};
export const errorGetReceiver = () => {
  return {
    type: "ERROR_GET_RECEIVER",
  };
};
export const getReceiver = () => {
  return (dispatch) => {
    dispatch(requestGetReceiver());
    API.get(`/receiver`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetReceiver(getData));
      })
      .catch((error) => {
        dispatch(errorGetReceiver());
      });
  };
};
export const DeleteRequestReceiver = () => {
  return {
    type: "DELETE_REQUEST_RECEIVER",
  };
};
export const DeleteSuccessReceiver = (user_id) => {
  return {
    type: "DELETE_SUCCESS_RECEIVER",
    user_id: user_id,
  };
};
export const DeleteErrorReceiver = () => {
  return {
    type: "DELETE_ERROR_RECEIVER",
  };
};
export const deleteReceiver = (vendorId) => {
  return (dispatch) => {
    dispatch(DeleteRequestReceiver());
    API.delete(`/receiver/${vendorId}`)
      .then((res) => {
        dispatch(getReceiver());
        dispatch(DeleteSuccessReceiver(vendorId));
      })
      .catch((error) => {
        dispatch(DeleteErrorReceiver());
      });
  };
};
export const UpdateRequestReceiver = () => {
  return {
    type: "UPDATE_REQUEST_RECEIVER",
  };
};
export const UpdateSuccessReceiver = (data) => {
  return {
    type: "UPDATE_SUCCESS_RECEIVER",
    payload: data,
  };
};

export const UpdateErrorReceiver = () => {
  return {
    type: "UPDATE_ERROR_RECEIVER",
  };
};

export const updateReceiver = (
  data,
  receiver_id,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(UpdateRequestReceiver());
    API.put(
      `/receiver/${receiver_id}`,
      data,
      // {
      //   name: data.name,
      //   company: data.company,
      //    country_id:data.country_id,
      //   address: data.address,
      //   address_2:data.address_2,
      //   address_3:data.address_3,
      //   postal_code:data.postal_code,
      //   city:data.city,
      //   state:data.state,
      //   email: data.email,
      //   phone: data.phone,
      // },
      {}
    )
      .then((res) => {
        dispatch(UpdateSuccessReceiver([data]));
        handleVisible("success");
      })
      .catch((error) => {
        dispatch(UpdateErrorReceiver());
        handleDangerVisible("error");
      });
  };
};
export const requestGetReceiverById = () => {
  return {
    type: "REQUEST_GET_RECEIVER_BY_ID",
  };
};
export const successGetReceiverById = (data) => {
  return {
    type: "SUCCESS_GET_RECEIVER_BY_ID",
    payload: data,
  };
};
export const errorGetReceiverById = () => {
  return {
    type: "ERROR_GET_RECEIVER_BY_ID",
  };
};
export const getReceiverById = (id) => {
  return (dispatch) => {
    dispatch(requestGetReceiverById());
    API.get(`/receiver/${id}`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetReceiverById(getData));
      })
      .catch((error) => {
        dispatch(errorGetReceiverById());
      });
  };
};
export const setEditReceiver = (data) => {
  return {
    type: "SET_EDIT_RECEIVER",
    payload: data,
  };
};
