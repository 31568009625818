const initialState = {
  isFetchingShipmentByDates: false,
  ShipmentDateList: [],

  isFetchingLedger: false,
  ledgerList: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_SHIPMENT_BY_DATES":
      return { ...state, isFetchingShipmentByDates: true };
    case "SUCCESS_GET_SHIPMENT_BY_DATES":
      return {
        ...state,
        ShipmentDateList: action.payload.data,
        isFetchingShipmentByDates: false,
      };
    case "ERROR_GET_SHIPMENT_BY_DATES":
      return { ...state, isFetchingShipmentByDates: false };

    case "REQUEST_GET_LEDGER":
      return { ...state, isFetchingLedger: true };
    case "SUCCESS_GET_LEDGER":
      return {
        ...state,
        ledgerList: action.payload.data,
        isFetchingLedger: false,
      };
    case "ERROR_GET_LEDGER":
      return { ...state, isFetchingLedger: false };

    default:
      return state;
  }
};

export default dashboardReducer;
