import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import "jspdf-autotable";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  addShipment,
  backSteps,
  nextSteps,
  startSteps,
} from "./Shipment.action";
import { getCountry } from "../country/Country.action";
import { getSender } from "../sender/Sender.action";
import Step2SelectPackaging from "./Step2SelectPackaging";
import CreateShipmentDumy from "./createShipment/CreateShipmentDumy";
const AddShipment = (props) => {
  const [skipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  useEffect(() => {
    // props.setHeading("Add Hall");
    // props.getSender()
    props.startSteps();
    document.title = props.title;
  }, []);
  return (
    <>
      {/* {props.isFetchingSender || props.isFetchingCountry ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : ( */}
      <Container fluid>
        {false ? (
          <div className="main" style={{ height: "88vh" }}>
            <h1>Add Sender from Master Control In Order to add Shipments</h1>
          </div>
        ) : (
          <div className="main" style={{ backgroundColor: "#F9FAFB" }}>
            <CreateShipmentDumy/>
          </div>
        )}
      </Container>
      {/* )} */}
    </>
  );
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <CreateShipmentDumy />;

      case 1:
        return <Step2SelectPackaging />;
    }
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    addShipment: (data, handleVisible, handleDangerVisible) =>
      dispatch(addShipment(data, handleVisible, handleDangerVisible)),
    getCountry: () => dispatch(getCountry()),
    getSender: () => dispatch(getSender()),
    handleNextStep: () => dispatch(nextSteps()),
    handleBackStep: () => dispatch(backSteps()),
    startSteps: () => dispatch(startSteps()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  senderList: state.CargoSenderReducer.senderList,
  isFetchingSender: state.CargoSenderReducer.isFetchingSender,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  countryList: state.CargoCountryReducer.countryList,
  stepsCreateShipment: state.CargoShipmentReducer.stepsCreateShipment,
  activeSteps: state.CargoShipmentReducer.activeSteps,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddShipment);
