import API from "../../../global/api";
export const requestGetShipmentByTrackingId = () => {
  return {
    type: "REQUEST_GET_SHIPMENT_BY_TRACKING_ID",
  };
};
export const successGetShipmentByTrackingId = (data) => {
  return {
    type: "SUCCESS_GET_SHIPMENT_BY_TRACKING_ID",
    payload: data,
  };
};
export const errorGetShipmentByTrackingId = () => {
  return {
    type: "ERROR_GET_SHIPMENT_BY_TRACKING_ID",
  };
};
export const getShipmentByTrackingId = (id) => {
  return (dispatch) => {
    dispatch(requestGetShipmentByTrackingId());
    API.get(`/shipment/shipment_tracking_history/${id}`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetShipmentByTrackingId(getData));
      })
      .catch((error) => {
        dispatch(errorGetShipmentByTrackingId());
      });
  };
};
export const setEditStatusFields = (data) => {
  return {
    type: "SET_EDIT_STATUS_FIELDS",
    payload: data,
  };
};
export const setEditDate = (data) => {
  return {
    type: "SET_EDIT_DATE",
    payload: data,
  };
};
