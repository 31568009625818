import API from "../../../global/api";

export const requestAddService = () => {
  return {
    type: "REQUEST_ADD_SERVICE",
  };
};
export const successAddService = (data) => {
  return {
    type: "SUCCESS_ADD_SERVICE",
    payload: data,
  };
};
export const errorAddService = () => {
  return {
    type: "ERROR_ADD_SERVICE",
  };
};
export const addService = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddService());
    API.post(`/cargo_company`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successAddService(data1));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddService());
        handleDangerVisible(error.response.data.message);
      });
  };
};

export const requestGetService = () => {
  return {
    type: "REQUEST_GET_SERVICE",
  };
};
export const successGetService = (data) => {
  return {
    type: "SUCCESS_GET_SERVICE",
    payload: data,
  };
};
export const errorGetService = () => {
  return {
    type: "ERROR_GET_SERVICE",
  };
};
export const getService = () => {
  return (dispatch) => {
    dispatch(requestGetService());
    API.get(`/cargo_company?size=10&page=1`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetService(getData));
      })
      .catch((error) => {
        dispatch(errorGetService());
      });
  };
};

export const DeleteRequestService = () => {
  return {
    type: "DELETE_REQUEST_SERVICE",
  };
};
export const DeleteSuccessService = (user_id) => {
  return {
    type: "DELETE_SUCCESS_SERVICE",
    user_id: user_id,
  };
};
export const DeleteErrorService = () => {
  return {
    type: "DELETE_ERROR_SERVICE",
  };
};
export const deleteService = (Id) => {
  return (dispatch) => {
    dispatch(DeleteRequestService());
    API.delete(`/cargo_company/${Id}`)
      .then((res) => {
        dispatch(getService());
        dispatch(DeleteSuccessService(Id));
      })
      .catch((error) => {
        dispatch(DeleteErrorService());
      });
  };
};
export const UpdateRequestService = () => {
  return {
    type: "UPDATE_REQUEST_SERVICE",
  };
};
export const UpdateSuccessService = (data) => {
  return {
    type: "UPDATE_SUCCESS_SERVICE",
    payload: data,
  };
};

export const UpdateErrorService = () => {
  return {
    type: "UPDATE_ERROR_SERVICE",
  };
};

export const updateService = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestService());
    API.put(
      `/cargo_company/${oldData.id}`,
      {
        name: data.name,
        code: data.serviceCode,
      },
      {}
    )
      .then((res) => {
        dispatch(getService());
        dispatch(UpdateSuccessService([data]));
      })
      .catch((error) => dispatch(UpdateErrorService()));
  };
};
