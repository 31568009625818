import React, { useReducer, createContext } from 'react';
import combineReducers from 'react-combine-reducers';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
//Here we are importing reducer and initial state from file
// import { usersReducer, usersInitialState } from './views/accounts/login/login.reducer';
// import {dashboardReducer, dashboardInitialState } from './views/dashboard/Dashboard.reducer';
// import {SearchImagesReducer, ImageSearchInitialState} from './views/dashboard/ImageSearch/image-search.reducers'
// import { UserAccountReducer, UserAccountInitialState } from './views/dashboard/UserAccounts/UserAccounts.reducer';
// import { ReportReducer, ReportInitialState } from './views/dashboard/Reports/Reports.reducer';
// import { fpReducer, fpInitialState } from './views/accounts/forget-password/forget-password.reducers';
import configureStore, { history } from './configureStore';

const store =configureStore()
// Combined Reducers from the folders
// const [globalReducer, initialGlobalReducer] = combineReducers({
//     users: [usersReducer, usersInitialState],
//     dashboard: [dashboardReducer, dashboardInitialState],
//     images: [SearchImagesReducer, ImageSearchInitialState],
//     userAccount: [UserAccountReducer, UserAccountInitialState],
//     reports: [ReportReducer, ReportInitialState],
//     fp_email: [fpReducer, fpInitialState]
//   });
  
export const Store = ({ children }) => {
  //  const [state, dispatch] = useReducer(globalReducer, initialGlobalReducer);
    return (
        <Provider store={store}> {children} </Provider>
    )
}

//export const Context = createContext(initialGlobalReducer);
