import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { sumBy, isEmpty } from "lodash";
import "jspdf-autotable";
import Swal from "sweetalert";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import Typography from "@mui/material/Typography";
import { InputAdornment } from "@mui/material";
import {
  resetReducerShipment,
  setBank,
  setEstimatedDate,
  setFinancialInstrument,
  setNo,
  setRebate,
  setSRO,
  setTaxType,
} from "./CreateShipment.action";
import moment from "moment";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import { addShipment, resetReducer } from "../Shipment.action";
const Step4HowWillPay = (props) => {
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
    props.resetReducerShipment();
    props.resetReducer();
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const handleChangeTaxType = (e) => {
    props.setTaxType(e.target.value);
  };
  const handleAddShipment = (e) => {
    props.addShipment(
      {
        shipment_desc: props.description,
        rebate: props.rebate,
        sro_number: props.sro,
        expected_delivery_date: props.estimatedDeliveryDate,
        // shipment_ref: props.shipmentRef === "" ? `${props.userDetail.firstName}/${props.userDetail.companyName}` : props.shipmentRef,
        shipment_ref: `${props.userDetail.firstName
          }${" "}${props.userDetail.lastName.toUpperCase()}/${props.userDetail.companyName
          }`,
        // total_amount: 111,
        vat: props.senderData[0].vatId,
        total_packages:
          props.packagingDetail[0].quantity === 0
            ? 0
            : sumBy(props.packagingDetail, "quantity"),
        total_weight:
          props.packagingDetail[0].weight === 0
            ? 0
            : sumBy(props.packagingDetail, "weight"),
        // shipment_date: props.date,
        financial_instruments: props.instrument,
        financial_instruments_no:
          props.instrument == 0 ? null : props.instrument_No,
        financial_instruments_bank: props.instrument_bank,
        shipment_purpose_id:
          props.shipmentType === "Doc" ? null : props.shipmentPurpose,
        // tracking_id: "1234",
        shipment_type: props.shipmentType,
        service_type_id: props.serviceType,
        shipment_tax_id: props.taxType,
        receiver_id:
          props.receiverData[0].id === "" ? null : props.receiverData[0].id,
        sender_id:
          props.senderData[0].id === "" ? null : props.senderData[0].id,
        data: props.shipmentType === "Doc" ? [] : props.shipmentDetail,
        packages: props.packagingDetail,
        invoice_type: props.invoiceType,
        sender: {
          name: props.senderData[0].name,
          company: props.senderData[0].company,
          address: props.senderData[0].address,
          address_2: props.senderData[0].address_2,
          address_3: props.senderData[0].address_3,
          postal_code: props.senderData[0].postal_code,
          city: props.senderData[0].city,
          state: props.senderData[0].state,
          email: props.senderData[0].email,
          // phone_type: props.senderData[0].phone_type,
          code: props.senderData[0].code,
          phone: props.senderData[0].phone,
          country_id: props.senderData[0].country_id,
          ntn: props.senderData[0].vatId,
          cnic: props.senderData[0].cnic,
        },
        receiver: {
          name: props.receiverData[0].name,
          company: props.receiverData[0].company,
          address: props.receiverData[0].address,
          address_2: props.receiverData[0].address_2,
          address_3: props.receiverData[0].address_3,
          postal_code: props.receiverData[0].postal_code,
          city: props.receiverData[0].city,
          state: props.receiverData[0].state,
          email: props.receiverData[0].email,
          // phone_type: props.senderData[0].phone_type,
          code: props.receiverData[0].code,
          phone: props.receiverData[0].phone,
          country_id: props.receiverData[0].country_id,
          vat: props.receiverData[0].vatId,
        },
      },
      handleVisible,
      handleDangerVisible
    );
  
  };
  const handleChange = (e) => {
    props.setFinancialInstrument(e.target.value);

    // setCountry(e.target.value);
  };
  function isValidObject(obj) {
    for (let key in obj) {
      if (obj[key] == null || obj[key].length == 0) {
        return false;
      }
    }
    return true;
  }
  const checkPackaging = () => {
    return isEmpty(props.packagingDetail.filter((obj) => obj.width === "" || obj.length === "" || obj.height === "" || obj.weight === "")) ? false : true;

  };
  const checkShipmentDetailsParrticulars = () => {
    return isEmpty(props.shipmentDetail.filter((obj) => obj.desc === "" || obj.quantity === "" || obj.quantity === 0 || obj.units === "" || obj.currency_id === "" || obj.country_id == 0 || obj.value === 0 || obj.value === "" || obj.uom_id === 0)) ? false : true;

  };
  const handleChangeEstimatedDeliveryDate = (e) => {
    props.setEstimatedDate(e.target.value);
  };
  const handleChangeRebate = (e) => {
    props.setRebate(e.target.value);

  };
  
  useEffect(() => {
    // props.getShipmentPurpose()
    // props.getCurrency()
    // props.getCountry()
  }, []);
  return (
    <Card
      style={{
        borderRadius: "30px",
        boxShadow:
          " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        border: "none",
        padding: "20px",
        marginTop: "20px",
      }}
    >
      <Row style={{ marginTop: "20px" }}>
        <Col xl="12" lg="12" md="12" sm="12" xs="12">
          <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
            <b>How Will You Pay?</b>
          </Typography>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <Col xl="4" lg="4" md="4" sm="12" xs="12">
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label" required>
              Select Who Will pay duties and taxes
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              label="Select How Will pay"
              onChange={handleChangeTaxType}
              value={props.taxType}
            >
              {props.payList.map((name) => (
                <MenuItem
                  key={name.shipment_tax_id}
                  value={name.shipment_tax_id}
                >
                  {name.name.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        <Col xl="4" lg="4" md="4" sm="12" xs="12">
          <TextField
            InputProps={{
              inputProps: {
                min: moment(new Date()).format("YYYY-MM-DD"),
              },
              startAdornment: (
                <InputAdornment position="start">
                  <DateRangeTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type="date"
            id="standard-basic"
            variant="standard"
            label="Estimated Delivery Date"
            value={props.estimatedDeliveryDate}
            // defaultValue={new Date().toISOString().split("T")[0]}
            onChange={handleChangeEstimatedDeliveryDate}
            sx={{
              width: "100%",
            }}
          />
        </Col>
        <Col xl="4" lg="4" md="4" sm="12" xs="12">
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label" required>
              Financial Instrument
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              label="Financail Instrument"
              onChange={handleChange}
              value={props.instrument}
            >
              <MenuItem key={1} value={1}>
                {"YES"}
              </MenuItem>
              <MenuItem key={0} value={0}>
                {"NO"}
              </MenuItem>
            </Select>
          </FormControl>
        </Col>
      </Row>
      {props.instrument == 1 && (
        <>
          <Row style={{ marginTop: "15px" }}>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <NumbersTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                label="Number"
                value={props.instrument_No}
                onChange={(e) => props.setNo(e.target.value.toUpperCase())}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DomainTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                label="Bank"
                value={props.instrument_bank}
                onChange={(e) => props.setBank(e.target.value.toUpperCase())}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label" required>
                  Select Rebate Claim
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="Select Rebate Claim"
                  value={props.rebate}
                  onChange={handleChangeRebate}
                >
                  <MenuItem key={1} value={1}>
                    {"YES"}
                  </MenuItem>
                  <MenuItem key={0} value={0}>
                    {"NO"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Col>
            {props.rebate == 1 && (
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="Sro Number"
                  value={props.sro}
                  onChange={(e) => props.setSRO(e.target.value.toUpperCase())}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            )}
          </Row>
          <Row></Row>
        </>
      )}

      <div className="sendDiv" style={{ marginTop: "15px" }}>
        {props.isSavingShipment ? (
          <div className="loader-div">
            <Spinner
              variant="secondary"
              animation="grow"
              size="xl"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <Button
            onClick={handleAddShipment}
            disabled={
              (props.serviceType === 0) ||
              (props.taxType === 0) ||
              (props.estimatedDeliveryDate === "") ||
              checkPackaging() ||
              (props.instrument === "") ||
              ((props.shipmentType == "Doc")
                ? false
                : ((props.shipmentPurpose == 0) ?
                  checkShipmentDetailsParrticulars() : false))||
                  props.senderData[0].company === "" ||
                  props.senderData[0].country_id === 0 ||
                  props.senderData[0].address === "" ||
                  props.senderData[0].phone === ""||
                  props.senderData[0].name === ""||
                  props.receiverData[0].company === "" ||
                  props.receiverData[0].country_id === 0 ||
                  props.receiverData[0].address === "" ||
                  props.receiverData[0].phone === ""||
                  props.receiverData[0].name === ""


            }
            style={{
              marginLeft: "2%",
              color: "black",
              // width: "20%",
              backgroundColor: "#C5CBCF",
              border: "1px solid white",
            }}
          >
            Save
          </Button>
        )}
      </div>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTaxType: (data) => dispatch(setTaxType(data)),
    setEstimatedDate: (data) => dispatch(setEstimatedDate(data)),
    setFinancialInstrument: (data) => dispatch(setFinancialInstrument(data)),
    setRebate: (data) => dispatch(setRebate(data)),
    setSRO: (data) => dispatch(setSRO(data)),
    setNo: (data) => dispatch(setNo(data)),
    setBank: (data) => dispatch(setBank(data)),
    resetReducer: () => dispatch(resetReducer()),
    resetReducerShipment: () => dispatch(resetReducerShipment()),
    addShipment: (data, handleVisible, handleDangerVisible) =>
      dispatch(addShipment(data, handleVisible, handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  payList: state.CargoCreateShipmentReducer.payList,
  isSavingShipment: state.CargoShipmentReducer.isSavingShipment,
  senderData: state.CargoCreateShipmentReducer.senderData,
  receiverData: state.CargoCreateShipmentReducer.receiverData,
  shipmentDetail: state.CargoCreateShipmentReducer.shipmentDetail,
  shipmentType: state.CargoCreateShipmentReducer.shipmentType,
  shipmentPurpose: state.CargoCreateShipmentReducer.shipmentPurpose,
  shipmentRef: state.CargoCreateShipmentReducer.shipmentRef,
  taxType: state.CargoCreateShipmentReducer.taxType,
  instrument: state.CargoCreateShipmentReducer.instrument,
  rebate: state.CargoCreateShipmentReducer.rebate,
  sro: state.CargoCreateShipmentReducer.sro,
  instrument_No: state.CargoCreateShipmentReducer.instrument_No,
  instrument_bank: state.CargoCreateShipmentReducer.instrument_bank,
  description: state.CargoCreateShipmentReducer.description,
  serviceType: state.CargoCreateShipmentReducer.serviceType,
  packagingDetail: state.CargoShipmentReducer.packagingDetail,
  invoiceType: state.CargoCreateShipmentReducer.invoiceType,
  userDetail: state.CargoSenderReducer.userDetail,
  estimatedDeliveryDate: state.CargoCreateShipmentReducer.estimatedDeliveryDate,
});

export default connect(mapStateToProps, mapDispatchToProps)(Step4HowWillPay);
