// reducers.js
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import accountReducer from "../views/user_views/accounts/account.reducers";
import VoucherReducer from "../views/user_views/forms/Vouchers/Voucher.reducers";
import accountsLevelReducer from "../views/user_views/forms/accountsLevels/accountsLevel.reducer";
import TransactitonSummaryReducer from "../views/user_views/forms/accountsTransactionSummary/TransactionSummaryReducer.reducers";
import userReducer from "../views/user_views/accounts/account.reducers";
//
import BackUpReducer from "../views/user_views/forms/BackUp/BackUp.reducers";
// cargo
import CargoLoginReducer from "../views/cargo_views/cargoLogin/CargoLogin.reducers";
import CargoComplaintReducer from "../views/cargo_views/complaint/Complaint.reducers";
import CargoSenderReducer from "../views/cargo_views/sender/Sender.reducers";
import CargoCountryReducer from "../views/cargo_views/country/Country.reducers";
import CargoPackagesReducer from "../views/cargo_views/packages/Packages.reducers";
import CargoReceiverReducer from "../views/cargo_views/receiver/Receiver.reducers";
import CargoUserReducer from "../views/cargo_views/User/UserControl.reducers";
import CargoServiceReducer from "../views/cargo_views/Service/Service.reducers";
import CargoCreateShipmentReducer from "../views/cargo_views/shipment/createShipment/CreateShipment.reducers";
import CargoShipmentReducer from "../views/cargo_views/shipment/Shipment.reducers";
import CargoCurrencyReducer from "../views/cargo_views/currency/Currency.reducers";
import TrackCargoReducer from "../views/cargo_views/tracking/TrackCargo.reducers";
import CargoRateReducer from "../views/cargo_views/rates/Rates.reducers";
import permissionReducer from "../views/cargo_views/Permissions/UserPermissions.reducer";
import ReportingReducer from "../views/cargo_views/reporting/reporting.reducers";
import UserControlReducer from "../views/cargo_views/User/UserControl.reducers";
import CargoDashboardReducer from "../views/cargo_views/dashboard/Dashboard.reducers";
import AccountLegderReducer from "../views/cargo_views/ledger/Ledger.reducer";
// rootReducer.js
// Use ES6 object literal shorthand syntax to define the object shape
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    accountReducer,
    userReducer,
    VoucherReducer,
    accountsLevelReducer,
    TransactitonSummaryReducer,
    BackUpReducer,
    // cargo
    CargoLoginReducer,
    CargoComplaintReducer,
    CargoSenderReducer,
    CargoCountryReducer,
    CargoPackagesReducer,
    CargoReceiverReducer,
    CargoUserReducer,
    CargoServiceReducer,
    CargoCreateShipmentReducer,
    CargoCurrencyReducer,
    CargoShipmentReducer,
    AccountLegderReducer,
    TrackCargoReducer,
    CargoRateReducer,
    permissionReducer,
    ReportingReducer,
    UserControlReducer,
    CargoDashboardReducer,
  });
