import API from "../../../global/api";

export const requestAddCountry = () => {
  return {
    type: "REQUEST_ADD_COUNTRY",
  };
};
export const successAddCountry = (data) => {
  return {
    type: "SUCCESS_ADD_COUNTRY",
    payload: data,
  };
};
export const errorAddCountry = () => {
  return {
    type: "ERROR_ADD_COUNTRY",
  };
};
export const addCountry = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddCountry());
    API.post(`/country`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successAddCountry(data1));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddCountry());
        handleDangerVisible(error.response.data.message);
      });
  };
};

export const requestGetCountry = () => {
  return {
    type: "REQUEST_GET_COUNTRY",
  };
};
export const successGetCountry = (data) => {
  return {
    type: "SUCCESS_GET_COUNTRY",
    payload: data,
  };
};
export const errorGetCountry = () => {
  return {
    type: "ERROR_GET_COUNTRY",
  };
};
export const getCountry = () => {
  return (dispatch) => {
    dispatch(requestGetCountry());
    API.get(`/country`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetCountry(getData));
      })
      .catch((error) => {
        dispatch(errorGetCountry());
      });
  };
};

export const DeleteRequestCountry = () => {
  return {
    type: "DELETE_REQUEST_COUNTRY",
  };
};
export const DeleteSuccessCountry = (user_id) => {
  return {
    type: "DELETE_SUCCESS_COUNTRY",
    user_id: user_id,
  };
};
export const DeleteErrorCountry = () => {
  return {
    type: "DELETE_ERROR_COUNTRY",
  };
};
export const deleteCountry = (vendorId) => {
  return (dispatch) => {
    dispatch(DeleteRequestCountry());
    API.delete(`/country/${vendorId}`)
      .then((res) => {
        dispatch(getCountry());
        dispatch(DeleteSuccessCountry(vendorId));
      })
      .catch((error) => {
        dispatch(DeleteErrorCountry());
      });
  };
};
export const UpdateRequestCountry = () => {
  return {
    type: "UPDATE_REQUEST_COUNTRY",
  };
};
export const UpdateSuccessCountry = (data) => {
  return {
    type: "UPDATE_SUCCESS_COUNTRY",
    payload: data,
  };
};

export const UpdateErrorCountry = () => {
  return {
    type: "UPDATE_ERROR_COUNTRY",
  };
};

export const updateCountry = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestCountry());
    API.put(
      `/country/${oldData.country_id}`,
      {
        name: data.name,
        code: data.code,
      },
      {}
    )
      .then((res) => {
        dispatch(getCountry());
        dispatch(UpdateSuccessCountry([data]));
      })
      .catch((error) => dispatch(UpdateErrorCountry()));
  };
};
