const initialState = {
  packageWeightList: [],
  isFetchingPackageWeightList: false,
  calculateRateList: [],
  isFetchingCalculateRateList: false,
  uploadRateList: [],
  isUploadingRateList: false,
  isshowTable: false,
  isFetchingServiceTypeList: false,
  serviceTypeList: [],
  isFetchingCountry: false,
  countryList: [],
  calculateRate:[],
};

const RateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SHOW_DATA":
      return { ...state, isshowTable: action.payload };
    case "REQUEST_GET_PACKAGE_WEIGHT":
      return { ...state, isFetchingPackageWeightList: true };
    case "SUCCESS_GET_PACKAGE_WEIGHT":
      return {
        ...state,
        packageWeightList: action.payload.data,
        isFetchingPackageWeightList: false,
      };
    case "ERROR_GET_PACKAGE_WEIGHT":
      return { ...state, isFetchingPackageWeightList: false };

    case "REQUEST_GET_CALCULATE_RATES":
      return { ...state, isFetchingCalculateRateList: true };
    case "SUCCESS_GET_CALCULATE_RATES":
      return {
        ...state,
        // Object.fromEntries(Object.entries(props.calculateRateList[0]).filter(([key, value]) => key )
        calculateRate: action.body,
        calculateRateList: action.payload,
        
        // calculateRateList: Object.fromEntries(Object.entries(action.payload[0]).filter(([key, value]) => key )) ,
        isFetchingCalculateRateList: false,
      };
    case "ERROR_GET_CALCULATE_RATES":
      return {
        ...state,
        isFetchingCalculateRateList: false,
        calculateRateList: [],
      };

    case "REQUEST_UPLOAD_RATES":
      return { ...state, isUploadingRateList: true };
    case "SUCCESS_UPLOAD_RATES":
      return {
        ...state,
        uploadRateList: action.payload.data,
        isUploadingRateList: false,
      };
    case "ERROR_UPLOAD_RATES":
      return { ...state, isUploadingRateList: false };

    case "REQUEST_GET_SERVICE_TYPE":
      return { ...state, isFetchingServiceTypeList: true };
    case "SUCCESS_GET_SERVICE_TYPE":
      return {
        ...state,
        serviceTypeList: action.payload.data,
        isFetchingServiceTypeList: false,
      };
    case "ERROR_GET_SERVICE_TYPE":
      return { ...state, isFetchingServiceTypeList: false };

    case "REQUEST_GET_COUNTRY":
      return { ...state, isFetchingCountry: true };
    case "SUCCESS_GET_COUNTRY":
      return {
        ...state,
        countryList: action.payload.data,
        isFetchingCountry: false,
      };
    case "ERROR_GET_COUNTRY":
      return { ...state, isFetchingCountry: false };
    default:
      return state;
  }
};

export default RateReducer;
