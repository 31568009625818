import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "lodash";
import {
  deleteShipment,
  getShipment,
  getShipmentById,
  getShipmentByStatusId,
  getShipmentPagination,
  getShipmentPaginationWithDates,
  updateShipment,
} from "./Shipment.action";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { Button, Pagination } from "@mui/material";
import Typography from "@mui/material/Typography";
import { InputAdornment } from "@mui/material";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import TextField from "@mui/material/TextField";

const ViewShipmentListDumy = (props) => {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    props.getShipment(value);
    setPage(value);
  };
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedProcess, setCheckedProcess] = useState(false);
  const [checkedArrived, setCheckedArrived] = useState(false);
  const [checkedPickUp, setCheckedPickUp] = useState(false);
  const [checkedCompleted, setCheckedCompleted] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    //  props.setHeading("Uom List");
    // props.getShipment(1);
    // props.getShipmentWithDate(1,{startedDate:"",endDate:""})
    document.title = props.title;
  }, []);
  const handleChangeDate = (event) => {
    props.getShipmentWithDate(1, startDate, endDate);
  };

  return (
    <>
      {props.isFetchingShipmentList ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Row>
              <Col>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="date"
                  id="standard-basic"
                  variant="standard"
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="date"
                  id="standard-basic"
                  variant="standard"
                  label="End Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col>
                <Button
                  // variant="outline-secondary"
                  id="button-addon2"
                  disabled={isEmpty(startDate) || isEmpty(endDate)}
                  onClick={handleChangeDate}
                  style={{
                    color: "black",
                    backgroundColor: "#C5CBCF",
                    border: "1px solid white",

                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {!isEmpty(props.shipmentList) &&
                  props.shipmentList.map((item, i) => {
                    return (
                      <React.Fragment key={item.shipment_id}>
                        <>
                          <Card
                            style={{
                              marginTop: "7px",
                              backgroundColor: "#f1f1f1",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              props.getShipmentById(item.shipment_id);
                              navigate("/cargo/shipment/shipment_by_id");
                            }}
                          >
                            <Row>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b> Tracking id</b>
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b> Shipment Date </b>
                                {item.shipment_date}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b>Ship from</b>
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b>Ship To</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.tracking_id}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b>Service</b> {item.service_type.name}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.shipment_senders[0].name}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.shipment_receivers[0].name}
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {/* {item.tracking_id} */}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b>Shipment Type</b> {item.shipment_type}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.shipment_senders[0].company}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.shipment_receivers[0].company}
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {/* {item.tracking_id} */}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {/* <b>Description</b> {item.shipment_desc} */}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.shipment_senders[0].city},{" "}
                                {item.shipment_senders[0].country.name}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {/* {item.shipment_receivers[0].company} */}
                                {item.shipment_receivers[0].city},{" "}
                                {item.shipment_receivers[0].country.name}
                              </Col>
                            </Row>
                          </Card>
                        </>
                      </React.Fragment>
                    );
                  })}
                {!isEmpty(props.shipmentList) && (
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style={{ marginTop: "10px" }}
                  >
                    {/* <Row style={{marginTop:'10px'}}>
                  <Col xl="10" lg="10" md="10" sm="12" xs="12"> */}
                    <Pagination
                      count={props.shipmentTotalPage}
                      variant="outlined"
                      shape="rounded"
                      page={page}
                      color="primary"
                      onChange={handleChange}
                    />
                    {/* </Col>
               </Row> */}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getShipment: (data) => dispatch(getShipmentPagination(data)),
    getShipmentWithDate: (pageno, startDate, endDate) =>
      dispatch(getShipmentPaginationWithDates(pageno, startDate, endDate)),

    getShipmentById: (id) => dispatch(getShipmentById(id)),
    getShipmentByStatusId: (id) => dispatch(getShipmentByStatusId(id)),
    deleteShipment: (SenderId) => dispatch(deleteShipment(SenderId)),
    updateShipment: (newData, oldData) =>
      dispatch(updateShipment(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  senderList: state.CargoSenderReducer.senderList,
  isFetchingSender: state.CargoSenderReducer.isFetchingSender,
  isFetchingShipmentList: state.CargoShipmentReducer.isFetchingShipmentList,
  shipmentList: state.CargoShipmentReducer.shipmentList,
  shipmentTotalPage: state.CargoShipmentReducer.shipmentTotalPage,
  // flavorsDataList: state.FlavourReducer.flavorsDataList,
  // sizeDataList: state.sizeReducer.sizeDataList,
  // masterControlDataList:state.MasterControlReducer.masterControlDataList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewShipmentListDumy);
