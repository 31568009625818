const initialState = {
  isFetchingAccount: false,
  accountList: [],
  isFetchingReport: false,
  reportList: [],
  isFetchingTrialBalance: false,
  trialList: [],
};

const ReportingReducer = (state = initialState, action) => {
  switch (action.type) {
    // Ledger Report
    case "REQUEST_GET_ACCOUNT":
      return { ...state, isFetchingAccount: true };
    case "SUCCESS_GET_ACCOUNT":
      return {
        ...state,
        accountList: action.payload.data,
        isFetchingAccount: false,
      };
    case "ERROR_GET_ACCOUNT":
      return { ...state, isFetchingAccount: false };
    // Generate Report
    case "REQUEST_LEDGER_REPORT":
      return { ...state, isFetchingReport: true };
    case "SUCCESS_LEDGER_REPORT":
      return {
        ...state,
        reportList: action.payload.data,
        isFetchingReport: false,
      };
    case "ERROR_LEDGER_REPORT":
      return { ...state, isFetchingReport: false };

    // Generate PDF
    case "REQUEST_TRIAL_DATA":
      return { ...state, isFetchingTrialBalance: true };
    case "SUCCESS_TRIAL_DATA":
      return {
        ...state,
        trialList: action.payload,
        isFetchingTrialBalance: false,
      };
    case "ERROR_TRIAL_DATA":
      return { ...state, isFetchingTrialBalance: false };
    default:
      return state;
  }
};

export default ReportingReducer;
