import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";

import { isEmpty } from "lodash";
import { baseURL, headers } from "../../../global/api";
import { ThemeProvider, createTheme } from "@mui/material";
import { deleteService, getService, updateService } from "./Service.action";

const ViewService = (props) => {
  const defaultMaterialTheme = createTheme();
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  useEffect(() => {
    //  props.setHeading("Uom List");
    props.getService();
    document.title = props.title;
  }, []);
  return (
    <>
      {props.isFetchingCouponsData ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{ height: "100%", backgroundColor: "#F9FAFB" }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                style={{
                  fontFamily: "Public Sans,sans-serif",
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                title="Service Details"
                columns={[
                  { title: "Service Id", field: "id", editable: "never" },
                  { title: "Service Name", field: "name" },
                ]}
                data={props.serviceList}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  paging: true,
                  pageSize: 50, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                  maxBodyHeight: "600px",
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#919EAB",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
                editable={{
                  isEditHidden: (rowData) =>
                    Checkpermissions("permissions_cat_name", "Service Edit"),
                  isDeleteHidden: (rowData) =>
                    Checkpermissions("permissions_cat_name", "Service Delete"),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        {
                          props.updateService(newData, oldData);
                        }
                      }, 600);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        props.deleteService(oldData.id);
                      }, 600);
                    }),
                }}
              />
            </ThemeProvider>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getService: (data) => dispatch(getService(data)),
    deleteService: (serviceId) => dispatch(deleteService(serviceId)),
    updateService: (newData, oldData) =>
      dispatch(updateService(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  serviceList: state.CargoServiceReducer.serviceList,
  isFetchingService: state.CargoServiceReducer.isFetchingService,
  // flavorsDataList: state.FlavourReducer.flavorsDataList,
  // sizeDataList: state.sizeReducer.sizeDataList,
  // masterControlDataList:state.MasterControlReducer.masterControlDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewService);
