

import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Mail from '@mui/icons-material/Mail';
import { MailOutline } from '@material-ui/icons';
import Collapse from '@mui/material/Collapse';

import LineWeightIcon from '@mui/icons-material/LineWeight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import StarBorder from '@mui/icons-material/StarBorder';
import { NavLink } from "react-router-dom";
import AddBoxIcon from '@mui/icons-material/AddBox';
import MenuList from './menu';
import IconMenu from "./IconMenu.js";
import { Navbar } from "react-bootstrap";
import {Container} from "react-bootstrap"; 
import { isEmpty } from 'lodash';
//import Routing, { cargoRoutes } from '../routes';
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import AddComplaint from '../complaint/AddComplaint';
import CargoLogin from '../cargoLogin/CargoLogin';
const drawerWidth = 290;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer(props) {

  console.log(props,props.props,"pppppppppppppppppppppppp")
  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openProduct, setOpenProduct] = React.useState(false);

 
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = [{
    title: 'test',
    icon: MailIcon,
    route: '',
    innerMenuItems: [{
      title: 'iertestt',
      icon: MailIcon,
      route: ''
    }]
  },
  {
    title: 'test2',
    icon: AddBoxIcon,
    route: '',
    innerMenuItems: []
  }]
  return (
    <Box sx={{ display: 'flex' }} >
      {/* <CssBaseline /> */}
      <AppBar position="fixed" open={open} sx={{
        background:'#f9fafb'
      }} >
        <Toolbar>
          <IconButton
            color="#F9FAFB"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            // onMouseOver={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 8,
              color:"black",
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap component="div" sx={{color: "#637381",fontSize: "20px",
                    fontFamily: "Public Sans,sans-serif", fontWeight:"700"}}>
            {props.pageTitle} 
          </Typography>
          <IconMenu/>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" PaperProps={{
         sx: {
          backgroundColor: "#F9FAFB",
        }
        
      }} open={open} 
      // onMouseOver={handleDrawerOpen}
      // onMouseLeave={handleDrawerClose}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} style={{ color: "#637381" }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
          {/* <Toolbar /> */}
        <Divider />
          <MenuList moduleKey={'props.props.moduleKey'} heading={'props.props.heading'} permissions={permissions} />

      </Drawer>
      <Box component="main" style={{backgroundColor:'#F9FAFB'}}  sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
