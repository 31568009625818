const initialState = {
  isLogin: false,
  loginUserId: "",
  unableLogin: true,
  userDetails: {},
  moduleKey: '',
  heading: "Inventory",
  isTrialBalance: false,
  trialList: [],
  isFetchingLevel5: false,
  level5DataList: [],
  isWrongCredentials: false,
  isFetchingTrialBalance: false,
  message:"",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case "LOGIN_USER_REQUEST":
      return { ...state, isLogin: true, isWrongCredentials: false };
    case "LOGIN_USER_SUCCESS":
      return { ...state, isLogin: false, userDetails: action.payload, isWrongCredentials: false };
    case "LOGIN_USER_ERROR":
      return { ...state, isLogin: false, isWrongCredentials: true ,message:action.payload};
    case "CHANGE_IS_WRONG":
      return { ...state, isWrongCredentials: false };

    case "SET_MODULE_KEY":
      return { ...state, moduleKey: action.payload };

    case "SET_HEADING_KEY":
      console.log(action.payload, "heading")
      return { ...state, heading: action.payload };
    case "REQUEST_TRIAL_DATA":
      return { ...state, isFetchingTrialBalance: true };
    case "SUCCESS_TRIAL_DATA":
      return { ...state, trialList: action.payload, isFetchingTrialBalance: false };
    case "ERROR_TRIAL_DATA":
      return { ...state, isFetchingTrialBalance: false };
    case "REQUEST_GET_LEVEL_5":
      return { ...state, isFetchingLevel5: true };
    case "SUCCESS_GET_LEVEL_5":
      return { ...state, level5DataList: action.payload, isFetchingLevel5: false };
    case "ERROR_GET_LEVEL_5":
      return { ...state, isFetchingLevel5: false };
    default:
      return state;
  }
};

export default userReducer;
