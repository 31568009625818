import API from "../../../global/api";

export const requestGetShipmentByDates = () => {
  return {
    type: "REQUEST_GET_SHIPMENT_BY_DATES",
  };
};
export const successGetShipmentByDates = (data) => {
  return {
    type: "SUCCESS_GET_SHIPMENT_BY_DATES",
    payload: data,
  };
};
export const errorGetShipmentByDates = () => {
  return {
    type: "ERROR_GET_SHIPMENT_BY_DATES",
  };
};
export const getShipmentByDates = (data) => {
  return (dispatch) => {
    dispatch(requestGetShipmentByDates());
    API.post(`shipment/get_shipment_with_dates`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetShipmentByDates(data1));
      })
      .catch((error) => {
        dispatch(errorGetShipmentByDates());
      });
  };
};

export const requestGetLedger = () => {
  return {
    type: "REQUEST_GET_LEDGER",
  };
};
export const successGetLedger = (data) => {
  return {
    type: "SUCCESS_GET_LEDGER",
    payload: data,
  };
};
export const errorGetLedger = () => {
  return {
    type: "ERROR_GET_LEDGER",
  };
};
export const getLedger = (data) => {
  return (dispatch) => {
    dispatch(requestGetLedger());
    API.post(`cargo_accounts/get_ledger_dashboard`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetLedger(data1));
      })
      .catch((error) => {
        dispatch(errorGetLedger());
      });
  };
};
