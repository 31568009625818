import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
// import Select from "react-select";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import {
  addComplaint,
  getComplaintById,
  resetFunction,
} from "./Complaint.action";
import moment from "moment";

const AddComplaint = (props) => {
  const [consigneNo, setConsigneNo] = useState();
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [consigneDate, setConsigneDate] = useState("");
  const [doxType, setDoxType] = useState("");
  const [sender, setSender] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [receiver, setReceiver] = useState("");
  const [weight, setWeight] = useState(0);
  const [unit, setUnit] = useState("");
  const [complain, setComplain] = useState("");
  const [shipmentId, setShipmentId] = useState(0);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [warningVisibleAlert, setWarningVisibleAlert] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const handleWarningVisible = () => {
    setWarningVisibleAlert(true);
    setTimeout(() => {
      setWarningVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "Consignee Number not Found",
      icon: "error",
      button: "Ok",
    });
  };

  function saveComplaint() {
    props.addComplaint(
      {
        cn_number: consigneNo,
        complaint_satement: complain,
        shipment_id: props.complaintByID[0].shipment_id,
      },
      handleVisible,
      handleDangerVisible
    );
    setConsigneNo("");
    setComplain("");
    props.resetFunction();
  }

  function searchShipment() {
    props.getComplaintById(consigneNo, handleWarningVisible);
  }
  const styles = {
    heading: {
      color: "black",
      backgroundColor: "#C5CBCF",
      textAlign: "center",
      padding: "3px",
      borderRadius: "8px",
      marginBottom: "20px",
    },
  };
  useEffect(() => {
    // clearData();
    document.title = props.title;
  }, []);
  return (
    <>
      <Container fluid>
        <div
          className="main"
          style={{
            height: "auto",
            borderRadius: "30px",
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <Row style={{ marginTop: "15px" }}>
            <Col xl="2" lg="2" md="2" sm="2" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <NumbersTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="number"
                id="standard-basic"
                variant="standard"
                required
                label="C/N No"
                value={consigneNo}
                onChange={(e) => setConsigneNo(e.target.value)}
                sx={{
                  width: "100%",
                  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </Col>
            <Col xl="1" lg="1" md="1" sm="1" xs="12">
              <Button
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "70%",
                  height: "100%",
                  backgroundColor: "#C5CBCF",
                  border: "1px solid white",
                }}
                onClick={searchShipment}
              >
                <SearchIcon />
              </Button>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="date"
                id="standard-basic"
                variant="standard"
                label="Complain Date"
                value={date}
                // defaultValue={new Date().toISOString().split("T")[0]}
                onChange={(e) => setDate(e.target.value)}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <TextField
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="date"
                id="standard-basic"
                variant="standard"
                label="C/N Date"
                value={
                  !isEmpty(props.complaintByID)
                    ? props.complaintByID[0].shipment_date
                    : ""
                }
                onChange={(e) => setConsigneDate(e.target.value)}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <TextField
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddBoxTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                label="Dox Type "
                value={
                  !isEmpty(props.complaintByID)
                    ? props.complaintByID[0].shipment_type
                    : ""
                }
                onChange={(e) => setDoxType(e.target.value)}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col xl="6" lg="6" md="6" sm="6" xs="12">
              <div style={styles.heading}>
                {" "}
                <h3>SHIPPER/SENDER</h3>{" "}
              </div>
              <Row style={{ marginTop: "15px" }}>
                <Col xl="4" lg="4" md="4" sm="4" xs="12">
                  <TextField
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlineTwoToneIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="text"
                    id="standard-basic"
                    variant="standard"
                    label="Shipper/Sender"
                    value={
                      !isEmpty(props.complaintByID)
                        ? props.complaintByID[0].shipment_senders[0].name
                        : ""
                    }
                    onChange={(e) => setSender(e.target.value)}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Col>

                <Col xl="4" lg="4" md="4" sm="4" xs="12">
                  <TextField
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <AddLocationAltTwoToneIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="text"
                    id="standard-basic"
                    variant="standard"
                    label="City"
                    value={
                      !isEmpty(props.complaintByID)
                        ? props.complaintByID[0].shipment_senders[0].city
                        : ""
                    }
                    onChange={(e) => setCity(e.target.value)}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Col>
                <Col xl="4" lg="4" md="4" sm="4" xs="12">
                  <TextField
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <LanguageTwoToneIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="text"
                    id="standard-basic"
                    variant="standard"
                    label="Country"
                    value={
                      !isEmpty(props.complaintByID)
                        ? props.complaintByID[0].shipment_senders[0].country
                            .name
                        : ""
                    }
                    onChange={(e) => setCountry(e.target.value)}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl="6" lg="6" md="6" sm="6" xs="12">
              <div style={styles.heading}>
                <h3>CONSIGNEE/RECEIVER</h3>
              </div>
              <Row style={{ marginTop: "15px" }}>
                <Col xl="4" lg="4" md="4" sm="4" xs="12">
                  <TextField
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlineTwoToneIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="text"
                    id="standard-basic"
                    variant="standard"
                    label="Consignee/Receiver"
                    value={
                      !isEmpty(props.complaintByID)
                        ? props.complaintByID[0].shipment_receivers[0].name
                        : ""
                    }
                    onChange={(e) => setReceiver(e.target.value)}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Col>
                <Col xl="4" lg="4" md="4" sm="4" xs="12">
                  <TextField
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <AddLocationAltTwoToneIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="text"
                    id="standard-basic"
                    variant="standard"
                    label="City"
                    value={
                      !isEmpty(props.complaintByID)
                        ? props.complaintByID[0].shipment_receivers[0].city
                        : ""
                    }
                    onChange={(e) => setCity(e.target.value)}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Col>
                <Col xl="4" lg="4" md="4" sm="4" xs="12">
                  <TextField
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <LanguageTwoToneIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="text"
                    id="standard-basic"
                    variant="standard"
                    label="Country"
                    value={
                      !isEmpty(props.complaintByID)
                        ? props.complaintByID[0].shipment_receivers[0].country
                            .name
                        : ""
                    }
                    onChange={(e) => setCountry(e.target.value)}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col xl="6" lg="6" md="6" sm="6" xs="12">
              <div style={styles.heading}>
                {" "}
                <h3>COMPLAIN</h3>{" "}
              </div>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DriveFileRenameOutlineTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                label="Complain"
                value={complain}
                onChange={(e) => setComplain(e.target.value.toUpperCase())}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
            <Col xl="6" lg="6" md="6" sm="6" xs="12">
              <div style={styles.heading}>
                <h3>C/N INFO</h3>
              </div>
              <Row style={{ marginTop: "15px" }}>
                <Col xl="6" lg="6" md="6" sm="6" xs="12">
                  <TextField
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <NumbersTwoToneIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="text"
                    id="standard-basic"
                    variant="standard"
                    label="Unit"
                    value={
                      !isEmpty(props.complaintByID)
                        ? props.complaintByID[0].total_packages
                        : ""
                    }
                    onChange={(e) => setUnit(e.target.value)}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Col>
                <Col xl="6" lg="6" md="6" sm="6" xs="12">
                  <TextField
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <NumbersTwoToneIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="number"
                    id="standard-basic"
                    variant="standard"
                    label="Weight"
                    value={
                      !isEmpty(props.complaintByID)
                        ? props.complaintByID[0].total_weight
                        : ""
                    }
                    onChange={(e) => setWeight(e.target.value)}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="sendDiv" style={{ marginTop: "30px" }}>
            {props.isAddingComplaint ? (
              <div className="loader-div">
                <Spinner
                  variant="secondary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <Button
                disabled={isEmpty(consigneNo)}
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "20%",
                  backgroundColor: "#C5CBCF",
                  border: "1px solid white",
                }}
                onClick={saveComplaint}
              >
                Save Complain
              </Button>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addComplaint: (data, handleVisible, handleDangerVisible) =>
      dispatch(addComplaint(data, handleVisible, handleDangerVisible)),

    getComplaintById: (id, handleWarningVisible) =>
      dispatch(getComplaintById(id, handleWarningVisible)),
    resetFunction: () => dispatch(resetFunction()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  complaint: state.CargoComplaintReducer.complaint,
  isAddingComplaint: state.CargoComplaintReducer.isAddingComplaint,
  complaintByID: state.CargoComplaintReducer.complaintByID,
  isFetchingComplaintByID: state.CargoComplaintReducer.isFetchingComplaintByID,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddComplaint);
