import API, { baseURL } from "../../../../global/api";
import { isEmpty,sumBy,get} from 'lodash';
import jsPDF from "jspdf";
import moment from 'moment';
const _ = require("lodash");

//LEVEL 1
export const requestLevel1GetData = () => {
    return {
        type: "REQUEST_LEVEL1_GET_DATA",
    };
};
export const successLevel1GetData = (data) => {
    return {
        type: "SUCCESS_LEVEL1_GET_DATA",
        payload: data,
    };
};
export const errorLevel1GetData = () => {
    return {
        type: "ERROR_LEVEL1_GET_DATA",
    };
};

export const getLevel1Data = () => {
    return (dispatch) => {
        dispatch(requestLevel1GetData());
        API.get(`/levelOne/get_all_levelone`).then((res) => {
            let getData = res.data.Name;
            dispatch(successLevel1GetData(getData));
        }).catch((error) => {
            dispatch(errorLevel1GetData());
        })
    };
};
// get level 2
export const getLevelOneName = (data) => {
    return (dispatch) => {
        dispatch(requestLevelOneData());
        API.get(`/levelTwo/get_by_levelOne/${data}`).then((res) => {
           // dispatch(getLevel2Data());
            dispatch(successLevelOneData(res.data));
        }).catch((error) => {
            dispatch(errorAddLevelOne());
        })
    };
};

export const requestLevelOneData = () => {
    return {
        type: "REQUEST_LEVEL1_DATA",
    };
};
export const successLevelOneData = (data) => {
    return {
        type: "SUCCESS_LEVEL1_DATA",
        payload: data,
    };
};
export const errorAddLevelOne = () => {
    return {
        type: "ERROR_LEVEL1_DATA",
    };
};
// endget level 2
// start level 3
export const getLevelTwoName = (data) => {
    return (dispatch) => {
        dispatch(requestLevelTwoData());
    
        API.get(`/levelThree/get_by_levelTwo/${data}`).then((res) => {
           // dispatch(getLevel2Data());
            dispatch(successLevelTwoData(res.data));
        }).catch((error) => {
            dispatch(errorAddLevelTwo());
        })
    };
};

export const requestLevelTwoData = () => {
    return {
        type: "REQUEST_LEVEL2_DATA",
    };
};
export const successLevelTwoData = (data) => {
    return {
        type: "SUCCESS_LEVEL2_DATA",
        payload: data,
    };
};
export const errorAddLevelTwo = () => {
    return {
        type: "ERROR_LEVEL2_DATA",
    };
};
// end LEVEL 3
// start level 4
export const getLevelFourName = (data) => {
    return (dispatch) => {
        dispatch(requestLevelFourData());
    
        API.get(`levelThree/get_by_levelTwo/${data}`).then((res) => {
           // dispatch(getLevel2Data());
            dispatch(successLevelFourData(res.data));
        }).catch((error) => {
            dispatch(errorAddLevelFour());
        })
    };
};

export const requestLevelFourData = () => {
    return {
        type: "REQUEST_LEVEL4_DATA",
    };
};
export const successLevelFourData = (data) => {
    return {
        type: "SUCCESS_LEVEL4_DATA",
        payload: data,
    };
};
export const errorAddLevelFour = () => {
    return {
        type: "ERROR_LEVEL4_DATA",
    };
};
// // end LEVEL 4
export const requestAddLevel2 = () => {
    return {
        type: "REQUEST_ADD_LEVEL2",
    };
};
export const successAddLevel2 = (data) => {
    return {
        type: "SUCCESS_ADD_LEVEL2",
        payload: data,
    };
};
export const errorAddLevel2 = () => {
    return {
        type: "ERROR_ADD_LEVEL2",
    };
};


export const AddLevel2Name = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddLevel2());
        API.post(`/levelTwo/add-levelTwo`, data).then((res) => {
            dispatch(getLevel2Data());
            dispatch(successAddLevel2(data));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddLevel2());
            handleDangerVisible();
        })
    };
};

export const requestLevel2GetData = () => {
    return {
        type: "REQUEST_LEVEL2_GET_DATA",
    };
};
export const successLevel2GetData = (data) => {
    return {
        type: "SUCCESS_LEVEL2_GET_DATA",
        payload: data,
    };
};
export const errorLevel2GetData = () => {
    return {
        type: "ERROR_LEVEL2_GET_DATA",
    };
};

export const getLevel2Data = () => {
    return (dispatch) => {
        dispatch(requestLevel2GetData());
        API.get(`levelTwo/get-all-levelTwo`).then((res) => {
            let getData = res.data;
            dispatch(successLevel2GetData(getData));
        }).catch((error) => {
            dispatch(errorLevel2GetData());
        })
    };
};
//LEVEL 3
export const requestAddLevel3 = () => {
    return {
        type: "REQUEST_ADD_LEVEL5",
    };
};
export const successAddLevel3 = (data) => {
    return {
        type: "SUCCESS_ADD_LEVEL3",
        payload: data,
    };
};
export const errorAddLevel3 = () => {
    return {
        type: "ERROR_ADD_LEVEL3",
    };
};


export const AddLevel3Name = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddLevel3());
        API.post(`/levelThree/add-levelThree`, data).then((res) => {
            dispatch(getLevel3Data());
            dispatch(successAddLevel3(data));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddLevel3());
            handleDangerVisible();
        })
    };
};

export const requestLevel3GetData = () => {
    return {
        type: "REQUEST_LEVEL3_GET_DATA",
    };
};
export const successLevel3GetData = (data) => {
    return {
        type: "SUCCESS_LEVEL3_GET_DATA",
        payload: data,
    };
};
export const errorLevel3GetData = () => {
    return {
        type: "ERROR_LEVEL2_GET_DATA",
    };
};

export const getLevel3Data = () => {
    return (dispatch) => {
        dispatch(requestLevel3GetData());
        API.get(`levelThree/get_all_levelThree_data`).then((res) => {
            let getData = res.data;
            dispatch(successLevel3GetData(getData));
        }).catch((error) => {
            dispatch(errorLevel3GetData());
        })
    };
};
//LEVEL 4
export const requestAddLevel4 = () => {
    return {
        type: "REQUEST_ADD_LEVEL4",
    };
};
export const successAddLevel4 = (data) => {
    return {
        type: "SUCCESS_ADD_LEVEL4",
        payload: data,
    };
};
export const errorAddLevel4 = () => {
    return {
        type: "ERROR_ADD_LEVEL4",
    };
};

export const AddLevel4Name = (data,  handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddLevel4());
        API.post(`/levelThree/add_levelThree`, data).then((res) => {
           //. dispatch(getLevel4Data());
            dispatch(successAddLevel4(data));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddLevel4());
            handleDangerVisible();
        })
    };
};

export const requestLevel4GetData = () => {
    return {
        type: "REQUEST_LEVEL4_GET_DATA",
    };
};
export const successLevel4GetData = (data) => {
    return {
        type: "SUCCESS_LEVEL4_GET_DATA",
        payload: data,
    };
};
export const errorLevel4GetData = () => {
    return {
        type: "ERROR_LEVEL4_GET_DATA",
    };
};

export const getLevel4Data = () => {
    return (dispatch) => {
        dispatch(requestLevel4GetData());
        API.get(`/levelThree/get_all_data`).then((res) => {
            let getData = res.data;
            dispatch(successLevel4GetData(getData));
        }).catch((error) => {
            dispatch(errorLevel4GetData());
        })
    };
};
//LEVEL 5
export const requestAddLevel5 = () => {
    return {
        type: "REQUEST_ADD_LEVEL5",
    };
};
export const successAddLevel5 = (data) => {
    return {
        type: "SUCCESS_ADD_LEVEL5",
        payload: data,
    };
};
export const errorAddLevel5 = () => {
    return {
        type: "ERROR_ADD_LEVEL5",
    };
};


export const AddLevel5Name = (data,handleVisible,handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddLevel5());
        API.post(`/levelFour/add_levelFour`, data).then((res) => {
            dispatch(getLevel5Data());
            dispatch(successAddLevel5(data));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddLevel5());
            handleDangerVisible();
        })
    };
};

export const requestLevel5GetData = () => {
    return {
        type: "REQUEST_LEVEL5_GET_DATA",
    };
};
export const successLevel5GetData = (data) => {
    return {
        type: "SUCCESS_LEVEL5_GET_DATA",
        payload: data,
    };
};
export const errorLevel5GetData = () => {
    return {
        type: "ERROR_LEVEL2_GET_DATA",
    };
};

export const getLevel5Data = () => {
    return (dispatch) => {
        dispatch(requestLevel5GetData());
        API.get(`/levelFour/get_all_levelFour_data`).then((res) => {
            let getData = res.data;
            dispatch(successLevel5GetData(getData));
        }).catch((error) => {
            dispatch(errorLevel5GetData());
        })
    };
};
//get level 5 for report
export const requestLevel5Data = () => {
    return {
        type: "REQUEST_LEVEL5_DATA",
    };
};
export const successLevel5Data = (data) => {
    return {
        type: "SUCCESS_LEVEL5_DATA",
        payload: data,
    };
};
export const errorLevel5Data = () => {
    return {
        type: "ERROR_LEVEL5_DATA",
    };
};

export const getLevel5DataForReport = () => {
    return (dispatch) => {
        dispatch(requestLevel5Data());
        API.get(`/levelFour/get_levelFour_details`).then((res) => {
            let getData = res.data;
            dispatch(successLevel5Data(getData));
        }).catch((error) => {
            dispatch(errorLevel5Data());
        })
    };
};

//getcreditdebit
export const requestcreditdebit = () => {
    return {
        type: "REQUEST_CREDITDEBIT_GET_DATA",
    };
};
export const successLcreditdebit = (data) => {
    return {
        type: "SUCCESS_CREDITDEBIT_GET_DATA",
        payload: data,
    };
};
export const errorLcreditdebit = () => {
    return {
        type: "ERROR_CREDITDEBIT_GET_DATA",
    };
};

export const getcreditdebit = () => {
    return (dispatch) => {
        dispatch(requestcreditdebit());
        API.get(`levelFour/credit_debit/`).then((res) => {
            let getData = res.data;
            dispatch(successLcreditdebit(getData));
        }).catch((error) => {
            dispatch(errorLcreditdebit());
        })
    };
};


export const DeleteRequestLevel5 = () => {
    return {
        type: "DELETE_REQUEST_LEVEL5",
    };
};
export const DeleteSuccessLevel5 = (level_id) => {
    return {
        type: "DELETE_SUCCESS_LEVEL5",
        level_id: level_id,
    };
};
export const DeleteErrorLevel5 = () => {
    return {
        type: "DELETE_ERROR_LEVEL5",
    };
};

export const deleteLevel5 = (levelFiveId) => {
    return dispatch => {
        dispatch(DeleteRequestLevel5());
        API.delete(`/levelFive/delete-data/${levelFiveId}`)
            .then(res => {
                dispatch(getLevel5Data());
                dispatch(DeleteSuccessLevel5(levelFiveId))
            }).catch((error) =>
                dispatch(DeleteErrorLevel5()));
    }
};

export const updateLevel5 = (levelFiveId, data) => {
    return dispatch => {
       // dispatch(UpdateRequestLevel5());
        API.put(`/levelFive/update_level_five/${levelFiveId}`, data)
            .then(res => {
                dispatch(getLevel5Data());
               // dispatch(DeleteSuccessLevel5(levelFiveId))
            }).catch((error) =>
               dispatch(DeleteErrorLevel5()));
    }
};



export const requestUploadLevel2File = () => {
    return {
        type: "REQUEST_UPLOAD_LEVEL2_FILE",
    };
};
export const successUploadLevel2File = (data) => {
    return {
        type: "SUCCESS_UPLOAD_LEVEL2_FILE",
        payload: data,
    };
};
export const errorUploadLevel2File = () => {
    return {
        type: "ERROR_UPLOAD_LEVEL2_FILE",
    };
};

export const uploadLevel2File = (data, handleVisible, handleDangerVisible) => {
    const formData = new FormData();
    formData.append("file", get(data, 'file'));
    formData.append("levelOneId", `${data.levelOneId}`);
    return (dispatch) => {
        dispatch(requestUploadLevel2File());
        API.post(`/levelTwo/upload-lvlTwo/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(successUploadLevel2File(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorUploadLevel2File());
            handleDangerVisible();
        })
    };
};




export const requestUploadLevel3File = () => {
    return {
        type: "REQUEST_UPLOAD_LEVEL3_FILE",
    };
};
export const successUploadLevel3File = (data) => {
    return {
        type: "SUCCESS_UPLOAD_LEVEL3_FILE",
        payload: data,
    };
};
export const errorUploadLevel3File = () => {
    return {
        type: "ERROR_UPLOAD_LEVEL3_FILE",
    };
};

export const uploadLevel3File = (data, handleVisible, handleDangerVisible) => {
    const formData = new FormData();
    formData.append("file", get(data, 'file'));
    formData.append("levelOneId", `${data.levelOneId}`);
    formData.append("levelTwoId", `${data.levelTwoId}`);
    return (dispatch) => {
        dispatch(requestUploadLevel3File());
        API.post(`/levelThree/upload-lvlThree`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(successUploadLevel3File(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorUploadLevel3File());
            handleDangerVisible();
        })
    };
};



export const requestUploadLevel4File = () => {
    return {
        type: "REQUEST_UPLOAD_LEVEL4_FILE",
    };
};
export const successUploadLevel4File = (data) => {
    return {
        type: "SUCCESS_UPLOAD_LEVEL4_FILE",
        payload: data,
    };
};
export const errorUploadLevel4File = () => {
    return {
        type: "ERROR_UPLOAD_LEVEL4_FILE",
    };
};

export const uploadLevel4File = (data,id1,id2, handleVisible, handleDangerVisible) => {
    console.log(data,id1,id2,"chechupload")
    const formData = new FormData();
    formData.append("file", get(data, 'file'));
    formData.append("levelOneId", `${id1}`);
    formData.append("levelTwoId", `${id2}`);
    // formData.append("levelThreeId", `${data.levelThreeId}`);
    return (dispatch) => {
        dispatch(requestUploadLevel4File());
        API.post(`${baseURL}/levelThree/upload_levelThree`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(successUploadLevel4File(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorUploadLevel4File());
            handleDangerVisible();
        })
    };
};



export const requestUploadLevel5File = () => {
    return {
        type: "REQUEST_UPLOAD_LEVEL5_FILE",
    };
};
export const successUploadLevel5File = (data) => {
    return {
        type: "SUCCESS_UPLOAD_LEVEL5_FILE",
        payload: data,
    };
};
export const errorUploadLevel5File = () => {
    return {
        type: "ERROR_UPLOAD_LEVEL5_FILE",
    };
};

export const uploadLevel5File = (data,id1,id2,id3,handleVisible,handleDangerVisible) => {
    const formData = new FormData();
    formData.append("file", get(data, 'file'));
    formData.append("levelOneId", `${id1}`);
    formData.append("levelTwoId", `${id2}`);
    formData.append("levelThreeId", `${id3}`);
    return (dispatch) => {
        dispatch(requestUploadLevel5File());
        API.post(`${baseURL}/levelFour/upload_levelFour`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(successUploadLevel5File(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorUploadLevel5File());
            handleDangerVisible();
        })
    };
};
export const requestSearchAccountCashBook = () => {
    return {
        type: "REQUEST_SEARCH_CASH_BOOK",
    };
};
export const successSearchAccountCashBook= (data) => {
    return {
        type: "SUCCESS_SEARCH_CASH_BOOK",
        payload: data,
    };
};
export const errorSearchAccountCashBook = () => {
    return {
        type: "ERROR_SEARCH_CASH_BOOK",
    };
};

export const searchAccountCashBook = (data, startDate, endDate) => {
    return (dispatch) => {
        console.log(data, "data1")
        dispatch(requestSearchAccountCashBook());
        API.post(`/reports/cash_book_report`, data).then((res) => {
            let data1=res.data;
            console.log(data1,"jjjjjjjjj")
            let getData = res.data.data;
            console.log(getData, "data2")
            let dataD=_.filter(getData,{credit:0})
            console.log(dataD,"dabit")
            let td=sumBy(dataD,'debit')
            console.log(td,'hm')
            let dataC=_.filter(getData,{debit:0})
            console.log(dataC,"crdit")
            let tc=sumBy(dataC,'credit')
            console.log(tc,'hm')
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape
            const logo1=data1.restaurantImage;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(11);
            const title = "Cash Book Report";
            const companyName = `${data1.resImage.restaurantName}`;
            const companyAddress = `${data1.resImage.restaurantLocation}`;
            const companyNtn = `${data1.resImage.ntn}`;
            const Dates = !isEmpty(startDate) || !isEmpty(endDate) ? "Form: " + " " + startDate + "  to  " + endDate : "Form: -  To: - ";
            const openingBalance = `${getData[0].closingBalance} ${getData[0].openingBalanceSign ==="Debit" ? 'DR' : 'CR'}`;
            const totalDebit = `${data1.debitSum}`;
            const totalCredit = `${data1.creditSum}`;
            let i=getData.length-1;
            console.log(i,"bb")
            const closingBalance = `${data1.closing_balance}  ${data1.closing_balance_sign ==="Debit" ? 'DR' : 'CR'}`;
           /* const accountTitle = "Account Title:  " + getData.head;
            const TotalCredit = "Total Credit: " + getData.credit_sum.toLocaleString();
            const TotalDebit = "Total Debit: " + getData.debit_sum.toLocaleString();
            const Rarray = getData.a.slice(-1);
            const TotalBalance = "Closing Balance: " + Rarray[0].accBalance.toLocaleString();*/
//(${x.cross_acc})`, x.voucher_bill_no, isNull(x.debit) ? 0 : Number(x.debit).toLocaleString(), isNull(x.credit) ? 0 : Number(x.credit).toLocaleString(), Number(x.accBalance).toLocaleString(), x.accType == 2 ? "Debit" : x.accType == 1 ? "Credit" : ""
            const headers = [["DATE", "VOUCHER#", "NARRATION", "AMOUNT"]];
            const dataDebit = dataD.map((x,i) => [x.voucherDate, x.vocManualNumber, x.voucherDescription,x.debit ])
            const dataCredit = dataC.map((x,i) => [x.voucherDate, x.vocManualNumber, x.voucherDescription,x.credit ])
            doc.addImage(logo1, 'PNG', 40, 10, 65, 65);
            doc.text(companyName, 110, 30, 0, 20);
             doc.text(companyAddress,110, 45, 0, 20);
                doc.text(companyNtn, 110, 60, 0, 20);
                doc.text("DAILY CASH REPORT", 400, 35, 0, 20);
                doc.text(Dates, 400, 55, 0, 20);
                doc.setFontSize(18);
                doc.setFont(undefined,'bold');
                doc.text(title, 220, 80, 0, 70);
                doc.setLineWidth(2);
                doc.line(550,90,35,90);
                doc.setFontSize(12);
                doc.setFont(undefined,'normal');
                doc.text("OPENING BALANCE",40,110,0,20)
                doc.text(openingBalance,470,110,0,20)
                doc.text("RECEIPTS",250,125,0,20)
            let content = {
                startY: 135,
                startX: 10,
                head: headers,
                theme: 'grid',
                headStyles: {
                    fillColor: [13,110,253]
                    },
                body: dataDebit
            };
            doc.autoTable(content);
            let content1 = {
                startY: doc.lastAutoTable.finalY+40,
                startX: 10,
                head: headers,
                theme: 'grid',
                headStyles: {
                    fillColor: [13,110,253]
                    },
                body: dataCredit
            };

            doc.text("PAYMENTS",250,doc.lastAutoTable.finalY+30,0,20)
            doc.text("Total Receipt",43,doc.lastAutoTable.finalY+15,0,0)
            doc.text(totalDebit,455,doc.lastAutoTable.finalY+15,0,0)
           /* doc.text(accountNo, marginLeft, 140, 0, 20);
            doc.text(accountTitle, marginLeft, 160, 0, 20);
            doc.text(title, marginLeft, 175);
            doc.text(TotalCredit, marginLeft, 190);
            doc.text(TotalDebit, marginLeft, 208);
            doc.text(TotalBalance, marginLeft, 225);*/
            doc.autoTable(content1);
            doc.text("Total Payment",43,doc.lastAutoTable.finalY+15,0,0)
            doc.text(totalCredit,455,doc.lastAutoTable.finalY+15,0,0)
            doc.text("CLOSING BALANCE",40,doc.lastAutoTable.finalY+30,0,0)
            doc.text(closingBalance,470,doc.lastAutoTable.finalY+30,0,0)
            doc.setLineWidth(2);
            doc.line(150, doc.lastAutoTable.finalY+70, 40, doc.lastAutoTable.finalY+70);
            doc.text("Prepared By",60,doc.lastAutoTable.finalY+85,0,0)
            doc.setLineWidth(2);
            doc.line(240,doc.lastAutoTable.finalY+70,350,doc.lastAutoTable.finalY+70)
            doc.text("Checked By",265,doc.lastAutoTable.finalY+85,0,0)
            doc.setLineWidth(2);
            doc.line(430,doc.lastAutoTable.finalY+70,540,doc.lastAutoTable.finalY+70)
            doc.text("Approved By",455,doc.lastAutoTable.finalY+85,0,0)
            doc.setLineWidth(2);
         doc.setProperties({ title: `Account cash book ${moment(new Date()).format("YYYY-MM-DD")}.pdf` })
          //ct  doc.output('dataurlnewwindow')
            doc.save(`Cash Book Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`)
            dispatch(successSearchAccountCashBook(getData));
        }).catch((error) => {
            dispatch(errorSearchAccountCashBook());
           console.log(error, "eeeeeeeeeeee")
        })
    };
};