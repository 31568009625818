import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Card, Form } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import "jspdf-autotable";
import Swal from "sweetalert";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import AddBusinessTwoToneIcon from "@mui/icons-material/AddBusiness";
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import AddIcCallTwoToneIcon from "@mui/icons-material/AddIcCallTwoTone";
import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import DialpadTwoToneIcon from "@mui/icons-material/DialpadTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import { sumBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import EditShipmentMain from "./EditShipmentMain";
import { getCountry } from "../../country/Country.action";
import {
  getCargoService,
  getPayList,
  getServiceType,
  getShipmentPurpose,
  getUom,
} from "../createShipment/CreateShipment.action";
import { getReceiver } from "../../receiver/Receiver.action";
import { getSender } from "../../sender/Sender.action";
import { getCurrency } from "../../currency/Currency.action";
import moment from "moment";
import {
  setEditShipmentAddDetail,
  setEditShipmentData,
  setEditShipmentDetail,
  setEditShipmentPackAddData,
  setEditShipmentPackData,
  setEditShipmentPackRemoveData,
  setEditShipmentRemoveDetail,
  setEditShipmentSender,
  updateShipment,
} from "../Shipment.action";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
const EditShipment = (props) => {
  const [skipped] = React.useState(new Set());
  const [editable, setEditable] = useState(true);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const getSenderList =
    !isEmpty(props.senderList) &&
    props.senderList.map((x) => {
      let data = {
        value: x.sender_id,
        label: x.name,
        email: x.email,
        phone: x.phone,
        postal_code: x.postal_code,
        country_id: x.country_id,
        // country: x.country.name,
        address: x.address,
        address_2: x.address_2,
        address_3: x.address_3,
        city: x.city,
        state: x.state,
        company: x.company,
        __isNew__: false,
      };
      return data;
    });
  const getReceiverList =
    !isEmpty(props.receiverList) &&
    props.receiverList.map((x) => {
      let data = {
        value: x.receiver_id,
        label: x.name,
        email: x.email,
        phone: x.phone,
        postal_code: x.postal_code,
        country_id: x.country_id,
        // country: x.country.name,
        address: x.address,
        address_2: x.address_2,
        address_3: x.address_3,
        city: x.city,
        state: x.state,
        company: x.company,
        __isNew__: false,
      };
      return data;
    });
  const [emailError, setEmailError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [cellNumberError, setCellNumberError] = useState(false);

  const handleCompanyNameChange = (e) => {
    props.setEditSenderData({ key: "company", value: e.target.value });
    if (e.target.value.length > 2) {
      setCompanyError(false);
    } else {
      setCompanyError(true);
    }
  };
  const handleEmailChange = (e) => {
    props.setSenderData({ key: "email", value: e.target.value });
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };
  const handleCellNumberChange = (e) => {
    const pattern =
      /^(?:\+92|0)?(3\d{2}|5[0-9]\d{1}|7[0-9]\d{1}|8[0-9]\d{1}|9[0-9]\d{1})\d{7}$/;
    const inputValue = e.target.value;
    if (e.target.value.length <= 11) {
      //   setPhone(inputValue);
      props.setSenderData({ key: "phone", value: inputValue });

      if (pattern.test(inputValue)) {
        setCellNumberError(false);
      } else {
        setCellNumberError(true);
      }
    }
  };

  const onOptionsChanged = (e) => {
    if (e.__isNew__ === false) {
      setEditable(true);
      props.setShipmentData({ key: "sender_id", value: e.value });
      props.setEditSenderData({ key: "id", value: e.value });
      props.setEditSenderData({ key: "name", value: e.label });
      props.setEditSenderData({ key: "country_id", value: e.country_id });
      props.setEditSenderData({ key: "company", value: e.company });
      props.setEditSenderData({ key: "address", value: e.address });
      props.setEditSenderData({ key: "address_2", value: e.address_2 });
      props.setEditSenderData({ key: "address_3", value: e.address_3 });
      props.setEditSenderData({ key: "postal_code", value: e.postal_code });
      props.setEditSenderData({ key: "city", value: e.city });
      props.setEditSenderData({ key: "state", value: e.state });
      props.setEditSenderData({ key: "email", value: e.email });
      props.setEditSenderData({ key: "phone", value: e.phone });
    } else {
      setEditable(false);
      props.setEditSenderData({ key: "name", value: e.label });
      props.setEditSenderData({ key: "country_id", value: 0 });
      props.setEditSenderData({ key: "company", value: "" });
      props.setEditSenderData({ key: "address", value: "" });
      props.setEditSenderData({ key: "address_2", value: "" });
      props.setEditSenderData({ key: "address_3", value: "" });
      props.setEditSenderData({ key: "postal_code", value: "" });
      props.setEditSenderData({ key: "city", value: "" });
      props.setEditSenderData({ key: "state", value: "" });
      props.setEditSenderData({ key: "email", value: "" });
      props.setEditSenderData({ key: "phone", value: "" });
    }
  };
  const handleChangeCountry = (e) => {
    props.setEditShipmentDetail({ key: "country_id", value: e.target.value });
    // setCountry(e.target.value);
  };
  const handleChangeCountryData = (e, i) => {
    props.setEditShipmentDetail({ key: "country_id", value: e.target.value, length: i });
    // setCountry(e.target.value);
  };
  const handleChangeServiceType = (e) => {
    props.setShipmentData({ key: "service_type_id", value: e.target.value });
    // setCountry(e.target.value);
  };
  const handleChangeCurrency = (e, i) => {
    props.setEditShipmentDetail({
      key: "currency_id",
      value: e.target.value,
      length: i,
    });
  };
  const handleChangeUom = (e, i) => {
    props.setEditShipmentDetail({
      key: "units",
      value: e.target.value,
      length: i,
    });
  };
  const handleChangePurpose = (e) => {
    props.setShipmentData({
      key: "shipment_purpose_id",
      value: e.target.value,
    });
  };
  const handleClickPackage = (e) => {
    props.setShipmentData({ key: "shipment_type", value: "Pack" });
    isEmpty(props.shipmentByIdData) && handleAddInputDetail();
  }; const [packages, setPackages] = useState(1);
  const handleAddInput1 = (e) => {
    // props.handleRemoveInputPackagingDetail()
    let pkVal = isEmpty(e.target.value) ? 1 : e.target.value;
    setPackages(pkVal);
    if (props.packagingDetail.length < pkVal) {
      for (let i = props.packagingDetail.length; i < pkVal; i++) {
        props.handleAddInputPackagingDetail();
      }
    } else if (props.packagingDetail.length > pkVal) {
      for (let i = props.packagingDetail.length; i >= pkVal; i--) {
        props.handleRemoveInputPackagingDetail(i);
      }
    }
  };

  // const handleAddInput1 = () => {
  //   props.setAddShipmentPackData();
  // };
  //remove for add fuctio
  const handleRemoveInput1 = (i) => {
    props.setRemoveShipmentPackData(i);
  };
  const handleAddInputDetail = () => {
    props.setAddShipmentDetail();
  };
  //remove for add fuctio
  const handleRemoveInputDetail = (i) => {
    props.setRemoveShipmentDetail(i);
  };
  const handleChangeTaxType = (e) => {
    props.setShipmentData({ key: "shipment_tax_id", value: e.target.value });
  };
  // expected_delivery_date
  const handleChangeEstimatedDeliveryDate = (e) => {
    props.setShipmentData({
      key: "expected_delivery_date",
      value: e.target.value,
    });
  };
  const handleAddShipment = (e) => {
    props.updateShipment(
      {
        shipment_desc: props.shipmentById[0].shipment_type === "Doc" ? props.shipmentById[0].shipment_desc : "",
        // shipment_ref:
        //   props.shipmentRef === ""
        //     ? props.senderData[0].company
        //     : props.shipmentRef,
        // total_packages:
        //   props.packagingDetail[0].quantity === 0
        //     ? 0
        //     : sumBy(props.packagingDetail, "quantity"),
        // total_weight:
        //   props.packagingDetail[0].weight === 0
        //     ? 0
        //     : sumBy(props.packagingDetail, "weight"),
        // shipment_date: props.date,
        shipment_purpose_id:
          props.shipmentById[0].shipment_type === "Doc" ? null : props.shipmentById[0].shipment_purpose_id,
        shipment_type: props.shipmentById[0].shipment_type,
        service_type_id: props.shipmentById[0].service_type_id,
        shipment_tax_id: props.shipmentById[0].shipment_tax_id,
        expected_delivery_date: props.shipmentById[0].expected_delivery_date,
        data: props.shipmentById[0].shipment_type === "Doc" ? [] : props.shipmentByIdData,
        rebate: props.shipmentById[0].rebate,
        sro_number: props.shipmentById[0].sro_number,
        financial_instruments: props.shipmentById[0].financial_instruments,
        financial_instruments_no:
          props.shipmentById[0].instrument == 0 ? null : props.shipmentById[0].financial_instruments_no,
        financial_instruments_bank: props.shipmentById[0].financial_instruments_bank,
        // packages: props.packagingDetail,

        // receiver_id:
        //   props.receiverData[0].id === "" ? null : props.receiverData[0].id,
        // sender_id:
        //   props.senderData[0].id === "" ? null : props.senderData[0].id,
        // sender: {
        //   name: props.senderData[0].name,
        //   company: props.senderData[0].company,
        //   address: props.senderData[0].address,
        //   address_2: props.senderData[0].address_2,
        //   address_3: props.senderData[0].address_3,
        //   postal_code: props.senderData[0].postal_code,
        //   city: props.senderData[0].city,
        //   state: props.senderData[0].state,
        //   email: props.senderData[0].email,
        //   // phone_type: props.senderData[0].phone_type,
        //   code: props.senderData[0].code,
        //   phone: props.senderData[0].phone,
        //   country_id: props.senderData[0].country_id,
        // },
        // receiver: {
        //   name: props.receiverData[0].name,
        //   company: props.receiverData[0].company,
        //   address: props.receiverData[0].address,
        //   address_2: props.receiverData[0].address_2,
        //   address_3: props.receiverData[0].address_3,
        //   postal_code: props.receiverData[0].postal_code,
        //   city: props.receiverData[0].city,
        //   state: props.receiverData[0].state,
        //   email: props.receiverData[0].email,
        //   // phone_type: props.senderData[0].phone_type,
        //   code: props.receiverData[0].code,
        //   phone: props.receiverData[0].phone,
        //   country_id: props.receiverData[0].country_id,
        // },
      },
      props.shipmentById[0].shipment_id
    );
  };
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  useEffect(() => {
    props.getSender();
    props.getReceiver();
    props.getCountry();
    props.getShipmentPurpose();
    props.getCurrency();
    props.getPayList();
    props.getServiceType();
    props.getUom();
    document.title = props.title;
  }, []);
  return (
    <>
      {props.isFetchingShipmentById ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          {false ? (
            <div className="main" style={{ height: "88vh" }}>
              <h1>Add Sender from Master Control In Order to add Shipments</h1>
            </div>
          ) : (
            <div className="main" style={{ backgroundColor: "#F9FAFB" }}>
              {/* sender */}
              <Card
                style={{
                  height: "auto",
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  border: "none",
                  padding: "25px",
                  marginTop: "20px",
                }}
              >
                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Typography
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      <b>FROM</b>
                    </Typography>
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <CreatableSelect
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "none",
                          borderBottom: "1px solid black",
                          borderRadius: 0,
                          boxShadow: "none",
                          backgroundColor: "white",
                        }),
                      }}
                      isClearable
                      ////
                      defaultValue={{
                        value: props.shipmentById[0].sender_id,
                        label: props.shipmentById[0].shipment_senders[0].name,
                      }}
                      //  displayEmpty={true}
                      // isOptionSelected={props.shipmentById[0].shipment_senders[0].name}
                      // isOptionSelected={props.shipmentById[0].sender_id}
                      // defaultInputValue={props.shipmentById[0].shipment_senders[0].name}
                      // value={[props.shipmentById[0].shipment_senders[0].name]}
                      isDisabled={true}
                      options={!isEmpty(getSenderList) ? getSenderList : []}
                      onChange={onOptionsChanged}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DomainTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      required
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Company"
                      error={companyError}
                      helperText={
                        companyError
                          ? "Please Enter Your Company Name (Atleast 3 Character)"
                          : ""
                      }
                      ////
                      value={props.shipmentById[0].shipment_senders[0].company}
                      disabled={editable}
                      onChange={(e) => handleCompanyNameChange(e)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        required
                      >
                        Select Country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={
                          !isEmpty(props.shipmentById) &&
                          props.shipmentById[0].shipment_senders[0].country_id
                        }
                        disabled={editable}
                        label="Select Country"
                        onChange={handleChangeCountry}
                      >
                        {props.countryList.map((name) => (
                          <MenuItem
                            key={name.country_id}
                            value={name.country_id}
                          >
                            {name.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddIcCallTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      required
                      type="Number"
                      id="standard-basic"
                      variant="standard"
                      label="Phone"
                      value={props.shipmentById[0].shipment_senders[0].phone}
                      disabled={editable}
                      error={cellNumberError}
                      helperText={
                        cellNumberError ? "Please Enter Valid Cell Number" : ""
                      }
                      onChange={(e) => handleCellNumberChange(e)}
                      sx={{
                        width: "100%",
                        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                        {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                        "input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddBusinessTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      required
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Address"
                      disabled={editable}
                      ////
                      value={props.shipmentById[0].shipment_senders[0].address}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "address",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddBusinessTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Address 2"
                      disabled={editable}
                      value={
                        props.shipmentById[0].shipment_senders[0].address_2
                      }
                      // onChange={(e) => setCompanyAddress2(e.target.value)}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "address_2",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddBusinessTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Address 3"
                      disabled={editable}
                      value={
                        props.shipmentById[0].shipment_senders[0].address_3
                      }
                      // onChange={(e) => setCompanyAddress3(e.target.value)}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "address_3",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddLocationAltTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="City"
                      value={props.shipmentById[0].shipment_senders[0].city}
                      disabled={editable}
                      // onChange={(e) => setCity(e.target.value)}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "city",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddLocationAltTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="State"
                      value={props.shipmentById[0].shipment_senders[0].state}
                      disabled={editable}
                      // onChange={(e) => setState(e.target.value)}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "state",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DialpadTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      // type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Postal Code"
                      value={
                        props.shipmentById[0].shipment_senders[0].postal_code
                      }
                      disabled={editable}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "postal_code",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: "15px" }}>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Email"
                      value={props.shipmentById[0].shipment_senders[0].email}
                      disabled={editable}
                      error={emailError}
                      helperText={emailError ? "Please Enter Valid Email" : ""}
                      onChange={(e) => handleEmailChange(e)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>

                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NumbersTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="VAT/Tax ID"
                      value={props.shipmentById[0].shipment_senders[0].vatId}
                      // disabled={editable}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "vatId",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>

                {/* {editable === true ? (
            ""
          ) : (
            <Row style={{ marginTop: "15px" }}>
              <Col xl="2" lg="2" md="2" sm="4" xs="6">
                <Button
                  disabled={
                    props.shipmentById[0].senderData[0].company === "" ||
                    props.shipmentById[0].senderData[0].country_id === 0 ||
                    props.shipmentById[0].senderData[0].address === "" ||
                    props.shipmentById[0].senderData[0].phone === "" ||
                    props.shipmentById[0].senderData[0].email === ""
                  }
                  onClick={handleSaveSender}
                >
                  Save Sender
                </Button>
              </Col>
              <Col xl="8" lg="8" md="8" sm="4" xs="0"></Col>
              <Col xl="2" lg="2" md="2" sm="4" xs="6">
                <Button
                  disabled={
                    props.shipmentById[0].senderData[0].company === "" ||
                    props.shipmentById[0].senderData[0].country_id === 0 ||
                    props.shipmentById[0].senderData[0].address === "" ||
                    props.shipmentById[0].senderData[0].phone === "" ||
                    props.shipmentById[0].senderData[0].email === ""
                  }
                  onClick={handleContinue}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          )} */}
              </Card>
              {/* Receiver */}
              <Card
                style={{
                  height: "auto",
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  border: "none",
                  padding: "25px",
                  marginTop: "20px",
                }}
              >
                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Typography
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      <b>TO</b>
                    </Typography>
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <CreatableSelect
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "none",
                          borderBottom: "1px solid black",
                          borderRadius: 0,
                          boxShadow: "none",
                          backgroundColor: "white",
                        }),
                      }}
                      isClearable
                      // defaultValue={props.shipmentById[0].shipment_receiver[0].name}
                      // defaultInputValue={
                      //   props.shipmentById[0].shipment_receivers[0].name
                      // }
                      defaultValue={{
                        value: props.shipmentById[0].receiver_id,
                        label: props.shipmentById[0].shipment_receivers[0].name,
                      }}
                      isDisabled={true}
                      // value={props.shipmentById[0].shipment_receivers[0].name}
                      options={!isEmpty(getReceiverList) ? getReceiverList : []}
                      onChange={onOptionsChanged}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DomainTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      required
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Company"
                      error={companyError}
                      helperText={
                        companyError
                          ? "Please Enter Your Company Name (Atleast 3 Character)"
                          : ""
                      }
                      value={
                        props.shipmentById[0].shipment_receivers[0].company
                      }
                      disabled={editable}
                      onChange={(e) => handleCompanyNameChange(e)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        required
                      >
                        {" "}
                        Select Country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={
                          !isEmpty(props.shipmentById) &&
                          props.shipmentById[0].shipment_receivers[0].country_id
                        }
                        disabled={editable}
                        label="Select Country"
                        onChange={handleChangeCountry}
                      >
                        {props.countryList.map((name) => (
                          <MenuItem
                            key={name.country_id}
                            value={name.country_id}
                          >
                            {name.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddIcCallTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      required
                      type="Number"
                      id="standard-basic"
                      variant="standard"
                      label="Phone"
                      value={props.shipmentById[0].shipment_receivers[0].phone}
                      disabled={editable}
                      error={cellNumberError}
                      helperText={
                        cellNumberError ? "Please Enter Valid Cell Number" : ""
                      }
                      onChange={(e) => handleCellNumberChange(e)}
                      sx={{
                        width: "100%",
                        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                        {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                        "input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddBusinessTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      required
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Address"
                      disabled={editable}
                      value={
                        props.shipmentById[0].shipment_receivers[0].address
                      }
                      onChange={(e) =>
                        props.setSenderData({
                          key: "address",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddBusinessTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Address 2"
                      disabled={editable}
                      value={
                        props.shipmentById[0].shipment_receivers[0].address_2
                      }
                      // onChange={(e) => setCompanyAddress2(e.target.value)}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "address_2",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  {" "}
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddBusinessTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Address 3"
                      disabled={editable}
                      value={
                        props.shipmentById[0].shipment_receivers[0].address_3
                      }
                      // onChange={(e) => setCompanyAddress3(e.target.value)}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "address_3",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddLocationAltTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="City"
                      value={props.shipmentById[0].shipment_receivers[0].city}
                      disabled={editable}
                      // onChange={(e) => setCity(e.target.value)}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "city",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddLocationAltTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="State"
                      value={props.shipmentById[0].shipment_receivers[0].state}
                      disabled={editable}
                      // onChange={(e) => setState(e.target.value)}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "state",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DialpadTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      // type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Postal Code"
                      value={
                        props.shipmentById[0].shipment_receivers[0].postal_code
                      }
                      disabled={editable}
                      onChange={(e) =>
                        props.setSenderData({
                          key: "postal_code",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: "15px" }}>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Email"
                      value={props.shipmentById[0].shipment_receivers[0].email}
                      disabled={editable}
                      error={emailError}
                      helperText={emailError ? "Please Enter Valid Email" : ""}
                      onChange={(e) => handleEmailChange(e)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
                {/* {editable === true ? (
            ""
          ) : (
            <Row style={{ marginTop: "15px" }}>
              <Col xl="2" lg="2" md="2" sm="4" xs="6">
                <Button
                  disabled={
                    props.shipmentById[0].senderData[0].company === "" ||
                    props.shipmentById[0].senderData[0].country_id === 0 ||
                    props.shipmentById[0].senderData[0].address === "" ||
                    props.shipmentById[0].senderData[0].phone === "" ||
                    props.shipmentById[0].senderData[0].email === ""
                  }
                  onClick={handleSaveSender}
                >
                  Save Sender
                </Button>
              </Col>
              <Col xl="8" lg="8" md="8" sm="4" xs="0"></Col>
              <Col xl="2" lg="2" md="2" sm="4" xs="6">
                <Button
                  disabled={
                    props.shipmentById[0].senderData[0].company === "" ||
                    props.shipmentById[0].senderData[0].country_id === 0 ||
                    props.shipmentById[0].senderData[0].address === "" ||
                    props.shipmentById[0].senderData[0].phone === "" ||
                    props.shipmentById[0].senderData[0].email === ""
                  }
                  onClick={handleContinue}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          )} */}
              </Card>
              {/* Service Type */}
              <Card
                style={{
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  border: "none",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Typography
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      <b>SELECT SERVICE TYPE</b>
                    </Typography>
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="6" lg="6" md="6" sm="6" xs="12">
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        required
                      >
                        {" "}
                        Select Service
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={props.shipmentById[0].service_type_id}
                        // disabled={editable}
                        label="Select Service"
                        onChange={handleChangeServiceType}
                      >
                        {props.serviceTypeList.map((name) => (
                          <MenuItem
                            key={name.service_type_id}
                            value={name.service_type_id}
                          >
                            {name.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Typography
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      <p>
                        {" "}
                        <b>Shipment Detail</b>
                        <br />
                        What are you Shipping?
                      </p>
                    </Typography>
                  </Col>
                </Row>

                <Row style={{ marginTop: "15px" }}>
                  <Col xl="6" lg="6" md="6" sm="6" xs="12">
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {props.shipmentById[0].shipment_type ===
                              "Doc" ? (
                              <DoneTwoToneIcon />
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      value="Documents"
                      style={{
                        backgroundColor:
                          props.shipmentById[0].shipment_type === "Doc"
                            ? "#D9DDE0"
                            : "",
                      }}
                      onClick={(e) =>
                        props.setShipmentData({
                          key: "shipment_type",
                          value: "Doc",
                        })
                      }
                      sx={{
                        input: { cursor: "pointer" },
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="6" xs="12">
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {props.shipmentById[0].shipment_type ===
                              "Pack" ? (
                              <DoneTwoToneIcon />
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      value="Packages"
                      style={{
                        backgroundColor:
                          props.shipmentById[0].shipment_type === "Pack"
                            ? "#D9DDE0"
                            : "",
                      }}
                      onClick={handleClickPackage}
                      sx={{
                        input: { cursor: "pointer" },
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col xl="6" lg="6" md="6" sm="6" xs="12">
                    <Typography
                      style={{
                        fontFamily: "Public Sans,sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Documents include legal, financial or business paperwork.
                      Items with monetary value are NOT considered a document
                      shipment.
                    </Typography>
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="6" xs="12">
                    <Typography
                      style={{
                        fontFamily: "Public Sans,sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Packages are goods, merchandise or commodities for
                      personal or commercial purposes.
                    </Typography>
                  </Col>
                </Row>
                {props.shipmentById[0].shipment_type === "Pack" ? (
                  <Row style={{ marginTop: "20px" }}>
                    <Col xl="6" lg="6" md="6" sm="6" xs="12">
                      <FormControl variant="standard" fullWidth>
                        <Form.Label id="demo-simple-select-standard-label">
                          <b> Purpose of Your Shipment *</b>
                        </Form.Label>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={props.shipmentById[0].shipment_purpose_id}
                          placeholder="Select Purpose"
                          // label="Select Purpose"
                          onChange={handleChangePurpose}
                        >
                          {props.shipmentPurposeList.map((name) => (
                            <MenuItem
                              key={name.shipment_purpose_id}
                              value={name.shipment_purpose_id}
                            >
                              {name.purpose}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {props.shipmentById[0].shipment_type === "Pack" ? (
                  <Row style={{ marginTop: "20px" }}>
                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                      <Typography
                        style={{ fontFamily: "Public Sans,sans-serif" }}
                      >
                        <b>Tell Us What You're Shipping</b>
                      </Typography>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {props.shipmentById[0].shipment_type === "Pack"
                  ? props.shipmentByIdData.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <>
                          <Card
                            style={{
                              marginTop: "15px",
                              padding: "20px",
                              borderLeftWidth: "10px",
                            }}
                          >
                            <Row style={{ marginTop: "15px" }}>
                              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                                <TextField
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AddBoxTwoToneIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  type="text"
                                  id="standard-basic"
                                  variant="standard"
                                  label="Description"
                                  value={item.desc}
                                  // style={{ backgroundColor: selectType === "Doc" ? 'silver' : "" }}
                                  onChange={(e) =>
                                    props.setEditShipmentDetail({
                                      key: "desc",
                                      value: e.target.value,
                                      length: i,
                                    })
                                  }
                                  sx={{
                                    // input: { cursor: 'pointer' },
                                    width: "100%",
                                  }}
                                />
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                                <TextField
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AddBoxTwoToneIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  type="text"
                                  id="standard-basic"
                                  variant="standard"
                                  label="Code"
                                  value={item.code}
                                  // style={{ backgroundColor: selectType === "Doc" ? 'silver' : "" }}
                                  onChange={(e) =>
                                    props.setEditShipmentDetail({
                                      key: "code",
                                      value: e.target.value,
                                      length: i,
                                    })
                                  }
                                  sx={{
                                    // input: { cursor: 'pointer' },
                                    width: "100%",
                                  }}
                                />
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                                <TextField
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AddBoxTwoToneIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  type="Number"
                                  id="standard-basic"
                                  variant="standard"
                                  label="Quantity"
                                  value={item.quantity}
                                  // style={{ backgroundColor: selectType === "Doc" ? 'silver' : "" }}
                                  onChange={(e) =>
                                    props.setEditShipmentDetail({
                                      key: "quantity",
                                      value: Number(e.target.value),
                                      length: i,
                                    })
                                  }
                                  sx={{
                                    // input: { cursor: 'pointer' },
                                    width: "100%",
                                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                                    {
                                      WebkitAppearance: "none",
                                      margin: 0,
                                    },
                                    "input[type=number]": {
                                      MozAppearance: "textfield",
                                    },
                                  }}
                                />
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel
                                    id="demo-simple-select-standard-label"
                                    required
                                  >
                                    Units(who Item Packaged)
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={item.uom_id}
                                    // placeholder="Select Purpose of Your Shipment"
                                    label="Units(who Item Packaged)"
                                    onChange={(e) => handleChangeUom(e, i)}
                                  >
                                    {props.uomList.map((name) => (
                                      <MenuItem
                                        key={name.uomId}
                                        value={name.uomId}
                                      >
                                        {name.uomName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "15px" }}>
                              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel
                                    id="demo-simple-select-standard-label"
                                    required
                                  >
                                    Select Currency
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={item.currency_id}
                                    // placeholder="Select Currency *"
                                    label="Select Currency"
                                    // onChange={handleChangeCurrency(i)}
                                    onChange={(e) =>
                                      handleChangeCurrency(e, i)
                                    }
                                  >
                                    {props.currencyList.map((name) => (
                                      <MenuItem key={name.id} value={name.id}>
                                        {name.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                                <TextField
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AddBoxTwoToneIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  type="Number"
                                  id="standard-basic"
                                  variant="standard"
                                  label="Value"
                                  value={item.value}
                                  // style={{ backgroundColor: selectType === "Doc" ? 'silver' : "" }}
                                  onChange={(e) => {
                                    props.setEditShipmentDetail({
                                      key: "value",
                                      value: Number(e.target.value),
                                      length: i,
                                    })
                                    props.setEditShipmentDetail({
                                      key: "total",
                                      value: Number(e.target.value) * Number(item.quantity),
                                      length: i,
                                    })

                                  }
                                  }
                                  sx={{
                                    // input: { cursor: 'pointer' },
                                    width: "100%",
                                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                                    {
                                      WebkitAppearance: "none",
                                      margin: 0,
                                    },
                                    "input[type=number]": {
                                      MozAppearance: "textfield",
                                    },
                                  }}
                                />
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel
                                    id="demo-simple-select-standard-label"
                                    required
                                  >
                                    Where item Made
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={item.country_id}
                                    placeholder="item Made"
                                    label="item Made"
                                    // onChange={handleChangeCountry(i)}
                                    onChange={(e) =>
                                      handleChangeCountryData(e, i)
                                    }
                                  >
                                    {props.countryList.map((name) => (
                                      <MenuItem
                                        key={name.country_id}
                                        value={name.country_id}
                                      >
                                        {name.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Col>
                              <Col
                                lg="3"
                                md="3"
                                sm="3"
                                style={{ marginTop: "2.5%" }}
                              >
                                <Form.Group>
                                  {props.shipmentByIdData.length - 1 ===
                                    i && (
                                      <Button
                                        title="Click to Add Shipping"
                                        onClick={handleAddInputDetail}
                                        style={{
                                          marginLeft: "2%",
                                          color: "black",
                                          float: "right",
                                          backgroundColor: "#C5CBCF",
                                          border: "1px solid white",
                                        }}
                                      >
                                        <AddTwoToneIcon />
                                      </Button>
                                    )}

                                  {props.shipmentByIdData.length !== 1 && (
                                    <Button
                                      title="Click to Remove Shipping"
                                      onClick={() =>
                                        handleRemoveInputDetail(i)
                                      }
                                      style={{
                                        marginRight: "2%",
                                        color: "black",
                                        float: "right",
                                        backgroundColor: "#C5CBCF",
                                        border: "1px solid white",
                                      }}
                                    >
                                      <RemoveTwoToneIcon />
                                    </Button>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                          </Card>
                        </>
                      </React.Fragment>
                    );
                  })
                  : ""}
                {props.shipmentById[0].shipment_type === "Pack" ? (
                  <Row
                    style={{
                      backgroundColor: "#D9DDE0",
                      marginTop: "10px",
                      marginBottom: "10px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Col xl="3" lg="3" md="3" sm="4" xs="4">
                      <Typography
                        style={{ fontFamily: "Public Sans,sans-serif" }}
                      >
                        <b>
                          {" "}
                          Total Unit{" "}
                          {props.shipmentByIdData.quantity === 0
                            ? "--------"
                            : sumBy(props.shipmentByIdData, "quantity")}
                        </b>
                      </Typography>
                    </Col>
                    {/* <Col xl="3" lg="3" md="3" sm="4" xs="4">
                    <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
                      <b>
                        {" "}
                        Total Weight{" "}
                        {props.shipmentByIdData.weight === 0
                          ? "--------"
                          : sumBy(props.shipmentByIdData, "weight")}{" "}
                        kg{" "}
                      </b>
                      </Typography>
                    </Col> */}
                    <Col xl="3" lg="3" md="3" sm="4" xs="4">
                      <Typography
                        style={{ fontFamily: "Public Sans,sans-serif" }}
                      >
                        <b>
                          {" "}
                          Total Value{" "}
                          {props.shipmentByIdData.value === 0
                            ? "--------"
                            : sumBy(props.shipmentByIdData, "total")}{" "}
                          USD{" "}
                        </b>
                      </Typography>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {props.shipmentById[0].shipment_type === "Doc" ? (
                  <Row style={{ marginTop: "20px" }}>
                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                      <Typography
                        style={{ fontFamily: "Public Sans,sans-serif" }}
                      >
                        <b>Describe the documents in your shipment</b>
                      </Typography>
                    </Col>
                    <Col
                      xl="6"
                      lg="6"
                      md="6"
                      sm="6"
                      xs="12"
                      style={{ marginTop: "15px" }}
                    >
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddBoxTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="text"
                        id="standard-basic"
                        variant="standard"
                        label="Description"
                        value={props.shipmentById[0].shipment_desc}
                        // style={{ backgroundColor: selectType === "Doc" ? 'silver' : "" }}
                        onChange={(e) =>
                          props.setShipmentData({
                            key: "shipment_desc",
                            value: e.target.value,
                          })
                        }
                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                        }}
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row style={{ marginTop: "20px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Typography
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      <b>Add Shipment Reference</b>
                    </Typography>
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="6" lg="6" md="6" sm="6" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddBoxTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Reference"
                      disabled
                      defaultValue={props.shipmentById[0].shipment_ref}
                      onChange={(e) =>
                        props.setShipmentData({
                          key: "shipment_ref",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
              {/* packaging */}
              {/* <Card
                style={{
                  height: "auto",
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  border: "none",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <Row style={{ marginTop: "20px" }}>
                  <Col xl="6" lg="6" md="6" sm="6" xs="6">
                    <TextField
                      InputProps={{
                        inputProps: { min: 1 },
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddBoxTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="number"
                      id="standard-basic"
                      variant="standard"
                      label="Number of Packages *"
                      value={packages}
                      onChange={(e) => handleAddInput1(e)}
                      // onChange={(e) =>
                      //   props.setAddPackagingDetail({ key: "no_packages", value: e.target.value })
                      // }
                      sx={{
                        width: "100%",
                        // "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                        //     WebkitAppearance: "none",
                        //     margin: 0,
                        //   },
                        //   "input[type=number]": {
                        //     MozAppearance: "textfield",
                        //   },
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Typography
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      <b>Select Packaging</b>
                    </Typography>
                  </Col>
                </Row>
                {props.shipmentByIdPackages.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <>
                        <Card
                          style={{
                            marginTop: "10px",
                            padding: "20px",
                            borderLeftWidth: "10px",
                          }}
                        >
                          <Row style={{ marginTop: "15px" }}>
                            <Col xl="3" lg="3" md="3" sm="3" xs="6">
                              <TextField
                                InputProps={{
                                  style: { fontSize: 12 },
                                }}
                                type="text"
                                id="standard-basic"
                                variant="standard"
                                label="Packaging"
                                value={item.name}
                                // value="Documents"
                                disabled
                                // style={{ backgroundColor: selectType == "Doc" ? 'silver' : "" }}
                                // onChange={(e) => props.setPackagingDetail({ key: "name", value: e.target.value, length: i })}

                                sx={{
                                  // input: { cursor: 'pointer' },
                                  width: "100%",
                                }}
                              />
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3" xs="6">
                      <TextField
                        InputProps={{
                          inputProps: { min: 1 },
                          style: { fontSize: 12 },

                          endAdornment: (
                            <InputAdornment position="end">cm</InputAdornment>
                          ),
                        }}
                        type="number"
                        step="any"
                        id="standard-basic"
                        variant="standard"
                        label="Length"
                        value={item.length}
                        onChange={(e) => handleLength(e, i)}
                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="6">
                      <TextField
                        InputProps={{
                          inputProps: { min: 1 },

                          style: { fontSize: 12 },
                          endAdornment: (
                            <InputAdornment position="end">cm</InputAdornment>
                          ),
                        }}
                        type="number"
                        step="any"
                        id="standard-basic"
                        variant="standard"
                        label="Width"
                        value={props.packagingDetail[i].width}
                        // value="Documents"
                        // style={{ backgroundColor: selectType == "Doc" ? 'silver' : "" }}
                        // onChange={(e) => props.setPackagingDetail({ key: "width", value: Number(e.target.value), length: i })}
                        onChange={(e) => handleWidth(e, i)}
                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="6">
                      <TextField
                        InputProps={{
                          inputProps: { min: 1 },
                          style: { fontSize: 12 },
                          endAdornment: (
                            <InputAdornment position="end">cm</InputAdornment>
                          ),
                        }}
                        type="number"
                        step="any"
                        id="standard-basic"
                        variant="standard"
                        label="Height"
                        value={props.packagingDetail[i].height}
                        // value="Documents"
                        // style={{ backgroundColor: selectType == "Doc" ? 'silver' : "" }}
                        // onChange={(e) => props.setPackagingDetail({ key: "height", value: Number(e.target.value), length: i })}
                        onChange={(e) => handleHeight(e, i)}
                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>

                            <Col xl="3" lg="3" md="3" sm="6" xs="6">
                              <TextField
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AddBoxTwoToneIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                type="text"
                                id="standard-basic"
                                variant="standard"
                                label="Weight"
                                value={item.weight}
                                onChange={(e) =>
                                  props.setShipmentPackData({
                                    key: "weight",
                                    value: Number(e.target.value),
                                    length: i,
                                  })
                                }
                                sx={{
                                  width: "100%",
                                }}
                              />
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="6" xs="6">
                              <TextField
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AddBoxTwoToneIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                type="text"
                                id="standard-basic"
                                variant="standard"
                                label="Length"
                                value={item.length}
                                onChange={(e) =>
                                  props.setShipmentPackData({
                                    key: "length",
                                    value: e.target.value,
                                    length: i,
                                  })
                                }
                                sx={{
                                  width: "100%",
                                }}
                              />
                            </Col>
                          </Row>

                          <Row style={{ marginTop: "15px" }}>
                            <Col xl="3" lg="3" md="3" sm="6" xs="6">
                              <TextField
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AddBoxTwoToneIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                type="text"
                                id="standard-basic"
                                variant="standard"
                                label="Width"
                                value={item.width}
                                onChange={(e) =>
                                  props.setShipmentPackData({
                                    key: "width",
                                    value: e.target.value,
                                    length: i,
                                  })
                                }
                                sx={{
                                  width: "100%",
                                }}
                              />
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="6" xs="6">
                              <TextField
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AddBoxTwoToneIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                type="text"
                                id="standard-basic"
                                variant="standard"
                                label="Height"
                                value={item.height}
                                onChange={(e) =>
                                  props.setShipmentPackData({
                                    key: "height",
                                    value: e.target.value,
                                    length: i,
                                  })
                                }
                                sx={{
                                  width: "100%",
                                }}
                              />
                            </Col>
                            <Col
                              lg="3"
                              md="3"
                              sm="3"
                              style={{ marginTop: "2.5%" }}
                            >
                              <Form.Group>
                                {props.shipmentByIdPackages.length - 1 ===
                                  i && (
                                    <Button
                                      title="Click to Add Packaging"
                                      onClick={handleAddInput1}
                                      style={{
                                        marginLeft: "2%",
                                        color: "black",
                                        float: "right",
                                        backgroundColor: "#C5CBCF",
                                        border: "1px solid white",
                                      }}
                                    >
                                      <AddTwoToneIcon />
                                    </Button>
                                  )}

                                {props.shipmentByIdPackages.length !== 1 && (
                                  <Button
                                    title="Click to Remove Packaging"
                                    onClick={() => handleRemoveInput1(i)}
                                    style={{
                                      marginRight: "2%",
                                      color: "black",
                                      float: "right",
                                      backgroundColor: "#C5CBCF",
                                      border: "1px solid white",
                                    }}
                                  >
                                    <RemoveTwoToneIcon />
                                  </Button>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card>
                      </>
                    </React.Fragment>
                  );
                })}
                <Row
                  style={{
                    backgroundColor: "#D9DDE0",
                    marginTop: "10px",
                    marginBottom: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Col xl="3" lg="3" md="3" sm="4" xs="4">
                    <Typography
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      <b>
                        {" "}
                        Total Packages{" "}
                        {props.shipmentByIdPackages.quantity === 0
                          ? "--------"
                          : sumBy(props.shipmentByIdPackages, "quantity")}
                      </b>
                    </Typography>
                  </Col>
                  <Col xl="3" lg="3" md="3" sm="4" xs="4">
                    <Typography
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      <b>
                        {" "}
                        Total Weight{" "}
                        {props.shipmentByIdPackages.weight === 0
                          ? "--------"
                          : sumBy(props.shipmentByIdPackages, "weight")}{" "}
                        kg{" "}
                      </b>
                    </Typography>
                  </Col>
                </Row>
              </Card> */}
              {/* tax & date */}
              <Card
                style={{
                  height: "auto",
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  border: "none",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <Row style={{ marginTop: "20px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Typography
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      <b>How Will You Pay?</b>
                    </Typography>
                  </Col>
                </Row>
                {/* <Row style={{ marginTop: "10px" }}>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Typography
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      How will duties and taxes be paid?
                    </Typography>
                  </Col>
                </Row> */}
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="4" lg="4" md="4" sm="12" xs="12">
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        required
                      >
                        {" "}
                        Select Who Will pay duties and taxes
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={props.shipmentById[0].shipment_tax_id}
                        // placeholder="Select Currency *"
                        label="Select How Will pay"
                        onChange={handleChangeTaxType}
                      // onChange={(e) => handleChangeCurrency(e, i)}
                      >
                        {props.payList.map((name) => (
                          <MenuItem
                            key={name.shipment_tax_id}
                            value={name.shipment_tax_id}
                          >
                            {name.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="12" xs="12">
                    <TextField
                      InputProps={{
                        inputProps: {
                          min: moment(new Date()).format("YYYY-MM-DD"),
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <DateRangeTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="date"
                      id="standard-basic"
                      variant="standard"
                      label="Estimated Delivery Date"
                      //  value
                      value={
                        props.shipmentById[0].expected_delivery_date == null
                          ? ""
                          : props.shipmentById[0].expected_delivery_date
                      }
                      onChange={handleChangeEstimatedDeliveryDate}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="12" xs="12">
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-standard-label" required>
                        Financial Instrument
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Financail Instrument"
                        onChange={(e) => props.setShipmentData({
                          key: "financial_instruments",
                          value: e.target.value,
                        })}
                        value={props.shipmentById[0].financial_instruments == false ? 0 : 1}
                      >
                        <MenuItem key={1} value={1}>
                          {"YES"}
                        </MenuItem>
                        <MenuItem key={0} value={0}>
                          {"NO"}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
                {props.shipmentById[0].financial_instruments == true && (
                  <>
                    <Row style={{ marginTop: "15px" }}>
                      <Col xl="3" lg="3" md="3" sm="3" xs="12">
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <NumbersTwoToneIcon />
                              </InputAdornment>
                            ),
                          }}
                          type="text"
                          id="standard-basic"
                          variant="standard"
                          label="Number"
                          value={props.shipmentById[0].financial_instruments_no}
                          onChange={(e) => props.setShipmentData({
                            key: "financial_instruments_no",
                            value: e.target.value.toUpperCase(),
                          })}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Col>
                      <Col xl="3" lg="3" md="3" sm="3" xs="12">
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DomainTwoToneIcon />
                              </InputAdornment>
                            ),
                          }}
                          type="text"
                          id="standard-basic"
                          variant="standard"
                          label="Bank"
                          value={props.shipmentById[0].financial_instruments_bank}
                          onChange={(e) => props.setShipmentData({
                            key: "financial_instruments_bank",
                            value: e.target.value.toUpperCase(),
                          })}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Col>
                      <Col xl="3" lg="3" md="3" sm="3" xs="12">
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="demo-simple-select-standard-label" required>
                            Select Rebate Claim
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Select Rebate Claim"
                            value={props.shipmentById[0].rebate == true ? 1 : 0}
                            onChange={(e) => props.setShipmentData({
                              key: "rebate",
                              value: e.target.value,
                            })}
                          >
                            <MenuItem key={1} value={1}>
                              {"YES"}
                            </MenuItem>
                            <MenuItem key={0} value={0}>
                              {"NO"}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                      {props.shipmentById[0].rebate == true && (
                        <Col xl="3" lg="3" md="3" sm="3" xs="12">
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <NumbersTwoToneIcon />
                                </InputAdornment>
                              ),
                            }}
                            type="text"
                            id="standard-basic"
                            variant="standard"
                            label="Sro Number"
                            value={props.shipmentById[0].sro_number}
                            onChange={(e) => props.setShipmentData({
                              key: "sro_number",
                              value: e.target.value.toUpperCase(),
                            })}
                            sx={{
                              width: "100%",
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row></Row>
                  </>
                )}
                <div className="sendDiv" style={{ marginTop: "15px" }}>
                  <Button
                    onClick={handleAddShipment}
                    style={{
                      marginLeft: "2%",
                      color: "black",
                      // width: "50%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Card>
            </div>
          )}
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateShipment: (data, id) =>
      dispatch(updateShipment(data, id)),
    getCountry: () => dispatch(getCountry()),
    getUom: () => dispatch(getUom()),
    getReceiver: () => dispatch(getReceiver()),
    getSender: () => dispatch(getSender()),
    getShipmentPurpose: () => dispatch(getShipmentPurpose()),
    getCurrency: () => dispatch(getCurrency()),
    getPayList: () => dispatch(getPayList()),
    getServiceType: () => dispatch(getServiceType()),
    setShipmentData: (data) => dispatch(setEditShipmentData(data)),
    setShipmentPackData: (data) => dispatch(setEditShipmentPackData(data)),
    setAddShipmentPackData: () => dispatch(setEditShipmentPackAddData()),
    setRemoveShipmentPackData: (i) =>
      dispatch(setEditShipmentPackRemoveData(i)),
    setEditShipmentDetail: (data) => dispatch(setEditShipmentDetail(data)),
    setAddShipmentDetail: () => dispatch(setEditShipmentAddDetail()),
    setRemoveShipmentDetail: (i) => dispatch(setEditShipmentRemoveDetail(i)),
    setEditSenderData: (data) => dispatch(setEditShipmentSender(data)),
    // getCargoService: () => dispatch(getCargoService()),
    // handleNextStep: () => dispatch(nextSteps()),
    // handleBackStep: () => dispatch(backSteps()),
    // startSteps: () => dispatch(startSteps()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  senderList: state.CargoSenderReducer.senderList,
  isFetchingSender: state.CargoSenderReducer.isFetchingSender,
  receiverList: state.CargoReceiverReducer.receiverList,
  isFetchingReceiver: state.CargoReceiverReducer.isFetchingReceiver,
  shipmentPurposeList: state.CargoCreateShipmentReducer.shipmentPurposeList,
  isFetchingShipmentPurposeList:
    state.CargoCreateShipmentReducer.isFetchingShipmentPurposeList,
  payList: state.CargoCreateShipmentReducer.payList,
  isFetchingPayList: state.CargoCreateShipmentReducer.isFetchingPayList,
  serviceTypeList: state.CargoCreateShipmentReducer.serviceTypeList,
  isFetchingServiceTypeList:
    state.CargoCreateShipmentReducer.isFetchingServiceTypeList,
  currencyList: state.CargoCurrencyReducer.currencyList,
  isFetchingCurrency: state.CargoCurrencyReducer.isFetchingCurrency,
  countryList: state.CargoCountryReducer.countryList,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  uomList: state.CargoCreateShipmentReducer.uomList,
  isFetchingUomList: state.CargoCreateShipmentReducer.isFetchingUomList,

  shipmentById: state.CargoShipmentReducer.shipmentById,
  shipmentByIdData: state.CargoShipmentReducer.shipmentByIdData,
  shipmentByIdPackages: state.CargoShipmentReducer.shipmentByIdPackages,
  isFetchingShipmentById: state.CargoShipmentReducer.isFetchingShipmentById,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditShipment);
