import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import "jspdf-autotable";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import {
  setCargoService,
  setInvoiceType,
  setServiceType,
} from "./CreateShipment.action";

const SelectService = (props) => {
  const handleChangeInvoiceType = (e) => {
    props.setInvoiceType(e.target.value);
    // setCountry(e.target.value);
  };
  const handleChangeServiceType = (e) => {
    props.setServiceType(e.target.value);
    // setCountry(e.target.value);
  };
  useEffect(() => {}, []);
  return (
    <div style={{ textTransform: "uppercase" }}>
      <Row>
        <Col xl="12" lg="12" md="12" sm="12" xs="12">
          <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
            <b>Select Service Type</b>
          </Typography>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <Col xl="6" lg="6" md="6" sm="6" xs="12">
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label" required>
              Select Service
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={props.serviceType}
              label="Select Service"
              onChange={handleChangeServiceType}
              //   input={<OutlinedInput label="Select Estimated Delivery Time" />}
            >
              {props.serviceTypeList.map((name) => (
                <MenuItem
                  key={name.service_type_id}
                  value={name.service_type_id}
                >
                  {`${name.name.toUpperCase()}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        <Col xl="6" lg="6" md="6" sm="6" xs="12">
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label" required>
              Select Invoice Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              label="Select Invoice Type"
              value={props.invoiceType}
              onChange={handleChangeInvoiceType}
            >
              <MenuItem key={1} value={1}>
                {"CUSTOM INVOICE"}
              </MenuItem>
              <MenuItem key={0} value={0}>
                {"OWN INVOICE"}
              </MenuItem>
            </Select>
          </FormControl>
        </Col>
        {/* <Col xl="6" lg="6" md="6" sm="6" xs="12">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" required>
                                        Select Cargo Service
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={props.senderData[0].country_id}
                                        // disabled={editable}
                                        label="Select Cargo Service"
                                        onChange={handleChangeCargoService}
                                        input={<OutlinedInput label="Select Cargo Service" />}
                                    >
                                        {props.cargoServiceList.map(
                                            (name) => (
                                                (
                                                    <MenuItem
                                                        key={name.country_id}
                                                        value={name.country_id}
                                                    >
                                                        {name.name}
                                                    </MenuItem>
                                                )
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Col> */}
      </Row>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setServiceType: (data) => dispatch(setServiceType(data)),
    setCargoService: (data) => dispatch(setCargoService(data)),
    setInvoiceType: (data) => dispatch(setInvoiceType(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  serviceTypeList: state.CargoCreateShipmentReducer.serviceTypeList,
  cargoServiceList: state.CargoCreateShipmentReducer.cargoServiceList,
  invoiceType: state.CargoCreateShipmentReducer.invoiceType,
  serviceType: state.CargoCreateShipmentReducer.serviceType,
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectService);
