const initialState = {
  isFetchingSender: false,
  senderList: [],
  sender: [],
  userDetail: [],
  isAddingSender: false,
  senderByID: [],
  isAddingSenderById: false,
};

const SenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_SENDER":
      return { ...state, isFetchingSender: true };
    case "SUCCESS_GET_SENDER":
      return {
        ...state,
        senderList: action.payload.data,
        userDetail: action.payload.userDetails,
        isFetchingSender: false,
      };
    case "ERROR_GET_SENDER":
      return { ...state, isFetchingSender: false };

    case "REQUEST_GET_SENDER_BY_ID":
      return { ...state, isFetchingSenderByID: true };
    case "SUCCESS_GET_SENDER_BY_ID":
      return {
        ...state,
        senderByID: action.payload.data,
        isFetchingSenderByID: false,
      };
    case "ERROR_GET_SENDER_BY_ID":
      return { ...state, isFetchingSenderByID: false };

    case "REQUEST_ADD_SENDER":
      return { ...state, isAddingSender: true };
    case "SUCCESS_ADD_SENDER":
      return {
        ...state,
        sender: action.payload,
        isAddingSender: false,
      };
    case "ERROR_ADD_SENDER":
      return { ...state, isAddingSender: false };
    case "SET_EDIT_SENDER":
      let valueEmp = state.senderByID[0];
      let keyEmp = action.payload.key;
      valueEmp[keyEmp] = action.payload.value;
      return { ...state, senderByID: [valueEmp] };

    default:
      return state;
  }
};

export default SenderReducer;
