import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import "jspdf-autotable";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PaidTwoToneIcon from "@mui/icons-material/PaidTwoTone";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import MaterialTable from "material-table";
import Spinner from "react-bootstrap/Spinner";
import { ThemeProvider, createTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { getShipmentByDates, getLedger } from "./Dashboard.action";
import _ from "lodash";
import shipment from "../../../Images/shipment.png";
import amount from "../../../Images/amount.png";
import payment from "../../../Images/payment.png";
import calender from "../../../Images/calender.png";
import credit from "../../../Images/credit.png";

const AUCDashboard = (props) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const defaultMaterialTheme = createTheme();
  const dataLength = props.ShipmentDateList.length;
  const lastIndex =
    !_.isEmpty(props.ShipmentDateList) && props.ShipmentDateList.length - 1;

  const lastIndexItems = props.ShipmentDateList[lastIndex];
  function dashboard() {
    props.shipmentDate({
      startedDate: startDate,
      endDate: endDate,
    });
    props.ledger({
      startedDate: startDate,
      endDate: endDate,
    });
  }

  const changeStartDate = (e) => {
    setStartDate(e.target.value);
  };
  const changeEndDate = (e) => {
    setEndDate(e.target.value);
  };
  const cardStyle = {
    display: "flex",
    borderRadius: "20px",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    width: "auto",
    border: "none",
  };
  const cardValue = {
    fontFamily: "Public Sans,sans-serif",
    color: "#919EAB",
  };
  const imgStyle = {
    height: "65%",
    width: "65%",
    marginTop: "20%",
    marginLeft: "80%",
  };
  useEffect(() => {
    document.title = props.title;
    // For today
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    // For yesterday
    const yesterdayDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    setStartDate(yesterdayDate);
    setEndDate(todayDate);

    props.shipmentDate({
      startedDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    });
    props.ledger({
      startedDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    });
  }, []);
  return (
    <>
      {props.isFetchingLedger || props.isFetchingShipmentByDates ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              backgroundColor: "#F9FAFB",
            }}
          >
            <Row style={{ marginTop: "35px" }}>
              <Col xl="1" lg="1" md="1" sm="12" xs="12">
                <b>FROM:</b>
              </Col>

              <Col xl="2" lg="3" md="4" sm="12" xs="12">
                <TextField
                  type="date"
                  id="standard-basic"
                  variant="standard"
                  InputProps={{ inputFormat: "dd.MM.yyyy" }}
                  required
                  value={startDate}
                  onChange={(e) => changeStartDate(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="1" lg="1" md="1" sm="12" xs="12">
                <b>TO:</b>
              </Col>
              <Col xl="2" lg="3" md="4" sm="12" xs="12">
                <TextField
                  type="date"
                  InputProps={{
                    inputFormat: "dd.MM.yyyy",
                    inputProps: {
                      max: moment().format("YYYY-MM-DD"),
                    },
                  }}
                  id="standard-basic"
                  variant="standard"
                  required
                  value={endDate}
                  onChange={(e) => changeEndDate(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="6" lg="6" md="6" sm="6" xs="8">
                <div className="sendDiv">
                  <Button
                    disabled={_.isEmpty(startDate) || _.isEmpty(endDate)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: "2%",
                      color: "black",
                      width: "20%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                    onClick={dashboard}
                  >
                    Refresh
                  </Button>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "35px" }}>
              <Col xl="3" lg="3" md="3" sm="12" xs="12">
                <Card sx={cardStyle}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Row>
                      <Col xl="3" lg="6" md="6" sm="6" xs="6">
                        {/* <IconButton style={{ marginTop: "40%" }}>
                        <LocalShippingTwoToneIcon
                          sx={{ color: "#20B67E", fontSize: "65px" }}
                        />
                      </IconButton> */}
                        <Image src={shipment} style={imgStyle} />
                      </Col>
                      <Col xl="9" lg="6" md="6" sm="6" xs="6">
                        <CardContent style={{ textAlign: "center" }}>
                          <Typography
                            component="div"
                            variant="body"
                            fontSize="28px"
                          >
                            <b>{dataLength}</b>
                          </Typography>
                          <Typography
                            component="div"
                            variant="body"
                            sx={cardValue}
                          >
                            <b>Total C/Ns</b>
                          </Typography>
                        </CardContent>
                      </Col>
                    </Row>
                  </Box>
                </Card>
              </Col>
              <Col xl="3" lg="3" md="3" sm="12" xs="12">
                <Card sx={cardStyle}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Row>
                      <Col xl="3" lg="6" md="6" sm="6" xs="6">
                        {/* <IconButton style={{ marginTop: "40%" }}>
                        <PaidTwoToneIcon
                          sx={{ color: "#3C8AEA", fontSize: "50px" }}
                        />
                      </IconButton> */}
                        <Image src={amount} style={imgStyle} />
                      </Col>
                      <Col xl="9" lg="6" md="6" sm="6" xs="6">
                        <CardContent style={{ textAlign: "center" }}>
                          <Typography
                            component="div"
                            variant="body"
                            fontSize="28px"
                          >
                            <b>
                              {" "}
                              {!_.isEmpty(props.ShipmentDateList)
                                ? _.sumBy(
                                    props.ShipmentDateList,
                                    "total_amount"
                                  ) || 0
                                : 0}
                            </b>
                          </Typography>
                          <Typography
                            component="div"
                            variant="body1"
                            sx={cardValue}
                          >
                            <b>Total C/N Amount</b>
                          </Typography>
                        </CardContent>
                      </Col>
                    </Row>
                  </Box>
                </Card>
              </Col>
              <Col xl="3" lg="3" md="3" sm="12" xs="12">
                <Card sx={cardStyle}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Row>
                      <Col xl="3" lg="6" md="6" sm="6" xs="6">
                        {/* <IconButton style={{ marginTop: "42%" }}>
                        <DateRangeTwoToneIcon fontSize="large" />
                      </IconButton> */}
                        <Image src={calender} style={imgStyle} />
                      </Col>
                      <Col xl="9" lg="6" md="6" sm="6" xs="6">
                        <CardContent style={{ textAlign: "center" }}>
                          <Typography
                            component="div"
                            variant="body"
                            fontSize="28px"
                          >
                            <b>
                              {!_.isEmpty(props.ShipmentDateList)
                                ? props.ShipmentDateList[
                                    props.ShipmentDateList.length - 1
                                  ].shipment_date
                                : 0}
                            </b>
                          </Typography>
                          <Typography
                            component="div"
                            variant="body1"
                            sx={cardValue}
                          >
                            <b>Last C/N Date</b>
                          </Typography>
                        </CardContent>
                      </Col>
                    </Row>
                  </Box>
                </Card>
              </Col>
              <Col xl="3" lg="3" md="3" sm="12" xs="12">
                <Card sx={cardStyle}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Row>
                      <Col xl="3" lg="6" md="6" sm="6" xs="6">
                        {/* <IconButton style={{ marginTop: "42%" }}>
                        <PaidTwoToneIcon fontSize="large" />
                      </IconButton> */}
                        <Image src={amount} style={imgStyle} />
                      </Col>
                      <Col xl="9" lg="6" md="6" sm="6" xs="6">
                        <CardContent style={{ textAlign: "center" }}>
                          <Typography
                            component="div"
                            variant="body"
                            fontSize="28px"
                          >
                            <b>
                              {!_.isEmpty(props.ledgerList)
                                ? props.ledgerList[props.ledgerList.length - 1]
                                    .accBalance
                                : 0}
                            </b>
                          </Typography>
                          <Typography
                            component="div"
                            variant="body1"
                            sx={cardValue}
                          >
                            <b>Current Balance</b>
                          </Typography>
                        </CardContent>
                      </Col>
                    </Row>
                  </Box>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginTop: "25px" }}>
              <Col xl="3" lg="3" md="3" sm="12" xs="12">
                <Card sx={cardStyle}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Row>
                      <Col xl="3" lg="6" md="6" sm="6" xs="6">
                        {/* <IconButton style={{ marginTop: "42%" }}>
                        <LocalShippingTwoToneIcon fontSize="large" />
                      </IconButton> */}
                        <Image src={shipment} style={imgStyle} />
                      </Col>
                      <Col xl="9" lg="6" md="6" sm="6" xs="6">
                        <CardContent style={{ textAlign: "center" }}>
                          <Typography
                            component="div"
                            variant="body"
                            fontSize="28px"
                          >
                            <b>
                              {!_.isEmpty(props.ShipmentDateList)
                                ? props.ShipmentDateList[
                                    props.ShipmentDateList.length - 1
                                  ].tracking_id
                                : 0}
                            </b>
                          </Typography>
                          <Typography
                            component="div"
                            variant="body1"
                            sx={cardValue}
                          >
                            <b>Last C/N No.</b>
                          </Typography>
                        </CardContent>
                      </Col>
                    </Row>
                  </Box>
                </Card>
              </Col>
              <Col xl="3" lg="3" md="3" sm="12" xs="12">
                <Card sx={cardStyle}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Row>
                      <Col xl="3" lg="6" md="6" sm="6" xs="6">
                        {/* <IconButton style={{ marginTop: "42%" }}>
                        <PaidTwoToneIcon fontSize="large" />
                      </IconButton> */}
                        <Image src={amount} style={imgStyle} />
                      </Col>
                      <Col xl="9" lg="6" md="6" sm="6" xs="6">
                        <CardContent style={{ textAlign: "center" }}>
                          <Typography
                            component="div"
                            variant="body"
                            fontSize="28px"
                          >
                            <b>
                              {!_.isEmpty(props.ShipmentDateList)
                                ? props.ShipmentDateList[
                                    props.ShipmentDateList.length - 1
                                  ].total_amount || 0
                                : 0}
                            </b>
                          </Typography>
                          <Typography
                            component="div"
                            variant="body1"
                            sx={cardValue}
                          >
                            <b>Last C/N Amount</b>
                          </Typography>
                        </CardContent>
                      </Col>
                    </Row>
                  </Box>
                </Card>
              </Col>
              <Col xl="3" lg="3" md="3" sm="12" xs="12">
                <Card sx={cardStyle}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Row>
                      <Col xl="3" lg="6" md="6" sm="6" xs="6">
                        {/* <IconButton style={{ marginTop: "42%" }}>
                        <PaidTwoToneIcon fontSize="large" />
                      </IconButton> */}
                        <Image src={payment} style={imgStyle} />
                      </Col>
                      <Col xl="9" lg="6" md="6" sm="6" xs="6">
                        <CardContent style={{ textAlign: "center" }}>
                          <Typography
                            component="div"
                            variant="body"
                            fontSize="28px"
                          >
                            <b>0</b>
                          </Typography>
                          <Typography
                            component="div"
                            variant="body1"
                            sx={cardValue}
                          >
                            <b>Last Payment</b>
                          </Typography>
                        </CardContent>
                      </Col>
                    </Row>
                  </Box>
                </Card>
              </Col>
              <Col xl="3" lg="3" md="3" sm="12" xs="12">
                <Card sx={cardStyle}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Row>
                      <Col xl="3" lg="6" md="6" sm="6" xs="6">
                        {/* <IconButton style={{ marginTop: "42%" }}>
                        <PaidTwoToneIcon fontSize="large" />
                      </IconButton> */}
                        <Image src={credit} style={imgStyle} />
                      </Col>
                      <Col xl="9" lg="6" md="6" sm="6" xs="6">
                        <CardContent style={{ textAlign: "center" }}>
                          <Typography
                            component="div"
                            variant="body"
                            fontSize="28px"
                          >
                            <b>0</b>
                          </Typography>
                          <Typography
                            component="div"
                            variant="body1"
                            sx={cardValue}
                          >
                            <b>Credit Limit</b>
                          </Typography>
                        </CardContent>
                      </Col>
                    </Row>
                  </Box>
                </Card>
              </Col>
            </Row>
            <br />
            <br />
            {/* Material Table */}
            <Row>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    style={{
                      borderRadius: "30px",
                      boxShadow:
                        " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                    title="List Of C/N(s)"
                    columns={[
                      {
                        title: "Tracking ID",
                        field: "tracking_id",
                        editable: "never",
                      },
                      { title: "Shipment Type", field: "shipment_type" },
                      { title: "Date", field: "shipment_date" },
                      { title: "Total Weight", field: "total_weight" },
                      { title: "Status", field: "track_status" },
                    ]}
                    data={props.ShipmentDateList}
                    options={{
                      actionsColumnIndex: -1,
                      exportButton: true,
                      exportAllData: true,
                      paging: true,
                      pageSize: 20, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                      maxBodyHeight: "600px",
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#919EAB",
                        fontFamily: "Public Sans,sans-serif",
                        // color:"#C5CBCF",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                </ThemeProvider>
              </Col>

              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    style={{
                      borderRadius: "30px",
                      boxShadow:
                        " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                    title="List Of Payment(s)"
                    columns={[
                      {
                        title: "Date",
                        field: "voucher_date",
                        filtering: false,
                      },

                      {
                        title: "Debit",
                        field: "debit",
                        filtering: false,
                        render: (rowData) =>
                          !_.hasIn(rowData, "debit") || _.isNull(rowData.debit)
                            ? ""
                            : rowData.debit.toLocaleString(),
                      },
                      {
                        title: "Credit",
                        field: "credit",
                        filtering: false,
                        render: (rowData) =>
                          !_.hasIn(rowData, "credit") ||
                          _.isNull(rowData.credit)
                            ? ""
                            : rowData.credit.toLocaleString(),
                      },
                      {
                        title: "Balance",
                        field: "accBalance",
                        filtering: false,
                        render: (rowData) =>
                          !_.hasIn(rowData, "accBalance") ||
                          _.isNull(rowData.accBalance)
                            ? ""
                            : rowData.accBalance.toLocaleString(),
                      },
                      {
                        title: "DR/CR",
                        field: "accBalance_sign",
                        render: (rowData) =>
                          rowData.accBalance_sign == 2
                            ? "Debit"
                            : rowData.accBalance_sign == 1
                            ? "Credit"
                            : "",
                        filtering: false,
                      },
                    ]}
                    data={props.ledgerList}
                    options={{
                      actionsColumnIndex: -1,
                      exportButton: true,
                      exportAllData: true,
                      paging: true,
                      pageSize: 20, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                      maxBodyHeight: "600px",
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#919EAB",
                        fontFamily: "Public Sans,sans-serif",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                </ThemeProvider>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    shipmentDate: (data) => dispatch(getShipmentByDates(data)),
    ledger: (data) => dispatch(getLedger(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingShipmentByDates:
    state.CargoDashboardReducer.isFetchingShipmentByDates,
  ShipmentDateList: state.CargoDashboardReducer.ShipmentDateList,
  isFetchingLedger: state.CargoDashboardReducer.isFetchingLedger,
  ledgerList: state.CargoDashboardReducer.ledgerList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AUCDashboard);
