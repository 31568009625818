import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
// import Select from "react-select";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";

import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";

import { setHeading } from "../../user_views/accounts/account.actions";
import { addService } from "./Service.action";

const AddService = (props) => {
  const [name, setName] = useState("");
  // const [serviceCode, setServiceCode] = useState();

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function saveService() {
    props.addService(
      {
        name: name,
        // code:serviceCode,
      },
      handleVisible,
      handleDangerVisible
    );
    setName("");
  }

  const [nameError, setNameError] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value.toUpperCase());
    if (e.target.value.length > 1) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  useEffect(() => {
    //  props.setHeading("Add Hall");
    // props.getRole()
    document.title = props.title;
  }, []);
  return (
    <>
      <Container fluid>
        <div
          className="main"
          style={{
            height: "auto",
            borderRadius: "30px",
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <Row style={{ marginTop: "15px" }}>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DriveFileRenameOutlineTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                required
                error={nameError}
                value={name}
                helperText={
                  nameError
                    ? "Please Enter Your Service Name (Atleast 2 Character)"
                    : ""
                }
                label="Service Name"
                onChange={(e) => handleNameChange(e)}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
          </Row>

          <div className="sendDiv" style={{ marginTop: "15px" }}>
            {props.isAddingService ? (
              <div className="loader-div">
                <Spinner
                  variant="secondary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <Button
                disabled={isEmpty(name)}
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "20%",
                  backgroundColor: "#C5CBCF",
                  border: "1px solid white",
                }}
                onClick={saveService}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addService: (data, handleVisible, handleDangerVisible) =>
      dispatch(addService(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  service: state.CargoServiceReducer.service,
  isAddingService: state.CargoServiceReducer.isAddingService,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddService);
