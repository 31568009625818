const initialState = {
  isFetchingService:false,
  serviceList:[],
  service:[],
  isAddingService:false,

};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_SERVICE":
      return { ...state, isFetchingService: true };
    case "SUCCESS_GET_SERVICE":
      return {
        ...state,
        serviceList: action.payload.data,
        isFetchingService: false,
      };
    case "ERROR_GET_SERVICE":
      return { ...state, isFetchingService: false };

    case "REQUEST_ADD_SERVICE":
      return { ...state, isAddingService: true };
    case "SUCCESS_ADD_SERVICE":
      return {
        ...state,
        service: action.payload,
        isAddingService: false,
      };
    case "ERROR_ADD_SERVICE":
      return { ...state, isAddingService: false };

    default:
      return state;
  }
};

export default serviceReducer;