import { get, isEmpty } from "lodash";
import API from "../../../global/api";
import { isNumber } from "lodash";
import jsPDF from "jspdf";
import logo from "../../../Images/logo.png";
export const requestGetPackageWeight = () => {
  return {
    type: "REQUEST_GET_PACKAGE_WEIGHT",
  };
};
export const successGetPackageWeight = (data) => {
  return {
    type: "SUCCESS_GET_PACKAGE_WEIGHT",
    payload: data,
  };
};
export const errorGetPackageWeight = () => {
  return {
    type: "ERROR_GET_PACKAGE_WEIGHT",
  };
};
export const getPackageWeight = () => {
  return (dispatch) => {
    dispatch(requestGetPackageWeight());
    API.get(`/rate/get_weight`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetPackageWeight(getData));
      })
      .catch((error) => {
        dispatch(errorGetPackageWeight());
      });
  };
};

export const requestGetCalculateRates = () => {
  return {
    type: "REQUEST_GET_CALCULATE_RATES",
  };
};
export const successGetCalculateRates = (dataList, data) => {
  return {
    type: "SUCCESS_GET_CALCULATE_RATES",
    payload: dataList,
    body: data,
  };
};
export const errorGetCalculateRates = () => {
  return {
    type: "ERROR_GET_CALCULATE_RATES",
  };
};
export const getCalculateRates = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetCalculateRates());
    API.post(`/rate/rate_inquiry`, data)
      .then((res) => {
        let getData = res.data;
        let data = getData.shift();
        dispatch(successGetCalculateRates(getData, data));
      })
      .catch((error) => {
        handleDangerVisible(error.response.data.message);
        dispatch(errorGetCalculateRates());
      });
  };
};
export const setShowTable = (data) => {
  return {
    type: "SET_SHOW_DATA",
    payload: data,
  };
};

export const requestGetUploadRate = () => {
  return {
    type: "REQUEST_UPLOAD_RATES",
  };
};
export const successGetUploadRate = (data) => {
  return {
    type: "SUCCESS_UPLOAD_RATES",
    payload: data,
  };
};
export const errorGetUploadRate = () => {
  return {
    type: "ERROR_UPLOAD_RATES",
  };
};
export const uploadRate = (data, handleVisible, handleDangerVisible) => {
  const formData = new FormData();
  formData.append("date", `${data.date}`);
  formData.append("service_type_id", `${data.serviceId}`);
  formData.append("shipment_type", `${data.type}`);
  formData.append("file", get(data, "file"));
  formData.append("type", `${data.type2}`);
  // formData.append("uploadCnicImage", get(data, 'uploadCnicImage'));
  return (dispatch) => {
    dispatch(requestGetUploadRate());
    API.post(`/rate/upload_rate_file`, formData)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetUploadRate(getData));
        handleVisible("Rate Upload");
      })
      .catch((error) => {
        dispatch(errorGetUploadRate());
        handleDangerVisible("Error");
      });
  };
};

export const requestGetServiceType = () => {
  return {
    type: "REQUEST_GET_SERVICE_TYPE",
  };
};
export const successGetServiceType = (data) => {
  return {
    type: "SUCCESS_GET_SERVICE_TYPE",
    payload: data,
  };
};
export const errorGetServiceType = () => {
  return {
    type: "ERROR_GET_SERVICE_TYPE",
  };
};
export const getServiceType = () => {
  return (dispatch) => {
    dispatch(requestGetServiceType());
    API.get(`/service_type/get_service_type`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetServiceType(getData));
      })
      .catch((error) => {
        dispatch(errorGetServiceType());
      });
  };
};

export const requestGetCountry = () => {
  return {
    type: "REQUEST_GET_COUNTRY",
  };
};
export const successGetCountry = (data) => {
  return {
    type: "SUCCESS_GET_COUNTRY",
    payload: data,
  };
};
export const errorGetCountry = () => {
  return {
    type: "ERROR_GET_COUNTRY",
  };
};
export const getCountry = () => {
  return (dispatch) => {
    dispatch(requestGetCountry());
    API.get(`/country/get_country`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetCountry(getData));
      })
      .catch((error) => {
        dispatch(errorGetCountry());
      });
  };
};

// export const requestDownloadRateReport = () => {
//   return {
//     type: "REQUEST_DOWNLOAD_RATE_REPORT",
//   };
// };
// export const successDownloadRateReport = (data) => {
//   return {
//     type: "SUCCESS_DOWNLOAD_RATE_REPORT",
//     payload: data,
//   };
// };
// export const errorDownloadRateReport = () => {
//   return {
//     type: "ERROR_DOWNLOAD_RATE_REPORT",
//   };
// };

export const downloadRateReport = (data, tableData, percentage) => {
  return (dispatch) => {
    // console.log(data.name, "data1");
    // let name = data.name;
    // dispatch(requestDownloadRateReport());
    // API.post(`/download_pdf`, data)
    //   .then((res) => {
    //     let getData = res.data;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 4;
    const doc = new jsPDF(orientation, unit, size);
    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
    const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
    const pdfData = tableData.map((rowData) => [
      rowData.service,
      isEmpty(percentage) || !isNumber(rowData.rate)
        ? rowData.rate
        : Math.ceil((Number(percentage) * Number(rowData.rate)) / 100) +
          Number(rowData.rate),
    ]);

    // const data1 = tableData.map((elt) => [
    //   // elt.inventory.productName,
    // ]);
    let content = {
      startY: 190,
      startX: 5,
      head: [["Company", "Rate"]],
      body: pdfData,
    };
    // doc.addImage(logo, "PNG", 10, 1, 100, 100);
    // doc.setFontSize(20);
    // doc.setFont(undefined, "bold");
    // doc.text(companyName, 60, 22, 0, 0);
    // doc.setFontSize(13);
    // doc.setFont("TimesNewRoman", "Normal");
    // doc.text(companyAddress, 60, 29, 0, 0);
    // doc.text(companyNtn, 60, 36, 0, 0);
    doc.addImage(logo, "PNG", 40, 15, 100, 100);
    doc.setFontSize(20);
    doc.setFont("bold");
    doc.text(companyName, 200, 50, 0, 20);
    doc.setFontSize(13);
    doc.setFont("TimesNewRoman", "Normal");
    doc.text(companyAddress, 200, 70, 0, 20);
    doc.text(companyNtn, 200, 90, 0, 20);
    doc.setFontSize(20);
    doc.setFont(undefined, "bold");
    doc.text(`QUOTATION SLIP`, 230, 120, 0, 0);
    doc.setFontSize(13);
    doc.setFont("TimesNewRoman", "Normal");
    doc.text(`Date: ${data.body.date}`, 40, 140);
    doc.text(`Destination: ${data.body.country_id}`, 40, 160);
    doc.text(`Weight: ${data.body.weight}`, 40, 180);
    doc.autoTable(content);
    // doc.setFontSize(12);
    // doc.setFont(undefined, 'bold');
    // doc.text("Term And Condition", 15, doc.lastAutoTable.finalY + 5, 0, 0)
    // doc.setFont(undefined, 'normal')
    // doc.setFontSize(10);
    // var splitText = doc.splitTextToSize(props.termAndCondition, 450);
    // doc.text(splitText, 15, doc.lastAutoTable.finalY + 10, 0, 10)
    doc.save(`QUOTATION SLIP.pdf`);
    // dispatch(successDownloadRateReport(getData));
    // })
    // .catch((error) => {
    //   dispatch(errorDownloadRateReport());
    //   console.log(error, "eeeeeeeeeeee");
    // });
  };
};
