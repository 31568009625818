const initialState = {
  isFetchingCurrency: false,
  currencyList: [],
  currency: [],
  isAddingCurrency: false,
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_CURRENCY":
      return { ...state, isFetchingCurrency: true };
    case "SUCCESS_GET_CURRENCY":
      return {
        ...state,
        currencyList: action.payload.data,
        isFetchingCurrency: false,
      };
    case "ERROR_GET_CURRENCY":
      return { ...state, isFetchingCurrency: false };

    case "REQUEST_ADD_CURRENCY":
      return { ...state, isAddingCurrency: true };
    case "SUCCESS_ADD_CURRENCY":
      return {
        ...state,
        currency: action.payload,
        isAddingCurrency: false,
      };
    case "ERROR_ADD_CURRENCY":
      return { ...state, isAddingCurrency: false };

    default:
      return state;
  }
};

export default currencyReducer;
