import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import "./add-levels.css";
import { AddLevel4Name, getLevel4Data, getLevel1Data, getLevel2Data, getLevel3Data, getLevelOneName, getLevelTwoName } from "./accountsLevel.action";
import { connect } from "react-redux";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { sortBy } from "lodash";
import { setHeading } from "../../accounts/account.actions";

const Level4Tap = (props) => {


    const level4Data = sortBy(props.level4List.allData, ['props.level4List', 'levelFourData.allLevlKey']);
    const [state, setState] = React.useState({
        columns: [
            { title: 'Level 1 Name', field: 'levelFourData.level_one.name', render: rowData => rowData.levelFourData.level_one.name + " /" + rowData.levelFourData.level_one.key },
            { title: 'Level 2 Name', field: 'levelFourData.level_two.levelTwoName', render: rowData => rowData.levelFourData.level_two.levelTwoName + " /" + rowData.levelFourData.level_one.key + "-" + rowData.levelFourData.level_two.levelTwoKey },
            { title: 'Level 3 Name', field: 'levelFourData.level_three.levelThreeName', render: rowData => rowData.levelFourData.level_two.levelTwoName + " /" + rowData.levelFourData.level_one.key + "-" + rowData.levelFourData.level_two.levelTwoKey + "-" + rowData.levelFourData.level_three.levelThreeKey },
            { title: 'Level 4 Code', field: 'levelFourData.allLevlKey', editable: 'never' },
            { title: 'Level 4 Name', field: 'levelFourData.levelFourName' },
        ],
    });

    useEffect(() => {
        // props.getLevel1Data();
        // props.getLevel2Data();
        // props.getLevel3Data();
        props.getLevel4Data();
        props.setHeading("Level Three Detail");
    }, []);
    return (
        <>
            {props.isFetchinglevel4List ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        {/* <div className="sendDiv">
                            <Link to="/accounts/level4/add"> <Button >Back</Button></Link>
                        </div> */}
                        <MaterialTable
                            title="Level 3 Details"
                            // columns={state.columns}
                            columns={[
                                { title: 'Level 1 Name', field: 'levelThreeData.levelOneId.name', render: rowData => rowData.levelThreeData.levelOneId.name+ " /" + rowData.levelThreeData.levelOneId.key },
                               { title: 'Level 2 Name', field: 'levelThreeData.levelTwoId.levelTwoName', render: rowData => rowData.levelThreeData.levelTwoId.levelTwoName + " /" + rowData.levelThreeData.levelOneId.key + "-" + rowData.levelThreeData.levelTwoId.levelTwoKey },
                             //   { title: 'Level 2 Name', field: 'levelFourData.level_three.levelThreeName', render: rowData => rowData.levelFourData.level_two.levelTwoName + " /" + rowData.levelFourData.level_one.key + "-" + rowData.levelFourData.level_two.levelTwoKey + "-" + rowData.levelFourData.level_three.levelThreeKey },
                                { title: 'Level 3 Code', field: 'levelThreeData.allLevlKey', editable: 'never' },
                                { title: 'Level 3 Name', field: 'levelThreeData.levelThreeName' },
                            ]}
                            data={level4Data}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                pageSize: 30,
                                pageSizeOptions: [30, 50, 100],
                                emptyRowsWhenPaging: false,
                                exportPdf: (columns, data) => {
                                    console.log(data,"aaaaaaa")
                                    const unit = "pt";
                                    const size = "A4"; // Use A1, A2, A3 or A4
                                    const orientation = "potrait";
                                    const doc = new jsPDF(orientation, unit, size);
                                    const logo1=props.level4List.restaurantImage
                                    const companyName = props.level4List.resImage.restaurantName;
                                    const companyAddress = props.level4List.resImage.restaurantLocation;
                                    const companyNtn = `${props.level4List.resImage.ntn}`;
                                    const columnTitles = state.columns
                                        .map(columnDef => columnDef.title);

                                    // const pdfData = data.map(rowData =>
                                    //   state.columns.map(columnDef => (console.log(rowData.levelTwoData[columnDef.field], "eeeee"), columnDef.field == 'voucherDate' ? moment(rowData[columnDef.field]).format("YYYY-MM-DD") : rowData[columnDef.field])),
                                    // );
                                    const headers=[["Level 1 Name","Level 2 Name","Level 3 Code","Level 3 Name"]]
                                    const pdfData = data.map((elt) => [elt.levelThreeData.levelOneId.name, elt.levelThreeData.levelTwoId.levelTwoName, elt.levelThreeData.allLevlKey,elt.levelThreeData.levelThreeName,]);
                                    let content = {
                                        startY: 150,
                                        startX: 5,
                                        head: headers,
                                        body: pdfData
                                    };
                                    doc.addImage(logo1, 'PNG', 40, 15, 100, 100);
                                    doc.setFontSize(20);
                                    doc.setFont(undefined, 'bold')
                                    doc.text(companyName, 150, 50, 0, 20);
                                    doc.setFontSize(13);
                                    doc.setFont('TimesNewRoman', 'Normal')
                                    doc.text(companyAddress, 150, 70, 0, 20);
                                    doc.text(companyNtn, 150, 90, 0, 20);
                                    doc.setFontSize(16);
                                    doc.setFont('TimesNewRoman', 'bold')
                                    doc.text("Level 3 Report",250,110,0,20)
                                    doc.setFontSize(13);
                                    doc.setFont('TimesNewRoman', 'Normal')
                                    doc.autoTable(content);
                                    doc.save(`data.pdf`);
                                },
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#1976D2',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                        />
                    </div>


                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLevel1Data: () => dispatch(getLevel1Data()),
        getLevel2Data: () => dispatch(getLevel2Data()),
        getLevel3Data: () => dispatch(getLevel3Data()),
        getLevel4Data: () => dispatch(getLevel4Data()),
        AddLevel4Name: (data) => dispatch(AddLevel4Name(data)),
        getLevelOneName: (data) => dispatch(getLevelOneName(data)),
        getLevelTwoName: (data) => dispatch(getLevelTwoName(data)),
        setHeading:(data)=>dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    levelTow: state.accountsLevelReducer.levelTow,
    levelThree: state.accountsLevelReducer.levelThree,
    level1List: state.accountsLevelReducer.level1List,
    level2List: state.accountsLevelReducer.level2List,
    level3List: state.accountsLevelReducer.level3List,
    level4List: state.accountsLevelReducer.level4List,
    isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,
    isAddingLevel4: state.accountsLevelReducer.isAddingLevel4,
    isNotAddLevel4: state.accountsLevelReducer.isNotAddLevel4,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Level4Tap);