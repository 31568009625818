import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import "jspdf-autotable";
import Swal from "sweetalert";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { phone } from "phone";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import AddIcCallTwoToneIcon from "@mui/icons-material/AddIcCallTwoTone";
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import DialpadTwoToneIcon from "@mui/icons-material/DialpadTwoTone";
import AddBusinessTwoToneIcon from "@mui/icons-material/AddBusinessTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import { getCountry } from "../country/Country.action";
import { addReceiver } from "./Receiver.action";

const AddReceiver = (props) => {
  const [name, setName] = useState("");
  // const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhone] = useState();
  const [companyName, setCompanyName] = useState("");
  // const [website, setWebsiteURL] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  // const [CNIC, setCNIC] = useState(0);
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyAddress2, setCompanyAddress2] = useState("");
  const [companyAddress3, setCompanyAddress3] = useState("");
  const [state, setState] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [dialCode, setDialCode] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function saveReceiver() {
    props.addReceiver(
      {
        name: name,
        company: companyName,
        country_id: countryId,
        address: companyAddress,
        address_2: companyAddress2,
        address_3: companyAddress3,
        postal_code: postalCode,
        city: city,
        state: state,
        email: email,
        phone: phoneNo,
      },
      handleVisible,
      handleDangerVisible
    );
    setName("");
    setEmail("");
    setPhone("");
    setCompanyName("");
    setPostalCode("");
    setCity("");
    // setGst("");
    // setCnic("");
    // setNTN("");
    setCompanyAddress("");
    setCompanyAddress2("");
    setCompanyAddress3("");
    setState("");
    setCountryId(0);
    setCountry("");
    setCountryCode("");
    setDialCode("");
  }
  const onDrop = (pictureFiles, pictureDataURLs) => {
    props.imageFront({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  const onDropBackImage = (pictureFiles, pictureDataURLs) => {
    props.imageBack({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  const onDropCompanyLogo = (pictureFiles, pictureDataURLs) => {
    props.companyLogo({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cellNumberError, setCellNumberError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [addressError2, setAddressError2] = useState(false);
  const handleAddressChange = (e) => {
    if (e.target.value.length < 45) {
      setCompanyAddress(e.target.value.toUpperCase());
      setAddressError(false);
    } else {
      setAddressError(true);
    }
  };
  const handleAddress2Change = (e) => {
    if (e.target.value.length < 45) {
      setCompanyAddress2(e.target.value.toUpperCase());
      setAddressError2(false);
    } else {
      setAddressError2(true);
    }
  };
  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value.toUpperCase());
    if (e.target.value.length > 3) {
      setCompanyError(false);
    } else {
      setCompanyError(true);
    }
  };
  const handleNameChange = (e) => {
    setName(e.target.value.toUpperCase());
    if (e.target.value.length > 3) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };
  // const handleCellNumberChange = (e) => {
  //   let data= phone(e.target.value, {country: `${countryCode}`});
  //    setPhone(e.target.value);
  //    // const pattern =
  //    //   /^(?:\+92|0)?(3\d{2}|5[0-9]\d{1}|7[0-9]\d{1}|8[0-9]\d{1}|9[0-9]\d{1})\d{7}$/;
  //    // const inputValue = e.target.value;
  //    // if (e.target.value.length <= 11) {
  //    //   //   setPhone(inputValue);

  //      if (data.isValid==true) {
  //        setCellNumberError(false);
  //      } else {
  //        setCellNumberError(true);
  //      }

  //  };
  const handleCellNumberChange = (e) => {
    let data = phone(e.target.value, { country: `${countryCode}` });
    setPhone(e.target.value);
    if (countryCode == "US") {
      if (e.target.value.length == 10) {
        setCellNumberError(false);
      } else {
        setCellNumberError(true);
      }
    } else {
      if (data.isValid == true) {
        setCellNumberError(false);
        // props.setSenderData({ key: "phone", value: props.senderData[0].phone });
      } else {
        setCellNumberError(true);
        // props.setSenderData({ key: "phone", value: e.target.value });
      }
    }
    // }
  };
  const getCountryList =
    !isEmpty(props.countryList) &&
    props.countryList.map((x) => {
      let data = {
        value: x.country_id,
        label: x.name,
        country_code: x.code,
        dial_code: x.dial_code,
      };
      return data;
    });
  const handleChangeCountry = (e) => {
    setCountryId(e.value);
    setCountry(e.label);
    setCountryCode(e.country_code);
    setDialCode(e.dial_code);
    setPhone("");
    setCellNumberError(false);
  };

  useEffect(() => {
    // props.setHeading("Add Hall");
    props.getCountry();
    document.title = props.title;
  }, []);
  return (
    <>
      {props.isFetchingCountry ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Row style={{ marginTop: "15px" }}>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  error={nameError}
                  value={name}
                  helperText={
                    nameError
                      ? "Please Enter Your Name (Atleast 4 Character)"
                      : ""
                  }
                  label="Name"
                  onChange={(e) => handleNameChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DomainTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="Company Name "
                  error={companyError}
                  value={companyName}
                  helperText={
                    companyError
                      ? "Please Enter Your Company Name (Atleast 4 Character)"
                      : ""
                  }
                  onChange={(e) => handleCompanyNameChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs="12" style={{ zIndex: 10 }}>
                <p
                  style={{
                    fontSize: "12px",
                    fontFamily: "Public Sans,sans-serif",
                    marginBottom: "-2px",
                    color: "#00000061",
                  }}
                >
                  SELECT COUNTRY*
                </p>
                <Select
                  value={{ label: country }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      borderBottom: "1px solid black",
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: "white",
                      marginTop: "-1px",
                    }),
                  }}
                  isDisabled={isEmpty(props.countryList)}
                  // value={{label:props.receiverData[0].country_name}}
                  // isOptionDisabled={(option) =>
                  //   option.value==props.senderData[0].country _id
                  // }
                  onChange={handleChangeCountry}
                  options={getCountryList}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddBusinessTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="Address"
                  value={companyAddress}
                  error={addressError}
                  helperText={
                    addressError ? "Address Length (Maximium 45 Character)" : ""
                  }
                  onChange={(e) => handleAddressChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddBusinessTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="Address 2"
                  error={addressError2}
                  value={companyAddress2}
                  helperText={
                    addressError2
                      ? "Address Length (Maximium 45 Character)"
                      : ""
                  }
                  onChange={(e) => handleAddress2Change(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddBusinessTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="Address 3"
                  value={companyAddress3}
                  onChange={(e) =>
                    setCompanyAddress3(e.target.value.toUpperCase())
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddLocationAltTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="City *"
                  value={city}
                  onChange={(e) => setCity(e.target.value.toUpperCase())}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddLocationAltTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="State "
                  value={state}
                  onChange={(e) => setState(e.target.value.toUpperCase())}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DialpadTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  // type="Number"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="Postal/Zip Code "
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value.toUpperCase())}
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              {/* email */}
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <TextField
                  InputProps={{
                    type: "email",
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="email"
                  id="standard-basic"
                  variant="standard"
                  // required
                  label="Email "
                  error={emailError}
                  value={email}
                  helperText={emailError ? "Please Enter Valid Email" : ""}
                  onChange={(e) => handleEmailChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddIcCallTwoToneIcon />
                        {dialCode}
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="Phone"
                  value={phoneNo}
                  disabled={countryId === 0 ? true : false}
                  error={cellNumberError}
                  helperText={
                    cellNumberError ? "Please Enter Valid Cell Number" : ""
                  }
                  onChange={(e) => handleCellNumberChange(e)}
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: "15px" }}>
              {props.isAddingReceiver ? (
                <div className="loader-div">
                  <Spinner
                    variant="secondary"
                    animation="grow"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <Button
                  disabled={
                    isEmpty(name) ||
                    // isEmpty(email) ||
                    isEmpty(companyName) ||
                    isEmpty(companyAddress) ||
                    isEmpty(postalCode) ||
                    isEmpty(state) ||
                    isEmpty(phoneNo) ||
                    isEmpty(city) ||
                    countryId === 0 ||
                    nameError ||
                    companyError ||
                    cellNumberError ||
                    emailError ||
                    addressError ||
                    addressError2
                  }
                  style={{
                    marginLeft: "2%",
                    color: "black",
                    width: "20%",
                    backgroundColor: "#C5CBCF",
                    border: "1px solid white",
                  }}
                  onClick={saveReceiver}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addReceiver: (data, handleVisible, handleDangerVisible) =>
      dispatch(addReceiver(data, handleVisible, handleDangerVisible)),
    // getRestaurantData: () => dispatch(getRestaurantData()),
    // setHeading: (data) => dispatch(setHeading(data)),
    // getUser: () => dispatch(getUserRes()),
    // imageFront: (data) => dispatch(addImage(data)),
    // imageBack: (data) => dispatch(addImageBack(data)),
    // companyLogo: (data) => dispatch(addLogo(data)),
    getCountry: () => dispatch(getCountry()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  receiver: state.CargoReceiverReducer.receiver,
  isAddingReceiver: state.CargoReceiverReducer.isAddingReceiver,
  // sender: state.CargoSenderReducer.sender,
  // isAddingSender: state.CargoSenderReducer.isAddingSender,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  countryList: state.CargoCountryReducer.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReceiver);
