import * as React from "react";
import List from "@mui/material/List";
import { useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";
import PersonAddAltTwoToneIcon from "@mui/icons-material/PersonAddAltTwoTone";
import LineWeightTwoToneIcon from "@mui/icons-material/LineWeightTwoTone";
import NewReleasesTwoToneIcon from "@mui/icons-material/NewReleasesTwoTone";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";
import RateReviewTwoToneIcon from "@mui/icons-material/RateReviewTwoTone";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import TrendingUpTwoToneIcon from "@mui/icons-material/TrendingUpTwoTone";
//import { setHeading } from '../accounts/account.actions';

export default function Menu(props) {
  console.log(props, "mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm");
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  console.log(permissions, "hhhhhhhhhhhh");
  // const permissions = );
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openUser, setOpenUser] = React.useState(false);
  const [openRate, setOpenRate] = React.useState(false);
  const [openComplaint, setOpenComplaint] = React.useState(false);
  const [openShipment, setOpenShipment] = React.useState(false);
  const [openMasterControl, setOpenMasterControl] = React.useState(false);
  const [isActive, setIsActive] = React.useState();
  // const location = useLocation();
  const { pathname } = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };
  const listStyles = {
    backgroundColor: "#E7EFFA",
    color: "#1877F2",
    // padding: "5%",
    // margin: "0 5% 0 10%",
    borderRadius: "10px",
  };
  const buttonProps = (value) => ({
    selected: selectedIndex === value,
    onClick: () => setSelectedIndex(value),
  });
  const Checkpermissions = (permissionsType, permissionsName) => {
    console.log(permissionsType, permissionsName, "ppppppppp");
    return props.permissions.some(
      (p) => p[`${permissionsType}`] === permissionsName
    )
      ? true
      : false;
  };
  console.log(window.localStorage.getItem("module_key"), "oooo");

  // Admin Listing for Inventoryprops.permissions.some(p => p['permissionsCatName'] === "Master Control")

  let MenuList = (
    // style={{ margin: "15px", padding: "10px" }}
    <List>
      {/* {Checkpermissions("permissions_name", "Dashboard") && ( */}
      <NavLink
        to="/cargo/dashboard/dashboard"
        style={{
          textDecoration: "none",
          color: "#637381",
        }}
      >
        <ListItemButton
          style={{ padding: "3%", margin: "0 10% 0 10%" }}
          sx={{
            pl: 4,
            ...(window.location.pathname === "/cargo/dashboard/dashboard" && {
              ...listStyles,
            }),
          }}
        >
          <ListItemIcon>
            <HomeTwoToneIcon
              fontSize="large"
              style={{ color: "#637381", paddingBottom: "8%" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
            primaryTypographyProps={{
              fontSize: "16px",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: "400",
            }}
          />
        </ListItemButton>
      </NavLink>
      {/* )} */}

      {/* user */}
      {Checkpermissions("permissions_name", "User") && (
        <ListItemButton
          onClick={() => setOpenUser(!openUser)}
          style={{ padding: "3%", margin: "0 10% 0 10%" }}
        >
          <ListItemIcon>
            <PersonAddAltTwoToneIcon
              fontSize="large"
              style={{ color: "#637381", paddingBottom: "8%" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Users"
            style={{ color: "#637381" }}
            primaryTypographyProps={{
              fontSize: "16px",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: "400",
            }}
          />
          {openUser ? (
            <ExpandLess style={{ color: "#637381" }} />
          ) : (
            <ExpandMore style={{ color: "#637381" }} />
          )}
        </ListItemButton>
      )}
      <Collapse in={openUser} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink
            to="/cargo/user/add_user"
            style={{ textDecoration: "none", color: "#637381" }}
          >
            {Checkpermissions("permissions_cat_name", "User Add") && (
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname === "/cargo/user/add_user" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <AddBoxTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add User"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}

          <NavLink
            to="/cargo/user/view_users"
            style={{ textDecoration: "none", color: "#637381" }}
          >
            {Checkpermissions("permissions_cat_name", "User View") && (
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname === "/cargo/user/view_users" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="View Users"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/* Product */}
      {Checkpermissions("permissions_name", "Complaint") && (
        <ListItemButton
          onClick={() => setOpenComplaint(!openComplaint)}
          style={{ padding: "3%", margin: "0 10% 0 10%" }}
        >
          <ListItemIcon>
            <NewReleasesTwoToneIcon
              fontSize="large"
              style={{ color: "#637381", paddingBottom: "8%" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Complains"
            style={{ color: "#637381" }}
            primaryTypographyProps={{
              fontSize: "16px",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: "400",
            }}
          />
          {openComplaint ? (
            <ExpandLess style={{ color: "#637381" }} />
          ) : (
            <ExpandMore style={{ color: "#637381" }} />
          )}
        </ListItemButton>
      )}

      <Collapse in={openComplaint} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink
            to="/cargo/complain/add_complain"
            style={{ textDecoration: "none", color: "#637381" }}
          >
            {Checkpermissions("permissions_cat_name", "Complaint Add") && (
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/complain/add_complain" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <AddBoxTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add Complain"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}

          <NavLink
            to="/cargo/complain/view_complains"
            style={{ textDecoration: "none", color: "#637381" }}
          >
            {Checkpermissions("permissions_cat_name", "Complaint View") && (
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/complain/view_complains" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="View Complains"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/* Shipment */}
      {Checkpermissions("permissions_name", "Shipment") && (
        <ListItemButton
          onClick={() => setOpenShipment(!openShipment)}
          style={{ padding: "3%", margin: "0 10% 0 10%" }}
        >
          <ListItemIcon>
            <LocalShippingTwoToneIcon
              fontSize="large"
              style={{ color: "#637381", paddingBottom: "8%" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Shipment"
            style={{ color: "#637381" }}
            primaryTypographyProps={{
              fontSize: "16px",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: "400",
            }}
          />
          {openShipment ? (
            <ExpandLess style={{ color: "#637381" }} />
          ) : (
            <ExpandMore style={{ color: "#637381" }} />
          )}
        </ListItemButton>
      )}

      <Collapse in={openShipment} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink
            to="/cargo/shipment/add_shipment"
            style={{ textDecoration: "none", color: "#637381" }}
          >
            {Checkpermissions("permissions_cat_name", "Shipment Add") && (
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/shipment/add_shipment" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <AddBoxTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add Shipment"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}

          <NavLink
            to="/cargo/shipment/view_shipments"
            style={{ textDecoration: "none", color: "#637381" }}
          >
            {Checkpermissions("permissions_cat_name", "Shipment View") && (
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/shipment/view_shipments" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="View Shipments"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink
            to="/cargo/shipment/shipments_list"
            style={{ textDecoration: "none", color: "#637381" }}
          >
            {Checkpermissions("permissions_cat_name", "Shipment Details") && (
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/shipment/shipments_list" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Shipments List"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/*Reporting */}
      {Checkpermissions("permissions_name", "Reporting") && (
        <NavLink
          to="/cargo/reportings/reporting"
          style={{ textDecoration: "none", color: "#637381" }}
        >
          <ListItemButton
            style={{ padding: "3%", margin: "0 10% 0 10%" }}
            sx={{
              pl: 4,
              ...(window.location.pathname ===
                "/cargo/reportings/reporting" && {
                ...listStyles,
              }),
            }}
          >
            <ListItemIcon>
              <SummarizeTwoToneIcon
                fontSize="large"
                style={{ color: "#637381", paddingBottom: "8%" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Reporting"
              primaryTypographyProps={{
                fontSize: "16px",
                fontFamily: "Public Sans,sans-serif",
                fontWeight: "400",
              }}
            />
          </ListItemButton>
        </NavLink>
      )}

      {/*Permissions */}
      {Checkpermissions("permissions_name", "Permission") && (
        <NavLink
          to="/cargo/permissions/user_permissions"
          style={{ textDecoration: "none", color: "#637381" }}
        >
          <ListItemButton
            style={{ padding: "3%", margin: "0 10% 0 10%" }}
            sx={{
              pl: 4,
              ...(window.location.pathname ===
                "/cargo/permissions/user_permissions" && {
                ...listStyles,
              }),
            }}
          >
            <ListItemIcon>
              <VerifiedTwoToneIcon
                fontSize="large"
                style={{ color: "#637381", paddingBottom: "8%" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Permissions"
              primaryTypographyProps={{
                fontSize: "16px",
                fontFamily: "Public Sans,sans-serif",
                fontWeight: "400",
              }}
            />
          </ListItemButton>
        </NavLink>
      )}
      {/*Master Control */}
      {Checkpermissions("permissions_name", "MasterControl") && (
        <ListItemButton
          onClick={() => setOpenMasterControl(!openMasterControl)}
          style={{ padding: "3%", margin: "0 10% 0 10%" }}
        >
          <ListItemIcon>
            {/* style={{ color: "#1976d2" }} */}
            <AddBoxTwoToneIcon
              fontSize="large"
              style={{ color: "#637381", paddingBottom: "8%" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Control"
            primaryTypographyProps={{
              fontSize: "16px",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: "400",
              color: "#637381",
            }}
          />
          {openMasterControl ? (
            <ExpandLess style={{ color: "#637381" }} />
          ) : (
            <ExpandMore style={{ color: "#637381" }} />
          )}
        </ListItemButton>
      )}
      <Collapse in={openMasterControl} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {Checkpermissions("permissions_cat_name", "Currency Add") && (
            <NavLink
              to="/cargo/currency/add_currency"
              style={{ textDecoration: "none", color: "#637381" }}
            >
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/currency/add_currency" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <AddBoxTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add Currency"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            </NavLink>
          )}
          {Checkpermissions("permissions_cat_name", "Currency View") && (
            <NavLink
              to="/cargo/currency/view_currencies"
              style={{ textDecoration: "none", color: "#637381" }}
            >
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/currency/view_currencies" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="View Currencies"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            </NavLink>
          )}
          {Checkpermissions("permissions_cat_name", "Sender Add") && (
            <NavLink
              to="/cargo/sender/add_sender"
              style={{ textDecoration: "none", color: "#637381" }}
            >
              {/* {Checkpermissions("permissionsSubCatName","Store Product Upload")&& */}
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/sender/add_sender" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <AddBoxTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add Sender"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
              {/* } */}
            </NavLink>
          )}
          {/* "/inventory/catagory/listcatagory" */}
          {Checkpermissions("permissions_cat_name", "Sender View") && (
            <NavLink
              to="/cargo/sender/view_senders"
              style={{ textDecoration: "none", color: "#637381" }}
            >
              {/* {Checkpermissions("permissionsSubCatName","Store Product List")&& */}
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/sender/view_senders" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="View Senders"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
              {/* } */}
            </NavLink>
          )}
          {Checkpermissions("permissions_cat_name", "Receiver Add") && (
            <NavLink
              to="/cargo/receiver/add_receiver"
              style={{ textDecoration: "none", color: "#637381" }}
            >
              {/* {Checkpermissions("permissionsSubCatName","Store Product Upload")&& */}
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/receiver/add_receiver" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <AddBoxTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add Receiver"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
              {/* } */}
            </NavLink>
          )}
          {/* "/inventory/catagory/listcatagory" */}
          {Checkpermissions("permissions_cat_name", "Receiver View") && (
            <NavLink
              to="/cargo/receiver/view_receivers"
              style={{ textDecoration: "none", color: "#637381" }}
            >
              {/* {Checkpermissions("permissionsSubCatName","Store Product List")&& */}
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/receiver/view_receivers" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="View Receivers"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
              {/* } */}
            </NavLink>
          )}
          {Checkpermissions("permissions_cat_name", "Country Add") && (
            <NavLink
              to="/cargo/country/add_country"
              style={{ textDecoration: "none", color: "#637381" }}
            >
              {/* {Checkpermissions("permissionsSubCatName","Store Product Upload")&& */}
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/country/add_country" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <AddBoxTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add Country"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
              {/* } */}
            </NavLink>
          )}
          {/* "/inventory/catagory/listcatagory" */}
          {Checkpermissions("permissions_cat_name", "Country View") && (
            <NavLink
              to="/cargo/country/view_countries"
              style={{ textDecoration: "none", color: "#637381" }}
            >
              {/* {Checkpermissions("permissionsSubCatName","Store Product List")&& */}
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/country/view_countries" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="View Countries"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
              {/* } */}
            </NavLink>
          )}
          {Checkpermissions("permissions_cat_name", "Service Add") && (
            <NavLink
              to="/cargo/service/add_service"
              style={{ textDecoration: "none", color: "#637381" }}
            >
              {/* {Checkpermissions("permissionsSubCatName","Store Product Upload")&& */}
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/service/add_service" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <AddBoxTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add Service"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
              {/* } */}
            </NavLink>
          )}
          {/* "/inventory/catagory/listcatagory" */}
          {Checkpermissions("permissions_cat_name", "Service View") && (
            <NavLink
              to="/cargo/service/view_services"
              style={{ textDecoration: "none", color: "#637381" }}
            >
              {/* {Checkpermissions("permissionsSubCatName","Store Product List")&& */}
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/service/view_services" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="View Services"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
              {/* } */}
            </NavLink>
          )}
          {/* {Checkpermissions("permissions_cat_name", "Packages Add") &&
            <NavLink to="/cargo/packages/add_package">
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Add Package" />
              </ListItemButton>
            
            </NavLink>} */}

          {/* "/inventory/catagory/listcatagory" */}

          {/* {Checkpermissions("permissions_cat_name", "Packages View") &&
            <NavLink to="/cargo/packages/view_packages">
              
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="View Packages" />
              </ListItemButton>
              
            </NavLink>} */}
        </List>
      </Collapse>
      {/* user */}
      {Checkpermissions("permissions_name", "Rate") && (
        <ListItemButton
          onClick={() => setOpenRate(!openRate)}
          style={{ padding: "3%", margin: "0 10% 0 10%" }}
        >
          <ListItemIcon>
            <RateReviewTwoToneIcon
              fontSize="large"
              style={{ color: "#637381", paddingBottom: "8%" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Rate"
            style={{ color: "#637381" }}
            primaryTypographyProps={{
              fontSize: "16px",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: "400",
            }}
          />
          {openRate ? (
            <ExpandLess style={{ color: "#637381" }} />
          ) : (
            <ExpandMore style={{ color: "#637381" }} />
          )}
        </ListItemButton>
      )}
      <Collapse in={openRate} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink
            to="/cargo/rate/upload_rate"
            style={{ textDecoration: "none", color: "#637381" }}
          >
            {Checkpermissions("permissions_cat_name", "Upload Rate") && (
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/rate/upload_rate" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <TrendingUpTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Upload Rate"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}

          <NavLink
            to="/cargo/rate/rate_inquiry"
            style={{ textDecoration: "none", color: "#637381" }}
          >
            {Checkpermissions("permissions_cat_name", "Rate Inquiry") && (
              <ListItemButton
                style={{ margin: "0 5% 0 15%" }}
                sx={{
                  pl: 4,
                  ...(window.location.pathname ===
                    "/cargo/rate/rate_inquiry" && {
                    ...listStyles,
                  }),
                }}
              >
                <ListItemIcon>
                  <LineWeightTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Rate Inquiry"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "400",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      <NavLink
        to="/cargo/ledger/ledger_report"
        style={{ textDecoration: "none", color: "#637381" }}
      >
        <ListItemButton
          style={{ padding: "3%", margin: "0 10% 0 10%" }}
          sx={{
            pl: 4,
            ...(window.location.pathname === "/cargo/ledger/ledger_report" && {
              ...listStyles,
            }),
          }}
        >
          <ListItemIcon>
            <AccountBalanceTwoToneIcon
              fontSize="large"
              style={{ color: "#637381", paddingBottom: "8%" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Ledger"
            primaryTypographyProps={{
              fontSize: "16px",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: "400",
            }}
          />
        </ListItemButton>
      </NavLink>
    </List>
  );

  //  if(props.moduleKey === 'UserInventory' ||  window.localStorage.getItem('module_key') == 'user_inventory')  return  true ? inventoryList: inventoryListUser;
  //  else if(props.moduleKey === 'UserAccounts'|| window.localStorage.getItem('module_key') == 'user_accounts') return accountsList;
  //  else if(props.moduleKey === 'storeList'|| window.localStorage.getItem('module_key') == 'storeList') return storeList;
  //  else if(props.moduleKey==='setting' || window.localStorage.getItem('module_key') == 'setting')return settingList;
  //   else return inventoryList;
  return MenuList;
}
