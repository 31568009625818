import React, { useEffect, useRef } from "react";
import { Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import { getCountry } from "../../country/Country.action";
import { getSender } from "../../sender/Sender.action";
import Step1SelectSender from "./Step1SelectSender";
import Step2SelectReceiver from "./Step2SelectReceiver";
import {
  backSubSteps,
  getCargoService,
  getPayList,
  getServiceType,
  getShipmentPurpose,
  getUom,
  nextSubSteps,
  resetReducerShipment,
  setAddSenderData,
  setShowReceiver,
  setShowShipmentDetail,
} from "./CreateShipment.action";
import {
  backSteps,
  nextSteps,
  resetReducer,
  startSteps,
} from "../Shipment.action";
import Step3ShipmentDetail from "./Step3ShipmentDetail";
import { getCurrency } from "../../currency/Currency.action";
import { getReceiver } from "../../receiver/Receiver.action";

const CreateShipmentDumy = (props) => {
  const refSender = useRef(null);
  const refReceiver = useRef(null);
  const refDetail = useRef(null);
  const moveToReceiver = () => {
    // refSender.current?.scrollIntoView({behavior: 'smooth'})
    const elmnt = refSender;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };
  const moveToDetail = () => {
    // refSender.current?.scrollIntoView({behavior: 'smooth'})
    const elmnt = refReceiver;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  };

  useEffect(() => {
    props.resetReducerShipment();
    props.resetReducer();
    props.getSender();
    props.getReceiver();
    props.getCountry();
    props.getShipmentPurpose();
    props.getCurrency();
    props.getPayList();
    props.getServiceType();
    props.getUom();
    // props.getCargoService()
    props.setShowReceiver(false);
    props.setShowShipmentDetail(false);
  }, []);
  return (
    <>
      {props.isFetchingSender ||
      props.isFetchingUomList ||
      props.isFetchingPayList ||
      props.isFetchingServiceTypeList ||
      props.isFetchingCargoServiceList ||
      props.isFetchingReceiver ||
      props.isFetchingShipmentPurposeList ||
      props.isFetchingCurrency ||
      props.isFetchingCountry ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          {/* <div className="main" > */}

          <Card style={{ backgroundColor: "#F9FAFB", border: "none" }}>
            <div>
              <Step1SelectSender moveToReceiver={moveToReceiver} />
            </div>
            <div ref={refSender}>
              <Step2SelectReceiver moveToDetail={moveToDetail} />
            </div>
            <div ref={refReceiver}>
              <Step3ShipmentDetail />
            </div>
            {/* {props.showReceiver === true ?
                            <div ref={refSender}><Step2SelectReceiver  /></div> : ""} */}
            {/* {props.showShipmentDetail === true ?
                            <Step3ShipmentDetail />
                            : ""} */}
          </Card>
          {/* </div> */}
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleNextStep: () => dispatch(nextSubSteps()),
    handleBackStep: () => dispatch(backSubSteps()),

    setSenderData: (data) => dispatch(setAddSenderData(data)),
    handleNextStepMain: () => dispatch(nextSteps()),
    handleBackStepMain: () => dispatch(backSteps()),
    startSteps: () => dispatch(startSteps()),
    resetReducer: () => dispatch(resetReducer()),
    resetReducerShipment: () => dispatch(resetReducerShipment()),

    getCountry: () => dispatch(getCountry()),
    getUom: () => dispatch(getUom()),
    getReceiver: () => dispatch(getReceiver()),
    getSender: () => dispatch(getSender()),
    getShipmentPurpose: () => dispatch(getShipmentPurpose()),
    getCurrency: () => dispatch(getCurrency()),
    getPayList: () => dispatch(getPayList()),
    getServiceType: () => dispatch(getServiceType()),
    getCargoService: () => dispatch(getCargoService()),
    setShowReceiver: (data) => dispatch(setShowReceiver(data)),
    setShowShipmentDetail: (data) => dispatch(setShowShipmentDetail(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  senderData: state.CargoCreateShipmentReducer.senderData,
  subStepsCreateShipment:
    state.CargoCreateShipmentReducer.subStepsCreateShipment,
  activeSubSteps: state.CargoCreateShipmentReducer.activeSubSteps,
  showReceiver: state.CargoCreateShipmentReducer.showReceiver,
  showShipmentDetail: state.CargoCreateShipmentReducer.showShipmentDetail,
  showPackaging: state.CargoCreateShipmentReducer.showPackaging,

  senderList: state.CargoSenderReducer.senderList,
  isFetchingSender: state.CargoSenderReducer.isFetchingSender,
  receiverList: state.CargoReceiverReducer.receiverList,
  isFetchingReceiver: state.CargoReceiverReducer.isFetchingReceiver,
  shipmentPurposeList: state.CargoCreateShipmentReducer.shipmentPurposeList,
  isFetchingShipmentPurposeList:
    state.CargoCreateShipmentReducer.isFetchingShipmentPurposeList,
  payList: state.CargoCreateShipmentReducer.payList,
  isFetchingPayList: state.CargoCreateShipmentReducer.isFetchingPayList,
  isFetchingCargoServiceList:
    state.CargoCreateShipmentReducer.isFetchingCargoServiceList,
  isFetchingServiceTypeList:
    state.CargoCreateShipmentReducer.isFetchingServiceTypeList,
  currencyList: state.CargoCurrencyReducer.currencyList,
  isFetchingCurrency: state.CargoCurrencyReducer.isFetchingCurrency,
  countryList: state.CargoCountryReducer.countryList,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  isFetchingUomList: state.CargoCreateShipmentReducer.isFetchingUomList,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateShipmentDumy);
