const initialState = {
  isFetchingPackages:false,
  packagesList:[],
  packages:[],
  isAddingPackages:false,

};

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_PACKAGES":
      return { ...state, isFetchingPackages: true };
    case "SUCCESS_GET_PACKAGES":
      return {
        ...state,
        packagesList: action.payload.data,
        isFetchingPackages: false,
      };
    case "ERROR_GET_PACKAGES":
      return { ...state, isFetchingPackages: false };

    case "REQUEST_ADD_PACKAGES":
      return { ...state, isAddingPackages: true };
    case "SUCCESS_ADD_PACKAGES":
      return {
        ...state,
        packages: action.payload,
        isAddingPackages: false,
      };
    case "ERROR_ADD_PACKAGES":
      return { ...state, isAddingPackages: false };

    default:
      return state;
  }
};

export default packageReducer;