import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ReactSelect from "react-select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { connect } from "react-redux";
import { filter, isEmpty, isNumber, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
// import { getServiceType } from "../shipment/createShipment/CreateShipment.action";
import { InputAdornment } from "@mui/material";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import PercentTwoToneIcon from "@mui/icons-material/PercentTwoTone";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  getPackageWeight,
  getCalculateRates,
  setShowTable,
  getServiceType,
  getCountry,
  downloadRateReport,
} from "./Rates.action";
import jsPDF from "jspdf";
import logo from "../../../Images/logo.png";

import { ThemeProvider, createTheme } from "@mui/material";
// import { getCountry } from "../country/Country.action";
import moment from "moment";
import swal from "sweetalert";

const RateInquiry = (props) => {
  const theme = useTheme();

  const [weight, setWeight] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [stateZipCode, setStateZipCode] = useState("");
  const [selectedService, setSelectedService] = useState(false);
  const [selectedType, setSelectedType] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [getRate, setGetRate] = useState("");
  const [percentage, setPercentage] = useState("");
  // const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const defaultMaterialTheme = createTheme();

  function calculateRates() {
    props.setShowTable(true);
    props.getCalculateRates(
      {
        weight: weight,
        service_type_id: serviceId !== 0 ? serviceId : "ALL",
        country_id: selectedService ? stateZipCode : countryName,
        date: date,
        shipment_type: getRate,
        type2: selectedType && serviceType,
      },
      handleDangerVisible
    );
    setWeight("");
    setCountryId("");
    setCountryName("");
    setServiceId("");
    setDate(moment(new Date()).format("YYYY-MM-DD"));
    setGetRate("");
    // setPercentage("");
    setServiceType("");
    setStateZipCode("");
  }
  const exportPDF = () => {
    props.downloadPdf(props.calculateRate, props.calculateRateList, percentage);
    // props.downloadPdf({
    //   date: props.calculateRate.body.date,
    //   destination: props.calculateRate.body.country_id,
    //   weight: props.calculateRate.body.weight,
    // });
  };
  const getCountryList =
    !isEmpty(props.countryList) &&
    props.countryList.map((x) => {
      let data = {
        value: x.country_id,
        label: x.name,
        country_code: x.code,
        dial_code: x.dial_code,
      };
      return data;
    });
  const handleChangeCountry = (e) => {
    setCountryId(e.value);
    setCountryName(e.label);
  };
  const handleChangeServiceType = (e) => {
    setServiceId(e.target.value);

    setSelectedService(e.target.value === 16);
    setSelectedType(e.target.value === 11);
  };
  const handleChangeGetRate = (e) => {
    setGetRate(e.target.value);
    // setCountry(e.target.value);
  };
  const handleChangeServiceTypeVat = (e) => {
    setServiceType(e.target.value);
  };
  const service =
    !isEmpty(props.serviceTypeList) &&
    filter(props.serviceTypeList, { active: true });
  !isEmpty(service) &&
    service.push({
      name: "ALL",
      service_type_id: 0,
    });

  useEffect(() => {
    // props.getPackageWeight();
    props.getServiceType();
    props.getCountry();
    props.setShowTable(false);
    document.title = props.title;
  }, []);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  return (
    <>
      <Container fluid>
        <div
          className="main"
          style={{ backgroundColor: "#F9FAFB", height: "auto" }}
        >
          <Card
            style={{
              padding: isMobile ? "0 " : "3%",
              minWidth: 400,
              height: !isEmpty(props.calculateRateList) ? "auto" : "80vh",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              borer: "none",
            }}
          >
            <Row
              style={{
                textAlign: "center",
              }}
            >
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Typography
                  style={{
                    fontFamily: "Public Sans,sans-serif",
                    fontSize: "30px",
                  }}
                >
                  <b>RATE INQUIRY</b>
                </Typography>
              </Col>
            </Row>
            {props.isFetchingCountry ? (
              <div className="loader-div">
                <Spinner
                  variant="secondary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="4" lg="4" md="4" sm="6" xs="12">
                    <TextField
                      InputProps={{
                        // inputProps: {
                        //   max: moment(new Date()).format("YYYY-MM-DD"),
                        // },
                        startAdornment: (
                          <InputAdornment position="start">
                            <DateRangeTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="date"
                      id="standard-basic"
                      variant="standard"
                      label="Date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6" xs="12">
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        required
                      >
                        Shipment Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Shipment Type"
                        value={getRate}
                        onChange={handleChangeGetRate}
                      >
                        <MenuItem key={1} value={"Doc"}>
                          {"DOCUMENT"}
                        </MenuItem>
                        <MenuItem key={0} value={"Pack"}>
                          {"PACKAGE"}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6" xs="12">
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        required
                      >
                        Select Service
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={serviceId}
                        label="Select Service"
                        onChange={handleChangeServiceType}
                      >
                        {!isEmpty(service) &&
                          service.map((name) => (
                            <MenuItem
                              key={name.service_type_id}
                              value={name.service_type_id}
                            >
                              {`${name.name}`}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  {selectedService ? (
                    <Col xl="4" lg="4" md="4" sm="6" xs="12">
                      <TextField
                        InputProps={{
                          inputProps: { min: 1 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <NumbersTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="number"
                        id="standard-basic"
                        variant="standard"
                        label="Enter State ZipCode *"
                        value={stateZipCode}
                        onChange={(e) => setStateZipCode(e.target.value)}
                        sx={{
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                            {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                  ) : (
                    <Col
                      xl="4"
                      lg="4"
                      md="4"
                      sm="6"
                      xs="12"
                      style={{ zIndex: 8 }}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          fontFamily: "Public Sans,sans-serif",
                          marginBottom: "-2px",
                          color: "#00000061",
                        }}
                      >
                        SELECT COUNTRY*
                      </p>
                      <ReactSelect
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "none",
                            borderBottom: "1px solid black",
                            borderRadius: 0,
                            boxShadow: "none",
                            backgroundColor: "white",
                            marginTop: "-1px",
                          }),
                        }}
                        isDisabled={isEmpty(props.countryList)}
                        value={{ label: countryName }}
                        placeholder="Select Country..."
                        onChange={handleChangeCountry}
                        options={getCountryList}
                      />
                    </Col>
                  )}
                  <Col xl="4" lg="4" md="4" sm="6" xs="12">
                    <TextField
                      InputProps={{
                        inputProps: { min: 1 },
                        startAdornment: (
                          <InputAdornment position="start">
                            <NumbersTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="number"
                      id="standard-basic"
                      variant="standard"
                      label="Enter Weight *"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      sx={{
                        width: "100%",
                        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        "input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6" xs="12">
                    <TextField
                      InputProps={{
                        inputProps: { min: 1 },
                        startAdornment: (
                          <InputAdornment position="start">
                            <PercentTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="number"
                      id="standard-basic"
                      variant="standard"
                      label="Enter Profit Percentage "
                      value={percentage}
                      onChange={(e) => setPercentage(e.target.value)}
                      sx={{
                        width: "100%",
                        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        "input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  </Col>
                </Row>
                {selectedType && (
                  <Row style={{ marginTop: "15px" }}>
                    <Col xl="4" lg="4" md="4" sm="4" xs="12">
                      <FormControl variant="standard" fullWidth>
                        <InputLabel
                          id="demo-simple-select-standard-label"
                          required
                        >
                          Select Service Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={serviceType}
                          // disabled={editable}
                          label="Select Service Type"
                          onChange={handleChangeServiceTypeVat}
                        >
                          <MenuItem key={1} value={"DHL"}>
                            {"VIA UK DHL (VAT PAID)"}
                          </MenuItem>
                          <MenuItem key={0} value={"UPS"}>
                            {"VIA UK UPS (VAT PAID)"}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                )}

                <div className="sendDiv" style={{ marginTop: "30px" }}>
                  <Button
                    disabled={
                      (selectedService
                        ? stateZipCode == ""
                        : countryId == "") ||
                      (selectedType && serviceType === "") ||
                      weight == "" ||
                      getRate == ""
                    }
                    style={{
                      // marginLeft: "2%",
                      color: "black",
                      // width: "20%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                    onClick={calculateRates}
                  >
                    Get Rate
                  </Button>
                </div>
              </>
            )}

            {props.isFetchingCalculateRateList ? (
              <div className="loader-div">
                <Spinner
                  variant="secondary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              !isEmpty(props.calculateRateList) && (
                <Card
                  style={{
                    padding: "3%",
                    backgroundColor: "#F9FAFB",
                    display: props.isshowTable == true ? "block" : "none",
                  }}
                >
                  <Row style={{ textAlign: "center" }}>
                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                      <Typography
                        style={{
                          fontSize: "30px",
                          fontFamily: "Public Sans,sans-serif",
                        }}
                      >
                        <b>QUOTATION SLIP</b>
                      </Typography>
                    </Col>
                  </Row>
                  <div>
                    <div
                      style={{
                        width: "50%",
                        float: "left",
                      }}
                    >
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <Typography
                            style={{ fontFamily: "Public Sans,sans-serif" }}
                          >
                            <b>
                              Date:{" "}
                              {!isEmpty(props.calculateRate) &&
                                props.calculateRate.body.date}
                            </b>
                          </Typography>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <Typography
                            style={{ fontFamily: "Public Sans,sans-serif" }}
                          >
                            <b>
                              Destination:{" "}
                              {!isEmpty(props.calculateRate) &&
                                props.calculateRate.body.country_id}
                            </b>
                          </Typography>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <Typography
                            style={{ fontFamily: "Public Sans,sans-serif" }}
                          >
                            <b>
                              Weight:{" "}
                              {!isEmpty(props.calculateRate) &&
                                props.calculateRate.body.weight}
                            </b>
                          </Typography>
                        </Col>
                      </Row>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        float: "right",
                      }}
                    >
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <Button
                            style={{
                              float: "right",
                              marginRight: "3%",
                              marginTop: isMobile ? "30%" : "7%",
                              color: "black",
                              // width: "25%",
                              backgroundColor: "#C5CBCF",
                              border: "1px solid white",
                            }}
                            onClick={exportPDF}
                          >
                            Download
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MaterialTable
                      style={{
                        marginTop: isMobile ? "40%" : "10%",
                        borderRadius: "30px",
                        boxShadow:
                          " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        display: props.isshowTable == true ? "block" : "none",
                      }}
                      title="RATE LIST"
                      // style={{
                      //   display: props.isshowTable == true ? "" : "none",
                      // }}
                      columns={[
                        {
                          title: "Company",
                          field: "service",
                          editable: "never",
                        },
                        // Math.ceil((percentage * Number(Object.values(props.calculateRateList)) / 100) + Number(Object.values(props.calculateRateList)))
                        {
                          title: "Cost Rate",
                          field: "rate",
                          render: (rowData) =>
                            isNumber(rowData.rate)
                              ? Math.ceil(
                                  (percentage * Number(rowData.rate)) / 100
                                ) + Number(rowData.rate)
                              : rowData.rate,
                        },
                        // { title: "Selling Rate", field: "weight" },
                        // { title: "Profit", field: "country_id" },
                        // { title: "Cost Rate Per KG", field: "country_id" },
                        // { title: "Selling Rate Per KG", field: "country_id" },
                      ]}
                      data={props.calculateRateList}
                      options={{
                        actionsColumnIndex: -1,
                        exportButton: true,
                        exportAllData: true,
                        paging: true,
                        pageSize: 50, // make initial page size
                        emptyRowsWhenPaging: false, // To avoid of having empty rows
                        pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                        maxBodyHeight: "600px",
                        headerStyle: {
                          position: "sticky",
                          top: 0,
                          color: "#919EAB",
                          fontFamily: "Public Sans,sans-serif",
                          fontWeight: "550",
                          onRowAdd: "none",
                        },
                        // exportPdf: (columns, data) => {
                        //   console.log(columns, data, "ccccccccccccc");
                        //   // const doc = new jsPDF();
                        //   // //render:rowData=>rowData.hall_name
                        //   // const columnTitles = [
                        //   //   {
                        //   //     title: "Company",
                        //   //     field: "service",
                        //   //     render: (rowData) => rowData.service,
                        //   //   },
                        //   //   {
                        //   //     title: "Cost Rate",
                        //   //     field: "rate",
                        //   //     render: (rowData) =>
                        //   //       isNumber(rowData.rate)
                        //   //         ? Math.ceil(
                        //   //             (percentage * Number(rowData.rate)) / 100
                        //   //           ) + Number(rowData.rate)
                        //   //         : rowData.rate,
                        //   //   },
                        //   //   // { title: 'Menu Description', field: 'menu_desc',render:rowData=>rowData.menu_desc},
                        //   // ].map((columnDef) => columnDef.title);
                        //   // const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                        //   // const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                        //   // const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                        //   // const pdfData = data.map((rowData) => [
                        //   //   rowData.service,
                        //   //   rowData.rate,
                        //   // ]);
                        //   // let content = {
                        //   //   startY: 75,
                        //   //   startX: 5,
                        //   //   head: [columnTitles],
                        //   //   body: pdfData,
                        //   // };
                        //   // doc.addImage(logo, "PNG", 10, 1, 40, 40);
                        //   // doc.setFontSize(20);
                        //   // doc.setFont(undefined, "bold");
                        //   // doc.text(companyName, 60, 22, 0, 0);
                        //   // doc.setFontSize(13);
                        //   // doc.setFont("TimesNewRoman", "Normal");
                        //   // doc.text(companyAddress, 60, 29, 0, 0);
                        //   // doc.text(companyNtn, 60, 36, 0, 0);
                        //   // doc.setFontSize(20);
                        //   // doc.setFont(undefined, "bold");
                        //   // doc.text(`QUOTATION SLIP`, 70, 47, 0, 0);
                        //   // doc.setFontSize(13);
                        //   // doc.setFont("TimesNewRoman", "Normal");
                        //   // doc.text(
                        //   //   `Date: ${props.calculateRate.body.date}`,
                        //   //   10,
                        //   //   55
                        //   // );
                        //   // doc.text(
                        //   //   `Destination: ${props.calculateRate.body.country_id}`,
                        //   //   10,
                        //   //   62
                        //   // );
                        //   // doc.text(
                        //   //   `Weight: ${props.calculateRate.body.weight}`,
                        //   //   10,
                        //   //   69
                        //   // );
                        //   // doc.autoTable(content);
                        //   // // doc.setFontSize(12);
                        //   // // doc.setFont(undefined, 'bold');
                        //   // // doc.text("Term And Condition", 15, doc.lastAutoTable.finalY + 5, 0, 0)
                        //   // // doc.setFont(undefined, 'normal')
                        //   // // doc.setFontSize(10);
                        //   // // var splitText = doc.splitTextToSize(props.termAndCondition, 450);
                        //   // // doc.text(splitText, 15, doc.lastAutoTable.finalY + 10, 0, 10)
                        //   // doc.save(`QUOTATION SLIP.pdf`);
                        // },
                      }}
                    />
                  </ThemeProvider>
                </Card>
              )
            )}

            {/* <b>{Math.ceil((percentage * Number(Object.values(props.calculateRateList)) / 100) + Number(Object.values(props.calculateRateList)))}</b> */}
          </Card>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageWeight: (data) => dispatch(getPackageWeight(data)),
    getCalculateRates: (data, handleDangerVisible) =>
      dispatch(getCalculateRates(data, handleDangerVisible)),
    getServiceType: () => dispatch(getServiceType()),
    getCountry: () => dispatch(getCountry()),
    setShowTable: (data) => dispatch(setShowTable(data)),
    downloadPdf: (data, tableData, percentage) =>
      dispatch(downloadRateReport(data, tableData, percentage)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingPackageWeightList:
    state.CargoRateReducer.isFetchingPackageWeightList,
  packageWeightList: state.CargoRateReducer.packageWeightList,
  isshowTable: state.CargoRateReducer.isshowTable,
  calculateRateList: state.CargoRateReducer.calculateRateList,
  calculateRate: state.CargoRateReducer.calculateRate,
  isFetchingCalculateRateList:
    state.CargoRateReducer.isFetchingCalculateRateList,
  serviceTypeList: state.CargoRateReducer.serviceTypeList,
  isFetchingServiceTypeList: state.CargoRateReducer.isFetchingServiceTypeList,
  isFetchingCountry: state.CargoRateReducer.isFetchingCountry,
  countryList: state.CargoRateReducer.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(RateInquiry);
