import API from "../../../global/api";
import moment from "moment";
import { isEmpty, map, sumBy, isNull } from "lodash";
import jsPDF from "jspdf";
import logo from "../../../Images/logo.png";

// Ledger Report
export const requestGetAccount = () => {
  return {
    type: "REQUEST_GET_ACCOUNT",
  };
};
export const successGetAccount = (data) => {
  return {
    type: "SUCCESS_GET_ACCOUNT",
    payload: data,
  };
};
export const errorGetAccount = () => {
  return {
    type: "ERROR_GET_ACCOUNT",
  };
};
export const getAccount = () => {
  return (dispatch) => {
    dispatch(requestGetAccount());
    API.get(`/shipment/get_levelFive_account`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetAccount(getData));
      })
      .catch((error) => {
        dispatch(errorGetAccount());
      });
  };
};

// Generate Report

export const requestLedgerReport = () => {
  return {
    type: "REQUEST_LEDGER_REPORT",
  };
};
export const successLedgerReport = (data) => {
  return {
    type: "SUCCESS_LEDGER_REPORT",
    payload: data,
  };
};
export const errorLedgerReport = () => {
  return {
    type: "ERROR_LEDGER_REPORT",
  };
};
export const GenerateLedgerReport = (data) => {
  return (dispatch) => {
    dispatch(requestLedgerReport());
    API.post(`hhhhhhhhh`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successLedgerReport(getData));
      })
      .catch((error) => {
        dispatch(errorLedgerReport());
      });
  };
};

// Generate PDF

const sumArray = (array) => {
  const newArray = [];
  array.forEach((sub) => {
    sub.forEach((num, index) => {
      if (newArray[index]) {
        newArray[index] += num;
      } else {
        newArray[index] = num;
      }
    });
  });
  return newArray;
};

export const requestTrialData = () => {
  return {
    type: "REQUEST_TRIAL_DATA",
  };
};
export const successTrialData = (data) => {
  return {
    type: "SUCCESS_TRIAL_DATA",
    payload: data,
  };
};
export const errorTrialData = () => {
  return {
    type: "ERROR_TRIAL_DATA",
  };
};

export const getTrialDataPdf = (data, name) => {
  return (dispatch) => {
    dispatch(requestTrialData());
    API.post(`/cargo_accounts/get_ledger`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successTrialData(getData));
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(14);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNTN = `${process.env.REACT_APP_COMPANY_NTN}`;
        const accName = data.levelFiveId + " " + "/" + " " + name;
        const Dates =
          !isEmpty(data.startedDate) || !isEmpty(data.endDate)
            ? "Form: " + " " + data.startedDate + " to " + data.endDate
            : "Form: -  To: - ";

        const totalCredit = !isEmpty(data.credit_sum)
          ? "Total Credit: " + data.credit_sum
          : "Total Credit: - ";
        const totalDebit = !isEmpty(data.debit_sum)
          ? "Total Debit: " + data.debit_sum
          : "Total Debit: - ";
        const closingBalance = !isEmpty(data.closing_balance)
          ? "Closing Balance: " + data.closing_balance
          : "Closing Balance: - ";
        doc.addImage(logo, "PNG", 40, 18, 90, 100);
        doc.setFont("Times New Roman");
        doc.text(companyName, 180, 60, 0, 20);
        doc.text(companyAddress, 180, 85, 0, 16);
        doc.text(companyNTN, 200, 105, 0, 16);
        doc.text(accName, 20, 160, 0, 16);
        doc.text(Dates, 20, 180, 0, 16);
        doc.text(totalCredit, 20, 200, 0, 20);
        doc.text(totalDebit, 20, 220, 0, 20);
        doc.text(closingBalance, 20, 240, 0, 20);
        doc.save(
          `Trial Balance Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
      })
      .catch((error) => {
        dispatch(errorTrialData());
      });
  };
};
