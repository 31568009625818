import { Navigate, Route, Routes } from "react-router-dom";
import * as React from "react";
import { useLocation } from "react-router-dom";
import Login from "../views/user_views/accounts/Login";
import UserMainPage from "../views/user_views/accounts/UserMainPage";

// account
import Dashboard from "../views/user_views/drawer/ResponsiveDrawer";
import CashPayment from "../views/user_views/forms/Vouchers/CashPayment";
import CashReceipt from "../views/user_views/forms/Vouchers/CashReceipt";
import BankPayment from "../views/user_views/forms/Vouchers/BankPayment";
import BankReceipt from "../views/user_views/forms/Vouchers/BankReceipt";
import VoucherSearch from "../views/user_views/forms/Vouchers/VoucherSearch";
import VoucherSearchDetail from "../views/user_views/forms/Vouchers/VoucherSearchDetail";
import VoucherVerification from "../views/user_views/forms/Vouchers/VoucherVerification";
import AccountLedger from "../views/user_views/forms/ledger/Ledger";
import LedgerTable from "../views/user_views/forms/ledger/LedgerTable";
import JournalVoucher from "../views/user_views/forms/Vouchers/JournalVoucher";
import Level2 from "../views/user_views/forms/accountsLevels/Level2";
import Level2Tap from "../views/user_views/forms/accountsLevels/Level2Tap";
import Level2Upload from "../views/user_views/forms/accountsLevels/Level2Upload";
import Level2Report from "../views/user_views/forms/accountsLevels/Level2Report";
import TrailBalance from "../views/user_views/accounts/trialBalance";
import TransactionSummary from "../views/user_views/forms/accountsTransactionSummary/TransactionSummary";
import TransactionSummaryTable from "../views/user_views/forms/accountsTransactionSummary/TransactionSummaryTable";
import Level3 from "../views/user_views/forms/accountsLevels/Level3";
import Level3Tap from "../views/user_views/forms/accountsLevels/Level3Tap";
import Level3Upload from "../views/user_views/forms/accountsLevels/Level3Upload";
import Level3Report from "../views/user_views/forms/accountsLevels/Level3Report";
import Level4 from "../views/user_views/forms/accountsLevels/Level4";
import Level4Tap from "../views/user_views/forms/accountsLevels/Level4Tap";
import Level4Upload from "../views/user_views/forms/accountsLevels/Level4Upload";
import Level4Report from "../views/user_views/forms/accountsLevels/Level4Report";
import Level5 from "../views/user_views/forms/accountsLevels/Level5";
import Level5Tap from "../views/user_views/forms/accountsLevels/Level5Tap";
import Level5Upload from "../views/user_views/forms/accountsLevels/Level5Upload";
import Level5Report from "../views/user_views/forms/accountsLevels/Level5Report";
import ProfitLossStatment from "../views/user_views/forms/ProfitLoss/ProfitLossStatement";
import AddAging from "../views/user_views/forms/accountsAging/AddAging";
import AccountCashBook from "../views/user_views/forms/accountsCashBook/AccountCashBook";
import TrialBalanceView from "../views/user_views/accounts/trialBalanceView";
import ChartOfAccount from "../views/user_views/accounts/ChartOfAccount";
// permission
import UserPermissions from "../views/user_views/forms/AddDetails/Permissions/UserPermissions";
import BackUp from "../views/user_views/forms/BackUp/BackUp";
// cargo
import DashboardCargo from "../views/cargo_views/drawer/ResponsiveDrawer";
import Cergologin from "../views/cargo_views/cargoLogin/CargoLogin";
import AddComplaint from "../views/cargo_views/complaint/AddComplaint";
import AddSender from "../views/cargo_views/sender/AddSender";
import ViewSender from "../views/cargo_views/sender/ViewSender";
import AddCountry from "../views/cargo_views/country/AddCountry";
import ViewCountry from "../views/cargo_views/country/ViewCountry";
import AddPackages from "../views/cargo_views/packages/AddPackages";
import ViewPackage from "../views/cargo_views/packages/ViewPackage";
import AddReceiver from "../views/cargo_views/receiver/AddReceiver";
import ViewReceiver from "../views/cargo_views/receiver/ViewReceiver";
import AddShipment from "../views/cargo_views/shipment/AddShipment";
import ViewShipment from "../views/cargo_views/shipment/ViewShipment";
import EditSender from "../views/cargo_views/sender/EditSender";
import EditReceiver from "../views/cargo_views/receiver/EditReceiver";
import UserControl from "../views/cargo_views/User/UserControl";
import EditUser from "../views/cargo_views/User/EditUser";
import AddService from "../views/cargo_views/Service/AddService";
import ViewService from "../views/cargo_views/Service/ViewService";
import AddCurrency from "../views/cargo_views/currency/AddCurrency";
import ViewCurrency from "../views/cargo_views/currency/ViewCurrency";
import ViewComplaint from "../views/cargo_views/complaint/ViewComplaint";
import ViewShipmentList from "../views/cargo_views/shipment/ViewShipmentList";
import ViewShipmentById from "../views/cargo_views/shipment/ViewShipmentById";
import EditComplaint from "../views/cargo_views/complaint/EditComplaint";
import TrackCargo from "../views/cargo_views/tracking/TrackCargo";
import Rates from "../views/cargo_views/rates/Rates";
import Permissions from "../views/cargo_views/Permissions/UserPermissions";
import Reporting from "../views/cargo_views/reporting/Reporting";
import EditShipment from "../views/cargo_views/shipment/editShipment/EditShipment";
import ProtectedRoute from "../ProtectedRoute";

import LedgerReport from "../views/cargo_views/reporting/ledgerReport/LedgerReport";
import AddUser from "../views/cargo_views/User/AddUser";
import RegisterCargo from "../views/cargo_views/cargoLogin/RegisterCargo";
import ForgetPassword from "../views/cargo_views/cargoLogin/ForgetPassword";
import ViewShipmentListDumy from "../views/cargo_views/shipment/ViewShipmentListDumy";
import AUCDashboard from "../views/cargo_views/dashboard/AddDashboard";
import RateInquiry from "../views/cargo_views/rates/RateInquiry";
import RateUpload from "../views/cargo_views/rates/RateUpload";
import ViewRateLogs from "../views/cargo_views/rates/ViewRateLogs";
import Ledger from "../views/cargo_views/ledger/Ledger";
import LedgerTableCargo from "../views/cargo_views/ledger/LedgerTable";
const { startCase } = require("lodash");

export default function Routing() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = React.useState("AUC Test");
  React.useEffect(() => {
    document.pageTitle = pageTitle;
  }, [pageTitle]);

  const changePageTitle = (event) => {
    setPageTitle(event.target.value);
  };
  React.useEffect(() => {
    console.log("Location changed");
    const words = location.pathname.split("/");

    const convert = startCase(words[3]);

    setPageTitle(convert);
  }, [location]);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate
            to="/cargo-login"
            title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Login`}
            replace
          />
        }
      />

      <Route path="/accounts-login" element={<Login />}></Route>
      <Route
        path="/cargo-login"
        element={
          <Cergologin
            title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Login`}
          />
        }
      ></Route>

      <Route
        path="/forgot-password"
        element={
          <ForgetPassword
            title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Forget Password`}
          />
        }
      ></Route>
      <Route
        path="/cargo-register"
        element={
          <RegisterCargo
            title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Register`}
          />
        }
      ></Route>
      <Route
        path="/tracking/:id"
        element={
          <TrackCargo
            title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Track Cargo`}
          />
        }
      ></Route>
      <Route
        path="/tracking"
        element={
          <TrackCargo
            title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Track Cargo`}
          />
        }
      ></Route>
      <Route
        path="/calculaterates"
        element={
          <Rates
            title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Calculate Rate`}
          />
        }
      ></Route>
      <Route
        path="/rate-enquiry"
        element={
          <RateInquiry
            title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Rate Inquiry`}
          />
        }
      ></Route>

      <Route path="/cargo" element={<DashboardCargo title={pageTitle} />} />
      <Route
        path="/cargo"
        element={<DashboardCargo pageTitle={pageTitle} />}
      ></Route>
      {/* cargo */}
      {true && (
        <Route path="/cargo" element={<DashboardCargo pageTitle={pageTitle} />}>
          {/* user */}

          <Route
            path="/cargo/user/add_user"
            element={
              <AddUser
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Add User`}
              />
            }
          />
          <Route
            path="/cargo/user/view_users"
            element={
              <UserControl
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - View Users`}
              />
            }
          />
          <Route
            path="/cargo/user/edit_user"
            element={
              <EditUser
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Edit User`}
              />
            }
          />
          <Route
            path="/cargo/rate/upload_rate"
            element={
              <RateUpload
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Upload Rate`}
              />
            }
          ></Route>
          <Route
            path="/cargo/rate/rate_inquiry"
            element={
              <RateInquiry
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Rate Inquiry`}
              />
            }
          ></Route>

          {/* issuace */}
          <Route
            path="/cargo/complain/add_complain"
            element={
              <AddComplaint
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Add Complaint`}
              />
            }
          />
          <Route
            path="/cargo/complain/view_complains"
            element={
              <ViewComplaint
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - View Complaints`}
              />
            }
          />

          <Route
            path="/cargo/complain/edit_complain"
            element={
              <EditComplaint
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Edit Complain`}
              />
            }
          />
          {/* sender */}
          <Route
            path="/cargo/sender/add_sender"
            element={
              <AddSender
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Add Sender`}
              />
            }
          />
          <Route
            path="/cargo/sender/view_senders"
            element={
              <ViewSender
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - View Senders`}
              />
            }
          />
          <Route
            path="/cargo/sender/edit_sender"
            element={
              <EditSender
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Edit Sender`}
              />
            }
          />

          {/* country */}

          <Route
            path="/cargo/country/add_country"
            element={
              <AddCountry
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Add Country`}
              />
            }
          />
          <Route
            path="/cargo/country/view_countries"
            element={
              <ViewCountry
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - View Countries`}
              />
            }
          />

          {/* Service */}

          <Route
            path="/cargo/service/add_service"
            element={
              <AddService
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Add Service`}
              />
            }
          />
          <Route
            path="/cargo/service/view_services"
            element={
              <ViewService
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - View Services`}
              />
            }
          />
          {/* Packages */}

          {/* <Route
            path="/cargo/packages/add_package"
            element={
              <AddPackages
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Add Package`}
              />
            }
          />
          <Route
            path="/cargo/packages/view_packages"
            element={
              <ViewPackage
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - View Packages`}
              />
            }
          /> */}
          {/* Receiver */}

          <Route
            path="/cargo/receiver/add_receiver"
            element={
              <AddReceiver
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Add Receiver`}
              />
            }
          />
          <Route
            path="/cargo/receiver/view_receivers"
            element={
              <ViewReceiver
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - View Receivers`}
              />
            }
          />
          <Route
            path="/cargo/receiver/edit_receiver"
            element={
              <EditReceiver
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Edit Receiver`}
              />
            }
          />
          {/* Shipment */}

          <Route
            path="/cargo/shipment/add_shipment"
            element={
              <AddShipment
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Add Shipment`}
              />
            }
          />
          <Route
            path="/cargo/shipment/edit_shipment"
            element={
              <EditShipment
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Edit Shipment`}
              />
            }
          />
          <Route
            path="/cargo/shipment/view_shipments"
            element={
              <ViewShipment
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - View Shipments`}
              />
            }
          />
          <Route
            path="/cargo/shipment/shipments_list"
            element={
              // <ViewShipmentList
              <ViewShipmentListDumy
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Shipments List`}
              />
            }
          />
          <Route
            path="/cargo/shipment/shipment_by_id"
            element={
              <ViewShipmentById
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Shipment List By ID`}
              />
            }
          />
          {/* Permissions */}

          <Route
            path="/cargo/permissions/user_permissions"
            element={
              <Permissions
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Check Permissions`}
              />
            }
          />

          {/* Currency */}

          <Route
            path="/cargo/currency/add_currency"
            element={
              <AddCurrency
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Add Currency`}
              />
            }
          />
          <Route
            path="/cargo/currency/view_currencies"
            element={
              <ViewCurrency
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - View Currencies`}
              />
            }
          />
          {/* report */}
          <Route
            path="/cargo/reportings/reporting"
            element={
              <Reporting
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Reporting`}
              />
            }
          />
          <Route
            path="/cargo/reporting/ledger-report"
            element={
              <LedgerReport
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Ledger Report`}
              />
            }
          />
          {/* Dashboard */}
          <Route
            path="/cargo/dashboard/dashboard"
            element={
              <AUCDashboard
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Dashboard`}
              />
            }
          />
          {/* AUC Ledger */}
          <Route
            path="/cargo/ledger/ledger_report"
            element={
              <Ledger
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Ledger`}
              />
            }
          />
          <Route
            path="/cargo/ledger/ledger_table"
            element={
              <LedgerTableCargo
                title={`${process.env.REACT_APP_COMPANY_NAME_ABB} - Ledger View`}
              />
            }
          />
        </Route>
      )}
      {/* Account */}
      {true && (
        <Route path="/accounts" element={<Dashboard />}>
          <Route
            path="/accounts/vouchers/cashpayment"
            element={<CashPayment />}
          />
          <Route
            path="/accounts/trial-balance-report"
            element={<TrialBalanceView />}
          />
          <Route
            path="/accounts/vouchers/cashreceipt"
            element={<CashReceipt />}
          />
          <Route
            path="/accounts/vouchers/bankpayment"
            element={<BankPayment />}
          />
          <Route
            path="/accounts/vouchers/bankreceipt"
            element={<BankReceipt />}
          />
          <Route
            path="/accounts/vouchers/journal"
            element={<JournalVoucher />}
          />
          <Route
            path="/accounts/voucherverification"
            element={<VoucherVerification />}
          />
          <Route path="/accounts/vouchersearch" element={<VoucherSearch />} />
          <Route
            path="/accounts/voucher-search-detail"
            element={<VoucherSearchDetail />}
          />

          <Route path="/accounts/ledger" element={<AccountLedger />} />
          <Route path="/accounts/Ledger-List" element={<LedgerTable />} />

          <Route path="/accounts/level2/add" element={<Level2 />} />
          <Route path="/accounts/level2/details" element={<Level2Tap />} />
          <Route path="/accounts/level2/upload" element={<Level2Upload />} />
          <Route path="/accounts/level2/report" element={<Level2Report />} />

          <Route path="/accounts/level3/add" element={<Level3 />} />
          <Route path="/accounts/level3/details" element={<Level3Tap />} />
          <Route path="/accounts/level3/upload" element={<Level3Upload />} />
          <Route path="/accounts/level3/report" element={<Level3Report />} />

          <Route path="/accounts/level4/add" element={<Level4 />} />
          <Route path="/accounts/level4/details" element={<Level4Tap />} />
          <Route path="/accounts/level4/upload" element={<Level4Upload />} />
          <Route path="/accounts/level4/report" element={<Level4Report />} />
          <Route path="/accounts/level5/add" element={<Level5 />} />
          <Route path="/accounts/level5/details" element={<Level5Tap />} />
          <Route path="/accounts/level5/upload" element={<Level5Upload />} />
          <Route path="/accounts/level5/report" element={<Level5Report />} />

          <Route path="/accounts/trailbalance" element={<TrailBalance />} />
          <Route path="/accounts/chartofAccount" element={<ChartOfAccount />} />
          <Route
            path="/accounts/transaction-summary"
            element={<TransactionSummary />}
          />

          <Route path="/accounts/addaging" element={<AddAging />} />
          <Route path="/accounts/cash-book" element={<AccountCashBook />} />
          <Route
            path="/accounts/profit-loss"
            element={<ProfitLossStatment />}
          />
          <Route
            path="/accounts/transaction-summary-details"
            element={<TransactionSummaryTable />}
          />
        </Route>
      )}
      {/* day open close */}
      {true && (
        <Route path="/setting" element={<Dashboard />}>
          <Route path="/setting/permission" element={<UserPermissions />} />
        </Route>
      )}
    </Routes>
  );
}
