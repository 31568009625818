import API from "../../../global/api";

export const requestAddPackages= () => {
  return {
    type: "REQUEST_ADD_PACKAGES",
  };
};
export const successAddPackages= (data) => {
  return {
    type: "SUCCESS_ADD_PACKAGES",
    payload: data,
  };
};
export const errorAddPackages= () => {
  return {
    type: "ERROR_ADD_PACKAGES",
  };
};
export const addPackages= (data,handleVisible,handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddPackages());
    API.post(`/package`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successAddPackages(data1));
         handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorAddPackages());
      handleDangerVisible(error.response.data.message);
    })
  }
};


export const requestGetPackages= () => {
  return {
      type: "REQUEST_GET_PACKAGES",
  };
};
export const successGetPackages= (data) => {
  return {
      type: "SUCCESS_GET_PACKAGES",
      payload: data,
  };
};
export const errorGetPackages= () => {
  return {
      type: "ERROR_GET_PACKAGES",
  };
};
export const getPackages= () => {
  return (dispatch) => {
      dispatch(requestGetPackages());
      API.get(`/package?size=10&page=1`).then((res) => {
          let getData = res.data;
          console.log(getData,'List')
          dispatch(successGetPackages(getData));
      }).catch((error) => {
          dispatch(errorGetPackages());
      })
  };
};
export const DeleteRequestPackages = () => {
  return {
      type: "DELETE_REQUEST_PACKAGES",
  };
};
export const DeleteSuccessPackages = (user_id) => {
  return {
      type: "DELETE_SUCCESS_PACKAGES",
      user_id: user_id,
  };
};
export const DeleteErrorPackages = () => {
  return {
      type: "DELETE_ERROR_PACKAGES",
  };
};
export const deletePackages = (vendorId) => {
  return dispatch => {
      dispatch(DeleteRequestPackages());
      API.delete(`/package/${vendorId}`)
          .then(res => {
              dispatch(getPackages());
              dispatch(DeleteSuccessPackages(vendorId))
          }).catch((error) =>
          {
              dispatch(DeleteErrorPackages())
  });
  }
};
export const UpdateRequestPackages = () => {
  return {
      type: "UPDATE_REQUEST_PACKAGES",
  };
};
export const UpdateSuccessPackages = (data) => {
  return {
      type: "UPDATE_SUCCESS_PACKAGES",
      payload: data,
  };
};

export const UpdateErrorPackages = () => {
  return {
      type: "UPDATE_ERROR_PACKAGES",
  };
};

export const updatePackages = (data, oldData) => {
  return dispatch => {
      dispatch(UpdateRequestPackages());
      console.log(data,'hmhm')
      API.put(`/package/${oldData.package_id}`,
          {
            name: data.name,
            quantity:data.quantity,
            weight:data.weight,
            length:data.length,
            width:data.width,
            height:data.height   
          },{
      })
          .then(res => {
              dispatch(getPackages());
              dispatch(UpdateSuccessPackages([data]))
          }).catch((error) =>
              dispatch(UpdateErrorPackages()));
  }
};