import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { groupBy, isEmpty, get, isUndefined } from "lodash";

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1
  },
  textStyles: {
    marginLeft: 20
  },
  textStyles1: {
    marginLeft: 30
  },
  textStyles2: {
    marginLeft: 40
  },
  image: {
    width: "30%"
  },
});

var groupedLevel3;
var keys;
const PDFLevel4 = (props) => (
  groupedLevel3 = groupBy(!isEmpty(props.array) && props.array, function (x) {
    return x.levelThreeData.levelOneId.name
  }),
  keys = !isEmpty(groupedLevel3) && Object.keys(groupedLevel3),

  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image
          style={styles.image}
          src={props.data.restaurantImage}
        />
        <Text style={{ textAlign: "center", padding: "5%" }}>CompanyName: Green Apple</Text>
        {!isEmpty(keys) ? keys.map((y, index) => {
          let x = get(groupedLevel3, `${y}`);
          return (
            <View key={index}>
              <Text>{x[0].levelThreeData.levelOneId.key}- {x[0].levelThreeData.levelOneId.name}</Text>
              {Object.keys(groupBy(x, y => y.levelThreeData.levelTwoId.levelTwoName)) ? Object.keys(groupBy(x, y => y.levelThreeData.levelTwoId.levelTwoName)).map((xyz, index1) => {
                let x1 = get(groupBy(x, y => y.levelThreeData.levelTwoId.levelTwoName), `${xyz}`);
                return (
                  <View key={index1}>
                    <Text style={styles.textStyles}>{x1[0].levelThreeData.levelOneId.key + "-" + x1[0].levelThreeData.levelTwoId.levelTwoKey}- {x1[0].levelThreeData.levelTwoId.levelTwoName}</Text>
                    {Object.keys(groupBy(x1, z => z.levelThreeData.levelThreeName)) ? Object.keys(groupBy(x1, z => z.levelThreeData.levelThreeName)).map((wxyz, index2) => {
                      let x2 = get(groupBy(x1, z => z.levelThreeData.levelThreeName), `${wxyz}`);
                      return (
                        <View key={index2}>
                          <Text style={styles.textStyles1}>{x2[0].levelThreeData.levelOneId.key + "-" + x2[0].levelThreeData.levelTwoId.levelTwoKey + "-" + x2[0].levelThreeData.levelThreeKey}- {x2[0].levelThreeData.levelThreeName}</Text>
                          {!isEmpty(x2) ? x2.map((lvl4, index3) => {
                            return (
                              <View key={index3}>
                                <Text style={styles.textStyles2}>{lvl4.levelThreeData.allLevlKey}-{lvl4.levelThreeData.levelThreeName}             {!isUndefined(lvl4.amount) && lvl4.amount.total_amount}</Text>
                              </View>
                            );
                          }) : ""}
                        </View> //// - {lvl4.levelFourData.levelFourName} 
                      );
                    }) : ""}
                  </View>
                );
              }) : ""}
            </View>
          );
        }) : ""}
      </View>
    </Page>
  </Document>
);
export default PDFLevel4;
