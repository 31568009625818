import API from "../../../global/api";
import { isEmpty } from "lodash";
export const requestAddComplaint = () => {
  return {
    type: "REQUEST_ADD_COMPLAINT",
  };
};
export const successAddComplaint = (data) => {
  return {
    type: "SUCCESS_ADD_COMPLAINT",
    payload: data,
  };
};
export const errorAddComplaint = () => {
  return {
    type: "ERROR_ADD_COMPLAINT",
  };
};
export const addComplaint = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddComplaint());
    API.post(`/complaint/add_complaint`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successAddComplaint(data1));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddComplaint());
        handleDangerVisible(error.response.data.message);
      });
  };
};

export const UpdateRequestComplaint = () => {
  return {
    type: "UPDATE_REQUEST_COMPLAINT",
  };
};
export const UpdateSuccessComplaint = (data) => {
  return {
    type: "UPDATE_SUCCESS_COMPLAINT",
    payload: data,
  };
};

export const UpdateErrorComplaint = () => {
  return {
    type: "UPDATE_ERROR_COMPLAINT",
  };
};

export const updateComplaint = (
  data,
  complaint_id,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(UpdateRequestComplaint());
    API.post(`/complaint/update_complaint_status/${complaint_id}`, data, {})
      .then((res) => {
        dispatch(UpdateSuccessComplaint([data]));
        handleVisible("success");
      })
      .catch((error) => {
        dispatch(UpdateErrorComplaint());
        handleDangerVisible("error");
      });
  };
};

export const requestGetComplaint = () => {
  return {
    type: "REQUEST_GET_COMPLAINT",
  };
};
export const successGetComplaint = (data) => {
  return {
    type: "SUCCESS_GET_COMPLAINT",
    payload: data,
  };
};
export const errorGetComplaint = () => {
  return {
    type: "ERROR_GET_COMPLAINT",
  };
};

export const getComplaint = () => {
  return (dispatch) => {
    dispatch(requestGetComplaint());
    API.get(`/complaint/get_complaint`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetComplaint(getData));
      })
      .catch((error) => {
        dispatch(errorGetComplaint());
      });
  };
};

export const requestGetComplaintById = () => {
  return {
    type: "REQUEST_GET_COMPLAINT_BY_ID",
  };
};
export const successGetComplaintById = (data) => {
  return {
    type: "SUCCESS_GET_COMPLAINT_BY_ID",
    payload: data,
  };
};
export const errorGetComplaintById = () => {
  return {
    type: "ERROR_GET_COMPLAINT_BY_ID",
  };
};
export const getComplaintById = (id, handleWarningVisible) => {
  return (dispatch) => {
    dispatch(requestGetComplaintById());
    API.get(`/shipment/shipment_with_trackingId/${id}`)
      .then((res) => {
        let getData = res.data;
        isEmpty(getData.data) && handleWarningVisible();
        dispatch(successGetComplaintById(getData));
      })
      .catch((error) => {
        dispatch(errorGetComplaintById());
      });
  };
};
export const requestEditComplaintById = () => {
  return {
    type: "REQUEST_EDIT_COMPLAINT_BY_ID",
  };
};
export const successEditComplaintById = (data) => {
  return {
    type: "SUCCESS_EDIT_COMPLAINT_BY_ID",
    payload: data,
  };
};
export const errorEditComplaintById = () => {
  return {
    type: "ERROR_EDIT_COMPLAINT_BY_ID",
  };
};
export const editComplaintById = (id) => {
  return (dispatch) => {
    dispatch(requestEditComplaintById());
    API.get(`/complaint/get_complaint_with_id/${id}`)

      .then((res) => {
        let getData = res.data;
        // isEmpty(getData.data) && handleWarningVisible();
        dispatch(successEditComplaintById(getData));
      })
      .catch((error) => {
        dispatch(errorEditComplaintById());
      });
  };
};

export const setEditComplaint = (data) => {
  return {
    type: "SET_EDIT_COMPLAINT",
    payload: data,
  };
};

export const requestGetComplaintStatus = () => {
  return {
    type: "REQUEST_GET_COMPLAINT_STATUS",
  };
};
export const successGetComplaintStatus = (data) => {
  return {
    type: "SUCCESS_GET_COMPLAINT_STATUS",
    payload: data,
  };
};
export const errorGetComplaintStatus = () => {
  return {
    type: "ERROR_GET_COMPLAINT_STATUS",
  };
};

export const getComplaintStatus = () => {
  return (dispatch) => {
    dispatch(requestGetComplaint());
    API.get(`/complaint/get_complaint_status`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetComplaintStatus(getData));
      })
      .catch((error) => {
        dispatch(errorGetComplaintStatus());
      });
  };
};

export const resetFunction = () => {
  return {
    type: "RESET_DATA",
  };
};
