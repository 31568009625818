const initialState = {
  senderData: [
    {
      id: "",
      name: "",
      company: "",
      country_id: 0,
      address: "",
      address_2: "",
      address_3: "",
      postal_code: "",
      city: "",
      state: "",
      email: "",
      phone: "",
      key: "",
      vatId: "",
    },
  ],
  receiverData: [
    {
      id: "",
      name: "",
      company: "",
      country_id: 0,
      address: "",
      address_2: "",
      address_3: "",
      postal_code: "",
      city: "",
      state: "",
      email: "",
      key: "",
      vatId: "",
      phone: "",
    },
  ],
  shipmentDetail: [
    {
      desc: "",
      code: "",
      quantity: 0,
      uom_id: 1,
      value: 0,
      currency_id: 5,
      weight: 0,
      country_id: 0,
    },
  ],
  shipmentType: "Doc",
  shipmentPurpose: 0,
  shipmentRef: "",
  taxType: 0,
  estimatedDeliveryDate: "",
  instrument: "",
  description: "",
  serviceType: 0,
  shipmentPurposeList: [],
  isFetchingShipmentPurposeList: false,
  payList: [],
  isFetchingPayList: false,
  subStepsCreateShipment: [
    "Select Sender",
    "Select Receiver",
    "Select Package",
  ],
  activeSubSteps: 0,
  showReceiver: false,
  showShipmentDetail: false,
  showPackaging: false,
  isFetchingCargoServiceList: false,
  cargoServiceList: [],
  cargoService: 0,
  isFetchingServiceTypeList: false,
  serviceTypeList: [],
  isFetchingUomList: false,
  uomList: [],
  editSender: true,
  showButtonSender: true,
  editReceiver: true,
  showButtonReceiver: true,
  rebate: 1,
  invoiceType: 1,
  sro: "",
  instrument_No: "",
  instrument_bank: "",
};

const CreateShipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_REDUCER_SHIPMENT":
      return {
        ...state,
        senderData: [
          {
            id: "",
            name: "",
            company: "",
            country_id: 0,
            address: "",
            address_2: "",
            address_3: "",
            postal_code: "",
            city: "",
            state: "",
            email: "",
            phone: "",
            key: "",
            vatId: "",
            cnic: "",
          },
        ],
        receiverData: [
          {
            id: "",
            name: "",
            company: "",
            country_id: 0,
            address: "",
            address_2: "",
            address_3: "",
            postal_code: "",
            city: "",
            state: "",
            email: "",
            key: "",
            vatId: "",
            phone: "",
          },
        ],
        shipmentDetail: [
          {
            desc: "",
            code: "",
            quantity: 0,
            uom_id: 1,
            value: 0,
            currency_id: 5,
            weight: 0,
            country_id: "",
          },
        ],
        shipmentType: "Doc",
        shipmentPurpose: 0,
        shipmentRef: "",
        taxType: 0,
        estimatedDeliveryDate: "",
        instrument: "",
        description: "",
        serviceType: 0,
        // shipmentPurposeList: [],
        // payList: [],
        rebate: 1,
        invoiceType: 1,
        sro: "",
        instrument_No: "",
        instrument_bank: "",
      };
    case "SET_ADD_SENDER_DATA":
      let valueEmp = state.senderData[0];
      let keyEmp = action.payload.key;
      valueEmp[keyEmp] = action.payload.value;
      return {
        ...state,
        senderData: [valueEmp],
      };
    case "SET_ADD_RECEIVER_DATA":
      let valueReceiver = state.receiverData[0];
      let keyReceiver = action.payload.key;
      valueReceiver[keyReceiver] = action.payload.value;
      return {
        ...state,
        receiverData: [valueReceiver],
      };
    case "SET_PURPOSE":
      return {
        ...state,
        shipmentPurpose: action.payload,
      };
    case "SET_TAX_TYPE":
      return {
        ...state,
        taxType: action.payload,
      };

    case "SET_ESTIMATED_DATE":
      return {
        ...state,
        estimatedDeliveryDate: action.payload,
      };
    case "SET_INSTRUMENT":
      return {
        ...state,
        instrument: action.payload,
      };
    case "SET_REBATE":
      return {
        ...state,
        rebate: action.payload,
      };
    case "SET_INVOICE_TYPE":
      return {
        ...state,
        invoiceType: action.payload,
      };

    case "SET_SRO":
      return {
        ...state,
        sro: action.payload,
      };
    case "SET_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };

    case "SET_SHIPMENT_REF":
      return {
        ...state,
        shipmentRef: action.payload,
      };
    case "SET_EDITABLE":
      return {
        ...state,
        editSender: action.payload,
      };
    case "SET_SHOW_BUTTON_SENDER":
      return {
        ...state,
        showButtonSender: action.payload,
      };
    case "SET_SHOW_BUTTON_RECEIVER":
      return {
        ...state,
        showButtonReceiver: action.payload,
      };
    case "SET_NO":
      return {
        ...state,
        instrument_No: action.payload,
      };
    case "SET_BANK":
      return {
        ...state,
        instrument_bank: action.payload,
      };

    case "SET_EDITABLE_RECEIVER":
      return {
        ...state,
        editReceiver: action.payload,
      };

    case "SET_SHOW_RECEIVER":
      return {
        ...state,
        showReceiver: action.payload,
      };
    case "SET_SHOW_SHIPMENT_DETAIL":
      return {
        ...state,
        showShipmentDetail: action.payload,
      };
    case "SET_SHOW_PACKAGING":
      return {
        ...state,
        showPackaging: true,
      };

    case "SET_ADD_INPUT_SHIPMENT_DETAIL":
      return {
        ...state,
        shipmentDetail: [
          ...state.shipmentDetail,
          {
            desc: "",
            code: "",
            quantity: 0,
            uom_id: 1,
            value: 0,
            currency_id: "",
            weight: 0,
            country_id: "",
          },
        ],
      };
    // const list = [...inputField1];
    // list.splice(index, 1);
    // setInputField1(list);
    case "SET_REMOVE_INPUT_SHIPMENT_DETAIL":
      let prevList = [...state.shipmentDetail];
      prevList.splice(action.payload, 1);
      return {
        ...state,
        shipmentDetail: prevList,
      };

    case "SET_ADD_SHIPMENT_DETAIL":
      // list[index]["product"]
      let list = [...state.shipmentDetail];
      // let keyPackages11= action.payload.key;

      list[action.payload.length][action.payload.key] = action.payload.value;

      return {
        ...state,
        shipmentDetail: list,
      };

    case "NEXT_SUB_STEPS":
      let prevActiveStep = state.activeSubSteps;
      let activeStep = prevActiveStep + 1;
      // valuePackages[keyPackages] = action.payload.value;
      return {
        ...state,
        activeSubSteps: activeStep,
      };
    case "BACK_SUB_STEPS":
      let prevActiveStep1 = state.activeSubSteps;
      let activeStep1 = prevActiveStep1 - 1;
      // valuePackages[keyPackages] = action.payload.value;
      return {
        ...state,
        activeSubSteps: activeStep1,
      };
    case "START_STEPS":
      // let prevActiveStep1 = state.activeSubSteps;
      // let activeStep1= prevActiveStep1-1;
      // valuePackages[keyPackages] = action.payload.value;
      return {
        ...state,
        activeSubSteps: 0,
      };
    case "SET_TYPE":
      // let prevActiveStep1 = state.activeSubSteps;
      // let activeStep1= prevActiveStep1-1;
      // valuePackages[keyPackages] = action.payload.value;
      return {
        ...state,
        shipmentType: action.payload,
        // shipmentPurpose: 0,
      };

    case "REQUEST_GET_SHIPMENT_PURPOSE":
      return { ...state, isFetchingShipmentPurposeList: true };
    case "SUCCESS_GET_SHIPMENT_PURPOSE":
      return {
        ...state,
        shipmentPurposeList: action.payload.data,
        isFetchingShipmentPurposeList: false,
      };
    case "ERROR_GET_SHIPMENT_PURPOSE":
      return { ...state, isFetchingShipmentPurposeList: false };
    case "REQUEST_GET_PAY_LIST":
      return { ...state, isFetchingPayList: true };
    case "SUCCESS_GET_PAY_LIST":
      return {
        ...state,
        payList: action.payload.data,
        isFetchingPayList: false,
      };
    case "ERROR_GET_PAY_LIST":
      return { ...state, isFetchingPayList: false };
    case "REQUEST_GET_CARGO_SERVICE":
      return { ...state, isFetchingCargoServiceList: true };
    case "SUCCESS_GET_CARGO_SERVICE":
      return {
        ...state,
        cargoServiceList: action.payload.data,
        isFetchingCargoServiceList: false,
      };
    case "ERROR_GET_CARGO_SERVICE":
      return { ...state, isFetchingCargoServiceList: false };
    case "SET_CARGO_SERVICE":
      return {
        ...state,
        cargoService: action.payload,
      };
    case "REQUEST_GET_SERVICE_TYPE":
      return { ...state, isFetchingServiceTypeList: true };
    case "SUCCESS_GET_SERVICE_TYPE":
      return {
        ...state,
        serviceTypeList: action.payload.data,
        isFetchingServiceTypeList: false,
      };
    case "ERROR_GET_SERVICE_TYPE":
      return { ...state, isFetchingServiceTypeList: false };
    case "SET_SERVICE_TYPE":
      return {
        ...state,
        serviceType: action.payload,
      };
    case "REQUEST_GET_UOM":
      return { ...state, isFetchingUomList: true };
    case "SUCCESS_GET_UOM":
      return {
        ...state,
        uomList: action.payload.data,
        isFetchingUomList: false,
      };
    case "ERROR_GET_UOM":
      return { ...state, isFetchingUomList: false };
    default:
      return state;
  }
};

export default CreateShipmentReducer;
