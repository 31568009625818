import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import {
  AddTransactionSummary,
} from "./TransactionSummary.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "lodash";
import Select from "react-select";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { GetVoucher } from "../Vouchers/Voucher.action";
import { setHeading } from "../../accounts/account.actions";

const TransactionSummary = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [voucher, setVoucher] = useState("");
  const [voucherId, setVoucherId] = useState("");


  const voucherData = !isEmpty(props.voucherList) && props.voucherList.map((x) => { let data = { value: x._id, accType: x.voucherTypeId, label: x.abbrevation }; return data });
  const handleChangeAccountNo = (selectedOption) => {
    setVoucher(selectedOption.label);
    setVoucherId(selectedOption.value);

  };
  useEffect(() => {
    props.AddTransactionSummary({
      startedDate: "",
      endDate: "",
      voucher: "",
    });
    props.getVouchers();
    props.setHeading("Transaction Summary")
    // props.GetVoucher();
  });
  function searchData() {
    props.AddTransactionSummary({
      startedDate: startDate,
      endDate: endDate,
      voucher: voucher,
    });
  }
  return (
    <>
      {props.isFetchingVoucherList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>

              <Col lg="5" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Voucher Type *</b>
                  </Form.Label>

                  <Select
                    styles={{ overflow: "scroll" }}
                    placeholder="Select Voucher Type..."
                    onChange={handleChangeAccountNo}
                    options={voucherData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Col lg="12" sm="12" className="text-center">
                {voucherId === "" ? (
                  <Button
                    disabled


                  >
                    Search
                  </Button>
                ) : (
                  <Link
                    to={{
                      pathname: "/accounts/transaction-summary-details",
                      state: { start: startDate, end: endDate },
                    }}
                  >
                    <Button

                      onClick={(e) => searchData()}
                    >
                      Search
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>

          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddTransactionSummary: (data) => dispatch(AddTransactionSummary(data)),
    getVouchers: () => dispatch(GetVoucher()),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  voucherList: state.VoucherReducer.voucherList,
  isFetchingVoucherList: state.VoucherReducer.isFetchingVoucherList,
  // voucherListData: state.TransactitonSummaryReducer.voucherListData,
  summary: state.TransactitonSummaryReducer.summary,
  // isFetchingGetVouchers: state.TransactitonSummaryReducer.isFetchingGetVouchers,
  isFetchingTransactionSummary:
    state.TransactitonSummaryReducer.isFetchingTransactionSummary,
  // voucherList: state.VoucherReducer.voucherList,
  // isFetchingVoucherList: state.VoucherReducer.isFetchingVoucherList,
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSummary);
