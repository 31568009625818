import API from "../../../global/api";
export const SET_HEADING_KEY = "SET_HEADING_KEY";
export const requestGetUsers = () => {
  return {
    type: "REQUEST_GET_Users",
  };
};
export const successGetUsers = (data) => {
  return {
    type: "SUCCESS_GET_Users",
    payload: data,
  };
};
export const errorGetUsers = () => {
  return {
    type: "ERROR_GET_Users",
  };
};

export const getUsers = () => {
  return (dispatch) => {
    dispatch(requestGetUsers());
    API.get(`/user_cargo/get_admin_and_employee_user`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetUsers(getData));
      })
      .catch((error) => {
        dispatch(errorGetUsers());
      });
  };
};

export const requestGetPermissions = () => {
  return {
    type: "REQUEST_GET_Permissions",
  };
};
export const successGetPermissions = (data) => {
  return {
    type: "SUCCESS_GET_Permissions",
    payload: data,
  };
};
export const errorGetPermissions = () => {
  return {
    type: "ERROR_GET_Permissions",
  };
};

export const getPermissions = () => {
  return (dispatch) => {
    dispatch(requestGetPermissions());
    API.get(`/cargo_permission/get_all_permission`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetPermissions(getData));
      })
      .catch((error) => {
        dispatch(errorGetPermissions());
      });
  };
};

export const requestAddPermissions = () => {
  return {
    type: "REQUEST_ADD_Permissions",
  };
};
export const successAddPermissions = (data) => {
  return {
    type: "SUCCESS_ADD_Permissions",
    payload: data,
  };
};
export const errorAddPermissions = () => {
  return {
    type: "ERROR_ADD_Permissions",
  };
};

export const addPermissions = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddPermissions());
    API.post(`/cargo_permission/add_permission_data`, data)
      .then((res) => {
        handleVisible();
        let getData = res.data;
        dispatch(successAddPermissions(getData));
      })
      .catch((error) => {
        handleDangerVisible();
        dispatch(errorAddPermissions());
      });
  };
};

export const setHeading = (data) => {
  return {
    type: "SET_HEADING_KEY",
    payload: data,
  };
};
export const requestGetUsersById = () => {
  return {
    type: "REQUEST_GET_Users_BY_ID",
  };
};
export const successGetUsersById = (data) => {
  return {
    type: "SUCCESS_GET_Users_BY_ID",
    payload: data,
  };
};
export const errorGetUsersById = () => {
  return {
    type: "ERROR_GET_Users_BY_ID",
  };
};

export const getUsersById = (id) => {
  return (dispatch) => {
    dispatch(requestGetUsersById());
    API.get(`/cargo_permission/get_user_permission/${id}`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetUsersById(getData));
      })
      .catch((error) => {
        dispatch(errorGetUsersById());
      });
  };
};
export const setUsersByIdChecked = (data) => {
  return {
    type: "SET_Users_BY_ID_CHECKED",
    payload: data,
  };
};
