const initialState = {
  isFetchingCountry:false,
  countryList:[],
  country:[],
  isAddingCountry:false,

};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_COUNTRY":
      return { ...state, isFetchingCountry: true };
    case "SUCCESS_GET_COUNTRY":
      return {
        ...state,
        countryList: action.payload.data,
        isFetchingCountry: false,
      };
    case "ERROR_GET_COUNTRY":
      return { ...state, isFetchingCountry: false };

    case "REQUEST_ADD_COUNTRY":
      return { ...state, isAddingCountry: true };
    case "SUCCESS_ADD_COUNTRY":
      return {
        ...state,
        country: action.payload,
        isAddingCountry: false,
      };
    case "ERROR_ADD_COUNTRY":
      return { ...state, isAddingCountry: false };

    default:
      return state;
  }
};

export default countryReducer;