const initialState = {
  usersList: [],
  permissionsList: [],
  addPermissions: [],
  permissionsByUserIdChecked:[],
  isFetchingPermissionsByUserIdChecked: false,
  isFetchingPermissionsList: false,
  isAddingPermissions: false,
  heading: "Cargo",
  permissionsByUserId: [],
  isFetchingPermissionsByUserId: false,
};

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_Users":
      return { ...state, isFetchingUsersList: true };
    case "SUCCESS_GET_Users":
      return {
        ...state,
        usersList: action.payload,
        isFetchingUsersList: false,
      };
    case "ERROR_GET_Users":
      return { ...state, isFetchingUsersList: false };
    case "REQUEST_GET_Users_BY_ID":
      return { ...state, isFetchingPermissionsByUserId: true, permissionsByUserId:[] };
    case "SUCCESS_GET_Users_BY_ID":
      let editChecked = state.permissionsByUserId;
      action.payload.map((x, i) => {
        console.log(x, 'ttttttttttttttttttttttttttmapp')
        editChecked.push({
          permissions_name: x.permissions_name,
          permissions_cat_name: x.permissions_cat_name,
        });
      });
      return {
        ...state,
        permissionsByUserId: action.payload,
        permissionsByUserIdChecked:editChecked,
        isFetchingPermissionsByUserId: false,
      };
    case "ERROR_GET_Users_BY_ID":
      return { ...state, isFetchingPermissionsByUserId: false };

    case "REQUEST_GET_Permissions":
      return { ...state, isFetchingPermissionsList: true };
    case "SUCCESS_GET_Permissions":
      return {
        ...state,
        permissionsList: action.payload,
        permissionsByUserIdChecked:[],
        isFetchingPermissionsList: false,
      };
    case "ERROR_GET_Permissions":
      return { ...state, isFetchingPermissionsList: false };

    case "REQUEST_ADD_Permissions":
      return { ...state, isAddingPermissions: true };
    case "SUCCESS_ADD_Permissions":
      return {
        ...state,
        addPermissions: action.payload,
        isAddingPermissions: false,
      };
    case "ERROR_ADD_Permissions":
      return { ...state, isAddingPermissions: false };
    case "SET_HEADING_KEY":
      return { ...state, heading: action.payload };
      case "SET_Users_BY_ID_CHECKED":
        // let editChecked = state.permissionsByUserId;
        // action.payload.map((x, i) => {
        //   console.log(x, 'ttttttttttttttttttttttttttmapp')
        //   editChecked.push({
        //     permissions_name: x.permissions_name,
        //     permissions_cat_name: x.permissions_cat_name,
        //   });
        // });
        return {
          ...state,
          // permissionsByUserId: action.payload,
          permissionsByUserIdChecked:action.payload,
          // isFetchingPermissionsByUserId: false,
        };
    default:
      return state;
  }
};
export default permissionReducer;
