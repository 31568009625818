import API from "../../../global/api";
import logo from "../../../Images/logo.png";
import jsPDF from "jspdf";
import { appendNonSignificantZeros } from "../../../global/globalFunctions";
import moment from "moment";
import { isEmpty, map,sumBy, isNull } from "lodash";
//Action types
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const SET_MODULE_KEY = "SET_MODULE_KEY";
export const SET_HEADING_KEY = "SET_HEADING_KEY";

const sumArray = (array) => {
  const newArray = [];
  array.forEach((sub) => {
    sub.forEach((num, index) => {
      if (newArray[index]) {
        newArray[index] += num;
      } else {
        newArray[index] = num;
      }
    });
  });
  return newArray;
};
// Request Actions

export const loginUserRequest = () => {
  return {
    type: "LOGIN_USER_REQUEST",
  };
};
export const loginUserSuccess = (data) => {
  return {
    type: "LOGIN_USER_SUCCESS",
    payload: data,
  };
};
export const loginUserError = (msg) => {
  return {
    type: "LOGIN_USER_ERROR",
    payload:msg
  };
};


export const setModuleKey = (data) => {
  return {
    type: "SET_MODULE_KEY",
    payload: data,
  };
};
export const setHeading = (data) => {
  console.log(data,"www")
  return {
    type: "SET_HEADING_KEY",
    payload: data,
  };
};

export const changeIsWrong = () => {
  return {
    type: "CHANGE_IS_WRONG",
  };
};

export const getLogin = (username,handleDangerVisible) => {
  return (dispatch) => {
    dispatch(loginUserRequest())
    API.post(`/users/login`, username).then((res) => {
      let data1 = res.data;
      console.log(res.data, "rddddddd")
      dispatch(loginUserSuccess(data1));

      window.localStorage.setItem("res", res.message);
      window.localStorage.setItem("token", data1.token);
      window.localStorage.setItem("userId", data1._id);
      window.localStorage.setItem("isAuthenticatedUser", "true")

      window.localStorage.setItem("isUser", "true");
      window.localStorage.setItem("isAdmin", false);

      window.localStorage.setItem("isAuthenticated", "true");
      window.localStorage.setItem("permissions", JSON.stringify(data1.permission));

     window.location.replace('/home');

    }).catch((error) => {
      console.log(error, "rddddddd")
      dispatch(loginUserError(error.response.data.message))
      handleDangerVisible(error.response.data.message)
    });
  };
};



export const requestTrialData = () => {
  return {
    type: "REQUEST_TRIAL_DATA",
  };
};
export const successTrialData = (data) => {
  return {
    type: "SUCCESS_TRIAL_DATA",
    payload: data,
  };
};
export const errorTrialData = () => {
  return {
    type: "ERROR_TRIAL_DATA",
  };
};

export const getTrialData = (data) => {
  return (dispatch) => {
    dispatch(requestTrialData());
    API.post(`/reports/trail_balance`, data).then((res) => {
      let getData = res.data;
      console.log(getData,"trail")
      dispatch(successTrialData(getData));
    }).catch((error) => {
      dispatch(errorTrialData());
    })
  };
};


export const requestGetLevel5 = () => {
  return {
    type: "REQUEST_GET_LEVEL_5",
  };
};
export const successGetLevel5 = (data) => {
  return {
    type: "SUCCESS_GET_LEVEL_5",
    payload: data,
  };
};
export const errorGetLevel5 = () => {
  return {
    type: "ERROR_GET_LEVEL_5",
  };
};

export const getLevel5 = () => {
  return (dispatch) => {
    dispatch(requestGetLevel5());
    API.get(`/voucher-ledger/get-acc-ledger`).then((res) => {
      let getData = res.data;
      dispatch(successGetLevel5(getData));
    }).catch((error) => {
      dispatch(errorGetLevel5());
    })
  };
};

export const getTrialDataPdf = (data) => {
  return (dispatch) => {
    console.log(data, "checkDate");
    dispatch(requestTrialData());
    API.post(`/trial_balance/get_trial_balance_pdf`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "ccc");
        dispatch(successTrialData(getData));
        console.log(getData, "trialData");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape
         let total=sumBy(getData,"levelBalance")
         console.log(total,"abcdefgh")
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        let yMargin = 190;
        doc.setFontSize(14);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const Dates =
          !isEmpty(data.startedDate) || !isEmpty(data.endDate)
            ? "Form: " + " " + data.startedDate + " to " + data.endDate
            : "Form: -  To: - ";
        doc.addImage(logo, "PNG", 40, 35, 70, 70);
        doc.setFont("Times New Roman", "bolditalic");
        doc.text(companyName, 225, 60, 0, 20);
        doc.text("Trial Balance Report", 225, 75, 0, 20);
        doc.text(Dates, 200, 90, 0, 20);
        doc.text(`Grand Total : ${total.toLocaleString()}`,400,110)
        map(getData, (x, index) => {
          console.log(index, "trialDatamap");
          console.log(x, "pdfData");
          let contentLevelTwo = {
            startY: index == 0 ? 130 : doc.lastAutoTable.finalY + 15,
            startX: 10,
            head: [
              [
                `${x.level_twos[0].allKey}-${x.level_twos[0].levelTwoName}`,
                `${x.level_twos[0].levelBalance.toLocaleString()}  ${x.level_twos[0].levelBalanceSign}`,
              ],
            ],
            body: [],
            columnStyles: {
              0: { cellWidth: 500 },
              1: { cellWidth: 100 },
            },
          };
          doc.autoTable(contentLevelTwo);
          map(x.level_twos[0].level_threes, (x3, index3) => {
            console.log(x3, "kkkkkkkkkkkkkkkkkkkkkkkkk");
   
            let contentLevelthree = {
              startY:
                index3 == 0
                  ? doc.lastAutoTable.finalY + 2
                  : doc.lastAutoTable.finalY + 30,
              startX: 10,
              
              head: [
                [`${x3.allLvlKey}-${x3.levelThreeName}`, `${x3.levelBalance.toLocaleString()}  ${x3.levelBalanceSign}`],
              ],
              body: [],
              columnStyles: {
                0: { cellWidth: 500 },
                1: { cellWidth: 100 },
              },
              // headStyles: { fillColor: [255,255,255], color: }
            };
            doc.autoTable(contentLevelthree);
            map(x3.level_fours, (x4, index4) => {
              let contentLevelFour = {
                startY:
                  index4 == 0
                    ? doc.lastAutoTable.finalY + 2
                    : doc.lastAutoTable.finalY + 5,
                startX: 10,
                head: [
                  [
                    `${x4.allLevlKey}-${x4.levelFourName}`,
                    `${x4.levelBalance.toLocaleString()}  ${x4.levelBalanceSign}`,
                  ],
                ],
                body: [],
                columnStyles: {
                  0: { cellWidth: 500 },
                  1: { cellWidth: 100 },
                },
              };
              doc.autoTable(contentLevelFour);
              ///----
              let data5 = [];
              let totalData = [];
              map(x4.level_fives, (x5, index5) => {
                console.log(x5, "iiiiiiiiiiiiiiiiiiiiiiiiiiiii");
                let rowData = x5;
                let newArray = [];
                let newArrayTotalData = [];
                newArray.push(x5.allLevelKey);
                newArray.push(x5.levelFiveName);
         
                newArray.push(
                  Number(x5.transactions[0].opening_balance_sign) == 2
                    ? `${x5.transactions[0].opening_balance}`
                    : 0
                );
                newArray.push(
                  Number(x5.transactions[0].opening_balance_sign) == 1
                    ? `${x5.transactions[0].opening_balance}`
                    : 0
                );
                newArray.push(
                  `${x5.transactions[0].debit_sum}`

                 
                );
                newArray.push(
                  `${x5.transactions[0].credit_sum}`
                 
                );
                newArray.push(
                  Number(x5.transactions[0].closing_balance_sign) == 2
                    ? `${x5.transactions[0].closing_balance}`
                    : 0
                 
                );
                newArray.push(
                  Number(x5.transactions[0].closing_balance_sign) == 1
                    ? `${x5.transactions[0].closing_balance}`
                    : 0
                 
                );
                data5.push(newArray);
                ///Adding For getting Total
                newArrayTotalData.push(
                  Number(x5.transactions[0].opening_balance_sign) == 2
                    ? x5.transactions[0].opening_balance
                    : 0
                );
                newArrayTotalData.push(
                  Number(x5.transactions[0].opening_balance_sign) == 1
                    ? x5.transactions[0].opening_balance
                    : 0
                );
                newArrayTotalData.push(
                  x5.transactions[0].debit_sum
        
                );
                newArrayTotalData.push(
                  x5.transactions[0].credit_sum
            
                );
                newArrayTotalData.push(
                  Number(x5.transactions[0].closing_balance_sign) == 2
                    ? x5.transactions[0].closing_balance
                    : 0
                
                );
                newArrayTotalData.push(
                  Number(x5.transactions[0].closing_balance_sign) == 1
                    ? x5.transactions[0].closing_balance
                    : 0
                 
                );
                totalData.push(newArrayTotalData);
            
              });

              totalData = sumArray(totalData);
              !isEmpty(data5) &&
                data5.push([
                  "",
                  "",
                  `${totalData[0]}`,
                  `${totalData[1]}`,
                  `${totalData[2]}`,
                  `${totalData[3]}`,
                  `${totalData[4]}`,
                  `${totalData[5]}`,
                  `${totalData[6]}`,
                ]);

              let contentLevelFive = {
                startY: doc.lastAutoTable.finalY + 2,
                startX: 10,
                head: [
                  [
                    "CODE",
                    "DESCRIPTIPON",
                    "OPENING DR",
                    "OPENING CR",
                    "DURING DR",
                    "DURING CR",
                    "CLOSING DR",
                    "CLOSING CR",
                  ],
                ],
                body: data5,
                tableLineColor: [189, 195, 199],
                tableLineWidth: 0.75,
       
                alternateRowStyles: { fillColor: [231, 215, 252] },
             
              };
              doc.autoTable(contentLevelFive);
             
            
            });
          });
        });
        doc.save(
          `Trial Balance Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
      })
      .catch((error) => {
        dispatch(errorTrialData());
      });
  };
};
export const getChartOfAccountPdf = (data) => {
  return (dispatch) => {
    console.log(data, "checkDate");
    dispatch(requestTrialData());
    API.post(`/chart_of_account/chart_of_account_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "ccc");
        dispatch(successTrialData(getData));
        console.log(getData, "trialData");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape
        let total=sumBy(getData,"levelBalance")
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        let yMargin = 190;
        doc.setFontSize(14);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const Dates =
          !isEmpty(data.startedDate) || !isEmpty(data.endDate)
            ? "Form: " +
             
              moment(data.startedDate).format("DD-MM-YYYY") +
              " to " +
              moment(data.endDate).format("DD-MM-YYYY")
            : "Form: -  To: - ";
        doc.addImage(logo, "PNG", 40, 35, 70, 70);
        doc.setFont("Times New Roman", "bolditalic");
        doc.text(companyName, 225, 60, 0, 20);
        doc.text("Chart of Account", 225, 75, 0, 20);
        doc.text(Dates, 200, 90, 0, 20);
        doc.text(`Grand Total : ${total.toLocaleString()}`,400,110)
        map(getData, (x, index) => {
          console.log(index, "trialDatamap");
          // const data = getData[0].trial_bals.map((elt) => [moment(getData[0].createdAt).format("YYYY-MM-DD"), getData[0].debitCreditId == 1 ? elt.transCredit : 0, getData[0].debitCreditId == 2 ? elt.transDebit : 0, getData[0].levelBalance, getData[0].debit_credit.accountName ])
          console.log(x, "pdfData");
          let contentLevelTwo = {
            startY: index == 0 ? 130 : doc.lastAutoTable.finalY + 15,
            startX: 10,
            head: [
              [
                `${x.level_twos[0].allKey}-${x.level_twos[0].levelTwoName}`,
                `${x.level_twos[0].levelBalance.toLocaleString()}  ${x.level_twos[0].levelBalanceSign}`,
              ],
            ],
            body: [],
            columnStyles: {
              0: { cellWidth: 500 },
              1: { cellWidth: 100 },
            },
          };
          doc.autoTable(contentLevelTwo);
          map(x.level_twos[0].level_threes, (x3, index3) => {
            console.log(x3, "kkkkkkkkkkkkkkkkkkkkkkkkk");
            let contentLevelthree = {
              startY:
                index3 == 0
                  ? doc.lastAutoTable.finalY + 2
                  : doc.lastAutoTable.finalY + 30,
              startX: 10,
              head: [
                [`${x3.allLvlKey}-${x3.levelThreeName}`, `${x3.levelBalance.toLocaleString()}  ${x3.levelBalanceSign}`],
              ],
              body: [],
              columnStyles: {
                0: { cellWidth: 500 },
                1: { cellWidth: 100 },
              },
              // headStyles: { fillColor: [255,255,255], color: }
            };
            doc.autoTable(contentLevelthree);
            map(x3.level_fours, (x4, index4) => {
              let contentLevelFour = {
                startY:
                  index4 == 0
                    ? doc.lastAutoTable.finalY + 2
                    : doc.lastAutoTable.finalY + 5,
                startX: 10,
                head: [
                  [
                    `${x4.allLevlKey}-${x4.levelFourName}`,
                    `${x4.levelBalance.toLocaleString()}  ${x4.levelBalanceSign}`,
                  ],
                ],
                body: [],
                columnStyles: {
                  0: { cellWidth: 500 },
                  1: { cellWidth: 100 },
                },
                // headStyles: { fillColor: [220,220,220] }
              };
              doc.autoTable(contentLevelFour);
              ///----
              let data5 = [];
              let totalData = [];
              map(x4.level_fives, (x5, index5) => {
                console.log(x5, "iiiiiiiiiiiiiiiiiiiiiiiiiiiii");
                let rowData = x5;
                let newArray = [];
                let newArrayTotalData = [];
                newArray.push(x5.allLevelKey);
                newArray.push(x5.levelFiveName);

                // newArray.push(
                //   Number(x5.transactions[0].opening_balance_sign) == 2
                //     ? `${x5.transactions[0].opening_balance}`
                //     : 0
                // );
                // newArray.push(
                //   Number(x5.transactions[0].opening_balance_sign) == 1
                //     ? `${x5.transactions[0].opening_balance}`
                //     : 0
                // );
                // newArray.push(
                //   `${x5.transactions[0].debit_sum}`
                // );
                // newArray.push(
                //   `${x5.transactions[0].credit_sum}`
                // );
                newArray.push(
                  Number(x5.transactions[0].closing_balance_sign) == 2
                    ? `${x5.transactions[0].closing_balance}`
                    : 0
                );
                newArray.push(
                  Number(x5.transactions[0].closing_balance_sign) == 1
                    ? `${x5.transactions[0].closing_balance}`
                    : 0
                );
                data5.push(newArray);
                ///Adding For getting Total
                // newArrayTotalData.push(
                //   Number(x5.transactions[0].opening_balance_sign) == 2
                //     ? x5.transactions[0].opening_balance
                //     : 0

                //   // x5.open_blc_type == 2 ? !isEmpty(x5) && x5.open_blc : 0
                // );
                // newArrayTotalData.push(
                //   Number(x5.transactions[0].opening_balance_sign) == 1
                //     ? x5.transactions[0].opening_balance
                //     : 0
                //   // x5.open_blc_type == 1 ? !isEmpty(x5) && x5.open_blc : 0
                // );
                // newArrayTotalData.push(
                //   x5.transactions[0].debit_sum
                // );
                // newArrayTotalData.push(
                //   x5.transactions[0].credit_sum
                // );
                newArrayTotalData.push(
                  Number(x5.transactions[0].closing_balance_sign) == 2
                    ? x5.transactions[0].closing_balance
                    : 0
                );
                newArrayTotalData.push(
                  Number(x5.transactions[0].closing_balance_sign) == 1
                    ? x5.transactions[0].closing_balance
                    : 0
                );
                totalData.push(newArrayTotalData);
                // let dt= x4.level_fives
              });

              totalData = sumArray(totalData);
              !isEmpty(data5) &&
                data5.push([
                  "",
                  "",
                  `${totalData[0]}`,
                  `${totalData[1]}`,
                  // `${totalData[2]}`,
                  // `${totalData[3]}`,
                  // `${totalData[4]}`,
                  // `${totalData[5]}`,
                  // `${totalData[6]}`,
                ]);

              let contentLevelFive = {
                startY: doc.lastAutoTable.finalY + 2,
                startX: 10,
                head: [
                  [
                    "CODE",
                    "DESCRIPTIPON",
                    // "OPENING DR",
                    // "OPENING CR",
                    // "DURING DR",
                    // "DURING CR",
                    "Debit",
                    "Credit",
                  ],
                ],
                body: data5,
                tableLineColor: [189, 195, 199],
                tableLineWidth: 0.75,
                // theme: "grid",
                alternateRowStyles: { fillColor: [231, 215, 252] },
                //   headStyles: { fillColor: [210, 232, 16] }
                // headStyles: {
                //   fillColor: [139, 0, 0],
                // },
              };
              doc.autoTable(contentLevelFive);

              //  !isEmpty(data5) && doc.text(`Total Closing Balance: ${totalData[0]}, ${totalData[1]}, ${totalData[2]},${totalData[3]}, ${totalData[4]}, ${totalData[5]}`,50, doc.lastAutoTable.finalY + 15)
              ///----
              // console.log(doc.getVerticalCoordinateString(), "Eee")
              //  doc.text("Total",200, doc.lastAutoTable.finalY + 46);

              // closing of last map })
            });
          });
        });
        doc.save(
          `Chart of Account ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
        
       
      })
      .catch((error) => {
        dispatch(errorTrialData());
      });
  };
};