import API from "../../../../global/api";
import { get } from "lodash";
import logo from "../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";
//LEVEL 1
export const requestLevel1GetData = () => {
  return {
    type: "REQUEST_LEVEL1_GET_DATA",
  };
};
export const successLevel1GetData = (data) => {
  return {
    type: "SUCCESS_LEVEL1_GET_DATA",
    payload: data,
  };
};
export const errorLevel1GetData = () => {
  return {
    type: "ERROR_LEVEL1_GET_DATA",
  };
};

export const getLevel1Data = () => {
  return (dispatch) => {
    dispatch(requestLevel1GetData());
    API.get(`/levelOne/get_all_levelone`)
      .then((res) => {
        let getData = res.data.Name;
        dispatch(successLevel1GetData(getData));
      })
      .catch((error) => {
        dispatch(errorLevel1GetData());
      });
  };
};

export const getLevelOneName = (data) => {
  return (dispatch) => {
    dispatch(requestLevelOneData());
    API.get(`/levelTwo/get-by-levelOne/${data}`)
      .then((res) => {
        dispatch(successLevelOneData(res.data));
      })
      .catch((error) => {
        dispatch(errorAddLevelOne());
      });
  };
};

export const requestLevelOneData = () => {
  return {
    type: "REQUEST_LEVEL1_DATA",
  };
};
export const successLevelOneData = (data) => {
  return {
    type: "SUCCESS_LEVEL1_DATA",
    payload: data,
  };
};
export const errorAddLevelOne = () => {
  return {
    type: "ERROR_LEVEL1_DATA",
  };
};

export const getLevelTwoName = (data) => {
  return (dispatch) => {
    dispatch(requestLevelTwoData());

    API.get(`/levelThree/get-by-levelTwo/${data}`)
      .then((res) => {
        dispatch(successLevelTwoData(res.data));
      })
      .catch((error) => {
        dispatch(errorAddLevelTwo());
      });
  };
};

export const getLevelFourName = (data) => {
  return (dispatch) => {
    dispatch(requestLevelFourData());

    API.get(`levelFour/get-by-levelThree/${data}`)
      .then((res) => {
        // dispatch(getLevel2Data());
        dispatch(successLevelFourData(res.data));
      })
      .catch((error) => {
        dispatch(errorAddLevelFour());
      });
  };
};

export const requestLevelFourData = () => {
  return {
    type: "REQUEST_LEVEL4_DATA",
  };
};
export const successLevelFourData = (data) => {
  return {
    type: "SUCCESS_LEVEL4_DATA",
    payload: data,
  };
};
export const errorAddLevelFour = () => {
  return {
    type: "ERROR_LEVEL4_DATA",
  };
};

export const requestLevelTwoData = () => {
  return {
    type: "REQUEST_LEVEL2_DATA",
  };
};
export const successLevelTwoData = (data) => {
  return {
    type: "SUCCESS_LEVEL2_DATA",
    payload: data,
  };
};
export const errorAddLevelTwo = () => {
  return {
    type: "ERROR_LEVEL2_DATA",
  };
};
export const requestGenerateReport = () => {
  return {
    type: "REQUEST_GENERATE_REPORT",
  };
};
export const successGenerateReport = (data) => {
  return {
    type: "SUCCESS_GENERATE_REPORT",
    payload: data,
  };
};
export const errorGenerateReport = () => {
  return {
    type: "ERROR_GENERATE_REPORT",
  };
};

export const SearchGenerateReport = (Op, Cl) => {
  return (dispatch) => {
    dispatch(requestGenerateReport());
    //dispatch(requestLevel4GetData());http://192.168.1.15:3001/api/profit_loss/get_profit_and_loss
    API.post(`/reports/profit_loss`)
      .then((res) => {
        console.log(Op, Cl, "aaaaaa");
        let getData = res.data;
        //    let bookingData=getData[0].booking;
        console.log(getData, "rrrrr");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        const title = "Generate Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1=getData.restaurantImage;

        doc.addImage(logo1, "PNG", 40, 5, 130, 130);
        doc.setFontSize(18);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 60, 0, 0);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 80, 0, 20);
        doc.text(companyNtn, 150, 100, 0, 20);
        doc.text(title, 260, 120, 0, 0);
        //   doc.text("Joe's MotorBike Tyres",240,20,0,0);
        doc.setLineWidth(2);
        doc.line(20, 125, 575, 125);
        doc.text("Profit and Loss Statement", 235, 140, 0, 0);
        doc.line(20, 145, 575, 145);
        doc.text("For the period ended 2022", 235, 160, 0, 0);
        let sale =
          getData.local_sale.balance +
          getData.export_sale.balance +
          getData.other_income.balance +
          getData.rebate.balance;
        let data = [
          ["", "Export Sales", getData.export_sale.balance],
          ["", "Local Sales", getData.local_sale.balance],
          ["", "Rebate", getData.rebate.balance],
          ["", "Other income", getData.other_income.balance],
          ["", "Total Sales", sale],
        ];
        const headers = [["Income", "", ""]];
        let content = {
          startY: 170,
          startX: 10,
          styles: { halign: "left", cellWidth: 180 },
          head: headers,
          body: data,
        };
        doc.autoTable(content);
        let cogs =
          Number(Op) +
          Number(getData.purchase.balance) +
          Number(getData.direct_expense.balance);
        let cogs1 = Number(cogs) - Number(Cl);
        let gp = Number(sale) - Number(cogs1);
        let data1 = [
          ["", "Opening Stock", Op],
          ["Add:", "Purchases", getData.purchase.balance],
          ["", "Direct Expense", getData.direct_expense.balance],
          ["Less:", "Closing Stock", Cl],
        ]; //,["","Total Cost of Goods Sold(COGS)","Formula=OS+SP-CS"],["","Gross Profit","Formula=TS-COGS"]]
        const headers1 = [["Less:-Cost of Goods Sold", "", ""]];
        let content1 = {
          startY: doc.lastAutoTable.finalY + 10,
          startX: 10,
          styles: { halign: "left", cellWidth: 180 },
          head: headers1,
          body: data1,
        };
        doc.autoTable(content1);
        doc.setLineWidth(2);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 5,
          550,
          doc.lastAutoTable.finalY + 5
        );
        doc.setFontSize(10);
        // doc.setFont(undefined,'')
        doc.text(
          "Total Cost of Goods Sold(COGS)",
          225,
          doc.lastAutoTable.finalY + 20,
          0,
          0
        );
        doc.text(`${cogs1}`, 405, doc.lastAutoTable.finalY + 20, 0, 0);
        doc.text("Gross profit", 225, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.text(`${gp}`, 405, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 50,
          550,
          doc.lastAutoTable.finalY + 50
        );
        let data2 = [
          ["", "Admin", getData.admin.balance],
          ["", "Selling", getData.selling.balance],
          ["", "Financial cost", getData.financial_cost.balance],
          ["", "Other", getData.other_expense.balance],
        ]; //,["Total of operating Expense","","Formula=A+S+F+O+O"],["Profit before Tax","","Formula=GP-TOP"],["TAX EXPENSE","",""],["NET PROFIT","","Formula=PBT-TAX"]]
        const headers2 = [["Operating Expenses", "", ""]];
        let content2 = {
          startY: doc.lastAutoTable.finalY + 60,
          startX: 10,
          styles: { halign: "left", cellWidth: 180 },
          head: headers2,
          body: data2,
        };
        const te = getData.tax.balance;
        const tpe =
          Number(getData.admin.balance) +
          Number(getData.selling.balance) +
          Number(getData.financial_cost.balance) +
          Number(getData.other_expense.balance);
        const pbt = Number(gp) - Number(tpe);
        const NetProfit = Number(pbt) - Number(te);
        doc.autoTable(content2);
        doc.setLineWidth(2);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 5,
          550,
          doc.lastAutoTable.finalY + 5
        );
        doc.setFontSize(10);
        doc.text(
          "Total of operating Expense",
          225,
          doc.lastAutoTable.finalY + 20,
          0,
          0
        );
        doc.text(`${tpe}`, 405, doc.lastAutoTable.finalY + 20, 0, 0);
        doc.text("Profit before Tax", 225, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.text(`${pbt}`, 405, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.text("TAX EXPENSE", 225, doc.lastAutoTable.finalY + 60, 0, 0);
        doc.text(`${te}`, 405, doc.lastAutoTable.finalY + 60, 0, 0);
        doc.text("NET PROFIT", 225, doc.lastAutoTable.finalY + 80, 0, 0);
        doc.text(`${NetProfit}`, 405, doc.lastAutoTable.finalY + 80, 0, 0);
        doc.setLineWidth(2);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 90,
          550,
          doc.lastAutoTable.finalY + 90
        );
        doc.setProperties({
          title: `Profit Loss Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        //ct doc.output('dataurlnewwindow')
        doc.save(
          `Profit Loss Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
        dispatch(successGenerateReport(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateReport());
      });
  };
};
