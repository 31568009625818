import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Card,
  Image,
} from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Select from "react-select";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import ImageUploader from "react-images-upload";
import logo from "../../../Images/logo.png";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import CurrencyExchangeTwoToneIcon from "@mui/icons-material/CurrencyExchangeTwoTone";
import AttachMoneyTwoToneIcon from "@mui/icons-material/AttachMoneyTwoTone";
import { addCurrency } from "./Currency.action";

const AddCurrency = (props) => {
  const [name, setName] = useState("");
  const [currencySign, setCurrencySign] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function saveCurrency() {
    props.addCurrency(
      {
        name: name,
        sign: currencySign,
        rate: exchangeRate,
        code: currencyCode,
      },
      handleVisible,
      handleDangerVisible
    );
    setName("");
    setCurrencyCode("");
    setCurrencySign("");
    setExchangeRate("");
  }

  const [nameError, setNameError] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value.toUpperCase());
    if (e.target.value.length > 3) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  useEffect(() => {
    // props.setHeading("Add Hall");
    // props.getRole()
    document.title = props.title;
  }, []);

  return (
    <>
      <Container fluid>
        <div
          className="main"
          style={{
            height: "auto",
            borderRadius: "30px",
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <Row style={{ marginTop: "15px" }}>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DriveFileRenameOutlineTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                required
                error={nameError}
                value={name}
                helperText={
                  nameError
                    ? "Please Enter Your Country Name (Atleast 3 Character)"
                    : ""
                }
                label="Currency Name"
                onChange={(e) => handleNameChange(e)}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                required
                label="Currency Code"
                value={currencyCode}
                onChange={(e) => setCurrencyCode(e.target.value.toUpperCase())}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type=""
                id="standard-basic"
                variant="standard"
                required
                label="Currency Sign "
                value={currencySign}
                onChange={(e) => setCurrencySign(e.target.value.toUpperCase())}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyExchangeTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="number"
                id="standard-basic"
                variant="standard"
                required
                value={exchangeRate}
                label="Exchange Rate "
                onChange={(e) => setExchangeRate(e.target.value)}
                sx={{
                  width: "100%",
                }}
              />
            </Col>
          </Row>

          <div className="sendDiv" style={{ marginTop: "15px" }}>
            {props.isAddingCurrency ? (
              <div className="loader-div">
                <Spinner
                  variant="secondary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <Button
                disabled={
                  isEmpty(name) ||
                  isEmpty(currencySign) ||
                  isEmpty(exchangeRate) ||
                  isEmpty(currencyCode)
                }
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "20%",
                  backgroundColor: "#C5CBCF",
                  border: "1px solid white",
                }}
                onClick={saveCurrency}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCurrency: (data, handleVisible, handleDangerVisible) =>
      dispatch(addCurrency(data, handleVisible, handleDangerVisible)),
    //   setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  currency: state.CargoCurrencyReducer.currency,
  isAddingCurrency: state.CargoCurrencyReducer.isAddingCurrency,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCurrency);
