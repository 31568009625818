import * as React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "react-bootstrap";
import logo from "../../../Images/testLogo.jpg";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { getCargoLogin } from "./CargoLogin.action";
import { useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {/* {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'} */}
    </Typography>
  );
}
function CargoLogin(props) {
  const navigate = useNavigate();
  //const [state, dispatch] = useContext(Context);
  const [open, setOpen] = React.useState(false);
  const [openForgetModal, setOpenForgetModal] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [pass, setPassword] = React.useState("");
  const [dangerVisibleAlert, setDangerVisibleAlert] = React.useState(false);
  // const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const handleSubmit = () => {
    props.getLogin(
      {
        email: email,
        password: pass,
      },
      handleDangerVisible
    );

    setShow(true);
  };

  const handleRegister = () => {
    navigate("/cargo-register");
  };

  // const handleClose = () => {
  //   props.closeSnack()
  // };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  const handleForgetPassword = () => {
    navigate("/forgot-password");
  };

  const handleRegistration = () => {
    // navigate('/register')
  };
  useEffect(() => {
    // clearData();
    document.title = props.title;
  }, []);

  return (
    <>
      <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
        <Row>
          <Col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            style={{ backgroundColor: "#3285a8", height: "100vh" }}
          >
            <Row style={{ marginTop: "25%" }}>
              <Col xl="3" lg="1" md="1" sm="1"></Col>
              <Col xl="6" lg="10" md="10" sm="10">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  {/* <Form.Label><p style={{color:'white',fontSize:'22px',textAlign:'center',marginLeft:'20px'}}><b>WE REPORT , YOUR MIND</b></p></Form.Label> */}
                  <Form.Label>
                    <p
                      style={{
                        color: "white",
                        fontSize: "22px",
                        textAlign: "center",
                        marginLeft: "20px",
                      }}
                    >
                      <b>Login</b>
                    </p>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xl="3" lg="1" md="1" sm="1"></Col>
            </Row>
            <Row>
              <Col xl="2" lg="2" md="2" sm="2"></Col>
              <Col xl="8" lg="8" md="8" sm="8">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    <p style={{ color: "white" }}>Email</p>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="2" lg="2" md="2" sm="2"></Col>
            </Row>
            <Row>
              <Col xl="2" lg="2" md="2" sm="2"></Col>
              <Col xl="8" lg="8" md="8" sm="8">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    <p style={{ color: "white" }}>Password</p>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="Password"
                    placeholder="Password"
                    value={pass}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="2" lg="2" md="2" sm="4"></Col>
            </Row>
            {/* <Row> */}

            <div className="sendDiv">
              <Row style={{ marginBottom: "15px" }}>
                {" "}
                <Link
                  underline="always"
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => handleForgetPassword()}
                >
                  Forget Password?
                </Link>
              </Row>

              {isEmpty(email) || isEmpty(pass) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "black",
                    width: "20%",
                    backgroundColor: "white",
                    border: "1px solid white",
                  }}
                >
                  Sign In
                </Button>
              ) : (
                <Button
                  onClick={() => handleSubmit()}
                  style={{
                    marginLeft: "2%",
                    color: "black",
                    width: "20%",
                    backgroundColor: "white",
                    border: "1px solid white",
                  }}
                >
                  Sign In
                </Button>
              )}

              <Button
                onClick={() => handleRegister()}
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "20%",
                  backgroundColor: "white",
                  border: "1px solid white",
                }}
              >
                Register
              </Button>
            </div>
          </Col>
          {/* {props.isLogin ?
            (<Col            
            xl="6"
            lg="6"
            md="6"
            sm="6"
            style={{ backgroundColor: "#3285a8", height: "100vh",backgroundImage:`url(${logo})` }}>
              <div className="loader-div">
              <Spinner animation="border" variant="light" />
              </div>
              </Col>)

            : ( */}
          <Col xl="6" lg="6" md="6" sm="6">
            <Image
              src={logo}
              style={{
                height: "100vh",
                width: "100%",
                marginLeft: "-12px",
                marginRight: "-25px",
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLogin: (data, handleDangerVisible) =>
      dispatch(getCargoLogin(data, handleDangerVisible)),
  };
};

const mapStateToProps = (state, ownProps, number) => ({
  // isWrongCredentials: state.userReducer.isWrongCredentials,
  isLogin: state.CargoLoginReducer.isLogin,
  isWrongCredentials: state.CargoLoginReducer.isWrongCredentials,
  message: state.CargoLoginReducer.message,
});

export default connect(mapStateToProps, mapDispatchToProps)(CargoLogin);
