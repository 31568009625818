import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Card, Form } from "react-bootstrap";
// import Select from "react-select";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import { getResetPassword, getSetPassword } from "./CargoLogin.action";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import KeyTwoToneIcon from "@mui/icons-material/KeyTwoTone";
import DialpadTwoToneIcon from "@mui/icons-material/DialpadTwoTone";
import KeyIcon from "@mui/icons-material/Key";
// import { setShowTable } from "../rates/Rates.action";

const ForgetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    console.log("test111", event);
    setOpen(false);
    setChecked(true);
  };

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function forgetPassword() {
    // props.setShowTable(true);
    props.email(
      {
        email: email,
      },
      handleVisible,
      handleDangerVisible
    );
    // setEmail("");
  }

  function setNewPassword() {
    // props.setShowTable(true);
    props.password(
      {
        password: confirmPassword,
        email: email,
      },
      code,
      handleVisible,
      handleDangerVisible
    );
    setCode();
    setPassword("");
    setConfirmPassword("");
  }

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toUpperCase());
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };
  const handlePassChange = (e) => {
    setConfirmPassword(e.target.value);
    e.target.value == password
      ? setPasswordError(false)
      : setPasswordError(true);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // props.setShowTable(false);
    document.title = props.title;
  }, []);

  return (
    <Container fluid>
      <div
        className="main"
        style={{ backgroundColor: "#F9FAFB", height: "100vh" }}
      >
        <Card
          style={{
            padding: "3%",
            height: "94vh",
            borderRadius: "30px",
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borer: "none",
          }}
        >
          <>
            {props.resetPassword == "" ? (
              <>
                <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <Col xl="3" lg="3" md="3" sm="3" xs="0"></Col>
                  <Col xl="6" lg="6" md="6" sm="6" xs="12">
                    <Form.Label>
                      <b>Enter Email address</b>
                    </Form.Label>
                    <TextField
                      InputProps={{
                        type: "email",
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="email"
                      id="standard-basic"
                      variant="standard"
                      required
                      disabled={props.resetPassword == "" ? false : true}
                      error={emailError}
                      value={email}
                      helperText={emailError ? "Please Enter Valid Email" : ""}
                      onChange={(e) => handleEmailChange(e)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="3" lg="3" md="3" sm="3" xs="0"></Col>
                </Row>
                {/* <br /> */}
                <div className="sendDiv" style={{ marginTop: "20px" }}>
                  <Button
                    disabled={isEmpty(email)}
                    style={{
                      marginLeft: "2%",
                      color: "black",
                      width: "20%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                    onClick={() => forgetPassword()}
                  >
                    Reset Password
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}
            {/* <br /> */}
            {/* Enter Code */}
            {props.isFetchingResetPassword ? (
              <div className="loader-div">
                <Spinner
                  variant="secondary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : props.resetPassword == "" ? (
              ""
            ) : (
              <>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="3" lg="3" md="3" sm="3" xs="12">
                    <Form.Label>
                      <b>Enter Email address</b>
                    </Form.Label>
                    <TextField
                      InputProps={{
                        type: "email",
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="email"
                      id="standard-basic"
                      variant="standard"
                      required
                      disabled={props.resetPassword == "" ? false : true}
                      error={emailError}
                      value={email}
                      helperText={emailError ? "Please Enter Valid Email" : ""}
                      onChange={(e) => handleEmailChange(e)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="3" lg="3" md="3" sm="3" xs="12">
                    <Form.Label>
                      <b>Enter Code</b>
                    </Form.Label>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DialpadTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="number"
                      id="standard-basic"
                      variant="standard"
                      required
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      sx={{
                        width: "100%",
                        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        "input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  </Col>
                  <Col xl="3" lg="3" md="3" sm="3" xs="12">
                    <Form.Label>
                      <b>Enter New Password</b>
                    </Form.Label>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <KeyTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="password"
                      id="standard-basic"
                      variant="standard"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="3" lg="3" md="3" sm="3" xs="12">
                    <Form.Label>
                      <b>Enter Confirm Password</b>
                    </Form.Label>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <KeyTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="password"
                      id="standard-basic"
                      variant="standard"
                      required
                      error={passwordError}
                      disabled={isEmpty(password)}
                      value={confirmPassword}
                      helperText={
                        passwordError
                          ? "Password and Confirm Password is not Match"
                          : ""
                      }
                      onChange={(e) => handlePassChange(e)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>

                {/* Reset Password */}

                {/* <Row style={{ marginTop: "15px", marginLeft: "80px" }}>
                  <Col xl="4" lg="4" md="4" sm="6" xs="12">
                    <Form.Label>
                      <b>Enter New Password</b>
                    </Form.Label>
                  </Col>
                  <Col xl="8" lg="8" md="8" sm="6" xs="12">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <KeyIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      type="password"
                      id="outlined-basic"
                      required
                      variant="outlined"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row> */}
                <Row style={{ marginTop: "15px" }}>
                  {/* <Col xl="4" lg="4" md="4" sm="6" xs="12">
                    {" "}
                  
                  </Col> */}
                </Row>

                <br />
                <div className="sendDiv" style={{ marginTop: "10px" }}>
                  <Button
                    disabled={
                      isEmpty(confirmPassword) || isEmpty(code) || passwordError
                    }
                    style={{
                      marginLeft: "2%",
                      color: "black",
                      width: "20%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                    onClick={() => setNewPassword()}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </>
        </Card>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    email: (data, handleVisible, handleDangerVisible) =>
      dispatch(getResetPassword(data, handleVisible, handleDangerVisible)),
    // setShowTable: (data) => dispatch(setShowTable(data)),
    password: (data, id, handleVisible, handleDangerVisible) =>
      dispatch(getSetPassword(data, id, handleVisible, handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  resetPassword: state.CargoLoginReducer.resetPassword,
  isFetchingResetPassword: state.CargoLoginReducer.isFetchingResetPassword,
  passwordList: state.CargoLoginReducer.passwordList,
  isFetchingPassword: state.CargoLoginReducer.isFetchingPassword,
  //   isShowTable: state.CargoRateReducer.isShowTable,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
