import API from "../../../global/api";

export const requestGetUser = () => {
  return {
    type: "REQUEST_GET_USER",
  };
};
export const successGetUser = (data) => {
  return {
    type: "SUCCESS_GET_USER",
    payload: data,
  };
};
export const errorGetUser = () => {
  return {
    type: "ERROR_GET_USER",
  };
};
export const getUser = () => {
  return (dispatch) => {
    dispatch(requestGetUser());
    API.get(`/user_cargo`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetUser(getData));
      })
      .catch((error) => {
        dispatch(errorGetUser());
      });
  };
};
export const UpdateRequestUserStatus = () => {
  return {
    type: "UPDATE_REQUEST_USER_STATUS",
  };
};
export const UpdateSuccessUserStatus = (data) => {
  return {
    type: "UPDATE_SUCCESS_USER_STATUS",
    payload: data,
  };
};

export const UpdateErrorUserStatus = () => {
  return {
    type: "UPDATE_ERROR_USER_STATUS",
  };
};

export const updateUserStatus = (
  id,
  data,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(UpdateRequestUserStatus());

    API.post(
      `/user_cargo/update_user_status/${id}`,
      data,
      // {
      //   name: data.name,
      //   company: data.company,
      //    country_id:data.country_id,
      //   address: data.address,
      //   address_2:data.address_2,
      //   address_3:data.address_3,
      //   postal_code:data.postal_code,
      //   city:data.city,
      //   state:data.state,
      //   email: data.email,
      //   phone: data.phone,
      // }
      {}
    )
      .then((res) => {
        dispatch(getUser());
        dispatch(UpdateSuccessUserStatus([data]));
      })
      .catch((error) => dispatch(UpdateErrorUserStatus()));
  };
};
export const UpdateRequestUser = () => {
  return {
    type: "UPDATE_REQUEST_USER",
  };
};
export const UpdateSuccessUser = (data) => {
  return {
    type: "UPDATE_SUCCESS_USER",
    payload: data,
  };
};

export const UpdateErrorUser = () => {
  return {
    type: "UPDATE_ERROR_USER",
  };
};

export const updateUser = (data, id, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(UpdateRequestUser());

    API.put(
      `/user_cargo/${id}`,
      data,
      // {
      //   name: data.name,
      //   company: data.company,
      //    country_id:data.country_id,
      //   address: data.address,
      //   address_2:data.address_2,
      //   address_3:data.address_3,
      //   postal_code:data.postal_code,
      //   city:data.city,
      //   state:data.state,
      //   email: data.email,
      //   phone: data.phone,
      // }
      {}
    )
      .then((res) => {
        dispatch(getUser());
        dispatch(UpdateSuccessUser([data]));
        handleVisible(res.data.mesage);
      })
      .catch((error) => dispatch(UpdateErrorUser()));
  };
};

export const requestGetUserById = () => {
  return {
    type: "REQUEST_GET_USER_BY_ID",
  };
};
export const successGetUserById = (data) => {
  return {
    type: "SUCCESS_GET_USER_BY_ID",
    payload: data,
  };
};
export const errorGetUserById = () => {
  return {
    type: "ERROR_GET_USER_BY_ID",
  };
};
export const getUserById = (id) => {
  return (dispatch) => {
    dispatch(requestGetUserById());
    API.get(`/user_cargo/${id}`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetUserById(getData));
      })
      .catch((error) => {
        dispatch(errorGetUserById());
      });
  };
};
export const setEditUser = (data) => {
  return {
    type: "SET_EDIT_USER",
    payload: data,
  };
};

export const requestGetUserImage = () => {
  return {
    type: "REQUEST_GET_USER_IMAGE",
  };
};
export const successGetUserImage = (data) => {
  return {
    type: "SUCCESS_GET_USER_IMAGE",
    payload: data,
  };
};
export const errorGetUserImage = () => {
  return {
    type: "ERROR_GET_USER_IMAGE",
  };
};
export const getUserImage = (path) => {
  return (dispatch) => {
    dispatch(requestGetUserImage());
    API.get(`/get_image_data_by_path/${path}`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetUserImage(getData));
      })
      .catch((error) => {
        dispatch(errorGetUserImage());
      });
  };
};

export const requestGetRole = () => {
  return {
    type: "REQUEST_GET_ROLE",
  };
};
export const successGetRole = (data) => {
  return {
    type: "SUCCESS_GET_ROLE",
    payload: data,
  };
};
export const errorGetRole = () => {
  return {
    type: "ERROR_GET_ROLE",
  };
};
export const getRole = () => {
  return (dispatch) => {
    dispatch(requestGetRole());
    //
    API.post(`/user_cargo/get_role`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetRole(getData));
      })
      .catch((error) => {
        dispatch(errorGetRole());
      });
  };
};
