import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";

import {
  setEditComplaint,
  getComplaint,
  updateComplaint,
  getComplaintStatus,
} from "./Complaint.action";

const EditComplaint = (props) => {
  const [consigneNo, setConsigneNo] = useState(0);
  const [date, setDate] = useState("");
  const [consigneDate, setConsigneDate] = useState("");
  const [customerCNo, setCustomerCNo] = useState(0);
  const [doxType, setDoxType] = useState("");
  const [trackingNo, setTrackingNo] = useState(0);
  const [customerCode, setCustomerCode] = useState("");
  const [sender, setSender] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [receiver, setReceiver] = useState("");
  const [weight, setWeight] = useState(0);
  const [unit, setUnit] = useState("");
  const [complain, setComplain] = useState("");
  const [complainStatus, setComplainStatus] = useState("");

  const [shipmentId, setShipmentId] = useState(0);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [warningVisibleAlert, setWarningVisibleAlert] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  function saveComplaint() {
    props.updateComplaint(
      {
        status_id: props.editComplaintByID[0].status_id,
      },
      props.editComplaintByID[0].complaint_id,
      handleVisible,
      handleDangerVisible
    );
  }
  const styles = {
    heading: {
      color: "black",
      backgroundColor: "#C5CBCF",
      textAlign: "center",
      padding: "3px",
      borderRadius: "8px",
      marginBottom: "20px",
    },
  };

  useEffect(() => {
    // props.setHeading("Add Hall");
    props.getComplaintStatus();
    document.title = props.title;
  }, []);
  return (
    <>
      {props.isAddingSender ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Row style={{ marginTop: "15px" }}>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="date"
                  id="standard-basic"
                  variant="standard"
                  label="Complain Date"
                  defaultValue={new Date().toISOString().split("T")[0]}
                  onChange={(e) => setDate(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="date"
                  id="standard-basic"
                  variant="standard"
                  label="C/N Date"
                  value={
                    !isEmpty(props.editComplaintByID)
                      ? props.editComplaintByID[0].shipment.shipment_date
                      : ""
                  }
                  onChange={(e) => setConsigneDate(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddBoxTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="Dox Type "
                  value={
                    !isEmpty(props.editComplaintByID)
                      ? props.editComplaintByID[0].shipment.shipment_type
                      : ""
                  }
                  onChange={(e) => setDoxType(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  label="Tracking No"
                  value={
                    !isEmpty(props.editComplaintByID)
                      ? props.editComplaintByID[0].shipment.tracking_id
                      : ""
                  }
                  onChange={(e) => setTrackingNo(e.target.value)}
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "30px" }}>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <div style={styles.heading}>
                  {" "}
                  <h3>SHIPPER/SENDER</h3>{" "}
                </div>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Shipper/Sender"
                      value={
                        !isEmpty(props.editComplaintByID)
                          ? props.editComplaintByID[0].shipment
                              .shipment_senders[0].name
                          : ""
                      }
                      onChange={(e) => setSender(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddLocationAltTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="City"
                      value={
                        !isEmpty(props.editComplaintByID)
                          ? props.editComplaintByID[0].shipment
                              .shipment_senders[0].city
                          : ""
                      }
                      onChange={(e) => setCity(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LanguageTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Country"
                      value={
                        !isEmpty(props.editComplaintByID)
                          ? props.editComplaintByID[0].shipment
                              .shipment_senders[0].country.name
                          : ""
                      }
                      onChange={(e) => setCountry(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <div style={styles.heading}>
                  <h3>CONSIGNEE/RECEIVER</h3>
                </div>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Consignee/Receiver"
                      value={
                        !isEmpty(props.editComplaintByID)
                          ? props.editComplaintByID[0].shipment
                              .shipment_receivers[0].name
                          : ""
                      }
                      onChange={(e) => setReceiver(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddLocationAltTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="City"
                      value={
                        !isEmpty(props.editComplaintByID)
                          ? props.editComplaintByID[0].shipment
                              .shipment_receivers[0].city
                          : ""
                      }
                      onChange={(e) => setCity(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4" xs="12">
                    <TextField
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LanguageTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Country"
                      value={
                        !isEmpty(props.editComplaintByID)
                          ? props.editComplaintByID[0].shipment
                              .shipment_receivers[0].country.name
                          : ""
                      }
                      onChange={(e) => setCountry(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: "30px" }}>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <div style={styles.heading}>
                  {" "}
                  <h3>COMPLAIN</h3>{" "}
                </div>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="Complain"
                  value={
                    !isEmpty(props.editComplaintByID)
                      ? props.editComplaintByID[0].complaint_satement
                      : ""
                  }
                  onChange={(e) => setComplain(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <div style={styles.heading}>
                  <h3>C/N INFO</h3>
                </div>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="6" lg="6" md="6" sm="6" xs="12">
                    <TextField
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <NumbersTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      label="Unit"
                      value={
                        !isEmpty(props.editComplaintByID)
                          ? props.editComplaintByID[0].shipment.total_packages
                          : ""
                      }
                      onChange={(e) => setUnit(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="6" xs="12">
                    <TextField
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <NumbersTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="number"
                      id="standard-basic"
                      variant="standard"
                      label="Weight"
                      value={
                        !isEmpty(props.editComplaintByID)
                          ? props.editComplaintByID[0].shipment.total_weight
                          : ""
                      }
                      onChange={(e) => setWeight(e.target.value)}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <div style={styles.heading}>
                  {" "}
                  <h3>COMPLAIN STATUS</h3>{" "}
                </div>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Complain Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={
                      !isEmpty(props.editComplaintByID)
                        ? props.editComplaintByID[0].status_id
                        : ""
                    }
                    label="Select Complain Status"
                    onChange={(e) =>
                      props.setEditComplaint({
                        key: "status_id",
                        value: e.target.value,
                      })
                    }
                  >
                    {props.complaintStatusList.map((status) => (
                      <MenuItem key={status.status_id} value={status.status_id}>
                        {status.status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <div className="sendDiv" style={{ marginTop: "30px" }}>
              <Button
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "20%",
                  backgroundColor: "#C5CBCF",
                  border: "1px solid white",
                }}
                onClick={saveComplaint}
              >
                Edit Complain
              </Button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateComplaint: (data, id, handleVisible, handleDangerVisible) =>
      dispatch(updateComplaint(data, id, handleVisible, handleDangerVisible)),

    getComplaint: () => dispatch(getComplaint()),
    getComplaintStatus: () => dispatch(getComplaintStatus()),

    setEditComplaint: (data) => dispatch(setEditComplaint(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  editComplaintByID: state.CargoComplaintReducer.editComplaintByID,
  isFetchingEditComplaintByID:
    state.CargoComplaintReducer.isFetchingEditComplaintByID,
  isFetchingComplaintStatus:
    state.CargoComplaintReducer.isFetchingComplaintStatus,
  complaintStatusList: state.CargoComplaintReducer.complaintStatusList,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditComplaint);
