import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
import { isEmpty } from "lodash";
import { baseURL, headers } from "../../../global/api";
import { ThemeProvider, createTheme } from "@mui/material";
import {
  getUser,
  getUserById,
  getUserImage,
  updateUserStatus,
} from "./UserControl.action";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const UserControl = (props) => {
  const navigate = useNavigate();
  const defaultMaterialTheme = createTheme();
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  useEffect(() => {
    props.getUser();
    document.title = props.title;
  }, []);
  return (
    <>
      {props.isFetchingUsers ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{ height: "100%", backgroundColor: "#F9FAFB" }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                style={{
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                title="User Details"
                columns={[
                  // { title: "User Id", field: "id", editable: "never" },
                  { title: "First Name", field: "firstName" },
                  { title: "Last Name", field: "lastName" },
                  { title: "Company", field: "companyName" },
                  // { title: "WebSite", field: "webSite" },
                  { title: "Email", field: "email" },
                  { title: "Role", field: "role.role" },
                  { title: "Last Login", field: "last_login" },
                  { title: "Status", field: "user_status.status" },
                ]}
                data={props.usersList}
                options={{
                  actionsColumnIndex: -1,
                  // headerStyle: { position: 'sticky', top: 0 }, maxBodyHeight: '500px',
                  exportButton: true,
                  exportAllData: true,
                  paging: true,
                  pageSize: 50, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                  maxBodyHeight: "600px",
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#919EAB",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
                actions={[
                  (rowData) => ({
                    disabled:
                      Number(rowData.userStatusId) == 2 ||
                      Number(rowData.userStatusId) == 4
                        ? true
                        : false,
                    icon: () => <CheckCircleIcon />,
                    hidden: Checkpermissions(
                      "permissions_cat_name",
                      "User Edit Status"
                    ),
                    tooltip: "Approve User",
                    onClick: (event, rowData) => {
                      props.updateUserStatus(rowData.id, { userStatusId: 2 });
                    },
                  }),
                  (rowData) => ({
                    disabled:
                      Number(rowData.userStatusId) == 2 ||
                      Number(rowData.userStatusId) == 4
                        ? true
                        : false,
                    icon: "cancel",
                    hidden: Checkpermissions(
                      "permissions_cat_name",
                      "User Edit Status"
                    ),
                    tooltip: "Cancel User",
                    onClick: (event, rowData) => {
                      props.updateUserStatus(rowData.id, { userStatusId: 3 });
                    },
                  }),
                  (rowData) =>
                    Number(rowData.userStatusId) == 4
                      ? {
                          disabled:
                            Number(rowData.userStatusId) == 1 ? true : false,
                          icon: () => <TaskAltIcon />,
                          hidden: Checkpermissions(
                            "permissions_cat_name",
                            "User Edit Status"
                          ),
                          tooltip: "UnBlock User",
                          onClick: (event, rowData) => {
                            props.updateUserStatus(rowData.id, {
                              userStatusId: 2,
                            });
                          },
                        }
                      : {
                          disabled:
                            Number(rowData.userStatusId) == 1 ? true : false,
                          icon: () => <BlockIcon />,
                          hidden: Checkpermissions(
                            "permissions_cat_name",
                            "User Edit Status"
                          ),
                          tooltip: "Block User",
                          onClick: (event, rowData) => {
                            props.updateUserStatus(rowData.id, {
                              userStatusId: 4,
                            });
                          },
                        },
                  {
                    icon: "edit",
                    tooltip: "View/Edit User",
                    hidden: Checkpermissions(
                      "permissions_cat_name",
                      "User Edit"
                    ),
                    onClick: (event, rowData) => {
                      props.getUserByID(rowData.id);
                      // props.getImagePath(rowData.cnicBackImage)
                      navigate("/cargo/user/edit_user");
                    },
                  },
                ]}
              />
            </ThemeProvider>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (data) => dispatch(getUser(data)),
    updateUserStatus: (id, data) => dispatch(updateUserStatus(id, data)),
    getUserByID: (id) => dispatch(getUserById(id)),
    getImagePath: (path) => dispatch(getUserImage(path)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  usersList: state.CargoUserReducer.usersList,
  isFetchingUsers: state.CargoUserReducer.isFetchingUsers,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserControl);
