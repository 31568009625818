import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";

import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { baseURL, headers } from "../../../global/api";
import { ThemeProvider, createTheme } from "@mui/material";
import { getComplaint } from "./Complaint.action";
import { editComplaintById } from "./Complaint.action";
const ViewComplaint = (props) => {
  const navigate = useNavigate();
  const defaultMaterialTheme = createTheme();
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  useEffect(() => {
    props.getComplaint();
    document.title = props.title;
    // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingComplaint ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{ height: "100%", backgroundColor: "#F9FAFB" }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                style={{
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                title="Complain Details"
                columns={[
                  {
                    title: "Complain Id",
                    field: "complaint_id",
                    editable: "never",
                  },
                  { title: "Shipment Id", field: "shipment_id" },
                  { title: "Tracking No", field: "shipment.tracking_id" },
                  { title: "Complain", field: "complaint_satement" },
                  { title: "Status", field: "statu.status" },
                ]}
                //     data={(query) =>
                //     new Promise((resolve, reject) => {
                //         let url = `${baseURL}/complaint/get_complaint?`;
                //         url += "size=" + query.pageSize;
                //         url += "&page=" + (query.page+1);
                //         //  url += "&search=" + query.search;
                //         fetch(url, {
                //             method: 'GET',
                //             headers: headers
                //         })
                //             .then((response) => response.json())
                //             .then((result) => {
                //               console.log(result,"resultttttttttttttttttttttt")
                //                 resolve({
                //                     data: result.data,
                //                     page: result.page,
                //                     totalCount: result.total_results,
                //                 });
                //             });

                //     })
                // }
                data={props.complaintList}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  paging: true,
                  pageSize: 50, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                  maxBodyHeight: "600px",
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#919EAB",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "View Complain",
                    hidden: Checkpermissions(
                      "permissions_cat_name",
                      "Complaint Edit"
                    ),
                    onClick: (event, rowData) => {
                      props.editComplaintById(rowData.complaint_id);
                      navigate("/cargo/complain/edit_complain");
                    },
                  },
                ]}
              />
            </ThemeProvider>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getComplaint: (data) => dispatch(getComplaint(data)),

    editComplaintById: (id) => dispatch(editComplaintById(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  complaintList: state.CargoComplaintReducer.complaintList,
  isFetchingComplaint: state.CargoComplaintReducer.isFetchingComplaint,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewComplaint);
