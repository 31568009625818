import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
} from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { isEmpty, isUndefined, sortBy, isNull } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { getLevel5Data } from "../forms/accountsLevels/accountsLevel.action";
import { getChartOfAccountPdf, getTrialData } from "./account.actions";
import {
  startFinancialDate,
  endFinancialDate,
} from "../../../global/globalFunctions";
import { Link } from "react-router-dom";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import MaterialTable from "material-table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../Images/logo.png";
import { InsertChartOutlinedOutlined } from "@material-ui/icons";
import { getByDisplayValue } from "@testing-library/react";
import { data } from "browserslist";

const ChartOfAccount = (props) => {
  const [startDate, setStartDate] = useState(startFinancialDate);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [accountid, setaccountId] = useState("");

  const [clickNext, setClickNext] = useState(0);
  const [clickPrev, setClickPrev] = useState(0);
  const [clickPervTrue, setClickPrevTrue] = useState(false);
  console.log(clickNext, "nnnnnnnnnnnnnnnn");
  console.log(clickPrev, "ppppppppppppppppppp");
  console.log(clickPervTrue, "ttttttttttttttttttttttt");

  const handleNext = (v) => {
    setClickNext(v);
    //   setClickNext(clickNext + 1);
    props.getTrialData(
      {
        startedDate: "",
        endDate: "",
        // levelFiveId: "",
      },
      v
    );
    setClickPrev(v);
  };

  const handlePrev = (p, t) => {
    setClickPrev(p);
    setClickPrevTrue(t);
    props.getTrialData(
      {
        startedDate: "",
        endDate: "",
        // levelFiveId: "",
      },
      p,
      t
    );
  };
  const getFunc = (rowData) => {};
  const level5Data = props.level5List.map((x) => {
    let data = {
      value: x.levelfiveData.levelFiveId,
      label:
        x.levelfiveData.allLevelKey + " / " + x.levelfiveData.levelFiveName,
    };
    return data;
  });
  const handleChangeAccount = (select) => {
    setaccountId(select.value);
  };
  // end account

  const searchTrial = () => {
    props.getTrialData({
      startedDate: !isUndefined(startDate) ? startDate : "",
      endDate: !isUndefined(endDate) ? endDate : "",
      // levelFiveId: !isUndefined(accountid) ? accountid : "",
    });
  };
  const searchTrialPdf = (data) => {
    props.getTrialDataPdf({
      startedDate: !isUndefined(startDate) ? startDate : "",
      endDate: !isUndefined(endDate) ? endDate : "",
      // levelFiveId: !isUndefined(accountid) ? accountid : "",
    });
  };
  const trialData = sortBy(props.trialList.result, [
    "props.trialList",
    "allLevelKey",
  ]);
  console.log(
    !isEmpty(props.trialList.result) && props.trialList.result.length,
    "lllllllllllllllllll"
  );
  return (
    <>
      {props.isFetchingTrialBalance ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div>
            <Row>
              <Col lg="6" md="6" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="6" md="6" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                    value={endDate}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col
                lg="12"
                sm="12"
                className="text-center"
                style={{ marginTop: "15px" }}
              >
                {isEmpty(startDate) || isEmpty(endDate) ? (
                  <Button
                    disabled
                    // className="sendButton"
                    onClick={(e) => searchTrialPdf()}
                  >
                    Generate Report
                  </Button>
                ) : (
                  <Button
                    // className="sendButton"
                    onClick={(e) => searchTrialPdf()}
                  >
                    Generate Report
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTrialData: (data, clickNext, clickPrev, clickPervTrue) =>
      dispatch(getTrialData(data, clickNext, clickPrev, clickPervTrue)),
    getTrialDataPdf: (data) => dispatch(getChartOfAccountPdf(data)),

    getLevel5Data: () => dispatch(getLevel5Data()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5List: state.accountsLevelReducer.level5List,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
  trialList: state.accountReducer.trialList,
  isFetchingTrialBalance: state.accountReducer.isFetchingTrialBalance,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccount);
