

const initialState = {
  shipmentByTrackingId: [],
  isFetchingShipmentByTrackingId: false,
  shipmentByTrackingIdError: false,
  estimatedDeliveryDateEdit:"",
};

const TrackCargo = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_SHIPMENT_BY_TRACKING_ID":
      return {
        ...state,
        shipmentByTrackingId: [],
        isFetchingShipmentByTrackingId: true,
        shipmentByTrackingIdError: false,
      };
    case "SUCCESS_GET_SHIPMENT_BY_TRACKING_ID":
      console.log(action.payload.data,'111111111111')
      return {
        ...state,
        shipmentByTrackingId: action.payload.data,
        estimatedDeliveryDateEdit:action.payload.data[0].shipment.expected_delivery_date,
        isFetchingShipmentByTrackingId: false,
        shipmentByTrackingIdError: false,
      };
    case "ERROR_GET_SHIPMENT_BY_TRACKING_ID":
      return {
        ...state,
        shipmentByTrackingIdError: true,
        isFetchingShipmentByTrackingId: false,
      };
    case "SET_EDIT_STATUS_FIELDS":
      let editStatus = state.shipmentByTrackingId;

      editStatus = action.payload;

      return { ...state, shipmentByTrackingId: editStatus };
      case "SET_EDIT_DATE":
        let editDate = state.estimatedDeliveryDateEdit;
  
        editDate = action.payload;
  
        return { ...state, estimatedDeliveryDateEdit: editDate };
    default:
      return state;
  }
};

export default TrackCargo;
