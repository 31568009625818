const initialState = {
  isFetchingReceiver: false,
  receiverList: [],
  receiver: [],
  isAddingReceiver: false,
  receiverByID: [],
  isFetchingReceiverByID: false,
};

const ReceiverReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_RECEIVER":
      return { ...state, isFetchingReceiver: true };
    case "SUCCESS_GET_RECEIVER":
      return {
        ...state,
        receiverList: action.payload.data,
        isFetchingReceiver: false,
      };
    case "ERROR_GET_RECEIVER":
      return { ...state, isFetchingReceiver: false };

    case "REQUEST_ADD_RECEIVER":
      return { ...state, isAddingReceiver: true };
    case "SUCCESS_ADD_RECEIVER":
      return {
        ...state,
        receiver: action.payload,
        isAddingReceiver: false,
      };
    case "ERROR_ADD_RECEIVER":
      return { ...state, isAddingReceiver: false };

    case "REQUEST_GET_RECEIVER_BY_ID":
      return { ...state, isFetchingReceiverByID: true };
    case "SUCCESS_GET_RECEIVER_BY_ID":
      return {
        ...state,
        receiverByID: action.payload.data,
        isFetchingReceiverByID: false,
      };
    case "ERROR_GET_RECEIVER_BY_ID":
      return { ...state, isFetchingReceiverByID: false };
    case "SET_EDIT_RECEIVER":
      let valueEmp = state.receiverByID[0];

      let keyEmp = action.payload.key;

      valueEmp[keyEmp] = action.payload.value;
      let valueEmp2 = [valueEmp];

      return { ...state, receiverByID: [valueEmp] };

    default:
      return state;
  }
};

export default ReceiverReducer;
