import API from "../../../../global/api";

export const requestTransactionSummary = () => {
  return {
    type: "REQUEST_TRANSACTION_SUMMARY",
  };
};
export const successTransactionSummary = (data) => {
  return {
    type: "SUCCESS_TRANSACTION_SUMMARY",
    payload: data,
  };
};
export const errorTransactionSummary = () => {
  return {
    type: "ERROR_TRANSACTION_SUMMARY",
  };
};

export const AddTransactionSummary = (data) => {
  return (dispatch) => {
    dispatch(requestTransactionSummary());
    API.post(`/reports/transaction_summary_report`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successTransactionSummary(getData));
      })
      .catch((error) => {
        dispatch(errorTransactionSummary());
      });
  };
};

export const requestGetVouchers = () => {
  return {
    type: "REQUEST_GET_VOUCHERS",
  };
};
export const successGetVouchers = (data) => {
  return {
    type: "SUCCESS_GET_VOUCHERS",
    payload: data,
  };
};
export const errorGetVouchers = () => {
  return {
    type: "ERROR_GET_VOUCHERS",
  };
};

export const getVouchers = () => {
  return (dispatch) => {
    dispatch(requestGetVouchers());
    API.get(`/voucher/get_all_voucher`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetVouchers(getData));
      })
      .catch((error) => {
        dispatch(errorGetVouchers());
      });
  };
};
