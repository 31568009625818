import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";

import { isEmpty } from "lodash";
import { baseURL, headers } from "../../../global/api";
import { ThemeProvider, createTheme } from "@mui/material";
import { deleteCountry, getCountry, updateCountry } from "./Country.action";
const ViewCountry = (props) => {
  const defaultMaterialTheme = createTheme();
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  useEffect(() => {
    //  props.setHeading("Uom List");
    document.title = props.title;
    props.getCountry();
    // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingCountry ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{ height: "100%", backgroundColor: "#F9FAFB" }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                style={{
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                title="Country Details"
                columns={[
                  {
                    title: "Country Id",
                    field: "country_id",
                    editable: "never",
                  },
                  { title: "Country Name", field: "name" },
                  { title: "Country Code", field: "code" },
                ]}
                data={props.countryList}
                //    data={(query) =>
                //     new Promise((resolve, reject) => {
                //         let url = `${baseURL}/country?`;
                //         url += "size=" + query.pageSize;
                //         url += "&page=" + (query.page+1);
                //         //  url += "&search=" + query.search;
                //         fetch(url, {
                //             method: 'GET',
                //             header: headers,
                //         })
                //             .then((response) => response.json())
                //             .then((result) => {
                //                 resolve({
                //                     data: result.data,
                //                     page: result.page,
                //                     totalCount: result.total_results,
                //                 });
                //             });

                //     })
                // }
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  paging: true,
                  pageSize: 50, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                  maxBodyHeight: "600px",
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#919EAB",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
                editable={{
                  isEditHidden: (rowData) =>
                    Checkpermissions("permissions_cat_name", "Country Edit"),
                  isDeleteHidden: (rowData) =>
                    Checkpermissions("permissions_cat_name", "Country Delete"),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        {
                          props.updateCountry(newData, oldData);
                        }
                      }, 600);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        props.deleteCountry(oldData.country_id);
                      }, 600);
                    }),
                }}
              />
            </ThemeProvider>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountry: (data) => dispatch(getCountry(data)),
    deleteCountry: (countryId) => dispatch(deleteCountry(countryId)),
    updateCountry: (newData, oldData) =>
      dispatch(updateCountry(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  countryList: state.CargoCountryReducer.countryList,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  // flavorsDataList: state.FlavourReducer.flavorsDataList,
  // sizeDataList: state.sizeReducer.sizeDataList,
  // masterControlDataList:state.MasterControlReducer.masterControlDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCountry);
