import API from "../../../global/api";
import { useNavigate } from "react-router-dom";
export const RequestRegister = () => {
  return {
    type: "REQUEST_REGISTER",
  };
};
export const SuccessRegister = (data) => {
  return {
    type: "LOGIN_CARGO_SUCCESS_REGISTER",
    payload: data,
  };
};
export const ErrorRegister = () => {
  return {
    type: "ERROR_REGISTER",
  };
};
export const Register = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(RequestRegister());
    API.post(`/user_cargo/cargo_register`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(SuccessRegister(data1));
        handleVisible(res.data.message);
        window.location.replace("/cargo-login");
      })
      .catch((error) => {
        dispatch(loginCargoError());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const RegisterFromAdmin = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(RequestRegister());
    API.post(`/user_cargo/cargo_register`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(SuccessRegister(data1));
        handleVisible(res.data.message);
        // window.location.replace("/cargo/user/view_users");
      })
      .catch((error) => {
        dispatch(loginCargoError());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const loginCargoRequest = () => {
  return {
    type: "LOGIN_CARGO_REQUEST",
  };
};
export const loginCargoSuccess = (data) => {
  return {
    type: "LOGIN_CARGO_SUCCESS",
    payload: data,
  };
};
export const loginCargoError = () => {
  return {
    type: "LOGIN_CARGO_ERROR",
  };
};
export const getCargoLogin = (username, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(loginCargoRequest());
    API.post(`/user_cargo/cargo_login`, username)
      .then((res) => {
        let data1 = res.data;
        dispatch(loginCargoSuccess(data1));
        window.localStorage.setItem("res", res.message);
        window.localStorage.setItem("token", data1.token);
        window.localStorage.setItem("userId", data1.id);
        window.localStorage.setItem("isAuthenticatedUser", "true");

        window.localStorage.setItem("isUser", "true");
        window.localStorage.setItem("userType", data1.roleId);
        window.localStorage.setItem("isAdmin", false);

        window.localStorage.setItem("isAuthenticated", "true");
        window.localStorage.setItem(
          "permissions",
          JSON.stringify(data1.Permissions)
        );

        window.location.replace("/cargo/dashboard/dashboard");
      })
      .catch((error) => {
        dispatch(loginCargoError());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const setModuleKeyCargo = (data) => {
  return {
    type: "SET_MODULE_KEY_Cargo",
    payload: data,
  };
};
export const requestAddImage = () => {
  return {
    type: "REQUEST_ADD_IMAGE",
  };
};
export const successAddImage = (data) => {
  return {
    type: "SUCCESS_ADD_IMAGE",
    payload: data,
  };
};
export const errorAddImage = () => {
  return {
    type: "ERROR_ADD_IMAGE",
  };
};
export const addImage = (data) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("image", data.image);
    dispatch(requestAddImage());
    API.post(`/upload_files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        let getData = res.data;
        dispatch(successAddImage(getData));
      })
      .catch((error) => {
        dispatch(errorAddImage());
      });
  };
};
export const requestAddImageBack = () => {
  return {
    type: "REQUEST_ADD_IMAGE_BACK",
  };
};
export const successAddImageBack = (data) => {
  return {
    type: "SUCCESS_ADD_IMAGE_BACK",
    payload: data,
  };
};
export const errorAddImageBack = () => {
  return {
    type: "ERROR_ADD_IMAGE_BACK",
  };
};
export const addImageBack = (data) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("image", data.image);
    dispatch(requestAddImageBack());
    API.post(`/upload_files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        let getData = res.data;
        dispatch(successAddImageBack(getData));
      })
      .catch((error) => {
        dispatch(errorAddImageBack());
      });
  };
};
export const requestAddLogo = () => {
  return {
    type: "REQUEST_ADD_LOGO",
  };
};
export const successAddLogo = (data) => {
  return {
    type: "SUCCESS_ADD_LOGO",
    payload: data,
  };
};
export const errorAddLogo = () => {
  return {
    type: "ERROR_ADD_LOGO",
  };
};
export const addLogo = (data) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("image", data.image);
    dispatch(requestAddLogo());
    API.post(`/upload_files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        let getData = res.data;

        dispatch(successAddLogo(getData));
      })
      .catch((error) => {
        dispatch(errorAddLogo());
      });
  };
};
export const requestGetRole = () => {
  return {
    type: "REQUEST_GET_ROLE",
  };
};
export const successGetRole = (data) => {
  return {
    type: "SUCCESS_GET_ROLE",
    payload: data,
  };
};
export const errorGetRole = () => {
  return {
    type: "ERROR_GET_ROLE",
  };
};
export const getRole = () => {
  return (dispatch) => {
    dispatch(requestGetRole());
    API.post(`/user_cargo/get_cus_role`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetRole(getData));
      })
      .catch((error) => {
        dispatch(errorGetRole());
      });
  };
};
export const resetImages = (data) => {
  return {
    type: "RESET_IMAGE",
    // payload: data,
  };
};

// Forget Password
export const requestResetPassword = () => {
  return {
    type: "REQUEST_RESET_PASSWORD",
  };
};
export const successResetPassword = (data) => {
  return {
    type: "SUCCESS_RESET_PASSWORD",
    payload: data,
  };
};
export const errorResetPassword = () => {
  return {
    type: "ERROR_RESET_PASSWORD",
  };
};
export const getResetPassword = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestResetPassword());
    API.post(`/user_cargo/forgot_password`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successResetPassword(getData));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorResetPassword());
        handleDangerVisible(error.response.data.message);
      });
  };
};

export const requestSetPassword = () => {
  return {
    type: "REQUEST_SET_PASSWORD",
  };
};
export const successSetPassword = (data) => {
  return {
    type: "SUCCESS_SET_PASSWORD",
    payload: data,
  };
};
export const errorSetPassword = () => {
  return {
    type: "ERROR_SET_PASSWORD",
  };
};
export const getSetPassword = (
  data,
  id,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestSetPassword());
    API.post(`/user_cargo/reset_password/${id}`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successSetPassword(getData));
        handleVisible(res.data.message);
        window.location.replace("/cargo-login");
      })
      .catch((error) => {
        dispatch(errorSetPassword());
        handleDangerVisible(error.response.data.message);
      });
  };
};
