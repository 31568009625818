import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Card } from "react-bootstrap";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import { connect } from "react-redux";
import { isEmpty, sumBy } from "lodash";
import "jspdf-autotable";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import EditNoteTwoToneIcon from "@mui/icons-material/EditNoteTwoTone";
import Typography from "@mui/material/Typography";
import {
  getShipmentPurpose,
  handleAddInputShipmentDetail,
  handleRemoveInputShipmentDetail,
  setAddShipmentDetail,
  setDescription,
  setPurpose,
  setShipmentRef,
  setType,
} from "./CreateShipment.action";
import { getCurrency } from "../../currency/Currency.action";
import SelectService from "./SelectService";
import { getCountry } from "../../country/Country.action";
import Step5HowWillPay from "./Step5HowWillPay";
import Step2SelectPackaging from "../Step2SelectPackaging";
const Step3ShipmentDetail = (props) => {
  const handleChangeCurrency = (e, i) => {
    if (i == 0) {
      props.setShipmentDetail({
        key: "currency_id",
        value: e.target.value,
        length: i,
      });
    } else {
      props.setShipmentDetail({
        key: "currency_id",
        value: Number(props.shipmentDetail[0].currency_id),
        length: i,
      });
    }
  };
  const handleChangeValue = (e, i) => {
    props.setShipmentDetail({
      key: "value",
      value: Number(e.target.value),
      length: i,
    });
    props.setShipmentDetail({
      key: "total",
      value: Number(e.target.value) * Number(props.shipmentDetail[i].quantity),
      length: i,
    });
  };
  const handleChangeUom = (e, i) => {
    props.setShipmentDetail({
      key: "uom_id",
      value: e.target.value,
      length: i,
    });
  };
  const handleChangePurpose = (e) => {
    props.setPurpose(e.target.value);
  };
  const handleChangeCountry = (e, i) => {
    props.setShipmentDetail({
      key: "country_id",
      value: e.target.value,
      length: i,
    });
  };
  const handleAddInput1 = (i) => {
    props.handleAddInputShipmentDetail();
    props.setShipmentDetail({
      key: "currency_id",
      value: props.shipmentDetail[0].currency_id,
      length: i + 1,
    });
  };
  //remove for add fuctio
  const handleRemoveInput1 = (i) => {
    props.handleRemoveInputShipmentDetail(i);

    // const list = [...inputField1];
    // list.splice(index, 1);
    // setInputField1(list);
  };
  const [codeError, setCodeError] = useState(false);

  const handleCodeChange = (e, i) => {
    props.setShipmentDetail({
      key: "code",
      value: e.target.value.toUpperCase(),
      length: i,
    });
    if (e.target.value.length > 7) {
      setCodeError(false);
    } else {
      setCodeError(true);
    }
  };
  useEffect(() => {}, []);
  return (
    <>
      <Container fluid>
        {/* <div className="main" > */}
        <Card
          style={{
            borderRadius: "30px",
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            border: "none",
            padding: "20px",
            marginTop: "20px",
            // height: props.showButtonSender == false ? "90vh" : "75vh",
          }}
        >
          <Row>
            <Col xl="12" lg="12" md="12" sm="12" xs="12">
              <SelectService />
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xl="12" lg="12" md="12" sm="12" xs="12">
              <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
                <p>
                  {" "}
                  <b>Shipment Detail</b>
                  <br />
                  What are you Shipping?
                </p>
              </Typography>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col xl="6" lg="6" md="6" sm="6" xs="12">
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {props.shipmentType === "Doc" ? <DoneTwoToneIcon /> : ""}
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                value="DOCUMENT"
                style={{
                  backgroundColor:
                    props.shipmentType === "Doc" ? "#D9DDE0" : "",
                }}
                onClick={(e) => props.setType("Doc")}
                sx={{
                  input: { cursor: "pointer" },
                  width: "100%",
                }}
              />
            </Col>
            <Col xl="6" lg="6" md="6" sm="6" xs="12">
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {props.shipmentType === "Pack" ? <DoneTwoToneIcon /> : ""}
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                value="PACKAGES"
                style={{
                  backgroundColor:
                    props.shipmentType === "Pack" ? "#D9DDE0" : "",
                }}
                onClick={(e) => props.setType("Pack")}
                sx={{
                  input: { cursor: "pointer" },
                  width: "100%",
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col xl="6" lg="6" md="6" sm="6" xs="12">
              <Typography
                style={{
                  fontFamily: "Public Sans,sans-serif",
                  fontSize: "14px",
                }}
              >
                Documents include legal, financial or business paperwork. Items
                with monetary value are NOT considered a document shipment.
              </Typography>
            </Col>
            <Col xl="6" lg="6" md="6" sm="6" xs="12">
              <Typography
                style={{
                  fontFamily: "Public Sans,sans-serif",
                  fontSize: "14px",
                }}
              >
                Packages are goods, merchandise or commodities for personal or
                commercial purposes.
              </Typography>
            </Col>
          </Row>
          {props.shipmentType === "Pack" ? (
            <Row style={{ marginTop: "20px" }}>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <FormControl variant="standard" fullWidth>
                  <Form.Label id="demo-simple-select-standard-label">
                    <b> Purpose of Your Shipment *</b>
                  </Form.Label>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={props.shipmentPurpose}
                    placeholder="Select Purpose"
                    // label="Select Purpose"
                    onChange={handleChangePurpose}
                    // input={<OutlinedInput label="Select Purpose" />}
                  >
                    {props.shipmentPurposeList.map((name) => (
                      <MenuItem
                        key={name.shipment_purpose_id}
                        value={name.shipment_purpose_id}
                      >
                        {name.purpose.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {props.shipmentType === "Pack" ? (
            <Row style={{ marginTop: "20px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
                  <b>Tell Us What You're Shipping</b>
                </Typography>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {props.shipmentType === "Pack"
            ? props.shipmentDetail.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <>
                      <Card
                        style={{
                          marginTop: "15px",
                          padding: "20px",
                          borderLeftWidth: "10px",
                        }}
                      >
                        <Row style={{ marginTop: "15px" }}>
                          <Col xl="3" lg="3" md="3" sm="3" xs="12">
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EditNoteTwoToneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              type="text"
                              id="standard-basic"
                              variant="standard"
                              label="Description *"
                              value={item.desc}
                              placeholder="Complete Describe Your Item"
                              onChange={(e) =>
                                props.setShipmentDetail({
                                  key: "desc",
                                  value: e.target.value.toUpperCase(),
                                  length: i,
                                })
                              }
                              sx={{
                                // input: { cursor: 'pointer' },
                                width: "100%",
                                textTransform: "uppercase",
                              }}
                            />
                          </Col>
                          <Col xl="3" lg="3" md="3" sm="3" xs="12">
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EditNoteTwoToneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              type="text"
                              id="standard-basic"
                              variant="standard"
                              label="Commodity Code"
                              value={item.code}
                              error={codeError}
                              helperText={
                                codeError
                                  ? "Code Must be (Atleast 8 Character)"
                                  : ""
                              }
                              // value="Documents"
                              // style={{ backgroundColor: selectType === "Doc" ? 'silver' : "" }}
                              onChange={(e) => handleCodeChange(e, i)}
                              sx={{
                                // input: { cursor: 'pointer' },
                                width: "100%",
                                textTransform: "uppercase",
                              }}
                            />
                          </Col>
                          <Col xl="3" lg="3" md="3" sm="3" xs="12">
                            <TextField
                              InputProps={{
                                inputProps: { min: 1 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EditNoteTwoToneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              type="Number"
                              id="standard-basic"
                              variant="standard"
                              value={item.quantity}
                              label="Quantity *"
                              onChange={(e) =>
                                props.setShipmentDetail({
                                  key: "quantity",
                                  value: Number(e.target.value),
                                  length: i,
                                })
                              }
                              sx={{
                                // input: { cursor: 'pointer' },
                                width: "100%",
                                // textTransform: "uppercase",
                                // width: pageOptions?.width,
                                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                                  {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                "input[type=number]": {
                                  MozAppearance: "textfield",
                                },
                              }}
                            />
                          </Col>
                          <Col xl="3" lg="3" md="3" sm="3" xs="12">
                            <FormControl variant="standard" fullWidth>
                              <InputLabel
                                id="demo-simple-select-standard-label"
                                required
                              >
                                Units(who Item Packaged)
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={props.shipmentDetail[i].uom_id}
                                // placeholder="Select Purpose of Your Shipment"
                                label="Units(who Item Packaged) *"
                                onChange={(e) => handleChangeUom(e, i)}
                              >
                                {props.uomList.map((name) => (
                                  <MenuItem key={name.uomId} value={name.uomId}>
                                    {name.uomName.toUpperCase()}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "15px" }}>
                          <Col xl="3" lg="3" md="3" sm="3" xs="12">
                            <FormControl variant="standard" fullWidth>
                              <InputLabel
                                id="demo-simple-select-standard-label"
                                required
                              >
                                Select Currency
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                // value={countryId}
                                disabled={i > 0 ? true : false}
                                value={props.shipmentDetail[0].currency_id}
                                // placeholder="Select Currency *"
                                label="Select Currency *"
                                // onChange={handleChangeCurrency(i)}
                                onChange={(e) => handleChangeCurrency(e, i)}
                              >
                                {props.currencyList.map((name) => (
                                  <MenuItem key={name.id} value={name.id}>
                                    {name.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>
                          <Col xl="3" lg="3" md="3" sm="3" xs="12">
                            <TextField
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EditNoteTwoToneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              type="Number"
                              id="standard-basic"
                              variant="standard"
                              label="Value Per Piece *"
                              value={item.value}
                              onChange={(e) => handleChangeValue(e, i)}
                              sx={{
                                // input: { cursor: 'pointer' },
                                width: "100%",

                                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                                  {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                "input[type=number]": {
                                  MozAppearance: "textfield",
                                },
                              }}
                            />
                          </Col>
                          <Col xl="3" lg="3" md="3" sm="3" xs="12">
                            <FormControl variant="standard" fullWidth>
                              <InputLabel
                                id="demo-simple-select-standard-label"
                                required
                              >
                                Where item Made
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={props.shipmentDetail[i].country_id}
                                // placeholder="item Made"
                                label="item Made"
                                // onChange={handleChangeCountry(i)}
                                onChange={(e) => handleChangeCountry(e, i)}
                              >
                                {props.countryList.map((name) => (
                                  <MenuItem
                                    key={name.country_id}
                                    value={name.country_id}
                                  >
                                    {name.name.toUpperCase()}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>
                          <Col
                            lg="3"
                            md="3"
                            sm="3"
                            style={{ marginTop: "2.5%" }}
                          >
                            <Form.Group>
                              {props.shipmentDetail.length - 1 === i && (
                                <Button
                                  title="Click to Add Shipping"
                                  onClick={() => handleAddInput1(i)}
                                  disabled={
                                    props.shipmentDetail[i].desc === "" ||
                                    // props.shipmentDetail[i].code === "" ||
                                    props.shipmentDetail[i].quantity === 0 ||
                                    props.shipmentDetail[i].value === 0 ||
                                    // props.shipmentDetail[i].weight === 0 ||
                                    props.shipmentDetail[i].units === "" ||
                                    props.shipmentDetail[i].country_id === "" ||
                                    props.shipmentDetail[i].currency_id === ""
                                  }
                                  style={{
                                    marginLeft: "2%",
                                    color: "black",
                                    float: "right",
                                    backgroundColor: "#C5CBCF",
                                    border: "1px solid white",
                                  }}
                                >
                                  <AddTwoToneIcon />
                                </Button>
                              )}

                              {props.shipmentDetail.length !== 1 && (
                                <Button
                                  title="Click to Remove Shipping"
                                  onClick={() => handleRemoveInput1(i)}
                                  style={{
                                    marginRight: "2%",
                                    color: "black",
                                    float: "right",
                                    backgroundColor: "#C5CBCF",
                                    border: "1px solid white",
                                  }}
                                >
                                  <RemoveTwoToneIcon />
                                </Button>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card>
                    </>
                  </React.Fragment>
                );
              })
            : ""}
          {props.shipmentType === "Pack" ? (
            <Row
              style={{
                backgroundColor: "#D9DDE0",
                marginTop: "10px",
                marginBottom: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Col xl="3" lg="3" md="3" sm="4" xs="4">
                <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
                  <b>
                    {" "}
                    Total Unit{" "}
                    {props.shipmentDetail[0].quantity === 0
                      ? "--------"
                      : sumBy(props.shipmentDetail, "quantity")}
                  </b>
                </Typography>
              </Col>
              {/* <Col xl="3" lg="3" md="3" sm="4" xs="4">
                                <b> Total Weight {props.shipmentDetail[0].weight === 0 ? "--------" : sumBy(props.shipmentDetail, "weight")} kg </b>
                            </Col> */}
              <Col xl="3" lg="3" md="3" sm="4" xs="4">
                <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
                  <b>
                    {" "}
                    Total Value{" "}
                    {props.shipmentDetail[0].value === 0
                      ? "--------"
                      : sumBy(props.shipmentDetail, "total")}{" "}
                    USD{" "}
                  </b>
                </Typography>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {props.shipmentType === "Doc" ? (
            <Row style={{ marginTop: "20px" }}>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
                  <b>Describe the documents in your shipment</b>
                </Typography>
              </Col>
              <Col
                xl="6"
                lg="6"
                md="6"
                sm="6"
                xs="12"
                style={{ marginTop: "15px" }}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditNoteTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="Description"
                  value={props.description}
                  onChange={(e) =>
                    props.setDescription(e.target.value.toUpperCase())
                  }
                  sx={{
                    // input: { cursor: 'pointer' },
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row style={{ marginTop: "20px" }}>
            <Col xl="12" lg="12" md="12" sm="12" xs="12">
              <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
                <b>Add Shipment Reference</b>
              </Typography>
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col xl="6" lg="6" md="6" sm="6" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EditNoteTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                label="Reference"
                disabled
                value={
                  !isEmpty(props.userDetail) &&
                  `${props.userDetail.firstName.toUpperCase()}${" "}${props.userDetail.lastName.toUpperCase()}/${props.userDetail.companyName.toUpperCase()}`
                }
                // value={props.shipmentRef}
                onChange={(e) => props.setShipmentRef(e.target.value)}
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                }}
              />
            </Col>
          </Row>
        </Card>
        <Row>
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            <Step2SelectPackaging />
          </Col>
        </Row>
        <Row>
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            <Step5HowWillPay />
          </Col>
        </Row>

        {/* </div> */}
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShipmentPurpose: () => dispatch(getShipmentPurpose()),
    setShipmentRef: (data) => dispatch(setShipmentRef(data)),
    setDescription: (data) => dispatch(setDescription(data)),
    setType: (data) => dispatch(setType(data)),
    setPurpose: (data) => dispatch(setPurpose(data)),
    getCurrency: () => dispatch(getCurrency()),
    getCountry: () => dispatch(getCountry()),
    setShipmentDetail: (data) => dispatch(setAddShipmentDetail(data)),
    handleAddInputShipmentDetail: () =>
      dispatch(handleAddInputShipmentDetail()),
    handleRemoveInputShipmentDetail: (data) =>
      dispatch(handleRemoveInputShipmentDetail(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  packagesList: state.CargoPackagesReducer.packagesList,
  isFetchingPackages: state.CargoPackagesReducer.isFetchingPackages,
  shipmentPurposeList: state.CargoCreateShipmentReducer.shipmentPurposeList,
  isFetchingShipmentPurposeList:
    state.CargoCreateShipmentReducer.isFetchingShipmentPurposeList,
  shipmentType: state.CargoCreateShipmentReducer.shipmentType,
  currencyList: state.CargoCurrencyReducer.currencyList,
  isFetchingCurrency: state.CargoCurrencyReducer.isFetchingCurrency,
  senderData: state.CargoCreateShipmentReducer.senderData,
  description: state.CargoCreateShipmentReducer.description,

  countryList: state.CargoCountryReducer.countryList,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  shipmentDetail: state.CargoCreateShipmentReducer.shipmentDetail,
  uomList: state.CargoCreateShipmentReducer.uomList,
  shipmentRef: state.CargoCreateShipmentReducer.shipmentRef,
  userDetail: state.CargoSenderReducer.userDetail,
  shipmentPurpose: state.CargoCreateShipmentReducer.shipmentPurpose,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step3ShipmentDetail);
