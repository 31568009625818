const initialState = {
  isLogin: false,
  // loginUserId: "",
  // unableLogin: true,
  moduleKeyCargo: "",
  userDetails: {},
  isWrongCredentials: false,
  isAddingImage: false,
  imageURL: [],
  isAddingBackImage: false,
  backImageURL: [],
  isAddingLogo: false,
  logoURL: [],
  isFetchingRole: false,
  roleList: [],
  resetPassword: "",
  isFetchingResetPassword: false,
  passwordList: [],
  isFetchingPassword: false,
  isRegister:false
};

const CargoLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_ROLE":
      return { ...state, isFetchingRole: true };
    case "SUCCESS_GET_ROLE":
      return {
        ...state,
        roleList: action.payload.data,
        isFetchingRole: false,
      };
    case "ERROR_GET_ROLE":
      return { ...state, isFetchingRole: false };
    case "LOGIN_CARGO_REQUEST":
      return { ...state, isLogin: true, isWrongCredentials: false };
    case "LOGIN_CARGO_SUCCESS":
      return {
        ...state,
        isLogin: false,
        userDetails: action.payload,
        isWrongCredentials: false,
      };
    case "LOGIN_CARGO_ERROR":
      return { ...state, isLogin: false, isWrongCredentials: true };
    case "SET_MODULE_KEY_Cargo":
      return { ...state, moduleKeyCargo: action.payload };
    case "REQUEST_ADD_IMAGE":
      return { ...state, isAddingImage: true };
    case "SUCCESS_ADD_IMAGE":
      return {
        ...state,
        imageURL: action.payload,
        isAddingImage: false,
      };
    case "ERROR_ADD_IMAGE":
      return { ...state, isAddingImage: false };

    case "REQUEST_ADD_IMAGE_BACK":
      return { ...state, isAddingBackImage: true };
    case "SUCCESS_ADD_IMAGE_BACK":
      return {
        ...state,
        backImageURL: action.payload,
        isAddingBackImage: false,
      };
    case "ERROR_ADD_IMAGE_BACK":
      return { ...state, isAddingBackImage: false };
    case "REQUEST_ADD_LOGO":
      return { ...state, isAddingLogo: true };
    case "SUCCESS_ADD_LOGO":
      return {
        ...state,
        logoURL: action.payload,
        isAddingLogo: false,
      };
    case "ERROR_ADD_LOGO":
      return { ...state, isAddingLogo: false };
    case "RESET_IMAGE":
      return {
        ...state,
        logoURL: [],
        imageURL: [],
        backImageURL: [],
      };

    case "REQUEST_RESET_PASSWORD":
      return { ...state, isFetchingResetPassword: true };
    case "SUCCESS_RESET_PASSWORD":
      return {
        ...state,
        resetPassword: action.payload.message,
        isFetchingResetPassword: false,
      };
    case "ERROR_RESET_PASSWORD":
      return { ...state, isFetchingResetPassword: false };

    case "REQUEST_SET_PASSWORD":
      return { ...state, isFetchingPassword: true };
    case "SUCCESS_SET_PASSWORD":
      return {
        ...state,
        passwordList: action.payload.data,
        isFetchingPassword: false,
      };
    case "ERROR_SET_PASSWORD":
      return { ...state, isFetchingPassword: false };
      case "REQUEST_REGISTER":
        return { ...state, isRegister: true };
      case "SUCCESS_REGISTER":
        return {
          ...state,
          // passwordList: action.payload.data,
          isRegister: false,
        };
      case "ERROR_REGISTER":
        return { ...state, isRegister: false };
    default:
      return state;
  }
};

export default CargoLoginReducer;
