const initialState = {

    backUpList:[],
    isFetchingBackUp:false,

}
const BackUpReducer = (state = initialState, action) => {
    // console.log(action, "Awww")
    switch (action.type) {
    case "REQUEST_GET_BACK_UP":
        return { ...state, isFetchingBackUp: true };
    case "SUCCESS_GET_BACK_UP":
        return {
            ...state,
            backUpList: action.payload.data,
            isFetchingBackUp: false,
        };
case "ERROR_GET_BACK_UP":
        return { ...state ,isFetchingBackUp:false};
            default:
            return state;
    }
};
export default BackUpReducer;