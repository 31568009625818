import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
// import "../cargo_views/views/src/App.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Select from "react-select";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import ImageUploader from "react-images-upload";
import {
  Register,
  RegisterFromAdmin,
  addImage,
  addImageBack,
  addLogo,
} from "../cargoLogin/CargoLogin.action";
import { getRole } from "./UserControl.action";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import AddIcCallTwoToneIcon from "@mui/icons-material/AddIcCallTwoTone";
import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import KeyTwoToneIcon from "@mui/icons-material/KeyTwoTone";
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import DialpadTwoToneIcon from "@mui/icons-material/DialpadTwoTone";

const RegisterCargo = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsiteURL] = useState("");
  const [NTNNo, setNTNNo] = useState(0);
  const [GSTNo, setGSTNo] = useState(0);
  const [CNIC, setCNIC] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [role, setRole] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setOpen(false);
    setChecked(true);
  };

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function registerUser() {
    props.register(
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        cnic: CNIC,
        cnicFrontImage: props.imageURL.path,
        cnicBackImage: props.backImageURL.path,
        companyLogo: props.logoURL.path,
        role: roleId,
        term_and_condition: checked,
        password: confirmPassword,
        phone_number: cellNumber,

        companyName: roleId == 4 || roleId == 1 ? "AUC" : companyName,
        webSite: roleId == 4 || roleId == 1 ? "" : website,
        gst: roleId == 4 || roleId == 1 ? null : GSTNo,
        ntn: roleId == 4 || roleId == 1 ? null : NTNNo,
        address: roleId == 4 || roleId == 1 ? "Hadi Town" : companyAddress,
      },
      handleVisible,
      handleDangerVisible
    );
    setFirstName("");
    setLastName("");
    setEmail("");
    setCellNumber("");
    setCompanyName("");
    setWebsiteURL("");
    setNTNNo(0);
    setGSTNo(0);
    setCNIC("");
    setCompanyAddress("");
    setPassword("");
    setConfirmPassword("");
    setRoleId(0);
  }
  const onDrop = (pictureFiles, pictureDataURLs) => {
    props.imageFront({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  const onDropBackImage = (pictureFiles, pictureDataURLs) => {
    props.imageBack({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  const onDropCompanyLogo = (pictureFiles, pictureDataURLs) => {
    props.companyLogo({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [websiteURLError, setwebsiteURLError] = useState(false);
  const [cellNumberError, setCellNumberError] = useState(false);
  const [CNICError, setCNICError] = useState(false);

  const handleNameChange = (e) => {
    setFirstName(e.target.value.toUpperCase());
    if (e.target.value.length > 3) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  const handleCNICChange = (e) => {
    const cnicPattern = /^[0-9]{13}$/;
    if (e.target.value.length <= 13) {
      setCNIC(e.target.value);
      if (cnicPattern.test(e.target.value)) {
        setCNICError(false);
      } else {
        setCNICError(true);
      }
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handlePassChange = (e) => {
    setConfirmPassword(e.target.value);
    e.target.value == password
      ? setPasswordError(false)
      : setPasswordError(true);
  };
  const handleWebsiteURLChange = (e) => {
    setWebsiteURL(e.target.value.toUpperCase());
    const pattern =
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    if (pattern.test(e.target.value)) {
      setwebsiteURLError(false);
    } else {
      setwebsiteURLError(true);
    }
  };
  const handleCellNumberChange = (e) => {
    const pattern =
      /^(?:\+92|0)?(3\d{2}|5[0-9]\d{1}|7[0-9]\d{1}|8[0-9]\d{1}|9[0-9]\d{1})\d{7}$/;
    const inputValue = e.target.value;
    if (e.target.value.length <= 11) {
      setCellNumber(inputValue);

      if (pattern.test(inputValue)) {
        setCellNumberError(false);
      } else {
        setCellNumberError(true);
      }
    }
  };
  const getRoleList =
    !isEmpty(props.roleList) &&
    props.roleList.map((x) => {
      let data = { value: x.id, label: x.role };
      return data;
    });
  const handleChangeRole = (e) => {
    setRoleId(e.target.value);
    // setRole(e.label);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // props.setHeading("Add Hall");
    props.getRole();
    document.title = props.title;
  }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      {props.isFetchingRole ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Row style={{ marginTop: "15px" }}>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  // id="outlined-basic"
                  required
                  error={nameError}
                  value={firstName}
                  helperText={
                    nameError
                      ? "Please Enter Your First Name (Atleast 4 Character)"
                      : ""
                  }
                  label="First Name"
                  // variant="outlined"
                  onChange={(e) => handleNameChange(e)}
                  sx={{
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  style={{ backgroundColor: "white" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  id="standard-basic"
                  variant="standard"
                  type="text"
                  // id="outlined-basic"
                  value={lastName}
                  label="Last Name "
                  // variant="outlined"
                  onChange={(e) => setLastName(e.target.value.toUpperCase())}
                  sx={{
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  style={{ backgroundColor: "white" }}
                  InputProps={{
                    type: "email",
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="email"
                  id="standard-basic"
                  variant="standard"
                  // id="outlined-basic"
                  required
                  label="Email "
                  // variant="outlined"
                  error={emailError}
                  value={email}
                  helperText={emailError ? "Please Enter Valid Email" : ""}
                  onChange={(e) => handleEmailChange(e)}
                  sx={{
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  style={{ backgroundColor: "white" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddIcCallTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  // id="outlined-basic"
                  required
                  label="Cell Number "
                  // variant="outlined"
                  error={cellNumberError}
                  value={cellNumber}
                  helperText={
                    cellNumberError ? "Please Enter Valid Cell Number" : ""
                  }
                  onChange={(e) => handleCellNumberChange(e)}
                  sx={{
                    width: "100%",
                    textTransform: "uppercase",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  style={{ backgroundColor: "white" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  // id="outlined-basic"
                  required
                  label="CNIC"
                  // variant="outlined"
                  error={CNICError}
                  value={CNIC}
                  helperText={
                    CNICError ? "Please Enter Valid CNIC (Without Dashes)" : ""
                  }
                  onChange={(e) => handleCNICChange(e)}
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    textTransform: "uppercase",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  style={{ backgroundColor: "white" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="password"
                  id="standard-basic"
                  variant="standard"
                  // id="outlined-basic"
                  required
                  label="Password"
                  // variant="outlined"
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  style={{ backgroundColor: "white" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="password"
                  id="standard-basic"
                  variant="standard"
                  // id="outlined-basic"
                  required
                  label="Confirm Password"
                  // variant="outlined"
                  error={passwordError}
                  value={confirmPassword}
                  helperText={
                    passwordError
                      ? "Password and Confirm Password is not Match"
                      : ""
                  }
                  onChange={(e) => handlePassChange(e)}
                  sx={{
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label" required>
                    SELECT ACCOUNT TYPE
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={roleId}
                    label="SELECT ACCOUNT TYPE"
                    onChange={handleChangeRole}
                  >
                    {props.roleList.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        {name.role.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>

            {roleId == 4 || roleId == 1 || roleId == 0 ? (
              ""
            ) : (
              <>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="3" lg="3" md="3" sm="3" xs="12">
                    <TextField
                      style={{ backgroundColor: "white" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DomainTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      // id="outlined-basic"
                      required
                      label="Company Name "
                      value={companyName}
                      // variant="outlined"
                      onChange={(e) =>
                        setCompanyName(e.target.value.toUpperCase())
                      }
                      sx={{
                        width: "100%",
                        textTransform: "uppercase",
                      }}
                    />
                  </Col>
                  <Col xl="3" lg="3" md="3" sm="3" xs="12">
                    <TextField
                      style={{ backgroundColor: "white" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LanguageTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      // id="outlined-basic"
                      label="Website "
                      // variant="outlined"
                      error={websiteURLError}
                      value={website}
                      helperText={
                        websiteURLError ? "Please Enter Valid Website URL" : ""
                      }
                      onChange={(e) => handleWebsiteURLChange(e)}
                      sx={{
                        width: "100%",
                        textTransform: "uppercase",
                      }}
                    />
                  </Col>
                  <Col xl="3" lg="3" md="3" sm="3" xs="12">
                    <TextField
                      style={{ backgroundColor: "white" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DialpadTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="Number"
                      id="standard-basic"
                      variant="standard"
                      // id="outlined-basic"
                      required
                      label="NTN No "
                      // variant="outlined"
                      onChange={(e) => setNTNNo(e.target.value)}
                      sx={{
                        width: "100%",
                        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        "input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        textTransform: "uppercase",
                      }}
                    />
                  </Col>
                  <Col xl="3" lg="3" md="3" sm="3" xs="12">
                    <TextField
                      style={{ backgroundColor: "white" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DialpadTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="Number"
                      id="standard-basic"
                      variant="standard"
                      // id="outlined-basic"
                      label="GST No "
                      // variant="outlined"
                      onChange={(e) => setGSTNo(e.target.value)}
                      sx={{
                        width: "100%",
                        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        "input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        textTransform: "uppercase",
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="3" lg="3" md="3" sm="3" xs="12">
                    <TextField
                      style={{ backgroundColor: "white" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddLocationAltTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      // id="outlined-basic"
                      required
                      label="Company Address"
                      value={companyAddress}
                      // variant="outlined"
                      onChange={(e) =>
                        setCompanyAddress(e.target.value.toUpperCase())
                      }
                      sx={{
                        width: "100%",
                        textTransform: "uppercase",
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {/* <Row style={{ marginTop: "15px" }}>
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" required>
                    Select Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={roleId}
                    label="Select Role"
                    onChange={handleChangeRole}
                    input={<OutlinedInput label="Select Role" />}
                  >
                    {props.roleList.map(
                      (name) => (
                       
                        (
                          <MenuItem key={name.id} value={name.id}>
                            {name.role}
                          </MenuItem>
                        )
                      )
                    )}
                  </Select>
                </FormControl>
              </Col>
            </Row> */}
            <Row style={{ marginTop: "25px" }}>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ fontSize: "large" }}>
                      UPLOAD CNIC FRONT IMAGE *
                    </b>
                  </Form.Label>
                  <ImageUploader
                    fileContainerStyle={{
                      borderColor: "red",
                      borderWidth: "2px",
                    }}
                    withIcon={false}
                    buttonText="UPLOAD IMAGE"
                    onChange={onDrop}
                    // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                    imgExtension={[".jpg", ".jpeg", ".png"]}
                    maxFileSize={999999999999999999999}
                    singleImage={true}
                    withPreview={true}
                  />
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ fontSize: "large" }}>
                      UPLOAD CNIC BACK IMAGE *
                    </b>
                  </Form.Label>
                  <ImageUploader
                    withIcon={false}
                    buttonText="UPLOAD IMAGE"
                    onChange={onDropBackImage}
                    // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                    imgExtension={[".jpg", ".jpeg", ".png"]}
                    maxFileSize={999999999999999999999}
                    singleImage={true}
                    withPreview={true}
                  />
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ fontSize: "large" }}>UPLOAD COMPANY LOGO *</b>
                  </Form.Label>
                  <ImageUploader
                    withIcon={false}
                    buttonText="UPLOAD IMAGE"
                    onChange={onDropCompanyLogo}
                    // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                    imgExtension={[".jpg", ".jpeg", ".png"]}
                    maxFileSize={999999999999999999999}
                    singleImage={true}
                    withPreview={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <div className="sendDiv">
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      color="default"
                      checked={checked}
                      //  onChange={handleOpen}
                      onClick={handleOpen}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={{ fontFamily: "Public Sans,sans-serif" }}
                    >
                      By logging in, you agree to abide by our Terms of Use.
                    </Typography>
                  }
                />
              </div>
            </Row>
            <div className="sendDiv">
              {/* {props.isAddingLogo||props.isAddingImage||props.isAddingBackImage||props.isRegister ? (
                <div className="loader-div">
                  <Spinner
                    variant="primary"
                    animation="grow"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : ( */}
              {roleId == 4 || roleId == 1 ? (
                <Button
                  disabled={
                    isEmpty(firstName) ||
                    isEmpty(email) ||
                    // isEmpty(companyName) ||
                    isEmpty(CNIC) ||
                    // isEmpty(NTNNo) ||
                    // isEmpty(companyAddress) ||
                    isEmpty(cellNumber) ||
                    isEmpty(password) ||
                    isEmpty(confirmPassword) ||
                    roleId === 0
                    // isEmpty(roleId) ||
                    // checked
                    // ||
                    // isEmpty(props.imageURL) ||
                    // isEmpty(props.backImageURL) ||
                    // isEmpty(props.logoURL)
                  }
                  style={{
                    marginLeft: "2%",
                    color: "black",
                    width: "20%",
                    backgroundColor: "#C5CBCF",
                    border: "1px solid white",
                  }}
                  onClick={() => registerUser()}
                >
                  Save
                </Button>
              ) : (
                <Button
                  disabled={
                    isEmpty(firstName) ||
                    isEmpty(email) ||
                    isEmpty(companyName) ||
                    isEmpty(CNIC) ||
                    isEmpty(NTNNo) ||
                    isEmpty(companyAddress) ||
                    isEmpty(cellNumber) ||
                    isEmpty(password) ||
                    isEmpty(confirmPassword) ||
                    roleId === 0
                    // isEmpty(roleId) ||
                    // checked
                    // ||
                    // isEmpty(props.imageURL) ||
                    // isEmpty(props.backImageURL) ||
                    // isEmpty(props.logoURL)
                  }
                  style={{
                    marginLeft: "2%",
                    color: "black",
                    width: "20%",
                    backgroundColor: "#C5CBCF",
                    border: "1px solid white",
                  }}
                  onClick={() => registerUser()}
                >
                  SAVE
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontFamily: "Public Sans,sans-serif" }}
          >
            Terms of Use:
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{ fontFamily: "Public Sans,sans-serif" }}
          >
            You agree to use this platform/service in accordance with applicable
            laws and regulations. You understand that any misuse or abuse of the
            platform/service may result in the termination of your account. You
            agree not to engage in any activities that may harm or disrupt the
            functioning of the platform/service. You acknowledge that you are
            responsible for maintaining the confidentiality of your login
            credentials. You agree to respect the privacy of other users and not
            to disclose their personal information without their consent.
          </Typography>
          <Button
            onClick={handleChange}
            style={{
              color: "#6D7C89",
              width: "20%",
              backgroundColor: "#C5CBCF",
              border: "none",
              fontFamily: "Public Sans,sans-serif",
            }}
          >
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (data, handleVisible, handleDangerVisible) =>
      dispatch(RegisterFromAdmin(data, handleVisible, handleDangerVisible)),
    imageFront: (data) => dispatch(addImage(data)),
    imageBack: (data) => dispatch(addImageBack(data)),
    companyLogo: (data) => dispatch(addLogo(data)),
    getRole: () => dispatch(getRole()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isRegister: state.CargoLoginReducer.imageURL,
  imageURL: state.CargoLoginReducer.imageURL,
  isAddingImage: state.CargoLoginReducer.isAddingImage,
  backImageURL: state.CargoLoginReducer.backImageURL,
  isAddingBackImage: state.CargoLoginReducer.isAddingBackImage,
  logoURL: state.CargoLoginReducer.logoURL,
  isAddingLogo: state.CargoLoginReducer.isAddingLogo,
  isFetchingRole: state.UserControlReducer.isFetchingRole,
  roleList: state.UserControlReducer.roleList,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCargo);
