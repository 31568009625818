import API from "../../../global/api";

export const requestAddCurrency = () => {
  return {
    type: "REQUEST_ADD_CURRENCY",
  };
};
export const successAddCurrency = (data) => {
  return {
    type: "SUCCESS_ADD_CURRENCY",
    payload: data,
  };
};
export const errorAddCurrency = () => {
  return {
    type: "ERROR_ADD_CURRENCY",
  };
};
export const addCurrency = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddCurrency());
    API.post(`/currency`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successAddCurrency(data1));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddCurrency());
        handleDangerVisible(error.response.data.message);
      });
  };
};

export const requestGetCurrency = () => {
  return {
    type: "REQUEST_GET_CURRENCY",
  };
};
export const successGetCurrency = (data) => {
  return {
    type: "SUCCESS_GET_CURRENCY",
    payload: data,
  };
};
export const errorGetCurrency = () => {
  return {
    type: "ERROR_GET_CURRENCY",
  };
};
export const getCurrency = () => {
  return (dispatch) => {
    dispatch(requestGetCurrency());
    API.get(`/currency?size=1000&page=1`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetCurrency(getData));
      })
      .catch((error) => {
        dispatch(errorGetCurrency());
      });
  };
};

export const DeleteRequestCurrency = () => {
  return {
    type: "DELETE_REQUEST_CURRENCY",
  };
};
export const DeleteSuccessCurrency = (user_id) => {
  return {
    type: "DELETE_SUCCESS_CURRENCY",
    user_id: user_id,
  };
};
export const DeleteErrorCurrency = () => {
  return {
    type: "DELETE_ERROR_CURRENCY",
  };
};
export const deleteCurrency = (vendorId) => {
  return (dispatch) => {
    dispatch(DeleteRequestCurrency());
    API.delete(`/currency/${vendorId}`)
      .then((res) => {
        dispatch(getCurrency());
        dispatch(DeleteSuccessCurrency(vendorId));
      })
      .catch((error) => {
        dispatch(DeleteErrorCurrency());
      });
  };
};
export const UpdateRequestCurrency = () => {
  return {
    type: "UPDATE_REQUEST_CURRENCY",
  };
};
export const UpdateSuccessCurrency = (data) => {
  return {
    type: "UPDATE_SUCCESS_CURRENCY",
    payload: data,
  };
};

export const UpdateErrorCurrency = () => {
  return {
    type: "UPDATE_ERROR_CURRENCY",
  };
};

export const updateCurrency = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestCurrency());
    API.put(
      `/currency/${oldData.id}`,
      {
        name: data.name,
        sign: data.sign,
        rate: data.rate,
        code: data.code,
      },
      {}
    )
      .then((res) => {
        dispatch(getCurrency());
        dispatch(UpdateSuccessCurrency([data]));
      })
      .catch((error) => dispatch(UpdateErrorCurrency()));
  };
};
