import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "lodash";
import {
  deleteShipment,
  getShipment,
  getShipmentById,
  getShipmentByStatusId,
  updateShipment,
} from "./Shipment.action";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";

const ViewShipmentList = (props) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedProcess, setCheckedProcess] = useState(false);
  const [checkedArrived, setCheckedArrived] = useState(false);
  const [checkedPickUp, setCheckedPickUp] = useState(false);
  const [checkedCompleted, setCheckedCompleted] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    //  props.setHeading("Uom List");
    props.getShipment();
    document.title = props.title;
  }, []);
  const handleChangeAll = (event) => {
    props.getShipment();
    setCheckedAll(true);
    setCheckedProcess(false);
    setCheckedPickUp(false);
    setCheckedArrived(false);
    setCheckedCompleted(false);
  };
  const handleChangeProcess = (event) => {
    props.getShipmentByStatusId(1);
    setCheckedAll(false);
    setCheckedProcess(true);
    setCheckedPickUp(false);
    setCheckedArrived(false);
    setCheckedCompleted(false);
  };
  const handleChangePickUp = (event) => {
    props.getShipmentByStatusId(2);
    setCheckedAll(false);
    setCheckedProcess(false);
    setCheckedPickUp(true);
    setCheckedArrived(false);
    setCheckedCompleted(false);
  };
  const handleChangeArrived = (event) => {
    props.getShipmentByStatusId(3);
    setCheckedAll(false);
    setCheckedProcess(false);
    setCheckedPickUp(false);
    setCheckedArrived(true);
    setCheckedCompleted(false);
  };
  const handleChangeComplete = (event) => {
    props.getShipmentByStatusId(4);
    setCheckedAll(false);
    setCheckedProcess(false);
    setCheckedPickUp(false);
    setCheckedArrived(false);
    setCheckedCompleted(true);
  };

  return (
    <>
      {props.isFetchingShipmentList ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main" style={{ height: "100%" }}>
            <Row>
              {/* <Col xl="2" lg="2" md="2" sm="6" xs="6">
                <Card
                  style={{
                    marginTop: "7px",
                    backgroundColor: "#f1f1f1",
                    padding: "10px",
                    height: props.shipmentList.lenght > 4 ? "auto" : "82vh",
                  }}
                >
                  <Row>
                    <FormControlLabel
                      // required
                      control={
                        <Checkbox
                          checked={checkedAll}
                          onClick={handleChangeAll}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="All"
                    />
                  </Row>
                  <Row>
                    <FormControlLabel
                      // required
                      control={
                        <Checkbox
                          checked={checkedProcess}
                          onClick={handleChangeProcess}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="In prosses"
                    />
                  </Row>
                  <Row>
                    <FormControlLabel
                      // required
                      control={
                        <Checkbox
                          checked={checkedPickUp}
                          onClick={handleChangePickUp}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Picked Up"
                    />
                  </Row>
                  <Row>
                    <FormControlLabel
                      // required
                      control={
                        <Checkbox
                          checked={checkedArrived}
                          onClick={handleChangeArrived}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Arrived"
                    />
                  </Row>
                  <Row>
                    <FormControlLabel
                      // required
                      control={
                        <Checkbox
                          checked={checkedCompleted}
                          onClick={handleChangeComplete}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Completed"
                    />
                  </Row>
                </Card>
              </Col> */}
              <Col>
                {!isEmpty(props.shipmentList) &&
                  props.shipmentList.map((item, i) => {
                    return (
                      <React.Fragment key={item.shipment_id}>
                        <>
                          <Card
                            style={{
                              marginTop: "7px",
                              backgroundColor: "#f1f1f1",
                              padding: "10px",
                              cursor: "pointer !important",
                            }}
                            onClick={() => {
                              props.getShipmentById(item.shipment_id);
                              navigate("/cargo/shipment/view_shipment_by_id");
                            }}
                          >
                            <Row>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b> Tracking id</b>
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b> Shipment Date </b>
                                {item.shipment_date}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b>Ship from</b>
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b>Ship To</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.tracking_id}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b>Delivery Time</b> {item.service_type.name}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.shipment_senders[0].name}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.shipment_receivers[0].name}
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {/* {item.tracking_id} */}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                <b>Shipment Type</b> {item.shipment_type}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.shipment_senders[0].company}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.shipment_receivers[0].company}
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {/* {item.tracking_id} */}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {/* <b>Description</b> {item.shipment_desc} */}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {item.shipment_senders[0].city},{" "}
                                {item.shipment_senders[0].country.name}
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                {/* {item.shipment_receivers[0].company} */}
                                {item.shipment_receivers[0].city},{" "}
                                {item.shipment_receivers[0].country.name}
                              </Col>
                            </Row>
                          </Card>
                        </>
                      </React.Fragment>
                    );
                  })}
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getShipment: (data) => dispatch(getShipment(data)),
    getShipmentById: (id) => dispatch(getShipmentById(id)),
    getShipmentByStatusId: (id) => dispatch(getShipmentByStatusId(id)),
    deleteShipment: (SenderId) => dispatch(deleteShipment(SenderId)),
    updateShipment: (newData, oldData) =>
      dispatch(updateShipment(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  senderList: state.CargoSenderReducer.senderList,
  isFetchingSender: state.CargoSenderReducer.isFetchingSender,
  isFetchingShipmentList: state.CargoShipmentReducer.isFetchingShipmentList,
  shipmentList: state.CargoShipmentReducer.shipmentList,
  // flavorsDataList: state.FlavourReducer.flavorsDataList,
  // sizeDataList: state.sizeReducer.sizeDataList,
  // masterControlDataList:state.MasterControlReducer.masterControlDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewShipmentList);
