const initialState = {
  isFetchingUsers: false,
  usersList: [],
  isFetchingUserByID: false,
  userByID: [],
  isFetchingUserImage: false,
  userImage: [],
  isFetchingRole: false,
  roleList: [],
};

const UserControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_USER":
      return { ...state, isFetchingUsers: true };
    case "SUCCESS_GET_USER":
      return {
        ...state,
        usersList: action.payload,
        isFetchingUsers: false,
      };
    case "ERROR_GET_USER":
      return { ...state, isFetchingUsers: false };

    case "REQUEST_GET_USER_BY_ID":
      return { ...state, isFetchingUserByID: true };
    case "SUCCESS_GET_USER_BY_ID":
      return {
        ...state,
        userByID: action.payload,
        isFetchingUserByID: false,
      };
    case "ERROR_GET_USER_BY_ID":
      return { ...state, isFetchingUserByID: false };
    case "SET_EDIT_USER":
      let valueEmp = state.userByID[0];
      let keyEmp = action.payload.key;
      valueEmp[keyEmp] = action.payload.value;
      let valueEmp2 = [valueEmp];
      return { ...state, userByID: [valueEmp] };

    case "REQUEST_GET_USER_IMAGE":
      return { ...state, isFetchingUserImage: true };
    case "SUCCESS_GET_USER_IMAGE":
      return {
        ...state,
        userImage: action.payload,
        isFetchingUserImage: false,
      };
    case "ERROR_GET_USER_IMAGE":
      return { ...state, isFetchingUserImage: false };
    case "REQUEST_GET_ROLE":
      return { ...state, isFetchingRole: true };
    case "SUCCESS_GET_ROLE":
      return {
        ...state,
        roleList: action.payload.data,
        isFetchingRole: false,
      };
    case "ERROR_GET_ROLE":
      return { ...state, isFetchingRole: false };

    default:
      return state;
  }
};

export default UserControlReducer;
