import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import { getServiceType } from "../shipment/createShipment/CreateShipment.action";
import { InputAdornment } from "@mui/material";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import TextField from "@mui/material/TextField";
import { getPackageWeight, setShowTable, uploadRate } from "./Rates.action";

import { ThemeProvider, createTheme } from "@mui/material";
import { getCountry } from "../country/Country.action";
import moment from "moment";
import swal from "sweetalert";

const RateUpload = (props) => {
  const [type, setType] = useState("");
  // const [countryId, setCountryId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [date, setDate] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [selectedType, setSelectedType] = useState(false);
  // const [getRate, setGetRate] = useState("");
  // const [percentage, setPercentage] = useState("");
  const [file, setFile] = useState("");

  const defaultMaterialTheme = createTheme();

  function calculateRates() {
    props.setShowTable(true);
    props.uploadRate(
      {
        serviceId: serviceId,
        date: date,
        file: file,
        type: type,
        type2: serviceType,
      },
      handleVisible,
      handleDangerVisible
    );
    setDate("");
    setFile("");
    setServiceId("");
    setType("");
    setServiceType("");
  }
  const handleChangeServiceType = (e) => {
    setServiceId(e.target.value);

    setSelectedType(e.target.value === 11);
  };
  const handleChangeServiceTypeVat = (e) => {
    setServiceType(e.target.value);
  };
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    // props.getPackageWeight();
    props.getServiceType();
    // props.getCountry();
    // props.setShowTable(false);
    document.title = props.title;
  }, []);
  const handleChange = (e) => {
    setType(e.target.value);
  };
  return (
    <>
      <Container fluid>
        {props.isFetchingServiceTypeList ? (
          <div className="loader-div">
            <Spinner
              variant="secondary"
              animation="grow"
              size="xl"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <>
            <Card
              style={{
                height: "85vh",
                borderRadius: "30px",
                boxShadow:
                  " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                padding: "2%",
              }}
            >
              <Row style={{ marginTop: "20px" }}>
                <Col xl="3" lg="3" md="3" sm="3" xs="12">
                  <TextField
                    //
                    InputProps={{
                      inputProps: {
                        min: moment(new Date()).format("YYYY-MM-DD"),
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <DateRangeTwoToneIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="date"
                    required
                    id="standard-basic"
                    variant="standard"
                    label="Date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Col>
                <Col xl="3" lg="3" md="3" sm="3" xs="12">
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label" required>
                      Shipment Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Shipment Type"
                      value={type}
                      onChange={handleChange}
                    >
                      <MenuItem key={1} value={"Doc"}>
                        {"DOCUMENT"}
                      </MenuItem>
                      <MenuItem key={0} value={"Pack"}>
                        {"PACKAGES"}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col xl="3" lg="3" md="3" sm="3" xs="12">
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label" required>
                      Select Service
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={serviceId}
                      // disabled={editable}
                      label="Select Service"
                      onChange={handleChangeServiceType}
                    >
                      {props.serviceTypeList.map((name) => (
                        <MenuItem
                          key={name.service_type_id}
                          value={name.service_type_id}
                        >
                          {`${name.name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col
                  xl="3"
                  lg="3"
                  md="3"
                  sm="3"
                  xs="12"
                  style={{ zIndex: 5, marginTop: "15px" }}
                >
                  <Form.Group className="mb-3" controlId="garana">
                    {/* <Form.Label>
                      <b>Upload File Here</b>
                    </Form.Label> */}
                    <input
                      // value={file}
                      className="form-control"
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      style={{
                        border: "none",
                        borderBottom: "1px solid black",
                        borderRadius: 0,
                        outline: "none",
                        width: "100%",
                        background: "transparent",
                        marginTop: "-9px",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {selectedType && (
                <Row>
                  <Col xl="3" lg="3" md="3" sm="3" xs="12">
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        required
                      >
                        Select Service Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={serviceType}
                        // disabled={editable}
                        label="Select Service Type"
                        onChange={handleChangeServiceTypeVat}
                      >
                        <MenuItem key={1} value={"DHL"}>
                          {"VIA UK DHL (VAT PAID)"}
                        </MenuItem>
                        <MenuItem key={0} value={"UPS"}>
                          {"VIA UK UPS (VAT PAID)"}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              )}
              <div className="sendDiv" style={{ marginTop: "15px" }}>
                {props.isUploadingRateList ? (
                  <div className="loader-div">
                    <Spinner
                      variant="secondary"
                      animation="grow"
                      size="xl"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <Button
                    disabled={
                      date == "" ||
                      file == "" ||
                      serviceId == "" ||
                      type == "" ||
                      (selectedType && serviceType === "")
                    }
                    style={{
                      marginLeft: "2%",
                      color: "black",
                      width: "20%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                    onClick={calculateRates}
                  >
                    Upload Rate
                  </Button>
                )}
              </div>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageWeight: (data) => dispatch(getPackageWeight(data)),
    uploadRate: (data, handleVisible, handleDangerVisible) =>
      dispatch(uploadRate(data, handleVisible, handleDangerVisible)),
    getServiceType: () => dispatch(getServiceType()),
    getCountry: () => dispatch(getCountry()),
    setShowTable: (data) => dispatch(setShowTable(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingPackageWeightList:
    state.CargoRateReducer.isFetchingPackageWeightList,
  packageWeightList: state.CargoRateReducer.packageWeightList,
  isShowTable: state.CargoRateReducer.isShowTable,
  calculateRateList: state.CargoRateReducer.calculateRateList,
  isFetchingCalculateRateList:
    state.CargoRateReducer.isFetchingCalculateRateList,
  serviceTypeList: state.CargoCreateShipmentReducer.serviceTypeList,
  isFetchingServiceTypeList:
    state.CargoCreateShipmentReducer.isFetchingServiceTypeList,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  countryList: state.CargoCountryReducer.countryList,
  isUploadingRateList: state.CargoRateReducer.isUploadingRateList,
});

export default connect(mapStateToProps, mapDispatchToProps)(RateUpload);
