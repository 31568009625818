import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Container, Button, Card } from "react-bootstrap";
//import Select, { SelectInstance } from 'react-select';
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
import "jspdf-autotable";
import Swal from "sweetalert";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import AddIcCallTwoToneIcon from "@mui/icons-material/AddIcCallTwoTone";
import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import AddBusinessTwoToneIcon from "@mui/icons-material/AddBusinessTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import DialpadTwoToneIcon from "@mui/icons-material/DialpadTwoTone";
import { phone } from "phone";
import { getCountry } from "../../country/Country.action";
import { addSender, getSender } from "../../sender/Sender.action";
import InputLabel from "@mui/material/InputLabel";
import {
  setAddSenderData,
  setEditable,
  setShipmentRef,
  setShowButtonSender,
  setShowReceiver,
} from "./CreateShipment.action";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ReactSelect from "@mui/material/Select";
const Step1SelectSender = (props) => {
  const [editable, setEditable] = useState(true);
  const [newOrOld, setNewOrOld] = useState(0);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const getSenderList =
    !isEmpty(props.senderList) &&
    props.senderList.map((x) => {
      let data = {
        value: x.sender_id,
        label: `${x.name} / ${x.company}`,
        email: x.email,
        phone: x.phone,
        postal_code: x.postal_code,
        country_id: x.country_id,
        country: x.country.name,
        address: x.address,
        address_2: x.address_2,
        address_3: x.address_3,
        city: x.city,
        state: x.state,
        company: x.company,
        ntn: x.ntn,
        cnic: x.cnic,
        __isNew__: false,
      };
      return data;
    });
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  const [emailError, setEmailError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [addressError2, setAddressError2] = useState(false);
  const [cellNumberError, setCellNumberError] = useState(false);
  const [CNICError, setCNICError] = useState(false);

  const handleCompanyNameChange = (e) => {
    props.setSenderData({
      key: "company",
      value: e.target.value.toUpperCase(),
    });
    if (e.target.value.length > 2) {
      setCompanyError(false);
    } else {
      setCompanyError(true);
    }
  };
  const handleEmailChange = (e) => {
    props.setSenderData({ key: "email", value: e.target.value });

    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };
  const handleAddressChange = (e) => {
    if (e.target.value.length < 45) {
      props.setSenderData({
        key: "address",
        value: e.target.value.toUpperCase(),
      });
      setAddressError(false);
    } else {
      setAddressError(true);
    }
  };
  const handleAddress2Change = (e) => {
    if (e.target.value.length < 45) {
      props.setSenderData({
        key: "address_2",
        value: e.target.value.toUpperCase(),
      });
      setAddressError2(false);
    } else {
      setAddressError2(true);
    }
  };
  const handleCellNumberChange = (e) => {
    let data = phone(e.target.value, {
      country: `${props.senderData[0].country_code}`,
    });
    props.setSenderData({ key: "phone", value: e.target.value });
    if (props.senderData[0].country_code == "US") {
      if (e.target.value.length == 10) {
        setCellNumberError(false);
      } else {
        setCellNumberError(true);
      }
    } else {
      if (data.isValid == true) {
        setCellNumberError(false);
        // props.setSenderData({ key: "phone", value: props.senderData[0].phone });
      } else {
        setCellNumberError(true);
        // props.setSenderData({ key: "phone", value: e.target.value });
      }
    }
    // }
  };
  const handleCNICChange = (e) => {
    const cnicPattern = /^[0-9]{13}$/;
    if (e.target.value.length <= 13) {
      props.setSenderData({ key: "cnic", value: e.target.value });
      if (cnicPattern.test(e.target.value)) {
        setCNICError(false);
      } else {
        setCNICError(true);
      }
    }
  };
  const selectInputRef = useRef();
  const handleChangeSenderType = (e) => {
    setNewOrOld(e.target.value);
    e.target.value == 1 ? props.setEditable(false) : props.setEditable(true);
    e.target.value == 1
      ? props.setShowButtonSender(false)
      : props.setShowButtonSender(true);
    clearData();
    // props.setShowButtonSender(false);
  };
  const selectOption = (e) => {
    // props.setEditable(false);
    // props.setShowButtonSender(true);
    props.ownProps.moveToReceiver();
    props.setSenderData({ key: "key", value: true });
    props.setSenderData({ key: "id", value: e.value });
    props.setSenderData({ key: "name", value: e.label.toUpperCase() });
    props.setSenderData({ key: "country_id", value: e.country_id });
    props.setSenderData({
      key: "country_name",
      value: e.country.toUpperCase(),
    });
    props.setSenderData({ key: "company", value: e.company.toUpperCase() });
    setTestVal({ label: e.label.toUpperCase() });
    props.setSenderData({ key: "address", value: e.address.toUpperCase() });
    props.setSenderData({
      key: "address_2",
      value: e.address_2.toUpperCase(),
    });
    props.setSenderData({
      key: "address_3",
      value: e.address_3.toUpperCase(),
    });
    props.setSenderData({ key: "postal_code", value: e.postal_code });
    props.setSenderData({ key: "city", value: e.city.toUpperCase() });
    props.setSenderData({ key: "state", value: e.state.toUpperCase() });
    props.setSenderData({ key: "email", value: e.email });
    props.setSenderData({ key: "phone", value: e.phone });
    props.setSenderData({ key: "cnic", value: e.cnic });
    props.setSenderData({ key: "vatId", value: e.ntn });
  };
  const newSender = (e) => {
    props.setSenderData({
      key: "name",
      value: e.target.value.toUpperCase(),
    });
    props.setSenderData({ key: "key", value: false });
    props.setSenderData({ key: "id", value: "" });
    props.setSenderData({ key: "country_id", value: 0 });
    props.setSenderData({ key: "company", value: "" });
    props.setSenderData({ key: "address", value: "" });
    props.setSenderData({ key: "country_name", value: "" });
    props.setSenderData({ key: "address_2", value: "" });
    props.setSenderData({ key: "address_3", value: "" });
    props.setSenderData({ key: "postal_code", value: "" });
    props.setSenderData({ key: "city", value: "" });
    props.setSenderData({ key: "state", value: "" });
    props.setSenderData({ key: "email", value: "" });
    props.setSenderData({ key: "phone", value: "" });
    props.setSenderData({ key: "cnic", value: "" });
    props.setSenderData({ key: "vatId", value: "00000" });
  };
  const onOptionsChanged = (e, f) => {
    if (f.action == "clear") {

      setTestVal(null);
      clearData();
    }
   
    if (f.action == "select-option") {
      props.setEditable(false);
      props.setShowButtonSender(true);
      props.ownProps.moveToReceiver();
      props.setSenderData({ key: "key", value: true });
      props.setSenderData({ key: "id", value: e.value });
      props.setSenderData({ key: "name", value: e.label.toUpperCase() });
      props.setSenderData({ key: "country_id", value: e.country_id });
      props.setSenderData({
        key: "country_name",
        value: e.country.toUpperCase(),
      });
      props.setSenderData({ key: "company", value: e.company.toUpperCase() });
      setTestVal({ label: e.label.toUpperCase() });
      props.setSenderData({ key: "address", value: e.address.toUpperCase() });
      props.setSenderData({
        key: "address_2",
        value: e.address_2.toUpperCase(),
      });
      props.setSenderData({
        key: "address_3",
        value: e.address_3.toUpperCase(),
      });
      props.setSenderData({ key: "postal_code", value: e.postal_code });
      props.setSenderData({ key: "city", value: e.city.toUpperCase() });
      props.setSenderData({ key: "state", value: e.state.toUpperCase() });
      props.setSenderData({ key: "email", value: e.email });
      props.setSenderData({ key: "phone", value: e.phone });
      props.setSenderData({ key: "cnic", value: e.cnic });
      props.setSenderData({ key: "vatId", value: e.ntn });
      props.setShowReceiver(true);
    } else if (f.action == "create-option") {
      props.setShowReceiver(false);
      props.setEditable(false);
      props.setShowButtonSender(false);
      props.setSenderData({ key: "key", value: false });
      props.setSenderData({ key: "id", value: "" });
      setTestVal({ label: e.label });
      // props.setSenderData({ key: "edit", value: false });
      props.setSenderData({ key: "name", value: e.label.toUpperCase() });
      props.setSenderData({ key: "country_id", value: 0 });
      props.setSenderData({ key: "company", value: "" });
      props.setSenderData({ key: "address", value: "" });
      props.setSenderData({ key: "country_name", value: "" });
      props.setSenderData({ key: "address_2", value: "" });
      props.setSenderData({ key: "address_3", value: "" });
      props.setSenderData({ key: "postal_code", value: "" });
      props.setSenderData({ key: "city", value: "" });
      props.setSenderData({ key: "state", value: "" });
      props.setSenderData({ key: "email", value: "" });
      props.setSenderData({ key: "phone", value: "" });
      props.setSenderData({ key: "cnic", value: "" });
      props.setSenderData({ key: "vatId", value: "00000" });
    }
  };
  const handleChangeCountry = (e) => {
    props.setSenderData({ key: "country_id", value: e.value });
    props.setSenderData({ key: "country_name", value: e.label.toUpperCase() });
    props.setSenderData({ key: "country_code", value: e.country_code });
    props.setSenderData({ key: "dial_code", value: e.dial_code });
    props.setSenderData({ key: "phone", value: "" });
    setCellNumberError(false);
    // setCountry(e.target.value);
  };

  const handleContinue = () => {
    props.setShowReceiver(true);
    props.ownProps.moveToReceiver();
  };
  const handleSaveSender = () => {
    props.addSender(
      {
        name: props.senderData[0].name,
        company: props.senderData[0].company,
        country_id: props.senderData[0].country_id,
        address: props.senderData[0].address,
        address_2: props.senderData[0].address_2,
        address_3: props.senderData[0].address_3,
        postal_code: props.senderData[0].postal_code,
        city: props.senderData[0].city,
        state: props.senderData[0].state,
        email: props.senderData[0].email,
        phone: props.senderData[0].phone,
      },
      handleVisible,
      handleDangerVisible
    );
    props.ownProps.moveToReceiver();
    props.setShowReceiver(true);
  };
  const clearData = () => {
    props.setSenderData({ key: "name", value: "" });
    props.setSenderData({ key: "country_id", value: 0 });
    props.setSenderData({ key: "country_name", value: "" });
    props.setSenderData({ key: "company", value: "" });
    props.setSenderData({ key: "address", value: "" });
    props.setSenderData({ key: "address_2", value: "" });
    props.setSenderData({ key: "address_3", value: "" });
    props.setSenderData({ key: "postal_code", value: "" });
    props.setSenderData({ key: "city", value: "" });
    props.setSenderData({ key: "state", value: "" });
    props.setSenderData({ key: "email", value: "" });
    props.setSenderData({ key: "phone", value: "" });
    props.setSenderData({ key: "cnic", value: "" });
    props.setSenderData({ key: "vatId", value: "00000" });
  };
  useEffect(() => {
    // clearData();
  }, []);
  const [testval, setTestVal] = useState(null);
  const getCountryList =
    !isEmpty(props.countryList) &&
    props.countryList.map((x) => {
      let data = {
        value: x.country_id,
        label: x.name,
        country_code: x.code,
        dial_code: x.dial_code,
      };
      return data;
    });
  // const creatableRef = useRef<SelectInstance<ColourOption> | null>(null);
  return (
    <>
      <Container fluid>
        {/* <div className="main"> */}
        <Card
          style={{
            borderRadius: "30px",
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            border: "none",
            padding: "20px",
            marginTop: "20px",
            height: props.showButtonSender == false ? "55%" : "50%",
          }}
        >
          <Row>
            <Col xl="12" lg="12" md="12" sm="12" xs="12">
              <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
                <b>FROM</b>
              </Typography>
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            {/* <Col xl="12" lg="12" md="12" sm="12" xs="12" style={{ zIndex: 10 }}>
              <CreatableSelect
                isClearable={true}
                backspaceRemovesValue={true}
                escapeClearsValue={true}
                placeholder="Name"
                contentEditable={true}
                ref={selectInputRef}
                value={testval}
                options={!isEmpty(getSenderList) ? getSenderList : []}
                onChange={onOptionsChanged}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    borderBottom: "1px solid black",
                    borderRadius: 0,
                    boxShadow: "none",
                    backgroundColor: "white",
                  }),
                }}
              />
            </Col> */}
            <Col xl="6" lg="6" md="6" sm="6" xs="12">
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label" required>
                  Select Sender Type
                </InputLabel>
                <ReactSelect
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={newOrOld}
                  // placeholder="Select Sender Type"
                  // label="Select Purpose"
                  onChange={handleChangeSenderType}
                  // input={<OutlinedInput label="Select Purpose" />}
                >
                  <MenuItem key={1} value={1}>
                    New
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    From Contact
                  </MenuItem>
                </ReactSelect>
              </FormControl>
            </Col>
            {newOrOld == 0 || newOrOld == 2 ? (
              <Col xl="6" lg="6" md="6" sm="6" xs="12" style={{ zIndex: 10 }}>
                {/* <p style={{fontSize:'12px',marginBottom:'-8px'}}> */}
                <p
                  style={{
                    fontSize: "12px",
                    fontFamily: "Public Sans,sans-serif",
                    marginBottom: "-2px",
                    color: "#00000061",
                  }}
                >
                  SELECT SENDER*
                </p>
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      borderBottom: "1px solid black",
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: "white",
                      marginTop: "-1px",
                    }),
                  }}
                  isDisabled={isEmpty(getSenderList) || newOrOld == 0}
                  value={{ label: props.senderData[0].name }}
                  // placeholder="SELECT SENDER..."
                  onChange={selectOption}
                  options={!isEmpty(getSenderList) ? getSenderList : []}
                />
              </Col>
            ) : (
              <Col xl="6" lg="6" md="6" sm="6" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DomainTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="Sender Name"
                  // error={companyError}
                  // helperText={
                  //   companyError
                  //     ? "Please Enter Your Company Name (Atleast 3 Character)"
                  //     : ""
                  // }
                  value={props.senderData[0].name}
                  // disabled={props.editSender}
                  onChange={(e) => newSender(e)}
                  sx={{
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                />
              </Col>
            )}
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col xl="4" lg="4" md="4" sm="4" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DomainTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                required
                type="text"
                id="standard-basic"
                variant="standard"
                label="Company"
                error={companyError}
                helperText={
                  companyError
                    ? "Please Enter Your Company Name (Atleast 3 Character)"
                    : ""
                }
                value={props.senderData[0].company}
                disabled={props.editSender}
                onChange={(e) => handleCompanyNameChange(e)}
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                }}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="4" xs="12" style={{ zIndex: 8 }}>
              {/* <p style={{fontSize:'12px',marginBottom:'-8px'}}> */}
              <p
                style={{
                  fontSize: "12px",
                  fontFamily: "Public Sans,sans-serif",
                  marginBottom: "-2px",
                  color: "#00000061",
                }}
              >
                SELECT COUNTRY*
              </p>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    borderBottom: "1px solid black",
                    borderRadius: 0,
                    boxShadow: "none",
                    backgroundColor: "white",
                    marginTop: "-1px",
                  }),
                }}
                isDisabled={
                  isEmpty(props.countryList) || props.editSender == true
                }
                value={{ label: props.senderData[0].country_name }}
                // placeholder="SELECT COUNTRY..."
                onChange={handleChangeCountry}
                options={getCountryList}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="4" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddIcCallTwoToneIcon />
                      {props.senderData[0].dial_code}
                    </InputAdornment>
                  ),
                }}
                required
                type="Number"
                id="standard-basic"
                variant="standard"
                label="Phone"
                // defaultValue={props.senderData[0].dial_code}
                value={props.senderData[0].phone}
                disabled={props.editSender}
                error={cellNumberError}
                helperText={
                  cellNumberError ? "Please Enter Valid Cell Number" : ""
                }
                onChange={(e) => handleCellNumberChange(e)}
                sx={{
                  width: "100%",
                  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  textTransform: "uppercase",
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddBusinessTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                required
                type="text"
                id="standard-basic"
                variant="standard"
                label="Address"
                disabled={props.editSender}
                value={props.senderData[0].address}
                error={addressError}
                helperText={
                  addressError ? "Address Length (Maximium 45 Character)" : ""
                }
                onChange={(e) => handleAddressChange(e)}
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddBusinessTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                label="Address 2"
                disabled={props.editSender}
                value={props.senderData[0].address_2}
                error={addressError2}
                helperText={
                  addressError2 ? "Address Length (Maximium 45 Character)" : ""
                }
                // onChange={(e) => setCompanyAddress2(e.target.value)}
                onChange={(e) => handleAddress2Change(e)}
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddBusinessTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                label="Address 3"
                disabled={props.editSender}
                value={props.senderData[0].address_3}
                // onChange={(e) => setCompanyAddress3(e.target.value)}
                onChange={(e) =>
                  props.setSenderData({
                    key: "address_3",
                    value: e.target.value.toUpperCase(),
                  })
                }
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col xl="4" lg="4" md="4" sm="4" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddLocationAltTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                label="City *"
                value={props.senderData[0].city}
                disabled={props.editSender}
                // onChange={(e) => setCity(e.target.value)}
                onChange={(e) =>
                  props.setSenderData({
                    key: "city",
                    value: e.target.value.toUpperCase(),
                  })
                }
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                }}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="4" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddLocationAltTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                label="State *"
                value={props.senderData[0].state}
                disabled={props.editSender}
                // onChange={(e) => setState(e.target.value)}
                onChange={(e) =>
                  props.setSenderData({
                    key: "state",
                    value: e.target.value.toUpperCase(),
                  })
                }
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                }}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="4" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DialpadTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                // type="number"
                id="standard-basic"
                variant="standard"
                label="Postal Code/Zip Code *"
                value={props.senderData[0].postal_code}
                disabled={props.editSender}
                onChange={(e) =>
                  props.setSenderData({
                    key: "postal_code",
                    value: e.target.value.toUpperCase(),
                  })
                }
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col xl="4" lg="4" md="4" sm="4" xs="12">
              <TextField
                InputProps={{
                  style: { textTransform: "uppercase" },
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                // required
                type="email"
                id="standard-basic"
                variant="standard"
                label="Email"
                value={props.senderData[0].email}
                disabled={props.editSender}
                error={emailError}
                helperText={emailError ? "Please Enter Valid Email" : ""}
                onChange={(e) => handleEmailChange(e)}
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                }}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="4" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <NumbersTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="Number"
                id="standard-basic"
                variant="standard"
                label="CNIC (Without Dashes)"
                error={CNICError}
                value={props.senderData[0].cnic}
                helperText={CNICError ? "Please Enter Valid CNIC" : ""}
                onChange={(e) => handleCNICChange(e)}
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="4" xs="12">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <NumbersTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                type="text"
                id="standard-basic"
                variant="standard"
                label="VAT/Tax ID/NTN"
                value={props.senderData[0].vatId}
                // disabled={editable}
                onChange={(e) =>
                  props.setSenderData({
                    key: "vatId",
                    value: e.target.value.toUpperCase(),
                  })
                }
                sx={{
                  width: "100%",
                  textTransform: "uppercase",
                }}
              />
            </Col>
          </Row>
          {props.showButtonSender == false && (
            <Row style={{ marginTop: "30px" }}>
              <Col xl="2" lg="2" md="2" sm="4" xs="6">
                <Button
                  disabled={
                    props.senderData[0].company === "" ||
                    props.senderData[0].country_id === 0 ||
                    props.senderData[0].address === "" ||
                    props.senderData[0].city === "" ||
                    props.senderData[0].state === "" ||
                    props.senderData[0].postal_code === "" ||
                    props.senderData[0].phone === ""
                    // ||
                    // props.senderData[0].email === ""
                  }
                  style={{
                    marginLeft: "2%",
                    color: "black",
                    width: "80%",
                    backgroundColor: "#C5CBCF",
                    border: "1px solid white",
                  }}
                  onClick={handleSaveSender}
                >
                  Save Sender
                </Button>
              </Col>
              <Col xl="8" lg="8" md="8" sm="4" xs="0"></Col>
              <Col xl="2" lg="2" md="2" sm="4" xs="6">
                <Button
                  disabled={
                    props.senderData[0].company === "" ||
                    props.senderData[0].country_id === 0 ||
                    props.senderData[0].address === "" ||
                    props.senderData[0].phone === ""
                    // || props.senderData[0].email === ""
                  }
                  style={{
                    marginLeft: "2%",
                    color: "black",
                    width: "80%",
                    backgroundColor: "#C5CBCF",
                    border: "1px solid white",
                  }}
                  onClick={handleContinue}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          )}
        </Card>
        {/* </div> */}
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getCountry: () => dispatch(getCountry()),
    getSender: () => dispatch(getSender()),
    setShowReceiver: (data) => dispatch(setShowReceiver(data)),
    setSenderData: (data) => dispatch(setAddSenderData(data)),
    addSender: (data, handleVisible, handleDangerVisible) =>
      dispatch(addSender(data, handleVisible, handleDangerVisible)),
    setEditable: (data) => dispatch(setEditable(data)),
    setShowButtonSender: (data) => dispatch(setShowButtonSender(data)),
    setShipmentRef: (data) => dispatch(setShipmentRef(data)),
    ownProps,
  };
};
const mapStateToProps = (state, ownProps) => ({
  senderList: state.CargoSenderReducer.senderList,
  isFetchingSender: state.CargoSenderReducer.isFetchingSender,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  countryList: state.CargoCountryReducer.countryList,
  senderData: state.CargoCreateShipmentReducer.senderData,
  editSender: state.CargoCreateShipmentReducer.editSender,
  subStepsCreateShipment:
    state.CargoCreateShipmentReducer.subStepsCreateShipment,
  showButtonSender: state.CargoCreateShipmentReducer.showButtonSender,
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1SelectSender);
