import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Container,
  Card,
  Button,
  FormGroup,
  Modal,
  Breadcrumb,
  Navbar,
} from "react-bootstrap";
import { connect } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Select from "react-select";
import StarRateTwoToneIcon from "@mui/icons-material/StarRateTwoTone";

const Reporting = (props) => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [item, setItem] = useState("");
  const [itemCode, setItemCode] = useState("");

  const [state, setState] = React.useState({
    columns: [
      {
        title: "Date",
        field: "updatedAt",
        render: (rowData) => moment(rowData.updatedAt).format("YYYY-MM-DD"),
      },
      { title: "Item Name", field: "metrialName" },
      { title: "Item Code", field: "metrialCode" },
      { title: "D-P-I", field: "" },
      { title: "Previous Balance", field: "balance" },
      { title: "Add Item", field: "add" },
      { title: "Less Item", field: "less" },
      { title: "New Balance", field: "newBalance" },
    ],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    document.title = props.title;
    // props.getData();
  }, []);
  function searchData() {
    //   props.SearchLedgerByDate({
    //     startedDate: startDate,
    //     endDate: endDate,
    //     item: item,
    //     itemCode: itemCode
    //   });
  }
  return (
    <>
      <div></div>
      {props.isFetchingData ? (
        <div className="loader-div">
          <Button
            variant="secondary"
            disabled
            // style={{ backgroundColor: "#0D6EFD" }}
          >
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="secondary"
            />
            Loading
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <div>
              <Form>
                <Accordion>
                  {/* sale */}
                  <Card style={{ marginTop: 10 }}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header style={{ padding: 8 }}>
                        Ledger Reports
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg="10" md="10" sm="10">
                            <Form.Label>
                              <Link
                                to="/cargo/reporting/ledger-report"
                                style={{
                                  textDecoration: "none",
                                  color: "#637381",
                                }}
                              >
                                Ledger Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarRateTwoToneIcon />
                          </Col>

                          {/* <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/monthly/sale-report">
                                Monthly Sale Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col> */}
                        </Row>
                        {/* <Row>
                          <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/Report/Product-Sale-report">
                                Product Sale Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>

                          <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/Report/Specific-Product-Sale-report">
                                Specific Product Sale Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/Report/hot-selling-report">
                                Hot Selling Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/daliy/dayOpen-sale-report">
                                Daily Sale Report (without Day Open)
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card>
                  {/* Orders */}
                  {/* <Card style={{ marginTop: "5px" }}>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header style={{ padding: 8 }}>
                        Orders Report
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/daliy/orderCancel-report">
                              Daily Order Cancellation Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/monthly/orderCancel-report">
                              Monthly Order Cancellation Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card> */}
                  {/* Discount */}
                  {/* <Card style={{ marginTop: "5px" }}>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header style={{ padding: 8 }}>
                        Discount Report
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/daliy/discount-report">
                              Daily Discount Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/monthly/discount-report">
                              Monthly Discount Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card> */}
                  {/* promo */}
                  {/* <Card style={{ marginTop: "5px" }}>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header style={{ padding: 8 }}>
                        Promo Report
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/daliy/promo-report">
                              Daily Promo Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/monthly/promo-report">
                              Monthly Promo Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card> */}
                </Accordion>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({
  sender: state.CargoSenderReducer.sender,
  isAddingSender: state.CargoSenderReducer.isAddingSender,
  isFetchingCountry: state.CargoCountryReducer.isFetchingCountry,
  countryList: state.CargoCountryReducer.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);
