import API from "../../../global/api";

export const requestAddSender = () => {
  return {
    type: "REQUEST_ADD_SENDER",
  };
};
export const successAddSender = (data) => {
  return {
    type: "SUCCESS_ADD_SENDER",
    payload: data,
  };
};
export const errorAddSender = () => {
  return {
    type: "ERROR_ADD_SENDER",
  };
};
export const addSender = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddSender());
    API.post(`/sender`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successAddSender(data1));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddSender());
        handleDangerVisible(error.response.data.message);
      });
  };
};

export const requestGetSender = () => {
  return {
    type: "REQUEST_GET_SENDER",
  };
};
export const successGetSender = (data) => {
  return {
    type: "SUCCESS_GET_SENDER",
    payload: data,
  };
};
export const errorGetSender = () => {
  return {
    type: "ERROR_GET_SENDER",
  };
};
export const getSender = () => {
  return (dispatch) => {
    dispatch(requestGetSender());
    API.get(`/sender`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetSender(getData));
      })
      .catch((error) => {
        dispatch(errorGetSender());
      });
  };
};
export const DeleteRequestSender = () => {
  return {
    type: "DELETE_REQUEST_SENDER",
  };
};
export const DeleteSuccessSender = (user_id) => {
  return {
    type: "DELETE_SUCCESS_SENDER",
    user_id: user_id,
  };
};
export const DeleteErrorSender = () => {
  return {
    type: "DELETE_ERROR_SENDER",
  };
};
export const deleteSender = (vendorId) => {
  return (dispatch) => {
    dispatch(DeleteRequestSender());
    API.delete(`/sender/${vendorId}`)
      .then((res) => {
        dispatch(getSender());
        dispatch(DeleteSuccessSender(vendorId));
      })
      .catch((error) => {
        dispatch(DeleteErrorSender());
      });
  };
};
export const UpdateRequestSender = () => {
  return {
    type: "UPDATE_REQUEST_SENDER",
  };
};
export const UpdateSuccessSender = (data) => {
  return {
    type: "UPDATE_SUCCESS_SENDER",
    payload: data,
  };
};

export const UpdateErrorSender = () => {
  return {
    type: "UPDATE_ERROR_SENDER",
  };
};

export const updateSender = (data, id, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(UpdateRequestSender());
    API.put(
      `/sender/${id}`,
      data,
      // {
      //   name: data.name,
      //   company: data.company,
      //    country_id:data.country_id,
      //   address: data.address,
      //   address_2:data.address_2,
      //   address_3:data.address_3,
      //   postal_code:data.postal_code,
      //   city:data.city,
      //   state:data.state,
      //   email: data.email,
      //   phone: data.phone,
      // }
      {}
    )
      .then((res) => {
        dispatch(getSender());
        dispatch(UpdateSuccessSender([data]));
      })
      .catch((error) => dispatch(UpdateErrorSender()));
  };
};

export const requestGetSenderById = () => {
  return {
    type: "REQUEST_GET_SENDER_BY_ID",
  };
};
export const successGetSenderById = (data) => {
  return {
    type: "SUCCESS_GET_SENDER_BY_ID",
    payload: data,
  };
};
export const errorGetSenderById = () => {
  return {
    type: "ERROR_GET_SENDER_BY_ID",
  };
};
export const getSenderById = (id) => {
  return (dispatch) => {
    dispatch(requestGetSenderById());
    API.get(`/sender/${id}`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGetSenderById(getData));
      })
      .catch((error) => {
        dispatch(errorGetSenderById());
      });
  };
};
export const setEditSender = (data) => {
  return {
    type: "SET_EDIT_SENDER",
    payload: data,
  };
};
