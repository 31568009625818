const initialState = {
  isFetchingComplaint: false,
  complaintList: [],
  complaint: [],
  isAddingComplaint: false,
  complaintByID: [],
  isFetchingComplaintByID: false,
  editComplaintByID: [],
  isFetchingEditComplaintByID: false,
  complaintStatusList: [],
  isFetchingComplaintStatus: false,
};

const ComplaintReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_COMPLAINT":
      return { ...state, isFetchingComplaint: true };
    case "SUCCESS_GET_COMPLAINT":
      return {
        ...state,
        complaintList: action.payload.data,
        isFetchingComplaint: false,
      };
    case "ERROR_GET_COMPLAINT":
      return { ...state, isFetchingComplaint: false };

    case "REQUEST_ADD_COMPLAINT":
      return { ...state, isAddingComplaint: true };
    case "SUCCESS_ADD_COMPLAINT":
      return {
        ...state,
        complaint: action.payload,
        isAddingComplaint: false,
      };
    case "ERROR_ADD_COMPLAINT":
      return { ...state, isAddingComplaint: false };
    case "REQUEST_GET_COMPLAINT_BY_ID":
      return { ...state, isFetchingComplaintByID: true };
    case "SUCCESS_GET_COMPLAINT_BY_ID":
      return {
        ...state,
        complaintByID: action.payload.data,
        isFetchingComplaintByID: false,
      };
    case "ERROR_GET_COMPLAINT_BY_ID":
      return { ...state, isFetchingComplaintByID: false };

    case "REQUEST_EDIT_COMPLAINT_BY_ID":
      return { ...state, isFetchingEditComplaintByID: true };
    case "SUCCESS_EDIT_COMPLAINT_BY_ID":
      return {
        ...state,
        editComplaintByID: action.payload.data,
        isFetchingEditComplaintByID: false,
      };
    case "ERROR_EDIT_COMPLAINT_BY_ID":
      return { ...state, isFetchingEditComplaintByID: false };
    case "SET_EDIT_COMPLAINT":
      let valueEmp = state.editComplaintByID[0];
      let keyEmp = action.payload.key;
      valueEmp[keyEmp] = action.payload.value;
      let valueEmp2 = [valueEmp];
      return { ...state, editComplaintByID: [valueEmp] };

    case "REQUEST_GET_COMPLAINT_STATUS":
      return { ...state, isFetchingComplaintStatus: true };
    case "SUCCESS_GET_COMPLAINT_STATUS":
      return {
        ...state,
        complaintStatusList: action.payload.data,
        isFetchingComplaintStatus: false,
      };
    case "ERROR_GET_COMPLAINT_STATUS":
      return { ...state, isFetchingComplaintStatus: false };
    case "RESET_DATA":
      return { ...state, complaintByID: [], complaint: [] };
    default:
      return state;
  }
};

export default ComplaintReducer;
