import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Card,
  Image,
} from "react-bootstrap";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import ImageUploader from "react-images-upload";
import logo from "../../../Images/logo.png";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import AddIcCallTwoToneIcon from "@mui/icons-material/AddIcCallTwoTone";
import NumbersTwoToneIcon from "@mui/icons-material/NumbersTwoTone";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import DialpadTwoToneIcon from "@mui/icons-material/DialpadTwoTone";

import {
  getRole,
  getUserImage,
  setEditUser,
  updateUser,
} from "./UserControl.action";
import { getCountry } from "../country/Country.action";
import {
  addImage,
  addImageBack,
  addLogo,
  resetImages,
} from "../cargoLogin/CargoLogin.action";
import { baseURL } from "../../../global/api";

const EditUser = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [postalCode, setPostalCode] = useState(0);
  const [city, setCity] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyAddress2, setCompanyAddress2] = useState("");
  const [companyAddress3, setCompanyAddress3] = useState("");
  const [state, setState] = useState("");
  const [countryId, setCountryId] = useState("");
  const [country, setCountry] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function saveSender() {
    props.updateUser(
      {
        firstName: props.userByID[0].firstName,
        lastName: props.userByID[0].lastName,
        email: props.userByID[0].email,
        companyName: props.userByID[0].companyName,
        webSite: props.userByID[0].webSite,
        gst: props.userByID[0].gst,
        ntn: props.userByID[0].ntn,
        cnic: props.userByID[0].cnic,
        address: props.userByID[0].address,
        phone_number: props.userByID[0].phone_number,
        role: props.userByID[0].roleId,
        companyLogo: isEmpty(props.logoURL)
          ? props.userByID[0].companyLogo
          : props.logoURL.path,
        cnicFrontImage: isEmpty(props.imageURL)
          ? props.userByID[0].cnicFrontImage
          : props.imageURL.path,
        cnicBackImage: isEmpty(props.backImageURL)
          ? props.userByID[0].cnicBackImage
          : props.backImageURL.path,
      },
      props.userByID[0].id,
      handleVisible,
      handleDangerVisible
    );
  }

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [websiteURLError, setwebsiteURLError] = useState(false);
  const [cellNumberError, setCellNumberError] = useState(false);
  const [CNICError, setCNICError] = useState(false);

  const handleNameChange = (e) => {
    props.setEditUser({
      key: "firstName",
      value: e.target.value.toUpperCase(),
    });
    if (e.target.value.length > 3) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };
  const handleEmailChange = (e) => {
    props.setEditUser({ key: "email", value: e.target.value });
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };
  const handleCellNumberChange = (e) => {
    const pattern =
      /^(?:\+92|0)?(3\d{2}|5[0-9]\d{1}|7[0-9]\d{1}|8[0-9]\d{1}|9[0-9]\d{1})\d{7}$/;
    const inputValue = e.target.value;
    if (e.target.value.length <= 11) {
      props.setEditUser({
        key: "phone_number",
        value: inputValue,
      });
      // setCellNumber(inputValue);

      if (pattern.test(inputValue)) {
        setCellNumberError(false);
      } else {
        setCellNumberError(true);
      }
    }
  };
  const handleCNICChange = (e) => {
    const cnicPattern = /^[0-9]{13}$/;
    if (e.target.value.length <= 13) {
      // setCNIC(e.target.value);
      props.setEditUser({ key: "cnic", value: e.target.value });
      if (cnicPattern.test(e.target.value)) {
        setCNICError(false);
      } else {
        setCNICError(true);
      }
    }
  };
  const getCountryList =
    !isEmpty(props.countryList) &&
    props.countryList.map((x) => {
      let data = { value: x.country_id, label: x.name };
      return data;
    });
  const handleChangeCountry = (e) => {
    setCountryId(e.target.value);
    setCountry(e.target.value);
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    props.imageFront({
      image: pictureFiles[0],
    });
  };
  const onDropBackImage = (pictureFiles, pictureDataURLs) => {
    props.imageBack({
      image: pictureFiles[0],
    });
  };
  const onDropCompanyLogo = (pictureFiles, pictureDataURLs) => {
    props.companyLogo({
      image: pictureFiles[0],
    });
  };

  useEffect(() => {
    props.getRole();
    props.resetImage();
    document.title = props.title;
  }, []);
  return (
    <>
      {props.isFetchingUserById || props.isFetchingRole ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{
              height: "auto",
              borderRadius: "30px",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Row style={{ marginTop: "15px" }}>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  error={nameError}
                  value={
                    !isEmpty(props.userByID) && props.userByID[0].firstName
                  }
                  helperText={
                    nameError
                      ? "Please Enter Your First Name (Atleast 3 Character)"
                      : ""
                  }
                  label="First Name"
                  onChange={(e) => handleNameChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="Last Name "
                  value={!isEmpty(props.userByID) && props.userByID[0].lastName}
                  onChange={(e) =>
                    props.setEditUser({
                      key: "lastName",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    type: "email",
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="email"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="Email "
                  error={emailError}
                  value={!isEmpty(props.userByID) && props.userByID[0].email}
                  helperText={emailError ? "Please Enter Valid Email" : ""}
                  onChange={(e) => handleEmailChange(e)}
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddIcCallTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="Cell Number "
                  value={
                    !isEmpty(props.userByID) && props.userByID[0].phone_number
                  }
                  error={cellNumberError}
                  // value={cellNumber}
                  helperText={
                    cellNumberError ? "Please Enter Valid Cell Number" : ""
                  }
                  onChange={(e) => handleCellNumberChange(e)}
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DomainTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="Company Name "
                  value={
                    !isEmpty(props.userByID) && props.userByID[0].companyName
                  }
                  onChange={(e) =>
                    props.setEditUser({
                      key: "companyName",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LanguageTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  label="Website "
                  value={!isEmpty(props.userByID) && props.userByID[0].webSite}
                  onChange={(e) =>
                    props.setEditUser({ key: "webSite", value: e.target.value })
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DialpadTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  required
                  value={!isEmpty(props.userByID) && props.userByID[0].ntn}
                  label="NTN No "
                  onChange={(e) =>
                    props.setEditUser({ key: "ntn", value: e.target.value })
                  }
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DialpadTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  label="GST No "
                  value={!isEmpty(props.userByID) && props.userByID[0].gst}
                  onChange={(e) =>
                    props.setEditUser({ key: "gst", value: e.target.value })
                  }
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddLocationAltTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="standard-basic"
                  variant="standard"
                  required
                  value={!isEmpty(props.userByID) && props.userByID[0].address}
                  label="Company Address"
                  onChange={(e) =>
                    props.setEditUser({
                      key: "address",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="Number"
                  id="standard-basic"
                  variant="standard"
                  required
                  label="CNIC"
                  error={CNICError}
                  // value={CNIC}
                  helperText={
                    CNICError ? "Please Enter Valid CNIC (Without Dashes)" : ""
                  }
                  value={!isEmpty(props.userByID) && props.userByID[0].cnic}
                  onChange={(e) => handleCNICChange(e)}
                  sx={{
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="12">
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label" required>
                    Select Acccount Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={!isEmpty(props.userByID) && props.userByID[0].roleId}
                    label="Select Acccount Type *"
                    onChange={(e) =>
                      props.setEditUser({
                        key: "roleId",
                        value: e.target.value,
                      })
                    }
                  >
                    {!isEmpty(props.roleList) &&
                      props.roleList.map((name) => (
                        <MenuItem key={name.id} value={name.id}>
                          {name.role.toUpperCase()}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Row style={{ marginTop: "25px" }}>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                {!isEmpty(props.userByID) && isEmpty(props.imageURL) && (
                  <>
                    <Form.Label>
                      <b style={{ fontSize: "large" }}>Old CNIC Front Image</b>
                    </Form.Label>
                    <Image
                      src={`${baseURL}/get_image_data_by_path/${props.userByID[0].cnicFrontImage}`}
                      // src={isNull(pfpPath) ? image : fetchProfileImages()}
                      rounded={true}
                      width="100%"
                      style={{ padding: "2%", textAlign: "center" }}
                    />
                  </>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ fontSize: "large" }}>
                      Upload CNIC Front Image *
                    </b>
                  </Form.Label>
                  <ImageUploader
                    fileContainerStyle={{
                      borderColor: "red",
                      borderWidth: "2px",
                    }}
                    withIcon={false}
                    buttonText="Upload Image"
                    onChange={onDrop}
                    imgExtension={[".jpg", ".jpeg", ".png"]}
                    maxFileSize={999999999999999999999}
                    singleImage={true}
                    withPreview={true}
                  />
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                {!isEmpty(props.userByID) && isEmpty(props.backImageURL) && (
                  <>
                    <Form.Label>
                      <b style={{ fontSize: "large" }}>Old CNIC Back Image</b>
                    </Form.Label>
                    <Image
                      src={`${baseURL}/get_image_data_by_path/${props.userByID[0].cnicBackImage}`}
                      // src={isNull(pfpPath) ? image : fetchProfileImages()}
                      rounded={true}
                      width="100%"
                      style={{ padding: "2%", textAlign: "center" }}
                    />
                  </>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ fontSize: "large" }}>
                      Upload CNIC Back Image *
                    </b>
                  </Form.Label>
                  <ImageUploader
                    withIcon={false}
                    buttonText="Upload Image"
                    onChange={onDropBackImage}
                    imgExtension={[".jpg", ".jpeg", ".png"]}
                    maxFileSize={999999999999999999999}
                    singleImage={true}
                    withPreview={true}
                  />
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs="12">
                {!isEmpty(props.userByID) && isEmpty(props.logoURL) && (
                  <>
                    <Form.Label>
                      <b style={{ fontSize: "large" }}>Old Company Logo</b>
                    </Form.Label>
                    <Image
                      src={`${baseURL}/get_image_data_by_path/${props.userByID[0].companyLogo}`}
                      // src={isNull(pfpPath) ? image : fetchProfileImages()}
                      rounded={true}
                      width="100%"
                      style={{ padding: "2%", textAlign: "center" }}
                    />
                  </>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ fontSize: "large" }}>Upload Company Logo *</b>
                  </Form.Label>
                  <ImageUploader
                    withIcon={false}
                    buttonText="Upload Image"
                    onChange={onDropCompanyLogo}
                    imgExtension={[".jpg", ".jpeg", ".png"]}
                    maxFileSize={999999999999999999999}
                    singleImage={true}
                    withPreview={true}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              <Button
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "20%",
                  backgroundColor: "#C5CBCF",
                  border: "1px solid white",
                }}
                onClick={() => saveSender()}
              >
                Save
              </Button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data, id, handleVisible, handleDangerVisible) =>
      dispatch(updateUser(data, id, handleVisible, handleDangerVisible)),
    getCountry: () => dispatch(getCountry()),
    setEditUser: (data) => dispatch(setEditUser(data)),
    getRole: () => dispatch(getRole()),
    getImagesPath: () => dispatch(getUserImage()),
    imageFront: (data) => dispatch(addImage(data)),
    imageBack: (data) => dispatch(addImageBack(data)),
    companyLogo: (data) => dispatch(addLogo(data)),
    resetImage: () => dispatch(resetImages()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  userByID: state.CargoUserReducer.userByID,
  isFetchingUserById: state.CargoUserReducer.isFetchingUserById,
  isFetchingRole: state.CargoLoginReducer.isFetchingRole,
  roleList: state.CargoLoginReducer.roleList,
  imageURL: state.CargoLoginReducer.imageURL,
  isAddingImage: state.CargoLoginReducer.isAddingImage,
  backImageURL: state.CargoLoginReducer.backImageURL,
  isAddingBackImage: state.CargoLoginReducer.isAddingBackImage,
  logoURL: state.CargoLoginReducer.logoURL,
  isAddingLogo: state.CargoLoginReducer.isAddingLogo,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
