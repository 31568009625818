const initialState = {
  isFetchingLedgerData: false,
  ledgerlist: [],
  ledgerSearchlist: [],
  isFetchingLevel5LedgerData: false,
  level5LedgerData: [],
  isFetchingSearchAccountLedger: false,
  searchAccountLedgerList: [],
  searchAccountLedgerListReport: [],
  isFetchingSearchAccountLedgerReport: false,
  searchAccountCashBookList: [],
  isFetchingSearchAccountCashBook: false,
};

const AccountLegderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_LEDGER":
      return { ...state, isFetchingLedgerData: true };
    case "SUCCESS_ADD_LEDGER":
      return {
        ...state,
        ledgerlist: action.payload.data,
        isFetchingLedgerData: false,
      };
    case "ERROR_ADD_LEDGER":
      return { ...state, isFetchingLedgerData: false };


    case "REQUEST_ADD_LEDGER_BY_DATE":
      return { ...state };
    case "SUCCESS_ADD_LEDGER_BY_DATE":
      return {
        ...state,
        ledgerSearchlist: action.payload.data,
      };
    case "ERROR_ADD_LEDGER_BY_DATE":
      return { ...state };


    case "REQUEST_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: true };
    case "SUCCESS_LEVEL5_LEDGER_DATA":
      return {
        ...state,
        level5LedgerData: action.payload,
        isFetchingLevel5LedgerData: false
      };
    case "ERROR_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: false };


    case "REQUEST_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: true };
    case "SUCCESS_LEVEL5_LEDGER_DATA":
      return {
        ...state,
        level5LedgerData: action.payload,
        isFetchingLevel5LedgerData: false
      };
    case "ERROR_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: false };

    case "ERROR_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: false };


    case "REQUEST_SEARCH_LEDGER":
      return { ...state, isFetchingSearchAccountLedger: true };
    case "SUCCESS_SEARCH_LEDGER":
      return {
        ...state,
        searchAccountLedgerList: action.payload,
        isFetchingSearchAccountLedger: false
      };
    case "ERROR_SEARCH_LEDGER":
      return { ...state, isFetchingSearchAccountLedger: false };


    case "REQUEST_SEARCH_LEDGER_REPORT":
      return { ...state, isFetchingSearchAccountLedgerReport: true };
    case "SUCCESS_SEARCH_LEDGER_REPORT":
      return {
        ...state,
        searchAccountLedgerListReport: action.payload,
        isFetchingSearchAccountLedgerReport: false
      };
    case "ERROR_SEARCH_CASH_BOOK":
      return { ...state, isFetchingSearchAccountCashBook: false };
      case "REQUEST_SEARCH_CASH_BOOK":
        return { ...state, isFetchingSearchAccountCashBook: true };
      case "SUCCESS_SEARCH_CASH_BOOK":
        return {
          ...state,
          searchAccountCashBookList: action.payload,
          isFetchingSearchAccountCashBook: false
        };
      case "ERROR_SEARCH_CASH_BOOK":
        return { ...state, isFetchingSearchAccountLedgerReport: false };
      return { ...state };
    default:
      return state;
  }
};
export default AccountLegderReducer;
