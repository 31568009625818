import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";

import { connect } from "react-redux";
import { sumBy, isEmpty } from "lodash";
import "jspdf-autotable";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import Typography from "@mui/material/Typography";
import {
  backSteps,
  handleAddInputPackagingDetail,
  handleAddInputPackagingDetailWithData,
  handleRemoveInputPackagingDetail,
  nextSteps,
  setAddPackagingDetail,
} from "./Shipment.action";
const Step2SelectPackaging = (props) => {
  const [packages, setPackages] = useState(1);
  const handleAddInput1 = (e) => {
    // props.handleRemoveInputPackagingDetail()
    let pkVal = isEmpty(e.target.value) ? 1 : e.target.value;
    setPackages(pkVal);
    // handleAddInputPackagingDetailWithData
    // if(e.target.value<props.packagingDetail.length)
    // for (let i = 1; i < e.target.value; i++) {
    //     props.handleRemoveInputPackagingDetail(i)
    //  }
    // else{
    // for (let i = 1; i < e.target.value; i++) {
    //    props.handleAddInputPackagingDetail()
    // }
    if (props.packagingDetail.length < pkVal) {
      for (let i = props.packagingDetail.length; i < pkVal; i++) {
        props.handleAddInputPackagingDetail();
      }
    } else if (props.packagingDetail.length > pkVal) {
      for (let i = props.packagingDetail.length; i >= pkVal; i--) {
        props.handleRemoveInputPackagingDetail(i);
      }
    }
  };
  const handleRemoveInput1 = (i) => {
    props.handleRemoveInputPackagingDetail(i);
  };
  const handleWeight = (e, i) => {
    let pkVal = Number(e.target.value) > 0 ? e.target.value : 0.1
    props.setPackagingDetail({
      key: "weight",
      value: Number(pkVal),
      length: i,
    });
    let accWeight =
      (Number(props.packagingDetail[i].length) *
        Number(props.packagingDetail[i].width) *
        Number(props.packagingDetail[i].height)) /
      5000;
    props.setPackagingDetail({
      key: "accWeight",
      value: (
        (Number(props.packagingDetail[i].length) *
          Number(props.packagingDetail[i].width) *
          Number(props.packagingDetail[i].height)) /
        5000
      ).toFixed(2),
      length: i,
    });
    Number(pkVal) < accWeight
      ? props.setPackagingDetail({
        key: "chWeight",
        value: Math.ceil(accWeight),
        length: i,
      })
      : props.setPackagingDetail({
        key: "chWeight",
        value: Math.ceil(pkVal),
        length: i,
      });
  };
  const handleWidth = (e, i) => {
    let pkVal = Number(e.target.value) > 0 ? e.target.value : 0.1
    props.setPackagingDetail({
      key: "width",
      value: Number(pkVal),
      length: i,
    });
    let accWeight =
      (Number(props.packagingDetail[i].length) *
        Number(pkVal) *
        Number(props.packagingDetail[i].height)) /
      5000;
    props.setPackagingDetail({
      key: "accWeight",
      value: (
        (Number(props.packagingDetail[i].length) *
          Number(pkVal) *
          Number(props.packagingDetail[i].height)) /
        5000
      ).toFixed(2),
      length: i,
    });
    Number(props.packagingDetail[i].weight) < accWeight
      ? props.setPackagingDetail({
        key: "chWeight",
        value: Math.ceil(accWeight),
        length: i,
      })
      : props.setPackagingDetail({
        key: "chWeight",
        value: Math.ceil(props.packagingDetail[i].weight),
        length: i,
      });
  };
  const handleHeight = (e, i) => {
    let pkVal = Number(e.target.value) > 0 ? e.target.value : 0.1
    props.setPackagingDetail({
      key: "height",
      value: Number(pkVal),
      length: i,
    });
    let accWeight =
      (Number(props.packagingDetail[i].length) *
        Number(props.packagingDetail[i].width) *
        Number(pkVal)) /
      5000;
    props.setPackagingDetail({
      key: "accWeight",
      value: (
        (Number(props.packagingDetail[i].length) *
          Number(props.packagingDetail[i].width) *
          Number(pkVal)) /
        5000
      ).toFixed(2),
      length: i,
    });
    Number(props.packagingDetail[i].weight) < accWeight
      ? props.setPackagingDetail({
        key: "chWeight",
        value: Math.ceil(accWeight),
        length: i,
      })
      : props.setPackagingDetail({
        key: "chWeight",
        value: Math.ceil(props.packagingDetail[i].weight),
        length: i,
      });
  };
  const handleLength = (e, i) => {
    let pkVal = Number(e.target.value) > 0 ? e.target.value : 0.1
    props.setPackagingDetail({
      key: "length",
      value: Number(pkVal),
      length: i,
    });
    let accWeight =
      (Number(pkVal) *
        Number(props.packagingDetail[i].width) *
        Number(props.packagingDetail[i].height)) /
      5000;
    props.setPackagingDetail({
      key: "accWeight",
      value: (
        (Number(pkVal) *
          Number(props.packagingDetail[i].width) *
          Number(props.packagingDetail[i].height)) /
        5000
      ).toFixed(2),
      length: i,
    });
    Number(props.packagingDetail[i].weight) < accWeight
      ? props.setPackagingDetail({
        key: "chWeight",
        value: Math.ceil(accWeight),
        length: i,
      })
      : props.setPackagingDetail({
        key: "chWeight",
        value: Math.ceil(props.packagingDetail[i].weight),
        length: i,
      });
  };

  useEffect(() => { }, []);
  return (
    <>
      <Card
        style={{
          borderRadius: "30px",
          boxShadow:
            " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: "none",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Row style={{ marginTop: "20px" }}>
          <Col xl="6" lg="6" md="6" sm="6" xs="6">
            <TextField
              InputProps={{
                inputProps: { min: 1 },
                startAdornment: (
                  <InputAdornment position="start">
                    <AddBoxTwoToneIcon />
                  </InputAdornment>
                ),
              }}
              type="number"
              id="standard-basic"
              variant="standard"
              label="Number of Packages *"
              value={packages}
              onChange={(e) => handleAddInput1(e)}
              // onChange={(e) =>
              //   props.setAddPackagingDetail({ key: "no_packages", value: e.target.value })
              // }
              sx={{
                width: "100%",
                // "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                //     WebkitAppearance: "none",
                //     margin: 0,
                //   },
                //   "input[type=number]": {
                //     MozAppearance: "textfield",
                //   },
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
              <b>Select Packaging</b>
            </Typography>
          </Col>
        </Row>
        {props.packagingDetail.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <>
                <Card
                  style={{
                    marginTop: "10px",
                    padding: "20px",
                    borderLeftWidth: "10px",
                  }}
                >
                  <Row style={{ marginTop: "15px" }}>
                    <Col xl="3" lg="3" md="3" sm="3" xs="6">
                      <TextField
                        InputProps={{
                          style: { fontSize: 12 },
                        }}
                        type="text"
                        id="standard-basic"
                        variant="standard"
                        label="Packaging"
                        value={props.packagingDetail[i].name}
                        // value="Documents"
                        disabled
                        // style={{ backgroundColor: selectType == "Doc" ? 'silver' : "" }}
                        // onChange={(e) => props.setPackagingDetail({ key: "name", value: e.target.value, length: i })}

                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="6">
                      <TextField
                        InputProps={{
                          inputProps: { min: 1 },
                          style: { fontSize: 12 },

                          endAdornment: (
                            <InputAdornment position="end">cm</InputAdornment>
                          ),
                        }}
                        type="number"
                        step="any"
                        id="standard-basic"
                        variant="standard"
                        label="Length"
                        value={props.packagingDetail[i].length}
                        onChange={(e) => handleLength(e, i)}
                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="6">
                      <TextField
                        InputProps={{
                          inputProps: { min: 1 },

                          style: { fontSize: 12 },
                          endAdornment: (
                            <InputAdornment position="end">cm</InputAdornment>
                          ),
                        }}
                        type="number"
                        step="any"
                        id="standard-basic"
                        variant="standard"
                        label="Width"
                        value={props.packagingDetail[i].width}
                        // value="Documents"
                        // style={{ backgroundColor: selectType == "Doc" ? 'silver' : "" }}
                        // onChange={(e) => props.setPackagingDetail({ key: "width", value: Number(e.target.value), length: i })}
                        onChange={(e) => handleWidth(e, i)}
                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="6">
                      <TextField
                        InputProps={{
                          inputProps: { min: 1 },
                          style: { fontSize: 12 },
                          endAdornment: (
                            <InputAdornment position="end">cm</InputAdornment>
                          ),
                        }}
                        type="number"
                        step="any"
                        id="standard-basic"
                        variant="standard"
                        label="Height"
                        value={props.packagingDetail[i].height}
                        // value="Documents"
                        // style={{ backgroundColor: selectType == "Doc" ? 'silver' : "" }}
                        // onChange={(e) => props.setPackagingDetail({ key: "height", value: Number(e.target.value), length: i })}
                        onChange={(e) => handleHeight(e, i)}
                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col xl="3" lg="3" md="3" sm="3" xs="6">
                      <TextField
                        InputProps={{
                          inputProps: { min: 0.1 },
                          style: { fontSize: 12 },
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                        type="number"
                        step="any"
                        id="standard-basic"
                        variant="standard"
                        label="Weight"
                        value={props.packagingDetail[i].weight}
                        // value="Documents"
                        // style={{ backgroundColor: selectType == "Doc" ? 'silver' : "" }}
                        onChange={(e) => handleWeight(e, i)}
                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="6">
                      <TextField
                        InputProps={{
                          style: { fontSize: 12 },
                          // endAdornment: (
                          //     <InputAdornment position="end">
                          //         kg
                          //     </InputAdornment>
                          // ),
                        }}
                        type="number"
                        step="any"
                        id="standard-basic"
                        variant="standard"
                        label="Dimensional Weight"
                        disabled
                        value={props.packagingDetail[i].accWeight}
                        // value="Documents"
                        // style={{ backgroundColor: selectType == "Doc" ? 'silver' : "" }}
                        onChange={(e) =>
                          props.setPackagingDetail({
                            key: "weight",
                            value: Number(e.target.value),
                            length: i,
                          })
                        }
                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="3" xs="6">
                      <TextField
                        InputProps={{
                          style: { fontSize: 12 },
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                        type="text"
                        step="any"
                        id="standard-basic"
                        variant="standard"
                        label="Chargeable Weight"
                        disabled
                        value={props.packagingDetail[i].chWeight}
                        // value="Documents"
                        // style={{ backgroundColor: selectType == "Doc" ? 'silver' : "" }}
                        onChange={(e) =>
                          props.setPackagingDetail({
                            key: "weight",
                            value: Number(e.target.value),
                            length: i,
                          })
                        }
                        sx={{
                          // input: { cursor: 'pointer' },
                          width: "100%",
                        }}
                      />
                    </Col>
                  </Row>
                  {/* <Row>
                                        <Col
                                            xl="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                            style={{ marginTop: "2.5%" }}
                                        >

                                            <Form.Group>
                                                {props.packagingDetail.length - 1 === i && (
                                                    <Button
                                                        title="Click to Add Packaging"
                                                        onClick={handleAddInput1}
                                                        disabled={props.packagingDetail[i].width === 0 ||
                                                            props.packagingDetail[i].length === 0 ||
                                                            props.packagingDetail[i].height === 0 ||
                                                            props.packagingDetail[i].weight === 0
                                                        }
                                                        style={{
                                                            color: 'white',
                                                            float: "right",

                                                        }}
                                                    >
                                                        <AddIcon />
                                                    </Button>
                                                    // <IconButton
                                                    //   title="Click to Add Demand"
                                                    //   onClick={handleAddInput1}
                                                    //   style={{
                                                    //    // float: "right",
                                                    //     color: "black",
                                                    //   }}
                                                    // >
                                                    //   <AddIcon />
                                                    // </IconButton>
                                                )}

                                                {props.packagingDetail.length !== 1 && (
                                                    <Button
                                                        title="Click to Remove Packaging"
                                                        onClick={() => handleRemoveInput1(i)}
                                                        style={{
                                                            float: "right",
                                                            color: "white",
                                                            marginRight: '2%',
                                                        }}
                                                    >
                                                        <RemoveIcon />
                                                    </Button>
                                                    // <IconButton
                                                    //   title="Click to Remove Demand"
                                                    //   onClick={() => handleRemoveInput1(i)}
                                                    //   style={{
                                                    //  //   float: "right",
                                                    //     color: "black",
                                                    //   }}
                                                    // >
                                                    //   <RemoveIcon />
                                                    // </IconButton>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row> */}
                  {/* <Row style={{ marginTop: "15px" }}>
                                    <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                            <TextField
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AddBoxIcon color="primary" />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                           kg
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type="text"
                                                id="outlined-basic"
                                                label="Weight"
                                                value={props.packagingDetail[i].weight}
                                                onChange={(e) => handleWeight(e,i)}

                                                sx={{
                                                    width: "100%",
                                                }}
                                            />
                                        </Col>
                                        <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                            <TextField

                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AddBoxIcon color="primary" />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                           kg
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type="text"
                                                id="outlined-basic"
                                                label="Acctual Weight"
                                                disabled
                                                value={props.packagingDetail[i].accWeight}
                                                onChange={(e) => props.setPackagingDetail({ key: "weight", value: Number(e.target.value), length: i })}

                                                sx={{
                                                    width: "100%",
                                                }}
                                            />
                                        </Col>
                                        <Col xl="3" lg="3" md="3" sm="6" xs="6">
                                            <TextField

                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AddBoxIcon color="primary" />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                           kg
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type="text"
                                                id="outlined-basic"
                                                label="Chargeable Weight"
                                                disabled
                                                value={props.packagingDetail[i].chWeight}
                                                onChange={(e) => props.setPackagingDetail({ key: "weight", value: Number(e.target.value), length: i })}
                                                sx={{
                                                    width: "100%",
                                                }}
                                            />
                                        </Col>
                                      
                                        <Col
                                            lg="3"
                                            md="3"
                                            sm="3"
                                            style={{ marginTop: "2.5%" }}
                                        >

                                            <Form.Group>
                                                {props.packagingDetail.length - 1 === i && (
                                                    <Button
                                                        title="Click to Add Packaging"
                                                        onClick={handleAddInput1}
                                                        disabled={props.packagingDetail[i].width === 0 ||
                                                            props.packagingDetail[i].length === 0 ||
                                                            props.packagingDetail[i].height === 0 ||
                                                            props.packagingDetail[i].weight === 0
                                                        }
                                                        style={{
                                                            color: 'white',
                                                            float: "right",

                                                        }}
                                                    >
                                                        <AddIcon />
                                                    </Button>
                                                )}

                                                {props.packagingDetail.length !== 1 && (
                                                    <Button
                                                        title="Click to Remove Packaging"
                                                        onClick={() => handleRemoveInput1(i)}
                                                        style={{
                                                            float: "right",
                                                            color: "white",
                                                            marginRight: '2%',
                                                        }}
                                                    >
                                                        <RemoveIcon />
                                                    </Button>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row> */}
                </Card>
              </>
            </React.Fragment>
          );
        })}
        <Row
          style={{
            backgroundColor: "#D9DDE0",
            marginTop: "10px",
            marginBottom: "10px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Col xl="3" lg="3" md="3" sm="4" xs="4">
            <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
              <b> Total Packages {props.packagingDetail.length}</b>
            </Typography>
          </Col>
          <Col xl="3" lg="3" md="3" sm="4" xs="4">
            <Typography style={{ fontFamily: "Public Sans,sans-serif" }}>
              <b>
                {" "}
                Total Weight{" "}
                {props.packagingDetail[0].weight === 0
                  ? "--------"
                  : sumBy(props.packagingDetail, "chWeight")}
              </b>
            </Typography>
          </Col>
        </Row>
      </Card>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPackagingDetail: (data) => dispatch(setAddPackagingDetail(data)),
    handleAddInputPackagingDetail: () =>
      dispatch(handleAddInputPackagingDetail()),
    handleAddInputPackagingDetailWithData: (data) =>
      dispatch(handleAddInputPackagingDetailWithData(data)),
    handleRemoveInputPackagingDetail: (data) =>
      dispatch(handleRemoveInputPackagingDetail(data)),
    handleNextStepMain: () => dispatch(nextSteps()),
    handleBackStepMain: () => dispatch(backSteps()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  packagingDetail: state.CargoShipmentReducer.packagingDetail,
  activeSteps: state.CargoShipmentReducer.activeSteps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2SelectPackaging);
