import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../../../../Images/logo.png";
import { groupBy, isEmpty, get, isUndefined } from "lodash";

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1
  },
  textStyles: {
    marginLeft: 20
  },
  textStyles1: {
    marginLeft: 30
  },
  image: {
    width: "30%"
  },
});

var groupedLevel3;
var keys;
const PDFLevel3 = (props) => (
  groupedLevel3 = groupBy(!isEmpty(props.array) && props.array, function (x) {
    return x.levelThreeData.level_one.name
  }),
  keys = !isEmpty(groupedLevel3) && Object.keys(groupedLevel3),

  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image
          style={styles.image}
          src={logo}
        />
        <Text style={{ textAlign: "center", padding: "5%" }}>companyName: Retro</Text>
        {!isEmpty(keys) ? keys.map((y, index) => {
          let x = get(groupedLevel3, `${y}`);
          return (
            <View key={index}>
              <Text>{x[0].levelThreeData.level_one.key}- {x[0].levelThreeData.level_one.name}</Text>
              {Object.keys(groupBy(x, y => y.levelThreeData.level_two.levelTwoName)) ? Object.keys(groupBy(x, y => y.levelThreeData.level_two.levelTwoName)).map((xyz, index1) => {
                let x1 = get(groupBy(x, y => y.levelThreeData.level_two.levelTwoName), `${xyz}`);
                return (
                  <View key={index1}>
                    <Text style={styles.textStyles}>{x1[0].levelThreeData.level_one.key + "-" + x1[0].levelThreeData.level_two.levelTwoKey}- {x1[0].levelThreeData.level_two.levelTwoName}</Text>
                    {!isEmpty(x1) ? x1.map((lvl2, index2) => {
                      return (
                        <View key={index2}>
                          <Text style={styles.textStyles1}>{lvl2.levelThreeData.allLvlKey}- {lvl2.levelThreeData.levelThreeName}        {!isUndefined(lvl2.amount) && lvl2.amount.total_amount}</Text>
                        </View>
                      );
                    }) : ""}
                  </View>
                );
              }) : ""}
            </View>
          );
        }) : ""}
      </View>
    </Page>
  </Document>
);
export default PDFLevel3;