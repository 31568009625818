import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button} from "react-bootstrap";
import "./add-levels.css";
import { getLevel1Data, uploadLevel2File } from "./accountsLevel.action";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import { isUndefined } from "lodash";
import Swal from 'sweetalert';

const Level2Upload = (props) => {
    const [level1Id, setLevel1Id] = useState();
    const [level2File, setLevel2File] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  
    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
          });
    }
  
    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
          });
    }

    function uploadFileLevel2() {
        props.uploadLevel2File({
            levelOneId: level1Id,
            file: level2File
        }, handleVisible, handleDangerVisible);
    }

    const getLevel1List = props.level1List.map((x) => { let data = { value: x.levelOneId, label: x.name }; return data });
    const handleChangeLevel1 = (selectedOption) => {
        setLevel1Id(selectedOption.value);
    };

    useEffect(() => {
    }, []);
    return (
        <>
            {props.isFetchinglevel1List ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select Level 1 </b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select Level 1..."
                                        onChange={(e) => handleChangeLevel1(e)}
                                        options={getLevel1List}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div style={{ paddingTop: "2%" }}>
                            <Row>
                                <Col xl="4" lg="4" md="2" sm="2" xs="2"></Col>
                                <Col xl="4" lg="4" md="4" sm="4" xs="4">
                                    <Form.Group>


                                        <Form.Label>
                                            <b style={{ fontSize: "x-large" }}>Upload Level2 File</b>
                                        </Form.Label>


                                        <input className="form-control" type="file" name="file" class="Upload File" id="upload file" onChange={(e) => setLevel2File(e.target.files[0])} />


                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className="sendDiv">
                           {isUndefined(level1Id) ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Save</Button> : <Button className="sendButton" onClick={uploadFileLevel2}>Save</Button>}

                        </div>
                    </div>

                </Container>

            )}

        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLevel1Data: () => dispatch(getLevel1Data()),
        uploadLevel2File: (data, handleVisible, handleDangerVisible) => dispatch(uploadLevel2File(data, handleVisible, handleDangerVisible))
    };
};
const mapStateToProps = (state, ownProps) => ({
    level1List: state.accountsLevelReducer.level1List,
    isAddingLevel2File: state.accountsLevelReducer.isAddingLevel2File,
    isnotAddingLevel2File: state.accountsLevelReducer.isnotAddingLevel2File.accountsLevelReducer,
    isFetchinglevel1List: state.accountsLevelReducer.isFetchinglevel1List,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Level2Upload);